import { getObjectUsers_API, getStaff_API } from '../../API/User'
import { putError } from "../../DB/Error/Error";
import { getUser, putUser } from '../../DB/User/User';
import { getObjectUser, putObjectUser } from '../../DB/User/ObjectUser';
export async function syncFromApiUsers() {
    let promises = []
    try {
        promises.push(syncFromApiUser_Staff('WORKERS'));
        promises.push(syncFromApiUser_ObjectUser());
        return await Promise.all(promises);
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiUser' }, 'Sync/FromAPI/User', 'syncFromApiUser')
        return Promise.resolve()
    }
}

async function syncFromApiUser_PutUser(data) {
    let oData = await getUser({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putUser(data)
}

async function syncFromApiUser_Staff(type) {
    try {
        const dataFromApi = await getStaff_API(type);
        if (!dataFromApi) {
            return;
        }
        const putProms = dataFromApi.map((data) => { return syncFromApiUser_PutUser(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiUser_Staff' }, 'Sync/FromAPI/User', 'syncFromApiUser')
        return Promise.resolve()
    }
}

async function syncFromApiUser_PutObjectUser(data) {
    let oData = await getObjectUser({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putObjectUser(data)
}

async function syncFromApiUser_ObjectUser() {
    try {
        const dataFromApi = await getObjectUsers_API();
        if (!dataFromApi) {
            return;
        }
        const putProms = dataFromApi.map((data) => { return syncFromApiUser_PutObjectUser(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiUser_ObjectUser' }, 'Sync/FromAPI/User', 'syncFromApiUser')
        return Promise.resolve()
    }
} 