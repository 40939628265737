import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_EventType } from '../DB/Event/EventType'
import { populateObject_ObjectEvent } from '../DB/Event/ObjectEvent'
import { putError } from '../DB/Error/Error.js';

export function getEventTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Event/getEventTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_EventType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'EventType',
                    'getEventTypes_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getObjectEvents_API() {
    const userGuid = getAuthenticatedUserGuid();
    let workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Event/getObjectEvents', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_ObjectEvent(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'Event',
                    'getObjectEvents_API'
                )
                return Promise.reject(error)
            }
        )
}

export function upsertObjectEvent_API(object) {
    const userGuid = getAuthenticatedUserGuid()

    object.active = object.active === 'true';

    return Api.post('/Event/upsertObjectEvent',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data == String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oData = populateObject_ObjectEvent(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Event',
                    'upsertObjectEvent_API'
                )
                return Promise.reject(error)
            }
        )
}