import { db } from "../db";
import { putError } from "../Error/Error";
import { DumpDocket } from "../../Model/DumpDocket/DumpDocket";
import { getObjectPhotos } from "../Photo/ObjectPhoto";

export function populateObject_DumpDocket(data) {
    if (!data) {
        return null;
    }
    let oDumpDocket = new DumpDocket(
        data.dumpDocketGuid,
        data.driverID,
        data.vehicleID,
        data.supplierID,
        data.supplierItemID,
        data.itemID,
        data.docketNumber,
        data.invoiceNo,
        data.dumpedDate,
        data.actualWeight,
        (typeof data.isComplete === 'string' && data.isComplete === 'true') || (typeof data.isComplete === 'boolean' && data.isComplete) ? 'true' : 'false',
        (typeof data.deleted === 'string' && data.deleted === 'true') || (typeof data.deleted === 'boolean' && data.deleted) ? 'true' : 'false',
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oDumpDocket;

}

async function populateDumpDocketLinkedObjects(oDumpDocket) {
    if (!oDumpDocket) {
        return oDumpDocket
    }
    try{
        let proms = []
        proms.push(getObjectPhotos(['objectType', 'objectGuid', 'active'], ['DumpDocket', oDumpDocket.dumpDocketGuid, 'true'])) 
        const results = await Promise.all(proms);
        oDumpDocket.objectPhotos = results.at(0); 
        return oDumpDocket
    }
    catch(error){
        await putError(error,oDumpDocket,'DumpDocket.js','populateDumpDocketLinkedObjects') 
        return oDumpDocket
    } 
}

async function getDumpDocket_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const dumpDocket = await db.dump_dockets.get(keyValueObject);
    let oDumpDocket = populateObject_DumpDocket(dumpDocket);
    return oDumpDocket
}

async function getDumpDockets_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const dumpDockets = await db.dump_dockets
        .where(where)
        .equals(equals)
        .toArray();
    const oDumpDockets = dumpDockets.map((dumpDocket) => { return populateObject_DumpDocket(dumpDocket); })
    return oDumpDockets;

}

async function putDumpDocket_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.dump_dockets.put(oData, oData.id);
    return;

}

async function deleteDumpDockets_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.dump_dockets
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getDumpDocket(keyValueObject) {
    try {
        let oData = await getDumpDocket_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'DumpDocket.js', 'getDumpDocket')
        return
    }
}

export async function getFullDumpDocket(keyValueObject){
    try {
        let oData = await getDumpDocket(keyValueObject)
        oData = await populateDumpDocketLinkedObjects(oData)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'DumpDocket.js', 'getFullDumpDocket')
        return
    } 
}

export async function getDumpDockets(where, equals) {
    try {
        let oData = await getDumpDockets_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'DumpDocket.js', 'getDumpDockets')
        return
    }
}

export async function putDumpDocket(oData) {
    try {
        await putDumpDocket_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'DumpDocket.js', 'putDumpDocket')
        return
    }
}

export async function deleteDumpDockets(where, equals) {
    try {
        await deleteDumpDockets_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'DumpDocket.js', 'deleteDumpDockets')
        return
    }
}
