
import { getJobSafetyCategories_API, getJobSafetyRisks_API, getJobSafetyHazards_API, getJobSafetyDeviceMappings_API, getJobSafetyReportHeaders_API, getJobSafetyDeviceReports_API, getJobSafetyDeviceReportActions_API, getJobSafetyReportAcknowledgements_API } from '../../API/JobSafetyReport'
import { putError } from "../../DB/Error/Error";
import { getJobSafetyCategory, putJobSafetyCategory } from '../../DB/JobSafetyReport/JobSafetyCategory';
import { getJobSafetyHazard, putJobSafetyHazard } from '../../DB/JobSafetyReport/JobSafetyHazard';
import { getJobSafetyRisk, putJobSafetyRisk } from '../../DB/JobSafetyReport/JobSafetyRisk';
import { getJobSafetyDeviceMapping, putJobSafetyDeviceMapping } from '../../DB/JobSafetyReport/JobSafetyDeviceMapping';
import { getJobSafetyReportHeader, putJobSafetyReportHeader } from '../../DB/JobSafetyReport/JobSafetyReportHeader';
import { getJobSafetyDeviceReport, putJobSafetyDeviceReport } from '../../DB/JobSafetyReport/JobSafetyDeviceReport';
import { getJobSafetyDeviceReportAction, putJobSafetyDeviceReportAction } from '../../DB/JobSafetyReport/JobSafetyDeviceReportAction';
import { getJobSafetyReportAcknowledgement, putJobSafetyReportAcknowledgement } from '../../DB/JobSafetyReport/JobSafetyReportAcknowledgement';

export async function syncFromApiJobSafetyReports() {
    let promises = []
    try {
        promises.push(syncFromApiJobSafetyReport_JobSafetyCategories());
        promises.push(syncFromApiJobSafetyReport_JobSafetyRisks());
        promises.push(syncFromApiJobSafetyReport_JobSafetyHazards());
        promises.push(syncFromApiJobSafetyReport_JobSafetyDeviceMappings());
        promises.push(syncFromApiJobSafetyReport_JobSafetyReportHeaders());
        promises.push(syncFromApiJobSafetyReport_JobSafetyDeviceReports());
        promises.push(syncFromApiJobSafetyReport_JobSafetyDeviceReportActions());
        promises.push(syncFromApiJobSafetyReport_JobSafetyReportAcknowledgements());
        return await Promise.all(promises);
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReports' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}

async function syncFromApiJobSafetyReport_PutJobSafetyCategory(data) {
    let oData = await getJobSafetyCategory({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putJobSafetyCategory(data)
}

async function syncFromApiJobSafetyReport_JobSafetyCategories() {
    try {
        const dataFromApi = await getJobSafetyCategories_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiJobSafetyReport_PutJobSafetyCategory(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReport_JobSafetyCategories' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}

async function syncFromApiJobSafetyReport_PutJobSafetyRisk(data) {
    let oData = await getJobSafetyRisk({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putJobSafetyRisk(data)
}

async function syncFromApiJobSafetyReport_JobSafetyRisks() {
    try {
        const dataFromApi = await getJobSafetyRisks_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiJobSafetyReport_PutJobSafetyRisk(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReport_JobSafetyRisks' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}

async function syncFromApiJobSafetyReport_PutJobSafetyHazard(data) {
    let oData = await getJobSafetyHazard({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putJobSafetyHazard(data)
}

async function syncFromApiJobSafetyReport_JobSafetyHazards() {
    try {
        const dataFromApi = await getJobSafetyHazards_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiJobSafetyReport_PutJobSafetyHazard(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReport_JobSafetyHazards' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}

async function syncFromApiJobSafetyReport_PutJobSafetyDeviceMapping(data) {
    let oData = await getJobSafetyDeviceMapping({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putJobSafetyDeviceMapping(data)
}

async function syncFromApiJobSafetyReport_JobSafetyDeviceMappings() {
    try {
        const dataFromApi = await getJobSafetyDeviceMappings_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiJobSafetyReport_PutJobSafetyDeviceMapping(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReport_JobSafetyDeviceMappings' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}

async function syncFromApiJobSafetyReport_PutJobSafetyReportHeader(data) {
    let oData = await getJobSafetyReportHeader({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putJobSafetyReportHeader(data)
}

async function syncFromApiJobSafetyReport_JobSafetyReportHeaders() {
    try {
        const dataFromApi = await getJobSafetyReportHeaders_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiJobSafetyReport_PutJobSafetyReportHeader(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReport_JobSafetyReportHeaders' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}

async function syncFromApiJobSafetyReport_PutJobSafetyDeviceReport(data) {
    let oData = await getJobSafetyDeviceReport({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putJobSafetyDeviceReport(data)
}

async function syncFromApiJobSafetyReport_JobSafetyDeviceReports() {
    try {
        const dataFromApi = await getJobSafetyDeviceReports_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiJobSafetyReport_PutJobSafetyDeviceReport(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReport_JobSafetyDeviceReports' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}

async function syncFromApiJobSafetyReport_PutJobSafetyDeviceReportAction(data) {
    let oData = await getJobSafetyDeviceReportAction({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putJobSafetyDeviceReportAction(data)
}

async function syncFromApiJobSafetyReport_JobSafetyDeviceReportActions() {
    try {
        const dataFromApi = await getJobSafetyDeviceReportActions_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiJobSafetyReport_PutJobSafetyDeviceReportAction(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReport_JobSafetyDeviceReportActions' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}

async function syncFromApiJobSafetyReport_PutJobSafetyReportAcknowledgement(data) {
    let oData = await getJobSafetyReportAcknowledgement({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putJobSafetyReportAcknowledgement(data)
}

async function syncFromApiJobSafetyReport_JobSafetyReportAcknowledgements() {
    try {
        const dataFromApi = await getJobSafetyReportAcknowledgements_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiJobSafetyReport_PutJobSafetyReportAcknowledgement(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiJobSafetyReport_JobSafetyReportAcknowledgements' }, 'Sync/FromAPI/JobSafetyReport', 'syncFromApiJobSafetyReports')
        return Promise.resolve()
    }
}