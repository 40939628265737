import { db } from "../db";
import { putError } from "../Error/Error";
import { PrestartMeeting } from "../../Model/Prestart/PrestartMeeting";
import { getPrestartTasks } from "./PrestartTask";

const formatThreeWayBool =(value)=>{
    if(value === null || typeof value === 'undefined'){
        return value;
    }
    else if (typeof value === 'string'){
        return value === 'true' ? 'true' : 'false'
    }
    else if (typeof value === 'boolean'){
        return value ? 'true' : 'false'
    }
    return value
}

export function populateObject_PrestartMeeting(data) {
    if (!data) {
        return null
    }
    let oData = new PrestartMeeting(  
        data.prestartMeetingID,
        data.prestartMeetingGuid,
        data.userID,
        data.jobScheduleID,
        data.jobSupervisorUserID,
        data.meetingHeldByUserID,
        data.completionDate,
        data.scheduledStartDate,
        data.addressID,
        formatThreeWayBool(data.ctTrained),
        formatThreeWayBool(data.ctWearingPPE),
        formatThreeWayBool(data.ctCrewFit),
        formatThreeWayBool(data.ctCrewAuthourized),
        formatThreeWayBool(data.ctCorrectEquipment),
        formatThreeWayBool(data.ctToolsCondition), 
        formatThreeWayBool(data.hiHazardRegistered),
        formatThreeWayBool(data.hiWorksite),
        formatThreeWayBool(data.hiCardinalRule),
        formatThreeWayBool(data.hiPhysical),
        formatThreeWayBool(data.hiErgonomic),  
        formatThreeWayBool(data.envDamage),
        formatThreeWayBool(data.envImpact),   
        formatThreeWayBool(data.qualInspected),
        formatThreeWayBool(data.qualMaterial),
        formatThreeWayBool(data.qualEquipment),  
        formatThreeWayBool(data.prmtSpaceEntry),
        formatThreeWayBool(data.prmtWorkingHeight),
        formatThreeWayBool(data.prmtHotWork),
        formatThreeWayBool(data.prmtWorkingAround),
        formatThreeWayBool(data.prmtNZWorksafe), 
 
        data.statusGuid, 
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false', 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.mode,
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID
    )
    return oData
}

async function populatePrestartMeetingLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try{
        let proms = []
        proms.push(getPrestartTasks([ 'prestartMeetingGuid', 'active'], [oData.prestartMeetingGuid, 'true'])) 
        const results = await Promise.all(proms);
        oData.objectUsers = results.at(0);
        oData.coordinates = results.at(1);
        return oData
    }
    catch(error){
        await putError(error,oData,'PrestartMeeting.js','populatePrestartMeetingLinkedObjects') 
        return oData
    } 
}

async function getPrestartMeeting_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.prestart_meetings.get(keyValueObject);
    const oData = populateObject_PrestartMeeting(data)
    return oData
}

async function getPrestartMeetings_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.prestart_meetings
        .where(where)
        .equals(equals)
        .toArray();
    const oData = data.map((x) => { return populateObject_PrestartMeeting(x) });
    return oData
}

async function putPrestartMeeting_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.prestart_meetings.put(oData, oData.id);
    return;
}

async function deletePrestartMeetings_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.prestart_meetings
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getPrestartMeeting(keyValueObject) {
    try {
        let oData = await getPrestartMeeting_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'PrestartMeeting.js', 'getPrestartMeeting')
        return
    }
}

export async function getFullPrestartMeeting(keyValueObject) {
    try {
        let oData = await getPrestartMeeting(keyValueObject);
        oData = await populatePrestartMeetingLinkedObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error,keyValueObject,'Address.js','getFullAddress') 
        return Promise.resolve(null);
    }
}

export async function getPrestartMeetings(where, equals) {
    try {
        let oData = await getPrestartMeetings_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'PrestartMeeting.js', 'getPrestartMeetings')
        return
    }
}

export async function getFullPrestartMeetings(where, equals) {
    try {
        const oData = await getPrestartMeetings(where, equals)
        let proms = oData.map((x) => { return populatePrestartMeetingLinkedObjects(x) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'PrestartMeeting.js','getFullPrestartMeetings')  
        return Promise.resolve([]);
    }
}
export async function putPrestartMeeting(oData) {
    try {
        await putPrestartMeeting_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'PrestartMeeting.js', 'putPrestartMeeting')
        return
    }
}

export async function deletePrestartMeetings(where, equals) {
    try {
        await deletePrestartMeetings_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'PrestartMeeting.js', 'deletePrestartMeetings')
        return
    }
}
