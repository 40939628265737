import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_ReportDefinition } from '../DB/Reporting/ReportDefinition'
import { populateObject_ReportData } from '../DB/Reporting/ReportData'
import { putError } from '../DB/Error/Error.js';

export function getReportDefinitions_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Report/getReportDefinitions', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_ReportDefinition(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Report',
                    'getReportDefinitions_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getReportData_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();


    return Api.get('/Report/getReportData', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate,
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                data.reportDefinition = typeof data.reportDefinition === 'string' ? JSON.parse(data.reportDefinition) : data.reportDefinition
                let oData = populateObject_ReportData(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate,
                    },
                    'Report',
                    'getReportData_API'
                )
                return Promise.reject(error)
            }
        )
}


export function upsertReportData_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.isComplete = object.isComplete === 'true';
    object.active = object.active === 'true';
    delete object['reportDefinition']; 
    return Api.post('/Report/upsertReportData',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oData = populateObject_ReportData(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Report',
                    'upsertReportData_API'
                )
                return Promise.reject(error)
            }
        )
}