import BaseObject from "../Base/BaseObject";  
export default class CodeType extends BaseObject {
    constructor(
        codeTypeID,
        codeTypeGuid,
        codeType,
        codeTypeSystemName,
        companyID,
        regionID 
    ) {
        super(
            codeTypeID,
            codeTypeGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        ); 
        this.codeTypeID = codeTypeID;
        this.codeTypeGuid = codeTypeGuid;
        this.codeType = codeType;
        this.codeTypeSystemName = codeTypeSystemName;

    }

}