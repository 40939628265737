import BaseObject from "../Base/BaseObject";  

export default class DeviceProperty extends BaseObject {
    constructor(
        devicePropertyID,
        devicePropertyGuid,
        deviceTypeGuid,
        description,
        control,
        mobileEnabled, 
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID 
    ) {
        super(
            devicePropertyID,
            devicePropertyGuid,
            'true',
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.deviceTypeGuid = deviceTypeGuid;
        this.devicePropertyGuid = devicePropertyGuid;
        this.description = description;
        this.control = control;
        this.mobileEnabled = mobileEnabled;

    }

}