import { upsertJobReferences_API } from "../../API/JobReference";
import { putError } from "../../DB/Error/Error";
import { getJobReferences, putJobReference } from "../../DB/JobReference/JobReference";

export async function syncToApiJob(){
    let upsertProms = []; 
    upsertProms.push(syncToApiJobReferences()) 
    await Promise.all(upsertProms);
    return;
}
async function syncToApiJobReferences(){ 
    try{ 
        const dataToApi = await getJobReferences('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertJobReferences_API(data)})); 
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putJobReference(data)}));  
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiJobReferences'}, 'Sync/ToAPI/syncToApiJob', 'syncToApiJob')
        return Promise.resolve();
    }
}