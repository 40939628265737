import { Suspense,useEffect } from "react";
import Routes from "./Routes/Index";
import './Assets/Css/App.min.css';
import "./Assets/Css/dx.material.foxy.css";
import 'devextreme/dist/css/dx.light.css';
import "./Assets/Css/foundation.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons' 
import { OnlineProvider } from './Provider/onlineProvider';
import { APIProvider } from '@vis.gl/react-google-maps';
import { AuthProvider } from "./Provider/authProvider";
import { SyncStatusProvider } from './Provider/syncingStatusProvider';
import { LocationProvider } from './Provider/locationProvider';
import PageProvider from './Provider/pageProvider';
import { getAuthenticatedUser } from "./Utils/AuthUtils";
import ErrorBoundary from "./Components/ErrorBoundary";
import Loading from "./Components/Loading";
import WorkOrderProvider from "./Provider/workOrderProvider";

library.add(fas, far, fab)

function App() {  
     
    const online = navigator.onLine 
    const syncing = localStorage.getItem("isSyncing") == null ?false: localStorage.getItem("isSyncing") === 'true';
    const syncMode = localStorage.getItem("syncMode")
    const authToken = localStorage.getItem("authToken") === 'null' ? null : localStorage.getItem("authToken");
    const oAuthUser = getAuthenticatedUser();
    const refreshAuthToken = localStorage.getItem("refreshAuthToken");
    useEffect(() => {
        function fetchData() { 
        }
        fetchData()
    }); 
    return ( 
        <ErrorBoundary>
            <APIProvider apiKey={process.env.REACT_APP_Google_Map_APK_Key}>
                    <OnlineProvider online={online}>
                        <AuthProvider  authToken={authToken} refreshAuthToken={refreshAuthToken} user={oAuthUser}>
                            <Suspense fallback={<div className="small-12 grid-x" style={{height:'100%'}}><Loading/></div>}>
                                <PageProvider>
                                    <WorkOrderProvider>
                                        <LocationProvider>
                                            <SyncStatusProvider syncing={syncing} mode={syncMode}>
                                                    <Routes />
                                            </SyncStatusProvider>
                                        </LocationProvider>                                       
                                    </WorkOrderProvider> 
                                </PageProvider> 
                            </Suspense>
                        </AuthProvider>
                    </OnlineProvider>
                </APIProvider>            
        </ErrorBoundary>
 
    );
    

}

export default App;