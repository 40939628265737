import { lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../Provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";  

const Home = lazy(()=>import('../Pages/Home'))
const WorkOrderList = lazy(()=>import('../Pages/WorkOrderList'))
const Login  = lazy(()=>import("../Pages/Login"));  
const History = lazy(()=>import( "../Pages/History"));
const VehicleLoads = lazy(()=>import("../Pages/VehicleLoads")); 
const WorkOrderMap = lazy(()=>import("../Pages/WorkOrderMap"));
const Reporting = lazy(()=>import("../Pages/Report")); 
const LoadWaste = lazy(()=>import( "../Pages/LoadWaste"));
const DumpWaste = lazy(()=>import( "../Pages/DumpWaste"));
const HazardForm = lazy(()=>import( "../Pages/HazardForm"));
const PreStartMeeting = lazy(()=>import( "../Pages/PrestartMeeting")); 
const JobSafetyReport = lazy(()=>import( "../Pages/JobSafetyReport"));

const Routes = () => {
    const { token } = useAuth();

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
            children: [
                {
                    path: "/home",
                    element: <Home/>
                },
                {
                    path: "/reporting",
                    element: <Reporting />
                },
                {
                    path: "/workOrderMap",
                    element: <WorkOrderMap />
                },
                {
                    path: "/workOrderList",
                    element: <WorkOrderList />
                },
                {
                    path: "/loads",
                    element: <VehicleLoads />
                },
                {
                    path: "/history",
                    element: <History />
                },
                {
                    path: "/login",
                    element: <Login />,
                },
                {
                    path: "/loadWaste",
                    element: <LoadWaste />
                },
                {
                    path: "/dumpWaste",
                    element: <DumpWaste />
                },
                {
                    path: "/hazardForm",
                    element: <HazardForm />
                },
                {
                    path: "/prestartMeeting",
                    element: <PreStartMeeting />
                },
                {
                    path: "/jobSafetyReport",
                    element: <JobSafetyReport />
                },
            ],
        },
        {
            path: "",
            element: <Home />
        }
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
        {
            path: "/login",
            element: <Login />,
        },
    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;