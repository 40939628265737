import { getUnitTypes_API } from '../../API/UnitType'
import { putError } from "../../DB/Error/Error";
import { getUnitType, putUnitType } from "../../DB/UnitType/UnitType";
export async function syncFromApiUnitTypes(){
    let promises = []
    try{
        promises.push(syncFromApiUnitType_UnitTypes()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiUnitType'}, 'Sync/FromAPI/UnitType', 'syncFromApiUnitType')
        return Promise.resolve()
    } 
}

async function syncFromApiUnitType_PutUnitType(data){
    let oData =  await getUnitType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putUnitType(data)
}

async function syncFromApiUnitType_UnitTypes(){ 
    try{
        const dataFromApi = await getUnitTypes_API(); 
        if(!dataFromApi){
            return;
        } 
        await Promise.all(dataFromApi.map((data)=>{return syncFromApiUnitType_PutUnitType(data)}));
        return; 
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiUnitType_UnitTypes'}, 'Sync/FromAPI/UnitType', 'syncFromApiUnitType')
        return Promise.resolve()
    } 
}