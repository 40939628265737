
import { getGenericObjects_API } from '../../API/GenericObject' 
import { putError } from "../../DB/Error/Error";
import { getGenericObject, putGenericObject } from '../../DB/GenericObject/GenericObject'; 
export async function syncFromApiGenericObjects(){
    let promises = []
    try{
        promises.push(syncFromApiGenericObject_GenericObjects()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiGenericObject'}, 'Sync/FromAPI/GenericObject', 'syncFromApiGenericObject')
        return Promise.resolve()
    } 
}

async function syncFromApiGenericObject_PutGenericObject(data){
    let oData =  await getGenericObject({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putGenericObject(data)
}

async function syncFromApiGenericObject_GenericObjects(){ 
    try{
        const dataFromApi = await getGenericObjects_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiGenericObject_PutGenericObject(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from ssyncFromApiGenericObject_GenericObject'}, 'Sync/FromAPI/GenericObject', 'syncFromApiGenericObject')
        return Promise.resolve()
    } 
}
 