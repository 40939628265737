import { db } from "../db";
import { putError } from "../Error/Error";
import ServiceType from '../../Model/ServiceType/ServiceType'

export function populateObject_ServiceType(serviceType) {
    if (!serviceType) {
        return null
    }
    let oServiceType = new ServiceType(
        serviceType.serviceTypeID,
        serviceType.serviceTypeGuid,
        serviceType.serviceName,
        serviceType.defaultPrice,
        serviceType.hasDRReport,
        serviceType.wasteRemoved,
        serviceType.companyID,
        serviceType.regionID
    )
    return oServiceType;
}

async function getServiceType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const serviceType = await db.service_types.get(keyValueObject);
    let oServiceType = populateObject_ServiceType(serviceType)
    return oServiceType;
}

async function getServiceTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const serviceTypes = await db.service_types
        .where(where)
        .equals(equals)
        .toArray();
    let oServiceTypes = serviceTypes.map((serviceType) => { return populateObject_ServiceType(serviceType) }).sort((a, b) => a.serviceName.localeCompare(b.serviceName));
    return oServiceTypes;
}

async function getAllServiceTypes_db() {
    const serviceTypes = await db.service_types
        .toArray();
    let oServiceTypes = serviceTypes.map((serviceType) => { return populateObject_ServiceType(serviceType) }).sort((a, b) => a.serviceName.localeCompare(b.serviceName));
    return oServiceTypes;
}

async function putServiceType_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.service_types.put(oData, oData.id);
    return;
}

async function deleteServiceTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.service_types
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getServiceType(keyValueObject) {
    try {
        let oData = await getServiceType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ServiceType.js', 'getServiceType')
        return
    }
}

export async function getServiceTypes(where, equals) {
    try {
        let oData = await getServiceTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ServiceType.js', 'getServiceTypes')
        return
    }
}

export async function getAllServiceTypes(where, equals) {
    try {
        let oData = await getAllServiceTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ServiceType.js', 'getServiceTypes')
        return
    }
}


export async function putServiceType(oData) {
    try {
        await putServiceType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ServiceType.js', 'putServiceType')
        return
    }
}

export async function deleteServiceTypes(where, equals) {
    try {
        await deleteServiceTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ServiceType.js', 'deleteServiceTypes')
        return
    }
}

