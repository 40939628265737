import { db } from "../db";
import { putError } from "../Error/Error";
import { JobSafetyDeviceReportAction } from "../../Model/JobSafetyReport/JobSafetyDeviceReportAction";
import { getJobSafetyRisk } from "./JobSafetyRisk";

export function populateObject_JobSafetyDeviceReportAction(data) {
    if (!data) {
        return null
    }
    let oData = new JobSafetyDeviceReportAction(
        data.jobSafetyDeviceReportActionGuid,
        data.jobSafetyDeviceReportGuid,
        data.deviceTypeID,
        data.deviceTypeGuid,
        data.hazardDescription,
        data.hazardControls,
        data.riskAssessment,
        data.categoryName,
        data.unControlledRiskID,
        data.unControlledScore,
        data.controlledRiskID,
        data.controlledScore,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oData
}

async function populateJobSafetyDeviceReportActionObjects(oData) {
    if (!oData) {
        return oData
    }
    try {
        oData.unControlledRisk = await getJobSafetyRisk({ id: oData.unControlledRiskID });
        oData.controlledRisk = await getJobSafetyRisk({ id: oData.controlledRiskID });
        return oData
    }
    catch (error) {
        await putError(error, oData, 'JobSafetyDeviceReportAction.js', 'populateJobSafetyDeviceReportActionObjects')
        return oData
    }
}

async function getJobSafetyDeviceReportAction_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.job_safety_device_actions.get(keyValueObject);
    let oData = populateObject_JobSafetyDeviceReportAction(data);
    return oData;
}

async function getJobSafetyDeviceReportActions_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.job_safety_device_actions
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_JobSafetyDeviceReportAction(object) })
    return oData;
}

async function putJobSafetyDeviceReportAction_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for (var i in oData) {
        if (typeof oData[i] === "object" && oData[i] !== null) {
            delete oData[i];
        }
    }
    await db.job_safety_device_actions.put(oData, oData.id)
    return
}

async function deleteJobSafetyDeviceReportActions_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_safety_device_actions
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getJobSafetyDeviceReportAction(keyValueObject) {
    try {
        let oData = await getJobSafetyDeviceReportAction_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobSafetyDeviceReportAction.js', 'getJobSafetyDeviceReportAction')
        return
    }
}

export async function getFullJobSafetyDeviceReportAction(keyValueObject) {
    try {
        let oData = await getJobSafetyDeviceReportAction(keyValueObject);
        oData = await populateJobSafetyDeviceReportActionObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error, keyValueObject, 'JobSafetyDeviceReportAction.js', 'getFullJobSafetyDeviceReportAction')
        return Promise.resolve(null);
    }
}

export async function getJobSafetyDeviceReportActions(where, equals) {
    try {
        let oData = await getJobSafetyDeviceReportActions_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyDeviceReportAction.js', 'getJobSafetyDeviceReportActions')
        return
    }
}

export async function getFullJobSafetyDeviceReportActions(where, equals) {
    try {
        const oData = await getJobSafetyDeviceReportActions(where, equals)
        let proms = oData.map((object) => { return populateJobSafetyDeviceReportActionObjects(object) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error, { where, equals }, 'JobSafetyDeviceReportAction.js', 'getFullJobSafetyDeviceReportActions')
        return Promise.resolve([]);
    }
}

export async function putJobSafetyDeviceReportAction(oData) {
    try {
        await putJobSafetyDeviceReportAction_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobSafetyDeviceReportAction.js', 'putJobSafetyDeviceReportAction')
        return
    }
}

export async function deleteJobSafetyDeviceReportActions(where, equals) {
    try {
        await deleteJobSafetyDeviceReportActions_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyDeviceReportAction.js', 'deleteJobSafetyDeviceReportActions')
        return
    }
}
