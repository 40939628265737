import BaseObject from "../Base/BaseObject";   
export default class AssemblyPoint extends BaseObject {
    constructor( 
        assemblyPointID,
        assemblyPointGuid,
        name,
        active, 
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID 
     )
    {
        super(
            assemblyPointID,
            assemblyPointGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''         
        )
        this.assemblyPointID = assemblyPointID;
        this.assemblyPointGuid = assemblyPointGuid;
        this.name = name;
    }   

}
