import { Navigate, Outlet } from "react-router-dom"; 
import { AuthContext } from "../Provider/authProvider";
import { useContext } from "react";

export const ProtectedRoute = () => {
    const { token } = useContext(AuthContext) 
    // Check if the user is authenticated
    if (!token) {
        // If not authenticated, redirect to the login page 
        return <Navigate to="/login" />;
    }

    // If authenticated, render the child routes
    return <Outlet />;
};