import dayjs from 'dayjs'
import { ping } from '../API/Auth'          
import { syncToApiChangeHistory } from '../Sync/ToApi/ChangeHistory'
import { syncToApiObjectUsers } from '../Sync/ToApi/ObjectUser'
import { syncToApiObjectEvents } from '../Sync/ToApi/ObjectEvent'
import { syncToApiObjectVehicles } from '../Sync/ToApi/ObjectVehicle'
import { syncToApiObjectMarks } from '../Sync/ToApi/ObjectMark'
import { syncToApiObjectMeasures } from '../Sync/ToApi/ObjectMeasure'
import { syncToApiObjectCodes } from '../Sync/ToApi/ObjectCode'
import { syncToApiObjectPhotos } from '../Sync/ToApi/ObjectPhoto'
import { syncToApiObjectSignatures } from '../Sync/ToApi/ObjectSignature'
import { syncToApiHazardForms } from '../Sync/ToApi/HazardForm'
import { syncToApiReportData } from '../Sync/ToApi/ReportData' 
import { syncToApiPrestart } from '../Sync/ToApi/Prestart'
import { syncToApiJobSafetyReports } from '../Sync/ToApi/JobSafetyReport'
import { syncToApiJob } from '../Sync/ToApi/Job'
import { syncToApiWaste } from '../Sync/ToApi/Waste'

import { syncFromApiAddresses } from '../Sync/FromApi/Address'
import { syncFromApiAssemblyPoints } from '../Sync/FromApi/AssemblyPoint'
import { syncFromApiCodes } from '../Sync/FromApi/Code'
import { syncFromApiCoordinates } from '../Sync/FromApi/Coordinate'
import { syncFromApiDevices } from '../Sync/FromApi/Device'
import { syncFromApiEquipments } from '../Sync/FromApi/Equipment'
import { syncFromApiEvents } from '../Sync/FromApi/Event'
import { syncFromApiFlags } from '../Sync/FromApi/Flag'
import { syncFromApiGenericObjects } from '../Sync/FromApi/GenericObject'
import { syncFromApiHazards } from '../Sync/FromApi/Hazard'
import { syncFromApiHazardForms } from '../Sync/FromApi/HazardForm'
import { syncFromApiItems } from '../Sync/FromApi/Item'
import { syncFromApiJobs } from '../Sync/FromApi/Job'
import { syncFromApiJobSafetyReports } from '../Sync/FromApi/JobSafetyReport'
import { syncFromApiMarks } from '../Sync/FromApi/Mark'
import { syncFromApiMeasures } from '../Sync/FromApi/Measure'
import { syncFromApiPhotos } from '../Sync/FromApi/Photo'
import { syncFromApiPrestarts } from '../Sync/FromApi/Prestart'
import { syncFromApiReports } from '../Sync/FromApi/Report'
import { syncFromApiServices } from '../Sync/FromApi/Service'
import { syncFromApiSignatures } from '../Sync/FromApi/Signature'
import { syncFromApiStatuses } from '../Sync/FromApi/Status'
import { syncFromApiSuppliers } from '../Sync/FromApi/Supplier'
import { syncFromApiUnitTypes } from '../Sync/FromApi/UnitType'
import { syncFromApiUsers } from '../Sync/FromApi/User'
import { syncFromApiVehicles } from '../Sync/FromApi/Vehicle'
import { syncFromApiWastes } from '../Sync/FromApi/Waste'
import { syncFromApiWorkOrders } from '../Sync/FromApi/WorkOrder'

import { putError } from '../DB/Error/Error'

import { deleteAddress } from '../DB/Address/Address'
import { deleteAddressBranch } from '../DB/Address/AddressBranch'
import { deleteAddressDevice } from '../DB/Address/AddressDevice'
import { deleteAddressDeviceProperty } from '../DB/Address/AddressDeviceProperty'
import { deleteAssemblyPoints } from '../DB/AssemblyPoint/AssemblyPoint'
import { deleteChangeHistory } from '../DB/ChangeHistory/ChangeHistory'
import { deleteCodeTypes } from '../DB/Code/CodeType'
import { deleteCodes } from '../DB/Code/Code'
import { deleteObjectCodes } from '../DB/Code/ObjectCode'
import { deleteObjectCoordinates } from '../DB/Coordinate/ObjectCoordinate'
import { deleteDeviceTypes } from '../DB/Device/DeviceType'
import { deleteDeviceProperties } from '../DB/Device/DeviceProperty'
import { deleteEquipments } from '../DB/Equipment/Equipment'
import { deleteObjectEquipments } from '../DB/Equipment/ObjectEquipment'
import { deleteEventTypes } from '../DB/Event/EventType'
import { deleteObjectEvents } from '../DB/Event/ObjectEvent'
import { deleteFlags } from '../DB/Flag/Flag'
import { deleteGenericObjects } from '../DB/GenericObject/GenericObject'
import { deleteHazardInfos } from '../DB/Hazard/HazardInfo'
import { deleteHazardControls } from '../DB/Hazard/HazardControl'
import { deleteHazardSubstances } from '../DB/Hazard/HazardSubstance'
import { deleteHazardForms } from '../DB/HazardForm/HazardForm'
import { deleteHazardFormHazardInfos } from '../DB/HazardForm/HazardFormHazardInfo'
import { deleteHazardFormSubstances } from '../DB/HazardForm/HazardFormSubstance'
import { deleteHazardFormUserAcknowledgements } from '../DB/HazardForm/HazardFormUserAcknowledgement'
import { deleteItems } from '../DB/Item/Item'
import { deleteJobTypes } from '../DB/JobType/JobType'
import { deleteScheduledJobs } from '../DB/Scheduledjob/Scheduledjob'
import { deleteJobReferences } from '../DB/JobReference/JobReference'
import { deleteJobSafetyCategorys } from '../DB/JobSafetyReport/JobSafetyCategory'
import { deleteJobSafetyHazards } from '../DB/JobSafetyReport/JobSafetyHazard'
import { deleteJobSafetyRisks } from '../DB/JobSafetyReport/JobSafetyRisk'
import { deleteJobSafetyDeviceMappings } from '../DB/JobSafetyReport/JobSafetyDeviceMapping'
import { deleteJobSafetyReportHeaders } from '../DB/JobSafetyReport/JobSafetyReportHeader'
import { deleteJobSafetyDeviceReports } from '../DB/JobSafetyReport/JobSafetyDeviceReport'
import { deleteJobSafetyDeviceReportActions } from '../DB/JobSafetyReport/JobSafetyDeviceReportAction'
import { deleteJobSafetyReportAcknowledgements } from '../DB/JobSafetyReport/JobSafetyReportAcknowledgement'
import { deleteMarkTypes } from '../DB/Mark/MarkType'
import { deleteObjectMarks } from '../DB/Mark/ObjectMark'
import { deleteMeasureTypes } from '../DB/Measure/MeasureType'
import { deleteMeasureTypeUnitTypes } from '../DB/Measure/MeasureTypeUnitType'
import { deleteObjectMeasures } from '../DB/Measure/ObjectMeasure'
import { deletePhotoTypes } from '../DB/Photo/PhotoType'
import { deleteObjectPhotos } from '../DB/Photo/ObjectPhoto'
import { deletePrestartMeetings } from '../DB/Prestart/PrestartMeeting'
import { deletePrestartTaskTypes } from '../DB/Prestart/PrestartTaskType'
import { deletePrestartTasks } from '../DB/Prestart/PrestartTask'
import { deleteReportDataList } from '../DB/Reporting/ReportData'
import { deleteReportDefintions } from '../DB/Reporting/ReportDefinition'
import { deleteServiceTypes } from '../DB/ServiceType/ServiceType'
import { deleteObjectSignatures } from '../DB/Signature/ObjectSignature'
import { deleteSignatureTypes } from '../DB/Signature/SignatureType'
import { deleteStatus } from '../DB/Status/Status'
import { deleteStatusTypes } from '../DB/Status/StatusType'
import { deleteSuppliers } from '../DB/Supplier/Supplier'
import { deleteSupplierItems } from '../DB/Supplier/SupplierItem'
import { deleteUnitTypes } from '../DB/UnitType/UnitType'
import { deleteUsers } from '../DB/User/User'
import { deleteObjectUsers } from '../DB/User/ObjectUser'
import { deleteVehicles } from '../DB/Vehicle/Vehicle'
import { deleteVehicleTypes } from '../DB/Vehicle/VehicleType'
import { deleteObjectVehicles } from '../DB/Vehicle/ObjectVehicle'
import { deleteLoadedWastes } from '../DB/LoadedWaste/LoadedWaste'
import { deleteWorkOrders } from '../DB/WorkOrder/WorkOrder'
import { deleteWorkOrderTeams } from '../DB/WorkOrder/WorkOrderTeam'
import { deleteWorkOrderEvents } from '../DB/WorkOrder/WorkOrderEvent'
 
export async function CleanUpIndexDB(where, equals){ 
    let promises = []; 
    promises.push(deleteAddress(where,equals))
    promises.push(deleteAddressBranch(where,equals))
    promises.push(deleteAddressDevice(where,equals))
    promises.push(deleteAddressDeviceProperty(where,equals))
    promises.push(deleteAssemblyPoints(where,equals))
    promises.push(deleteCodeTypes(where,equals))
    promises.push(deleteCodes(where,equals))
    promises.push(deleteObjectCodes(where,equals))
    promises.push(deleteObjectCoordinates(where,equals))
    promises.push(deleteDeviceTypes(where,equals))
    promises.push(deleteDeviceProperties(where,equals))
    promises.push(deleteEquipments(where,equals))
    promises.push(deleteObjectEquipments(where,equals))
    promises.push(deleteEventTypes(where,equals))
    promises.push(deleteObjectEvents(where,equals))
    promises.push(deleteFlags(where,equals))
    promises.push(deleteGenericObjects(where,equals))
    promises.push(deleteHazardInfos(where,equals))
    promises.push(deleteHazardControls(where,equals))
    promises.push(deleteHazardSubstances(where,equals))
    promises.push(deleteHazardForms(where,equals))
    promises.push(deleteHazardFormHazardInfos(where,equals))
    promises.push(deleteHazardFormSubstances(where,equals))
    promises.push(deleteHazardFormUserAcknowledgements(where,equals))
    promises.push(deleteItems(where,equals))
    promises.push(deleteJobTypes(where,equals))
    promises.push(deleteScheduledJobs(where,equals))
    promises.push(deleteJobReferences(where,equals))
    promises.push(deleteJobSafetyCategorys(where,equals))
    promises.push(deleteJobSafetyHazards(where,equals))
    promises.push(deleteJobSafetyRisks(where,equals))
    promises.push(deleteJobSafetyDeviceMappings(where,equals))
    promises.push(deleteJobSafetyReportHeaders(where,equals))
    promises.push(deleteJobSafetyDeviceReports(where,equals))
    promises.push(deleteJobSafetyDeviceReportActions(where,equals))
    promises.push(deleteJobSafetyReportAcknowledgements(where,equals))
    promises.push(deleteMarkTypes(where,equals))
    promises.push(deleteObjectMarks(where,equals))
    promises.push(deleteMeasureTypes(where,equals))
    promises.push(deleteMeasureTypeUnitTypes(where,equals))
    promises.push(deleteObjectMeasures(where,equals))
    promises.push(deletePhotoTypes(where,equals))
    promises.push(deleteObjectPhotos(where,equals))
    promises.push(deletePrestartMeetings(where,equals))
    promises.push(deletePrestartTaskTypes(where,equals))
    promises.push(deletePrestartTasks(where,equals))
    promises.push(deleteReportDataList(where,equals))
    promises.push(deleteReportDefintions(where,equals))
    promises.push(deleteServiceTypes(where,equals))
    promises.push(deleteObjectSignatures(where,equals))
    promises.push(deleteSignatureTypes(where,equals))
    promises.push(deleteStatus(where,equals))
    promises.push(deleteStatusTypes(where,equals))
    promises.push(deleteSuppliers(where,equals))
    promises.push(deleteSupplierItems(where,equals))
    promises.push(deleteUnitTypes(where,equals))
    promises.push(deleteUsers(where,equals))
    promises.push(deleteObjectUsers(where,equals))
    promises.push(deleteVehicles(where,equals))
    promises.push(deleteVehicleTypes(where,equals))
    promises.push(deleteObjectVehicles(where,equals))
    promises.push(deleteLoadedWastes(where,equals))
    promises.push(deleteWorkOrders(where,equals))
    promises.push(deleteWorkOrderTeams(where,equals))
    promises.push(deleteWorkOrderEvents(where,equals)) 
    promises.push(deleteChangeHistory(where,equals))
    await Promise.all(promises)  
    return
}

export async function SyncFromSource() {
    const startTime = dayjs(); 
    try{  
        await ping(); 

        let promises = []; 
        promises.push(syncFromApiAddresses()); 
        promises.push(syncFromApiAssemblyPoints()); 
        promises.push(syncFromApiCodes()); 
        promises.push(syncFromApiCoordinates()); 
        promises.push(syncFromApiDevices()); 
        promises.push(syncFromApiEquipments()); 
        promises.push(syncFromApiEvents()); 
        promises.push(syncFromApiFlags());
        promises.push(syncFromApiGenericObjects());
        promises.push(syncFromApiHazards());
        promises.push(syncFromApiHazardForms());
        promises.push(syncFromApiItems());
        promises.push(syncFromApiJobs());
        promises.push(syncFromApiJobSafetyReports());
        promises.push(syncFromApiMarks()); 
        promises.push(syncFromApiMeasures()); 
        promises.push(syncFromApiPhotos());
        promises.push(syncFromApiPrestarts());
        promises.push(syncFromApiReports()); 
        promises.push(syncFromApiServices());
        promises.push(syncFromApiSignatures()); 
        promises.push(syncFromApiStatuses());  
        promises.push(syncFromApiSuppliers());
        promises.push(syncFromApiUnitTypes());
        promises.push(syncFromApiUsers());
        promises.push(syncFromApiVehicles());
        promises.push(syncFromApiWastes());
        promises.push(syncFromApiWorkOrders());
  
        await Promise.all(promises)  
        const endtime = dayjs();
        const duration = endtime.diff(startTime, 'second', true)
        console.log('SyncFromSource resolved: duration ' + duration)
        return 
    }
    catch(error){
        await putError(error,{},'SyncFromSource','SyncFromSource')
        return 

    }
   
} 

export async function SyncToSource() {
    const startTime = dayjs();  
    try{
        await ping();
        let promises = [];
    
        promises.push(syncToApiChangeHistory())
        promises.push(syncToApiObjectCodes())
        promises.push(syncToApiObjectEvents())
        promises.push(syncToApiObjectMarks())
        promises.push(syncToApiObjectMeasures())
        promises.push(syncToApiObjectUsers())
        promises.push(syncToApiObjectPhotos())
        promises.push(syncToApiObjectVehicles())
        promises.push(syncToApiObjectSignatures())
        promises.push(syncToApiReportData())
        promises.push(syncToApiWaste())
        promises.push(syncToApiJob())
        promises.push(syncToApiHazardForms())
        promises.push(syncToApiPrestart())
        promises.push(syncToApiJobSafetyReports())
    
        await Promise.all(promises)  
        const endtime = dayjs();
        const duration = endtime.diff(startTime, 'second', true)
        console.log('SyncToSource resolved: duration ' + duration) 
        return 
    }
    catch(error){
        await putError(error,{},'SyncToSource','SyncToSource')
        return 

    }
   
}