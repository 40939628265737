import dayjs from 'dayjs'
export function setLocalStorageReportDefinitionSystemName(reportDefinitionSystemName) {
    localStorage.setItem('reportDefinitionSystemName', reportDefinitionSystemName);
}

export function setLocalStorageReportObjectGuid(reportObjectGuid) {
    localStorage.setItem('reportObjectGuid', reportObjectGuid);
}

export function setLocalStorageWorkOrderListType(type) {
    localStorage.setItem('workOrderListType', type);
}
export function setLocalStorageWorkOrderDate(date) {
    localStorage.setItem('workOrderDate', date);
}

export function setLocalStorageVehicle(oVehicle){
    localStorage.setItem('vehicle', JSON.stringify(oVehicle));
}

export function setLocalStorageReport(oObjectReport) {
    localStorage.setItem('report', JSON.stringify(oObjectReport));
}

export function setLocalStorageAddress(oAddress) {
    localStorage.setItem('address', JSON.stringify(oAddress));
}

export function setLocalStorageScheduledJob(oScheduledJob) {
    localStorage.setItem('scheduledJob', JSON.stringify(oScheduledJob));
}

export function setLocalStorageScheduledJobs(data){
    localStorage.setItem('scheduledJobs', JSON.stringify(data));
}

export function setLocalStorageUserDismissedOffSite(date) {
    localStorage.setItem('userDismissedOffSite', date); 
}
export function setLocalStorageLastPageRout(date) {
    localStorage.setItem('lastPageRout', date);
}
export function setLocalStoragePageRout(date) {
    localStorage.setItem('pageRout', date);
}

export function setLocalStorageWorkOrder(oWorkOrder) {
    localStorage.setItem('workOrder', JSON.stringify(oWorkOrder));
}

export function setLocalStorageSupplier(oSupplier) {
    localStorage.setItem('supplier', JSON.stringify(oSupplier));
}

export function setLocalStorageObjectUser(oObjectUser) {
    localStorage.setItem('objectUser', JSON.stringify(oObjectUser));
}

export function setLocalStorageObjectEvent(oObjectEvent){
    localStorage.setItem('objectEvent', JSON.stringify(oObjectEvent));
} 

export function setLocalStorageDumpDocket(data){
    localStorage.setItem('dumpDocket', JSON.stringify(data));
}

export function getLocalStorageDumpDocke(){
    let data = localStorage.getItem("dumpDocket")
    return data? JSON.parse(data):null
}

export function getLocalStorageObjectEvent(){
    let data = localStorage.getItem("objectEvent")
    return data? JSON.parse(data):null
}

export function getLocalStorageObjectUser(){
    let data = localStorage.getItem("objectUser")
    return data? JSON.parse(data):null
}

export function getLocalStorageVehicle(){
    let data = localStorage.getItem("vehicle")
    return data? JSON.parse(data):null
} 

export function getLocalStorageSupplier() { 
    let data = localStorage.getItem("supplier")
    return data? JSON.parse(data):null
}

export function getLocalStorageAddress() { 
    let data = localStorage.getItem("address")
    return data? JSON.parse(data):null
}

export function getLocalStoragePageRout() {
    let data = localStorage.getItem("pageRout")
    return data 
}
export function getLocalStorageLastPageRout() {
    let data = localStorage.getItem("lastPageRout")
    return data
}

export function getLocalStorageScheduledJob() { 
    let data = localStorage.getItem("scheduledJob")
    return JSON.parse(data)
}

export function getLocalStorageScheduledJobs(){
    let data = localStorage.getItem("scheduledJobs")
    return JSON.parse(data)
}

export function getLocalStorageWorkOrderListType() {
    let data = localStorage.getItem("workOrderListType")
    let update = data === null
    data = data === null ? 'User' : data;
    if(update){
        setLocalStorageWorkOrderListType(data)
    } 
    return data
}

export function getLocalStorageWorkOrder() { 
    let data = localStorage.getItem("workOrder")
    return data? JSON.parse(data):null
}

export function getLocalStorageWorkOrderDate() {
    let data = localStorage.getItem("workOrderDate") 
    let update = data === null
    data = data === null ? dayjs().format('YYYY-MM-DD') + 'T00:00:00' : data 
    if(update){
        setLocalStorageWorkOrderDate(data)
    }
    return data
}
export function getLocalStorageReport() {
    let data = localStorage.getItem("report")
    return data === null ? null :JSON.parse(data)
}
export function getLocalStorageReportDefinitionSystemName() {
    let data = localStorage.getItem("reportDefinitionSystemName")
    return data
}

export function getLocalStorageReportObjectGuid() {
    let data = localStorage.getItem("reportObjectGuid")
    return data
}

export function getLocalStorageDismissedOffSite() {
    let data = localStorage.getItem("userDismissedOffSite") 
    return data === null || data === 'null' ? null : data 
}