import DataSource from 'devextreme/data/data_source';
import { db } from "../db";
import { putError } from "../Error/Error";
import AssemblyPoint from "../../Model/AssemblyPoint/AssemblyPoint";

export function populateObject_AssemblyPoint(data) {
    if (!data) {
        return null
    }
    let oAssemblyPoint = new AssemblyPoint(
        data.assemblyPointID,
        data.assemblyPointGuid,
        data.name,
        'true',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID
    )
    return oAssemblyPoint
}

async function getAssemblyPoint_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.assembly_points.get(keyValueObject);
    const oData = populateObject_AssemblyPoint(data);
    return oData
}

async function getAssemblyPoints_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.assembly_points
        .where(where)
        .equals(equals)
        .toArray();
    const oData = data.map((object) => { return populateObject_AssemblyPoint(object) })
    return oData
}

async function putAssemblyPoint_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    await db.assembly_points.put(oData, oData.id);
    return;
}

async function deleteAssemblyPoints_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.assembly_points
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getAssemblyPoint(keyValueObject) {
    try {
        let oData = await getAssemblyPoint_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'AssemblyPoint.js', 'getAssemblyPoint')
        return
    }
}

export async function getAssemblyPoints(where, equals) {
    try {
        let oData = await getAssemblyPoints_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'AssemblyPoint.js', 'getAssemblyPoint')
        return
    }
}

export async function getLookupDataSourceAssemblyPoints(where, equals, addEmpty = false) {
    try {
        const objects = await getAssemblyPoints(where, equals);
        let oData = objects.map(function (x) { return { value: x.assemblyPointID, text: x.name }; });
        if (addEmpty) {
            oData.unshift({ value: "", text: "" });
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource;
    } catch (error) {
        await putError(error, {where, equals, addEmpty}, 'AssemblyPoint.js', 'getLookupDataSourceAssemblyPoints')
        return
    }

}

export async function putAssemblyPoint(oData) {
    try {
        await putAssemblyPoint_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'AssemblyPoint.js', 'getAssemblyPoint')
        return
    }
}

export async function deleteAssemblyPoints(where, equals) {
    try {
        await deleteAssemblyPoints_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'AssemblyPoint.js', 'getAssemblyPoint')
        return
    }
}
