import { db } from "../db";
import { putError } from "../Error/Error";
import { MarkType } from "../../Model/Mark/MarkType";

export function populateObject_MarkType(markType) {
    if (!markType) {
        return null
    }
    let oMakrType = new MarkType(
        markType.markTypeID,
        markType.markTypeGuid,
        markType.markType,
        markType.markTypeSystemName,
        markType.createdDate,
        markType.lastModifiedDate,
        markType.companyID,
        markType.regionID
    )
    return oMakrType
}

async function getMarkType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const markType = await db.mark_type.get(keyValueObject);
    const oMakrType = populateObject_MarkType(markType)
    return oMakrType
}

async function getMarkTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const markTypes = await db.mark_type
        .where(where)
        .equals(equals)
        .toArray();
    const oMarkTypes = markTypes.map((markType) => { return populateObject_MarkType(markType) });
    return oMarkTypes
}

async function putMarkType_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.mark_type.put(oData, oData.id);
    return;
}

async function deleteMarkTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.mark_type
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getMarkType(keyValueObject) {
    try {
        let oData = await getMarkType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'MarkType.js', 'getMarkType')
        return
    }
}

export async function getMarkTypes(where, equals) {
    try {
        let oData = await getMarkTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'MarkType.js', 'getMarkTypes')
        return
    }
}

export async function putMarkType(oData) {
    try {
        await putMarkType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'MarkType.js', 'putMarkType')
        return
    }
}

export async function deleteMarkTypes(where, equals) {
    try {
        await deleteMarkTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'MarkType.js', 'deleteMarkTypes')
        return
    }
}
