import { db } from "../db";
import { putError } from "../Error/Error";
import DataSource from 'devextreme/data/data_source';
import User from '../../Model/User/User'

export function getSelectObject_UserInitals(name) {
    if (!name) {
        return
    }
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...name.matchAll(rgx)] || [];

    initials = (
        (initials.shift()?.[0] || '') + '.' + (initials.pop()?.[1] || '')
    );
    return initials
}

export function populateObject_User(user, type = 'Staff') {
    if (!user) {
        return null
    }
    let oUser = new User(
        user.userID,
        user.userGuid,
        user.name,
        user.position,
        type,
        user.companyID,
        user.regionID
    )
    oUser.initals = getSelectObject_UserInitals(user.name)
    return oUser;

}

async function getUser_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const user = await db.users.get(keyValueObject)
    let oUser = populateObject_User(user)
    return oUser;
}


async function getUsers_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const users = await db.users
        .where(where)
        .equals(equals)
        .toArray();
    let oUsers = users.map((user) => { return populateObject_User(user) });
    return oUsers
}

async function putUser_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.users.put(oData, oData.id);
    return;
}

async function deleteUsers_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.users
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getLookupDataSourceUserInitals(where, equals, addEmpty = false) {
    try {
        const oUsers = await getUsers(where, equals);
        let oData = oUsers.map(function (x) { return { value: x.userID, text: x.initals }; });
        oData.sort((a, b) => a.text.localeCompare(b.text));
        if (addEmpty) {
            oData.unshift({ value: "", text: "" });
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource;
    } catch (error) {
        await putError(error, {where, equals, addEmpty}, 'User.js', 'getLookupDataSourceUserInitals')
        return
    }

}

export async function getLookupDataSourceUsers(where, equals, addEmpty = false, value,text) {
    try {
        const oUsers = await getUsers(where, equals);
        let oData = oUsers.map(function (x) { return { value: x[value], text: x[text] };  });
        oData.sort((a, b) => a.text.localeCompare(b.text));
        if (addEmpty) {
            oData.unshift({ value: "", text: "" });
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource;
    } catch (error) {
        await putError(error, {where, equals, addEmpty}, 'User.js', 'getLookupDataSourceUsers')
        return
    }

}

export async function getUser(keyValueObject) {
    try {
        let oData = await getUser_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'User.js', 'getUser')
        return
    }
}

export async function getUsers(where, equals) {
    try {
        let oData = await getUsers_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'User.js', 'getUsers')
        return
    }
}

export async function putUser(oData) {
    try {
        await putUser_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'User.js', 'putUser')
        return
    }
}

export async function deleteUsers(where, equals) {
    try {
        await deleteUsers_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'User.js', 'deleteUsers')
        return
    }
}
