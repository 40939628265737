import React from 'react'
import ReactDOM from 'react-dom/client' 
import './index.css'
import config from 'devextreme/core/config';    
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import App from './App'; 
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

let object = { licenseKey: process.env.REACT_APP_DEVEXTREME_licenseKey }
 
config(object);
const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=9007f632-1dc2-4644-9918-ce6566729c28;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/',
        enableAutoRouteTracking: true,
        loggingLevelConsole: 2,

    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();
 

ReactDOM.createRoot(document.getElementById('root')).render( 
         <App />
    ,
) 
serviceWorkerRegistration.register();