import { db } from "../db";
import { putError } from "../Error/Error";
import { JobType } from "../../Model/JobType/JobType";

export function populateObject_JobType(data) {
    if (!data) {
        return null
    }
    let oJobType = new JobType(
        data.jobTypeID,
        data.jobTypeGuid,
        data.customerID,
        data.customerGuid,
        data.customerName,
        data.type,
        data.systemType,
        'true',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        'true',
        ''
    )
    return oJobType
}

async function getJobType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const jobType = await db.job_types.get(keyValueObject)
    const oJobType = populateObject_JobType(jobType);
    return oJobType;
}

async function getJobTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const jobTypes = await db.job_types
        .where(where)
        .equals(equals)
        .toArray();
    const oJobTypes = jobTypes.map((jobType) => { return populateObject_JobType(jobType) })
    return oJobTypes;
}

async function putJobType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_types.put(oData, oData.id);
    return;
}

async function deleteJobTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_types
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getJobType(keyValueObject) {
    try {
        let oData = await getJobType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobType.js', 'getJobType')
        return
    }
}

export async function getJobTypes(where, equals) {
    try {
        let oData = await getJobTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'JobType.js', 'getJobTypes')
        return
    }
}

export async function putJobType(oData) {
    try {
        await putJobType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobType.js', 'putJobType')
        return
    }
}

export async function deleteJobTypes(where, equals) {
    try {
        await deleteJobTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'JobType.js', 'deleteJobTypes')
        return
    }
}
