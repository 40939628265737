import { getAuthenticatedUserGuid } from '../Utils/AuthUtils.jsx'
import Api from './Api.jsx';
import { populateObject_Supplier } from '../DB/Supplier/Supplier.js';
import { populateObject_SupplierItem } from '../DB/Supplier/SupplierItem.js';
import { putError } from '../DB/Error/Error.js';

export function getSuppliers_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Supplier/getSuppliers', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(
            (res) => {
                let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
                let oDataList = []
                dataList.forEach((data) => {
                    data.sent = 'true'
                    let oData = populateObject_Supplier(data)
                    oDataList.push(oData)
                });
                return oDataList;
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error, {
                    UserGuid: userGuid
                },
                    'Supplier',
                    'getSuppliers_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getSupplierItems_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Supplier/getSupplierItems', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(
            (res) => {
                let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
                let oDataList = []
                dataList.forEach((data) => {
                    data.sent = 'true'
                    let oData = populateObject_SupplierItem(data)
                    oDataList.push(oData)
                });
                return oDataList;
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error, {
                    UserGuid: userGuid
                },
                    'Supplier',
                    'getSupplierItems_API'
                )
                return Promise.reject(error)
            }
        )
}