import { createContext, useMemo, useState, useEffect, useContext } from "react"; 
import { getLocalStorageWorkOrderListType, getLocalStorageWorkOrderDate, setLocalStorageWorkOrderDate, setLocalStorageWorkOrderListType, getLocalStorageWorkOrder } from "../Utils/LocalStorageUtils";
import { getWorkOrder } from "../DB/WorkOrder/WorkOrder";
import { getFullWorkOrderTeams } from "../DB/WorkOrder/WorkOrderTeam";
import { getAuthenticatedUserGuid } from "../Utils/AuthUtils";
import { AuthContext } from "./authProvider";


export const WorkOrderContext = createContext();

export const WorkOrderProvider = ({children }) => {  

    const {token} = useContext(AuthContext)
    const [workOrderListType, setWorkOrderListType_] = useState(getLocalStorageWorkOrderListType());
    const [workOrderDate, setWorkOrderDate_] = useState(getLocalStorageWorkOrderDate());
    const [oWorkOrder, setWorkOrder_] = useState(getLocalStorageWorkOrder());
    const [oWorkOrderTeams, setWorkOrderTeam_] = useState([]);

    const setWorkOrderListType =(type)=>{
        setWorkOrderListType_(type)
        setLocalStorageWorkOrderListType(type)
        return;
    } 
    const setWorkOrderDate =(date)=>{
        setWorkOrderDate_(date) 
        return;
    }

    const setWorkOrderDatelocal =(date)=>{
        setLocalStorageWorkOrderDate(date) 
        return;
    }

    const populateWorkOrderTeams = async ()=>{
        const workOrder = await getWorkOrder({'workOrderDate':workOrderDate})
        if(!workOrder){
            setWorkOrderTeam_([]) 
            setWorkOrder_(null)
            return
        }
        const workOrderTeams = await getFullWorkOrderTeams('workOrderGuid',workOrder.workOrderGuid)
        if(workOrderListType === 'All'){
           setWorkOrderTeam_(workOrderTeams)
        }
        else{
           let oUserWorkOrderTeams = [];
           const userGuid = getAuthenticatedUserGuid()
           workOrderTeams.forEach(
               (oWorkOrderTeam) => {
                   let data = oWorkOrderTeam.objectUsers.filter((x) => { return x.userGuid === userGuid; });
                   if (data.length !== 0) {
                       oUserWorkOrderTeams.push(oWorkOrderTeam);
                   }
               }
           );
           setWorkOrderTeam_(oUserWorkOrderTeams)
        } 
        setWorkOrder_(workOrder)
    }

    const contextValue = useMemo(
        () => ({ 
            workOrderListType,
            workOrderDate,
            oWorkOrder,
            oWorkOrderTeams,
            setWorkOrderListType,
            setWorkOrderDate,
            populateWorkOrderTeams,
            setWorkOrderDatelocal 
        }),
        [workOrderListType,workOrderDate,oWorkOrder]// eslint-disable-line
    );


    useEffect(() => {
        const fetchData = async () => { 
            if(!token){
                setWorkOrderTeam_([]) 
                setWorkOrder_(null)
                return
            }
            await populateWorkOrderTeams()
        };
        fetchData();
    }, [workOrderListType,workOrderDate, token]);// eslint-disable-line

    return ( 
        <WorkOrderContext.Provider value={contextValue}>
            {children}
        </WorkOrderContext.Provider>
    );
};
 

export default WorkOrderProvider;