import { db } from "../db";
import { putError } from "../Error/Error";
import { TransferedWaste } from "../../Model/TransferedWaste/TransferedWaste";

export function populateObject_TransferedWaste(transferedWaste) {
    if (!transferedWaste) {
        return null;
    }
    let oTransferedWaste = new TransferedWaste(
        transferedWaste.transferedWasteGuid,
        transferedWaste.loadedWasteGuidFrom,
        transferedWaste.loadedWasteGuidTo,
        transferedWaste.percentTransfered,
        transferedWaste.userID,
        transferedWaste.transferedDate,
        (typeof transferedWaste.active === 'string' && transferedWaste.active === 'true') || (typeof transferedWaste.active === 'boolean' && transferedWaste.active) ? 'true' : 'false',
        transferedWaste.createdDate,
        transferedWaste.lastModifiedDate,
        transferedWaste.companyID,
        transferedWaste.regionID,
        (typeof transferedWaste.sent === 'string' && transferedWaste.sent === 'true') || (typeof transferedWaste.sent === 'boolean' && transferedWaste.sent) ? 'true' : 'false',
        transferedWaste.mode ? transferedWaste.mode : '',

    )
    return oTransferedWaste;

}

async function getTransferedWaste_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const transferedWaste = await db.transfered_waste.get(keyValueObject)
    let oTransferedWaste = populateObject_TransferedWaste(transferedWaste);
    return oTransferedWaste
}

async function getTransferedWastes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const transferedWastes = await db.transfered_waste
        .where(where)
        .equals(equals)
        .toArray();
    let oTransferedWastes = transferedWastes.map((transferedWaste) => { return populateObject_TransferedWaste(transferedWaste) });
    return oTransferedWastes

}

async function putTransferedWaste_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.transfered_waste.put(oData,oData.id);
    return;

}

async function deleteTransferedWastes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.transfered_waste
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getTransferedWaste(keyValueObject) {
    try {
        let oData = await getTransferedWaste_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'TransferedWaste.js', 'getTransferedWaste')
        return
    }
}

export async function getTransferedWastes(where, equals) {
    try {
        let oData = await getTransferedWastes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'TransferedWaste.js', 'getTransferedWastes')
        return
    }
}

export async function putTransferedWaste(oData) {
    try {
        await putTransferedWaste_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'TransferedWaste.js', 'putTransferedWaste')
        return
    }
}

export async function deleteTransferedWastes(where, equals) {
    try {
        await deleteTransferedWastes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'TransferedWaste.js', 'deleteTransferedWastes')
        return
    }
}
