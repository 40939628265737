import BaseObject from "../Base/BaseObject";   
export class JobType extends BaseObject {
    constructor( 
        jobTypeID,
        jobTypeGuid,
        customerID,
        customerGuid,
        customerName,
        type,
        systemType,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobTypeID,
            jobTypeGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.jobTypeID = jobTypeID;
        this.jobTypeGuid = jobTypeGuid;
        this.customerID = customerID;
        this.customerGuid = customerGuid;
        this.customerName = customerName;
        this.type = type;
        this.systemType = systemType;

    }

}
