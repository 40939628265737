
import { getAuthenticatedUserGuid } from '../Utils/AuthUtils' 
import Api from './Api.jsx'; 
import { populateObject_ServiceType } from '../DB/ServiceType/ServiceType'
import { putError } from '../DB/Error/Error.js';

export function getServiceTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/ServiceType/getServiceTypes', {
        params: {
            UserGuid: userGuid 
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_ServiceType(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    { 
                        UserGuid: userGuid  
                    },
                    'ServiceType',
                    'getServiceTypes_API'
                )
                return Promise.reject(error)
            }
        ) 
}