import { upsertHazardForm_API, upsertHazardFormHazardInfo_API, upsertHazardFormSubstance_API, upsertHazardFormUserAcknowledgement_API } from '../../API/HazardForm' 
import { putError } from "../../DB/Error/Error"; 
import { getHazardForms, putHazardForm } from '../../DB/HazardForm/HazardForm';
import { getHazardFormHazardInfos, putHazardFormHazardInfo } from '../../DB/HazardForm/HazardFormHazardInfo';
import { getHazardFormSubstances, putHazardFormSubstance } from '../../DB/HazardForm/HazardFormSubstance';
import { getHazardFormUserAcknowledgements, putHazardFormUserAcknowledgement } from '../../DB/HazardForm/HazardFormUserAcknowledgement';

export async function syncToApiHazardForms(){
    let promises = []
    try{ 
        promises.push(syncToApiHazard_HazardForms());
        promises.push(syncToApiHazard_HazardFormHazardInfos());
        promises.push(syncToApiHazard_HazardFormSubstances());
        promises.push(syncToApiHazard_HazardFormUserAcknowledgements());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard'}, 'Sync/FromAPI/Hazard', 'syncFromApiHazard')
        return Promise.resolve()
    } 
}
 
async function syncToApiHazard_HazardForms(){ 
    try{
        const dataToApi = await getHazardForms('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi =  await Promise.all(dataToApi.map((data)=>{return upsertHazardForm_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putHazardForm(data)}));
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiHazard_HazardForms'}, 'Sync/ToAPI/HazardFrom', 'syncToApiHazardForms')
        return Promise.resolve()
    } 
} 

async function syncToApiHazard_HazardFormHazardInfos(){ 
    try{
        const dataToApi = await getHazardFormHazardInfos('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertHazardFormHazardInfo_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putHazardFormHazardInfo(data)}));
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiHazard_HazardFormHazardInfos'}, 'Sync/ToAPI/HazardFrom', 'syncToApiHazardForms')
        return Promise.resolve()
    } 
}

async function syncToApiHazard_HazardFormSubstances(){ 
    try{
        const dataToApi = await getHazardFormSubstances('sent','false');
        if(!dataToApi){
            return;
        } 
        const dataFromApi =  await Promise.all(dataToApi.map((data)=>{return upsertHazardFormSubstance_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putHazardFormSubstance(data)}));
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiHazard_HazardFormSubstances'}, 'Sync/ToAPI/HazardFrom', 'syncToApiHazardForms')
        return Promise.resolve()
    } 
}

async function syncToApiHazard_HazardFormUserAcknowledgements(){ 
    try{
        const dataToApi = await getHazardFormUserAcknowledgements('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi =  await Promise.all(dataToApi.map((data)=>{return upsertHazardFormUserAcknowledgement_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putHazardFormUserAcknowledgement(data)}));
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiHazard_HazardFormUserAcknowledgements'}, 'Sync/ToAPI/HazardFrom', 'syncToApiHazardForms')
        return Promise.resolve()
    } 
}