import BaseObject from "../Base/BaseObject";  
export default class WorkOrderEvent extends BaseObject {
    constructor(
        workOrderEventID,
        workOrderEventGuid,
        workOrderTeamGuid,
        workOrderID,
        eventStartTime,
        eventFinishTime,
        name,
        description,  
        addressBranchID,   
        companyID,
        regionID

    ) {
        super(
            workOrderEventID,
            workOrderEventGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.workOrderEventID = workOrderEventID;
        this.workOrderEventGuid = workOrderEventGuid;
        this.workOrderTeamGuid = workOrderTeamGuid;
        this.workOrderID = workOrderID;
        this.eventStartTime = eventStartTime;
        this.eventFinishTime = eventFinishTime;
        this.name = name;
        this.description = description;  
        this.addressBranchID = addressBranchID;
        this.objectUsers = [];
        this.workOrderTeam = {};

    }  
} 