
import { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";
import { insideBoundingBox, getBoundingBox, toLatLon } from 'geolocation-utils'  
import { v4 as uuidv4 } from 'uuid';
import { Popup } from 'devextreme-react/popup';
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
 
import { AuthContext } from "./authProvider"; 
import { getAuthenticatedUser, getAuthenticatedUserGuid } from "../Utils/AuthUtils";
import { getLocalStorageAddress, getLocalStorageDismissedOffSite, setLocalStorageAddress, setLocalStorageUserDismissedOffSite} from "../Utils/LocalStorageUtils" 

import { UserLeftFromSitePopupCard } from '../Components/Events/UserLeftFromSitePopupCard' 

import { WorkOrderContext } from "./workOrderProvider"; 
import { PageContext } from "./pageProvider";
import { getObjectEvents, putObjectEvent } from "../DB/Event/ObjectEvent";
import { getObjectUser, populateObject_ObjectUser, putObjectUser } from "../DB/User/ObjectUser";
import { getFullAddress } from "../DB/Address/Address"; 
import { raiseChangHistoryRecord } from "../DB/ChangeHistory/ChangeHistory";
import { getHazardForm } from "../DB/HazardForm/HazardForm";
import { putHazardFormUserAcknowledgement, getHazardFormUserAcknowledgements } from "../DB/HazardForm/HazardFormUserAcknowledgement";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
    const { token } = useContext(AuthContext)
    const { raiseEvent } = useContext(PageContext)
    const { oWorkOrderTeams, oWorkOrder } = useContext(WorkOrderContext)
    const [nextDisplayTime, setNextDisplayTime_] = useState(getLocalStorageDismissedOffSite());
    const [popupVisible, setPopupVisible] = useState(false); 
    const [userLocation, setUserLocation] = useState(null);
    const [oOnSiteObjectEvent, setOnSiteObjectEvent] = useState(null); 
    const [oAddress, setProviderAddress] = useState(getLocalStorageAddress());
    const [watchId, setWatchId] = useState(null);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(customParseFormat) 

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    function error(err) {
        console.error(`ERROR(${err.code}): ${err.message}`);
    }

    const setNextDisplayTime = (date)=>{
        setNextDisplayTime_(date);
        setLocalStorageUserDismissedOffSite(date)
    }

    const hideInfoSave = useCallback(async () => { 
        setPopupVisible(false); 
    }, [setPopupVisible]);  

    const hideInfo = useCallback(async () => {
        let now = dayjs()
        let time = now.add(15,"minute");
        setNextDisplayTime(time.format('YYYY/MM/DDTHH:mm:ss'))
        setPopupVisible(false); 
    }, [setPopupVisible]); 

    const setAddress = (address) => {
        setLocalStorageAddress(address)
        setProviderAddress(address)
    }

    const getIsUserOnSite=(assressGuid)=>{
        if(!oAddress || oAddress.addressGuid !== assressGuid){
            return false;
        }
        else{
            return true
        }
    }

    const getArriveOnSiteDisabled = (assressGuid)=>{
        if(!oAddress){
            return false;
        }
        else if (oAddress.addressGuid === assressGuid){
            return true;
        }
        else {
            return true
        }
    }

    const getUserOnSiteEvent =(assressGuid)=>{
        if(!oOnSiteObjectEvent){
            return null
        } 
        else if(!oAddress){
            return null
        }
        else if(oAddress.addressGuid !== assressGuid){
            return null
        }
        else{
            return oOnSiteObjectEvent
        }
    }

    const getNonSignedOutSite = async () => {
        const userGuid = getAuthenticatedUserGuid()
        let wehere = ['objectType', 'eventSystemName', 'userGuid', 'active'];
        let equals = ['ObjectUser', 'onsite', userGuid, 'true']
        const oObjectEvents = await getObjectEvents(wehere, equals);
        if (!oObjectEvents || oObjectEvents.length === 0) {
            setOnSiteObjectEvent(null);
            setAddress(null);
            return null;
        }
        let openObjects = oObjectEvents.filter((x) => { return !x.eventEndTimeStamp; });
        if (openObjects.length === 0) {
            setOnSiteObjectEvent(null);
            setAddress(null);
            return null;
        }
        let objectEvent = openObjects.at(0);
        const oObjectUser = await getObjectUser({ 'objectUserGuid': objectEvent.objectGuid, 'userGuid': userGuid, 'active': 'true' });
        if (!oObjectUser) {
            setOnSiteObjectEvent(null);
            setAddress(null);
            return null;
        }
        const address = await getFullAddress({ 'addressGuid': oObjectUser.objectGuid });
        setOnSiteObjectEvent(objectEvent);
        setAddress(address);
        return address;
    }

    const checkIfNotCloseToDevice = (oCoordinates, latLong) => {
        if(oCoordinates.length === 0){
            return true;
        }
        let locations = oCoordinates.map((coordinate) => { return toLatLon([parseFloat(coordinate.latitude), parseFloat(coordinate.longitude)]) })
        let currentLocation = toLatLon([latLong.latitude, latLong.longitude])
        let bounds = getBoundingBox(locations, 100)
        try{
            return insideBoundingBox(currentLocation, bounds)

        }
        catch{ 
        }
    }

    const checkIfUserHasLeftSite =async (address, latLong) => { 
        if (!address) {
            return
        } 
        let oScheduledJobs = oWorkOrderTeams.map((x)=>{ return x.scheduledJobs}).flat()
        oScheduledJobs = oScheduledJobs.filter((x)=>{return x.addressGuid === address.addressGuid && x.addressDevice !== null}) 
        let oCoordinates = oScheduledJobs.map(
            (oScheduledJob) => {
                return oScheduledJob.addressDevice.coordinates;
            }
        ).flat();
        
        let onsite = checkIfNotCloseToDevice(oCoordinates, latLong)
        let now = dayjs() 
        if(nextDisplayTime){
            let hideTime = dayjs(nextDisplayTime, 'YYYY/MM/DDTHH:mm:ss');
            if(now.isSameOrBefore(hideTime)){ 
                return;
            } 
        }
        if (!onsite) { 
            setPopupVisible(true)
        }
    }

    const userLocationChanged = async (pos) => {
        if (!token) {
            if (watchId) {
                navigator.geolocation.clearWatch(watchId)
                setUserLocation(null) 
                setWatchId(null)
            } 
        }
        const latLong = pos.coords; 
        setUserLocation(latLong) 
 
    };

    const singOutAcknowledgement = async(timeStamp,oAacknowledgement )=>{
        const oUser = getAuthenticatedUser();
        let oldValue = 'Left From Site';
        let newValue = timeStamp;
        let changeHistroy = {
            changeGuid: uuidv4(),
            userID: oUser.userID,
            oldValue: oldValue,
            newValue: newValue,
            objectType: 'hazardReport',
            objectGuid: oAacknowledgement.hazardFormGuid,
            property: 'Entery',
            time: timeStamp,
            active: 'true',
            createdDate: timeStamp,
            lastModifiedDate: timeStamp,
            companyID: oUser.companyID,
            regionID: oUser.regionID,
            sent: 'false',
            mode: 'Add',
        }  
        await raiseChangHistoryRecord(changeHistroy)
        oAacknowledgement.sent = 'false';
        oAacknowledgement.signOutDateTime = timeStamp;
        await putHazardFormUserAcknowledgement(oAacknowledgement)
        return;
    }

    const signUserOutOfHazardForm = async(timeStamp)=>{
        if(!oAddress){
            return;
        }
        const oUser = getAuthenticatedUser();
        const oHazardForm = await getHazardForm({ addressID: oAddress.addressID, active: 'true' })
        let acknowledgements = await getHazardFormUserAcknowledgements(['hazardFormGuid','userID', 'active'], [oHazardForm.hazardFormGuid, oUser.userID,'true']);
        if(!acknowledgements || acknowledgements.length === 0){
            return;
        }
        let singedInAcknowledgements = acknowledgements.filter(x=> x.signOutDateTime === null);
        if(!singedInAcknowledgements || singedInAcknowledgements.length === 0){
            return;
        }
        let proms = singedInAcknowledgements.map((data)=>{return singOutAcknowledgement(timeStamp,data)});
        await Promise.all(proms)
        return;  
    }

    const leftFromSite = async (eventTime) => { 
        let timeStamp = dayjs().format('YYYY-MM-DDTHH:mm:ss');
        const oUser = getAuthenticatedUser(); 
        let objectEvent = oOnSiteObjectEvent;
        objectEvent.eventEndTimeStamp = eventTime;
        objectEvent.sent = 'false';
        objectEvent.lastModifiedDate = timeStamp;

        let oldValue = 'Left From Site';
        let newValue = timeStamp;
        let changeHistroy = {
            changeGuid: uuidv4(),
            userID: oUser.userID,
            oldValue: oldValue,
            newValue: newValue,
            objectType: 'ObjectEvent',
            objectGuid: objectEvent.objectEventGuid,
            property: 'Time On Site',
            time: timeStamp,
            active: 'true',
            createdDate: timeStamp,
            lastModifiedDate: timeStamp,
            companyID: oUser.companyID,
            regionID: oUser.regionID,
            sent: 'false',
            mode: 'Add',
        } 

        let proms = [];
        proms.push(putObjectEvent(objectEvent) );
        proms.push(raiseChangHistoryRecord(changeHistroy));
        proms.push(signUserOutOfHazardForm(eventTime));
        await Promise.all(proms) 
        // sign out users from hs forms
        setOnSiteObjectEvent(null);
        setAddress(null); 
        setNextDisplayTime(null)
        return;
    }

    const arrivedOnSite = async (eventTime, oAddress) => { 
        let timeStamp = dayjs().format('YYYY-MM-DDTHH:mm:ss');
        const oUser = getAuthenticatedUser();    
  
        let oObjectUser  = await getObjectUser({'objectType': 'Address','objectGuid': oAddress.addressGuid,'userGuid': oUser.userGuid,'active': 'true'})

        try{  
            if (!oObjectUser) {
                let objectUser = {
                    objectUserGuid: uuidv4(),
                    objectGuid: oAddress.addressGuid,
                    referenceGuid: oWorkOrder.workOrderGuid,
                    objectType: 'Address',
                    userGuid: oUser.userGuid,
                    userID: oUser.userID,
                    name: oUser.name,
                    position: oUser.position,
                    active: 'true',
                    createdDate: timeStamp,
                    lastModifiedDate: timeStamp,
                    companyID: oUser.companyID,
                    regionID: oUser.regionID,
                    sent: 'false',
                    mode: 'Add',
                };
                oObjectUser = populateObject_ObjectUser(objectUser);
                await putObjectUser(oObjectUser)
            }
            const oObjectEvent = await raiseEvent('OnSite', {objectType: 'ObjectUser',objectGuid: oObjectUser.objectUserGuid}, eventTime, null);

            let changeHistroy = {
                changeGuid: uuidv4(),
                userID: oUser.userID,
                oldValue: 'Arrived On Site',
                newValue: timeStamp,
                objectType: 'ObjectEvent',
                objectGuid: oObjectEvent.objectEventGuid,
                property: 'Time On Site',
                time: timeStamp,
                active: 'true',
                createdDate: timeStamp,
                lastModifiedDate: timeStamp,
                companyID: oUser.companyID,
                regionID: oUser.regionID,
                sent: 'false',
                mode: 'Add',
            };

            await raiseChangHistoryRecord(changeHistroy); 
            setAddress(oAddress);
            setOnSiteObjectEvent(oObjectEvent); 
        } 
        catch(error){ 
            window.alert('Error, Please try again')
        }
        return;
    } 
       
    useEffect(() => {
        const fetchData = async () => { 
            if(!userLocation){
                return
            }
            await getNonSignedOutSite() 
            //let address = await getNonSignedOutSite() 
            //if(!popupVisible){
                //checkIfUserHasLeftSite(address, userLocation); 
           // }
        };
        fetchData();
    }, [userLocation,oWorkOrderTeams, nextDisplayTime])// eslint-disable-line

    useEffect(() => {
        const fetchData = async () => {  
            if (!token) {
                if (watchId) {
                    navigator.geolocation.clearWatch(watchId)
                    setWatchId(null)
                }
                return
            } 
            if (navigator.geolocation) { 
                if (!watchId) {
                    let id = navigator.geolocation.watchPosition(userLocationChanged, error, options);
                    setWatchId(id)
                }
                else if (watchId) {
                    navigator.geolocation.clearWatch(watchId)
                    let id = navigator.geolocation.watchPosition(userLocationChanged, error, options);
                    setWatchId(id) 
                }
 
            }
            else {
                console.error('Geolocation is not supported by this browser.');
            }
        };
        fetchData();
    }, [token]);// eslint-disable-line

    const contextValue = useMemo(
        () => ({
            userLocation,
            oOnSiteObjectEvent,
            oAddress,
            getIsUserOnSite,
            setUserLocation,
            setOnSiteObjectEvent,
            setAddress,
            arrivedOnSite,
            leftFromSite,
            getUserOnSiteEvent,
            checkIfUserHasLeftSite,
            getArriveOnSiteDisabled
        }),
        [ userLocation,oOnSiteObjectEvent,oAddress]);// eslint-disable-line

    return (
        <LocationContext.Provider value={contextValue}>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={'Leave site'}
                container=".dx-viewport"
                width='95%'
                height='95%'
            >
                <div className="small-12 grid-x height100p" key={uuidv4()}>
                    <UserLeftFromSitePopupCard hideFn={hideInfoSave } fromGeoLocation={true } />
                </div>

            </Popup>
            {children}
        </LocationContext.Provider>
    );
};

export const useLocation = () => {
    return useContext(LocationContext);
};

export default LocationProvider;