import { db } from "../db";
import { putError } from "../Error/Error";
import ReportData from "../../Model/Reporting/ReportData";
import { getObjectPhotos } from "../Photo/ObjectPhoto";
import { getObjectMarks } from "../Mark/ObjectMark";
import { getObjectMeasures } from "../Measure/ObjectMeasure";
import { getObjectCodes } from "../Code/ObjectCode";
import { getObjectUsers } from "../User/ObjectUser";
import { getObjectVehicles } from "../Vehicle/ObjectVehicle";
import { getObjectEvents } from "../Event/ObjectEvent";

export function populateObject_ReportData(reportData) {
    if (!reportData) {
        return null
    }
    let oReportData = new ReportData(
        reportData.reportDataID,
        reportData.reportDataGuid,
        reportData.workOrderDate,
        reportData.objectType,
        reportData.objectGuid,
        reportData.statusGuid,
        reportData.statusName,
        reportData.reportDefinitionID,
        reportData.reportDefinitionGuid,
        reportData.reportDefinitionName,
        reportData.reportDefinitionSystemName,
        reportData.reportDefinition,
        reportData.reportDefinitionVersion,
        reportData.isComplete,
        reportData.reportCompletedByID,
        (typeof reportData.active === 'string' && reportData.active === 'true') || (typeof reportData.active === 'boolean' && reportData.active) ? 'true' : 'false',
        reportData.createdDate,
        reportData.lastModifiedDate,
        reportData.companyID,
        reportData.regionID,
        (typeof reportData.sent === 'string' && reportData.sent === 'true') || (typeof reportData.sent === 'boolean' && reportData.sent) ? 'true' : 'false',
        reportData.mode ? reportData.mode : '',
    )

    return oReportData
}

async function populateReportDataLinkedObjects(oReportData) {
    if (!oReportData) {
        return oReportData
    }
    try {
        let getProms = [];
        getProms.push(getObjectPhotos(['objectType', 'objectGuid'], ['ReportData', oReportData.reportDataGuid]).then((oObjectPhotos) => { oReportData.objectPhotos = oObjectPhotos }))
        getProms.push(getObjectMarks(['objectType', 'objectGuid'], ['ReportData', oReportData.reportDataGuid]).then((oObjectMarks) => { oReportData.objectMakrs = oObjectMarks }))
        getProms.push(getObjectMeasures(['objectType', 'objectGuid'], ['ReportData', oReportData.reportDataGuid]).then((oObjectMeasures) => { oReportData.objectMeasures = oObjectMeasures }))
        getProms.push(getObjectCodes(['objectType', 'objectGuid'], ['ReportData', oReportData.reportDataGuid]).then((oObjectCodes) => { oReportData.objectCodes = oObjectCodes }))
        getProms.push(getObjectUsers(['objectType', 'objectGuid'], ['ReportData', oReportData.reportDataGuid]).then((oObjectUsers) => { oReportData.objectUsers = oObjectUsers }))
        getProms.push(getObjectVehicles(['objectType', 'objectGuid'], ['ReportData', oReportData.reportDataGuid]).then((oObjectVehicles) => { oReportData.objectVehicles = oObjectVehicles }))
        getProms.push(getObjectEvents(['objectType', 'objectGuid'], ['ReportData', oReportData.reportDataGuid]).then((oObjectEvents) => { oReportData.objectEvents = oObjectEvents }))

        const results = await Promise.all(getProms);
        oReportData.objectPhotos = results.at(0)
        oReportData.objectMakrs = results.at(1)
        oReportData.objectMeasures = results.at(2)
        oReportData.objectCodes = results.at(3)
        oReportData.objectUsers = results.at(4)
        oReportData.objectVehicles = results.at(5)
        oReportData.objectEvents = results.at(6)
    }
    catch (error) {
        await putError(error, oReportData, 'ReportData.js', 'populateReportDataLinkedObjects')
    }


    return oReportData
}


async function getReportData_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const reportData = await db.report_data.get(keyValueObject);
    const oReportData = populateObject_ReportData(reportData)
    return oReportData
}

async function getReportDataList_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const reportDataList = await db.report_data
        .where(where)
        .equals(equals)
        .toArray();
    let oReportDataList = reportDataList.map((reportData) => { return populateObject_ReportData(reportData) });
    return oReportDataList
}

export function putReportData_db(oData) {
    if (!oData.id) {
        return Promise.resolve();
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null  && i !== 'reportDefinition' ) {
            delete oData[i];
        }
    }
    return db.report_data.put(oData, oData.id)
}

export function deleteReportDataList_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    return db.report_data
        .where(where)
        .equals(equals)
        .delete()
}


export async function getFullReportData(keyValueObject) {
    try {
        let oReportData = await getReportData(keyValueObject);
        oReportData = await populateReportDataLinkedObjects(oReportData);
        return oReportData
    }
    catch (error) {
        await putError(error, keyValueObject, 'ReportData.js', 'getFullReportData')
        return
    }
}

export async function getFullReportDataList(where, equals) {
    try {
        const oReportDataList = await getReportDataList(where, equals);
        const results = await Promise.all(oReportDataList.map((oReportData) => { return populateReportDataLinkedObjects(oReportData) }));
        return results.flat();
    } catch (error) {
        await putError(error, {where, equals}, 'ReportData.js', 'getFullReportDataList')
        return
    }

}
export async function getReportData(keyValueObject) {
    try {
        let oData = await getReportData_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ReportData.js', 'getReportData')
        return
    }
}

export async function getReportDataList(where, equals) {
    try {
        let oData = await getReportDataList_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ReportData.js', 'getReportDataList')
        return
    }
}

export async function putReportData(oData) {
    try {
        await putReportData_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ReportData.js', 'putReportData')
        return
    }
}

export async function deleteReportDataList(where, equals) {
    try {
        await deleteReportDataList_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ReportData.js', 'deleteReportDataList')
        return
    }
}