import { db } from "../db";
import { putError } from "../Error/Error";
import { ObjectUser } from '../../Model/User/ObjectUser'

export function populateObject_ObjectUser(userObject) {
    if (!userObject) {
        return null
    }
    let oUserObject = new ObjectUser(
        userObject.objectUserGuid,
        userObject.objectGuid,
        userObject.referenceGuid,
        userObject.objectType,
        userObject.userGuid,
        userObject.userID,
        userObject.name,
        userObject.position,
        (typeof userObject.active === 'string' && userObject.active === 'true') || (typeof userObject.active === 'boolean' && userObject.active) ? 'true' : 'false',
        userObject.createdDate,
        userObject.lastModifiedDate,
        userObject.companyID,
        userObject.regionID,
        (typeof userObject.sent === 'string' && userObject.sent === 'true') || (typeof userObject.sent === 'boolean' && userObject.sent) ? 'true' : 'false',
        userObject.mode ? userObject.mode : '',
    )
    return oUserObject

}
async function getObjectUser_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const userObject = await db.object_users.get(keyValueObject)
    let oUserObject = populateObject_ObjectUser(userObject)
    return oUserObject
}

async function getObjectUsers_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const userObjects = await db.object_users
        .where(where)
        .equals(equals)
        .toArray();
    let oUserObjects = userObjects.map((userObject) => { return populateObject_ObjectUser(userObject) });
    return oUserObjects
}

async function putObjectUser_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.object_users.put(oData, oData.id);
    return;
}

async function deleteObjectUsers_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_users
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getObjectUser(keyValueObject) {
    try {
        let oData = await getObjectUser_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectUser.js', 'getObjectUser')
        return
    }
}

export async function getObjectUsers(where, equals) {
    try {
        let oData = await getObjectUsers_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectUser.js', 'getObjectUsers')
        return
    }
}

export async function putObjectUser(oData) {
    try {
        await putObjectUser_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectUser.js', 'putObjectUser')
        return
    }
}

export async function deleteObjectUsers(where, equals) {
    try {
        await deleteObjectUsers_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectUser.js', 'deleteObjectUsers')
        return
    }
}
