import { upsertObjectSignature_API } from "../../API/Signature";
import { getObjectSignatures, putObjectSignature } from "../../DB/Signature/ObjectSignature";
import { putError } from "../../DB/Error/Error";

export async function syncToApiObjectSignatures(){
    try{
        const dataToApi = await getObjectSignatures('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertObjectSignature_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putObjectSignature(data)})); 
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiObjectSignatures'}, 'Sync/ToAPI/ObjectVehiles', 'syncToApiObjectSignatures')
        return Promise.resolve();
    }
}