import { db } from "../db";
import { putError } from "../Error/Error"; 
import { JobSafetyHazard } from "../../Model/JobSafetyReport/JobSafetyHazard";
import { getJobSafetyRisk } from "./JobSafetyRisk";

export function populateObject_JobSafetyHazard(data) {
    if (!data) {
        return null
    }
    let oData = new JobSafetyHazard(
        data.jobSafetyHazardID,
        data.jobSafetyHazardGuid,
        data.hazardDescription, 
        data.hazardControls,
        data.riskAssessment,
        data.unControlledRiskID,
        data.controlledRiskID, 
        data.jobSafetyCategoryID,
        data.jobSafetyCategoryGuid,
        data.categoryName, 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oData
}

async function populateJobSafetyHazardLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try{
        let proms = []
        proms.push(getJobSafetyRisk({jobSafetyRiskID:oData.unControlledRiskID}))
        proms.push(getJobSafetyRisk({jobSafetyRiskID:oData.controlledRiskID}));
        const results = await Promise.all(proms);
        oData.unControlledRisk = results.at(0);
        oData.controlledRisk = results.at(1);
        return oData
    }
    catch(error){
        await putError(error,oData,'JobSafetyHazard.js','populateJobSafetyHazardLinkedObjects') 
        return oData
    } 
}

async function getJobSafetyHazard_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.job_safety_hazards.get(keyValueObject);
    let oData = populateObject_JobSafetyHazard(data);
    return oData;
}

async function getJobSafetyHazards_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.job_safety_hazards
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object)=>{return populateObject_JobSafetyHazard(object)}) 
    return oData; 
}

async function putJobSafetyHazard_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_safety_hazards.put(oData, oData.id)
    return
}

async function deleteJobSafetyHazards_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_safety_hazards
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getJobSafetyHazard(keyValueObject) { 
    try { 
        let oData = await getJobSafetyHazard_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobSafetyHazard.js', 'getJobSafetyHazard')
        return
    }
}

export async function getFullJobSafetyHazard(keyValueObject) {
    try {
        let oData = await getJobSafetyHazard(keyValueObject);
        oData = await populateJobSafetyHazardLinkedObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error,keyValueObject,'Address.js','getFullAddress') 
        return Promise.resolve(null);
    }
}

export async function getJobSafetyHazards(where, equals) {
    try { 
        let oData = await getJobSafetyHazards_db(where,equals)
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'JobSafetyHazard.js', 'getJobSafetyHazards')
        return
    }
}

export async function getFullJobSafetyHazards(where, equals) {
    try {
        const oData = await getJobSafetyHazards(where, equals)
        let proms = oData.map((object) => { return populateJobSafetyHazardLinkedObjects(object) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'JobSafetyHazard.js','getFullJobSafetyHazards')  
        return Promise.resolve([]);
    }
}

export async function putJobSafetyHazard(oData) {
    try { 
        await putJobSafetyHazard_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobSafetyHazard.js', 'putJobSafetyHazard')
        return
    }
}

export async function deleteJobSafetyHazards(where, equals) {
    try { 
        await deleteJobSafetyHazards_db(where,equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'JobSafetyHazard.js', 'deleteJobSafetyHazards')
        return
    }
}
 