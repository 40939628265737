import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import Api from './Api.jsx'; 
import { populateObject_GenericObject } from '../DB/GenericObject/GenericObject'
import { putError } from '../DB/Error/Error.js';

export function getGenericObjects_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/GenericObject/getGenericObjects', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_GenericObject(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
           async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'GenericObjects',
                    'getGenericObjects_API'
                ) 
                return Promise.reject(error)
            }
        )         
}