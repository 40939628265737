import BaseObject from "../Base/BaseObject";   
export default class Address extends BaseObject {
    constructor(  
        addressID,
        accountManagerID,
        addressGuid,
        addressBranchGuid,
        customerGuid,
        accountManagerGuid,
        customerName,
        addressName,
        dsmNo,
        formattedAddress,
        shortAddress,
        contact,
        siteNotes,
        accountManager,
        customerAppCode,
        customerAppCodeSystemName,
        active, 
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID 
    ) {
        super(
            addressID,
            addressGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.addressID = addressID;
        this.accountManagerID = accountManagerID;
        this.addressGuid = addressGuid;
        this.addressBranchGuid = addressBranchGuid;
        this.customerGuid = customerGuid;
        this.accountManagerGuid = accountManagerGuid;
        this.customerName = customerName;
        this.addressName = addressName;
        this.dsmNo = dsmNo;
        this.formattedAddress = formattedAddress;
        this.shortAddress = shortAddress;
        this.contact = contact;
        this.siteNotes = siteNotes;
        this.accountManager = accountManager;
        this.customerAppCode = customerAppCode;
        this.customerAppCodeSystemName = customerAppCodeSystemName;
        this.coordinates = [];
        this.addressDevices = [];
        this.addressBranch = {};
        this.objectEvents = [];
        this.objectUsers = [];
       
    }   

}
