import { getMarkTypes_API, getObjectMarks_API } from '../../API/Mark' 
import { putError } from "../../DB/Error/Error";
import { getMarkType, putMarkType } from "../../DB/Mark/MarkType";
import { getObjectMark, putObjectMark } from "../../DB/Mark/ObjectMark";
export async function syncFromApiMarks(){
    let promises = []
    try{
        promises.push(syncFromApiMark_MarkTypes());
        promises.push(syncFromApiMark_ObjectMarks());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiMark'}, 'Sync/FromAPI/Mark', 'syncFromApiMark')
        return Promise.resolve()
    } 
}

async function syncFromApiMark_PutMarkType(data){
    let oData =  await getMarkType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putMarkType(data)
}

async function syncFromApiMark_MarkTypes(){
    try{
        const dataFromApi = await getMarkTypes_API(); 
        if(!dataFromApi){
            return;
        }
        const putProms = dataFromApi.map((data)=>{return syncFromApiMark_PutMarkType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiMark_MarkType'}, 'Sync/FromAPI/Mark', 'syncFromApiMark')
        return Promise.resolve()
    } 
}

async function syncFromApiMark_PutObjectMark(data){
    let oData =  await getObjectMark({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putObjectMark(data)
}

async function syncFromApiMark_ObjectMarks(){ 
    try{ 
        const dataFromApi = await getObjectMarks_API(); 
        if(!dataFromApi){
            return;
        }
        const putProms = dataFromApi.map((data)=>{return syncFromApiMark_PutObjectMark(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiMark_ObjectMark'}, 'Sync/FromAPI/Mark', 'syncFromApiMark')
        return Promise.resolve()
    } 
}

