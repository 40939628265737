
import { getFlags_API } from '../../API/Flag' 
import { putError } from "../../DB/Error/Error";
import { getFlag, putFlag } from '../../DB/Flag/Flag'; 
export async function syncFromApiFlags(){
    let promises = []
    try{
        promises.push(syncFromApiFlag_Flags()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiFlag'}, 'Sync/FromAPI/Flag', 'syncFromApiFlag')
        return Promise.resolve()
    } 
}

async function syncFromApiFlag_PutFlag(data){
    let oData =  await getFlag({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putFlag(data)
}

async function syncFromApiFlag_Flags(){ 
    try{
        const dataFromApi = await getFlags_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiFlag_PutFlag(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from ssyncFromApiFlag_Flag'}, 'Sync/FromAPI/Flag', 'syncFromApiFlag')
        return Promise.resolve()
    } 
}
 