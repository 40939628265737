import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import Api from './Api.jsx';
import { populateObject_PrestartMeeting } from '../DB/Prestart/PrestartMeeting.js'
import { populateObject_PrestartTask } from '../DB/Prestart/PrestartTask.js'
import { populateObject_PrestartTaskType } from '../DB/Prestart/PrestartTaskType.js';
import { putError } from '../DB/Error/Error.js';
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils.jsx';

export function getPrestartMeetings_API() {
    const userGuid = getAuthenticatedUserGuid();
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Prestart/getPrestartMeetings', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                data.active = 'true'
                let oData = populateObject_PrestartMeeting(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Prestart',
                    'getPrestartMeetings_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getPrestartTasks_API() {
    const userGuid = getAuthenticatedUserGuid();
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Prestart/getPrestartTasks', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true' 
                let oData = populateObject_PrestartTask(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Prestart',
                    'getPrestartTasks_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getPrestartTaskTypes_API() {
    const userGuid = getAuthenticatedUserGuid();
    return Api.get('/Prestart/getPrestartTaskTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                data.active = 'true'
                let oData = populateObject_PrestartTaskType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Prestart',
                    'getPrestartTaskTypes_API'
                )
                return Promise.reject(error)
            }
        )
}

const formatThreeWayBool =(value)=>{
    if(value === null || typeof value === 'undefined'){
        return null;
    }
    else if (typeof value === 'string'){
        return value === 'true' ? true : false
    }
    else if (typeof value === 'boolean'){
        return value 
    }
    return value
}

export function upsertPrestartMeeting_API(object) {
    const userGuid = getAuthenticatedUserGuid()

    object.ctTrained = formatThreeWayBool(object.ctTrained)
    object.ctWearingPPE = formatThreeWayBool(object.ctWearingPPE)
    object.ctCrewFit = formatThreeWayBool(object.ctCrewFit)
    object.ctCrewAuthourized = formatThreeWayBool(object.ctCrewAuthourized)
    object.ctCorrectEquipment = formatThreeWayBool(object.ctCorrectEquipment)
    object.ctToolsCondition = formatThreeWayBool(object.ctToolsCondition)
    object.hiHazardRegistered = formatThreeWayBool(object.hiHazardRegistered)
    object.hiWorksite = formatThreeWayBool(object.hiWorksite)
    object.hiCardinalRule = formatThreeWayBool(object.hiCardinalRule)
    object.hiPhysical = formatThreeWayBool(object.hiPhysical)
    object.hiErgonomic = formatThreeWayBool(object.hiErgonomic)
    object.envDamage = formatThreeWayBool(object.envDamage)
    object.envImpact = formatThreeWayBool(object.envImpact)
    object.qualInspected = formatThreeWayBool(object.qualInspected)
    object.qualMaterial = formatThreeWayBool(object.qualMaterial)
    object.qualEquipment = formatThreeWayBool(object.qualEquipment)
    object.prmtSpaceEntry = formatThreeWayBool(object.prmtSpaceEntry)
    object.prmtWorkingHeight = formatThreeWayBool(object.prmtWorkingHeight)
    object.prmtHotWork =formatThreeWayBool(object.prmtHotWork)
    object.prmtWorkingAround = formatThreeWayBool(object.prmtWorkingAround)
    object.prmtNZWorksafe = formatThreeWayBool(object.prmtNZWorksafe)
    object.active = object.active === 'true'
    return Api.post('/Prestart/upsertPrestartMeeting',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                data.active = 'true'
                let oObject = populateObject_PrestartMeeting(data)
                return oObject
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Prestart',
                    'getPrestartMeetings_API'
                )
                return Promise.reject(error)
            }
        )
}

export function upsertPrestartTask_API(object) {
    const userGuid = getAuthenticatedUserGuid()

    object.active = object.active === 'true'
    return Api.post('/Prestart/upsertPrestartTask',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true'; 
                let oObject = populateObject_PrestartTask(data)
                return oObject
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Prestart',
                    'getPrestartMeetings_API'
                )
                return Promise.reject(error)
            }
        )
}