import BaseObject from "../Base/BaseObject";  
export default class User extends BaseObject {
    constructor(
        userID,
        userGuid, 
        name, 
        position,
        type,  
        companyID,
        regionID 

    ) {
        super(
            userID,
            userGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.type = type
        this.userID = userID;
        this.userGuid = userGuid; 
        this.name = name;
        this.position = position
        this.initals =null
    }   
}
      