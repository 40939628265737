import { getHazardForms_API, getHazardFormHazardInfos_API, getHazardFormSubstances_API, getHazardFormUserAcknowledgements_API } from '../../API/HazardForm' 
import { putError } from "../../DB/Error/Error"; 
import { getHazardForm, putHazardForm } from '../../DB/HazardForm/HazardForm';
import { getHazardFormHazardInfo, putHazardFormHazardInfo } from '../../DB/HazardForm/HazardFormHazardInfo';
import { getHazardFormSubstance, putHazardFormSubstance } from '../../DB/HazardForm/HazardFormSubstance';
import { getHazardFormUserAcknowledgement, putHazardFormUserAcknowledgement } from '../../DB/HazardForm/HazardFormUserAcknowledgement';

export async function syncFromApiHazardForms(){
    let promises = []
    try{ 
        promises.push(syncFromApiHazard_HazardForms());
        promises.push(syncFromApiHazard_HazardFormHazardInfos());
        promises.push(syncFromApiHazard_HazardFormSubstances());
        promises.push(syncFromApiHazard_HazardFormUserAcknowledgements());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard'}, 'Sync/FromAPI/Hazard', 'syncFromApiHazard')
        return Promise.resolve()
    } 
}

async function syncFromApiHazard_PutHazardForm(data){
    let oData =  await getHazardForm({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putHazardForm(data)
}

async function syncFromApiHazard_HazardForms(){ 
    try{
        const dataFromApi = await getHazardForms_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiHazard_PutHazardForm(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_HazardForms'}, 'Sync/FromAPI/HazardFrom', 'syncFromApiHazardForms')
        return Promise.resolve()
    } 
}

async function syncFromApiHazard_PutHazardFormHazardInfo(data){
    let oData =  await getHazardFormHazardInfo({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putHazardFormHazardInfo(data)
}

async function syncFromApiHazard_HazardFormHazardInfos(){ 
    try{
        const dataFromApi = await getHazardFormHazardInfos_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiHazard_PutHazardFormHazardInfo(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_HazardFormHazardInfos'}, 'Sync/FromAPI/HazardFrom', 'syncFromApiHazardForms')
        return Promise.resolve()
    } 
}

async function syncFromApiHazard_PutSubstance(data){
    let oData =  await getHazardFormSubstance({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putHazardFormSubstance(data)
}

async function syncFromApiHazard_HazardFormSubstances(){ 
    try{
        const dataFromApi = await getHazardFormSubstances_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiHazard_PutSubstance(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_HazardFormHazardInfos'}, 'Sync/FromAPI/HazardFrom', 'syncFromApiHazardForms')
        return Promise.resolve()
    } 
}

async function syncFromApiHazard_PutUserAcknowledgement(data){
    let oData =  await getHazardFormUserAcknowledgement({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putHazardFormUserAcknowledgement(data)
}

async function syncFromApiHazard_HazardFormUserAcknowledgements(){ 
    try{
        const dataFromApi = await getHazardFormUserAcknowledgements_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiHazard_PutUserAcknowledgement(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_HazardFormUserAcknowledgements'}, 'Sync/FromAPI/HazardFrom', 'syncFromApiHazardForms')
        return Promise.resolve()
    } 
}