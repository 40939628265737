import BaseObject from "../Base/BaseObject";  
 
export class MeasureType extends BaseObject {
    constructor(
        measureTypeID,
        measureTypeGuid,
        measureType,
        measureTypeSystemName,
        defaultUnitTypeGuid,
        createdDate,
        lastmodifiedDate,
        companyID,
        regionID
    ) {
        super(
            measureTypeID,
            measureTypeGuid,
            'true',
            createdDate,
            null,
            lastmodifiedDate,
            null,
            companyID,
            regionID,
            'sent',
            ''
        );
        this.measureTypeID = measureTypeID;
        this.measureTypeGuid = measureTypeGuid;
        this.measureType = measureType;
        this.measureTypeSystemName = measureTypeSystemName;
        this.defaultUnitTypeGuid = defaultUnitTypeGuid
        this.measureTypeUnitTypes = [];
    }
}