import BaseObject from "../Base/BaseObject";  
export default class ObjectCoordinate extends BaseObject {
    constructor(
        objectCoordinateGuid,
        objectGuid,
        objectType,
        latitude,
        longitude,
        index,
        active,
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            objectCoordinateGuid,
            objectCoordinateGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.objectCoordinateGuid = objectCoordinateGuid;
        this.objectGuid = objectGuid;
        this.objectType = objectType;
        this.latitude = latitude;
        this.longitude = longitude;
        this.index = index;  
    } 

}
