import BaseObject from "../Base/BaseObject";  
export class EventType extends BaseObject {
    constructor(
        eventTypeID,
        eventTypeGuid,
        eventType,
        eventSystemName, 
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID 
    ) {
        super(
            eventTypeID,
            eventTypeGuid,
            'true',
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''
        ); 
        this.eventTypeID = eventTypeID ;
        this.eventTypeGuid = eventTypeGuid;
        this.eventType = eventType;
        this.eventSystemName = eventSystemName;
    }

} 