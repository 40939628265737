import { db } from "../db";
import { putError } from "../Error/Error";
import { HazardInfo } from "../../Model/Hazard/HazardInfo";

export function populateObject_HazardInfo(data) {
    if (!data) {
        return null
    }
    let oData = new HazardInfo(
        data.hazardInfoID,
        data.hazardInfoGuid,
        data.position,
        data.name,
        data.risk,
        'true',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        'true',
        ''
    )
    return oData;
}

async function getHazardInfo_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.hazard_infos.get(keyValueObject);
    const oData = populateObject_HazardInfo(data)
    return oData;
}

async function getHazardInfos_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.hazard_infos
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_HazardInfo(object) });
    return oData;
}

async function putHazardInfo_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.hazard_infos.put(oData, oData.id);
    return;
}

async function deleteHazardInfos_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.hazard_infos
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getHazardInfo(keyValueObject) {
    try {
        let oData = await getHazardInfo_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'HazardInfo.js', 'getHazardInfo')
        return
    }
}

export async function getHazardInfos(where, equals) {
    try {
        let oData = await getHazardInfos_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'HazardInfo.js', 'getHazardInfos')
        return
    }
}

export async function putHazardInfo(oData) {
    try {
        await putHazardInfo_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'HazardInfo.js', 'putHazardInfo')
        return
    }
}

export async function deleteHazardInfos(where, equals) {
    try {
        await deleteHazardInfos_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'HazardInfo.js', 'deleteHazardInfos')
        return
    }
}
