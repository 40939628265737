import { useState, useEffect, useRef, useContext, useCallback } from "react";
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid';
import { Popup } from 'devextreme-react/popup';
import DateBox from 'devextreme-react/date-box';
import { Button } from 'devextreme-react/button';
import { LocationContext } from "../../Provider/locationProvider";
import ValidationErrorCard from "../Validation/ValidationErrorCard";
import { PageContext } from "../../Provider/pageProvider";
import { getJobType } from "../../DB/JobType/JobType";
import { getLoadedWaste } from "../../DB/LoadedWaste/LoadedWaste";
import { getVehicle } from "../../DB/Vehicle/Vehicle";
import { WorkOrderContext } from "../../Provider/workOrderProvider";
import { getObjectUsers } from "../../DB/User/ObjectUser";
import { getAuthenticatedUser } from "../../Utils/AuthUtils";
import { getObjectVehicle } from "../../DB/Vehicle/ObjectVehicle";

export const UserLeftFromSitePopupCard = ({ hideFn, fromGeoLocation = false, dateLable }) => {

    const dateBox = useRef(null)
    const { getRefField } = useContext(PageContext)
    const { oWorkOrderTeams } = useContext(WorkOrderContext)
    const { oAddress, leftFromSite } = useContext(LocationContext);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupObject, setPopupObject] = useState({});
    const [popupType, setPopupType] = useState(false);

    const showInfo = useCallback(// eslint-disable-line
        async (object, type) => {
            let title = "Unable to leave site yet, please review the below and fix";
            setPopupTitle(title)
            setPopupObject(object);
            setPopupType(type);
            setPopupVisible(true);
        },
        [setPopupVisible],
    );

    const hideInfo = useCallback(async () => {
        hideFn();
        setPopupVisible(false);
    }, [setPopupVisible]);// eslint-disable-line

    const getPopupContent = () => {
        if (popupType === 'Driver') {
            return <ValidationErrorCard {...{ oErrorList: popupObject, functions: { hideInfo: hideInfo } }} />
        }
        else if (popupType === 'TeamLeader') {
            return <ValidationErrorCard {...{ oErrorList: popupObject, functions: { hideInfo: hideInfo } }} />
        }
        else if (popupType === 'Crew') {
            return <ValidationErrorCard {...{ oErrorList: popupObject, functions: { hideInfo: hideInfo } }} />
        }
    }

    //const crewPositionValidation = () => {
    // getObjectUsers objectType =WorkOrderTeam and userGuid
    // get workOrderTeams

    // if workorderlisttype == all
    // get scheduledjobs where addressGuid
    // else
    // get scheduledJobs -> filter form workOrderTeams
    // get objectCodes where objecyType = ObjectUser and objectGuid == objectUserGuid and 'codeTypeSystemName': 'crewposition'
    // if codeType == suppervisor
    // validate all devices have a report and are either report complete
    // or device has been marked as not cleaned
    // or a non complete reason has been entered
    // if validation fails display message and let them know to fix it or allocate someone to complete

    // if not suppervisor check for object mark on address where type is userToComplete and value is userGuid
    // if object marks mark is logged in users guid then validate
    // return true;
    // }

    const driverValidation_GetDriverVehicles = async () => {
        const oUser = getAuthenticatedUser();
        const oObjectUsers = await getObjectUsers(['objectType', 'userGuid', 'active'], ['ObjectVehicle', oUser.userGuid, 'true']);
        if (!oObjectUsers || oObjectUsers.length === 0) {
            return [];
        }
        let userVehicleGuids = [...new Set(oObjectUsers.map((x) => { return x.objectGuid }))]
        let proms = userVehicleGuids.map((objectVehicleGuid) => { return getObjectVehicle({ 'objectVehicleGuid': objectVehicleGuid }) })
        let results = await Promise.all(proms);
        let oVehicles = [];
        oVehicles = results.filter((x) => { return x.systemType === 'Truck' })
        return oVehicles;
    }

    const driverValidation_ValidateScheduledJob = async (oScheduledJob, vehicleID) => {
        const oJobTypeSite = await getJobType({ systemType: 'Site' });
        const oVehicle = await getVehicle({ id: vehicleID });
        let keyValueObject = {
            'addressGuid': oAddress.addressGuid,
            'jobTypeGuid': oJobTypeSite.jobTypeGuid,
            'jobGuid': oScheduledJob.jobScheduleGuid,
            'vehicleID': oVehicle.vehicleID,
            'deleted': 'false'
        }
        let oLoadedWaste = await getLoadedWaste(keyValueObject);
        let errorObject = null;
        if (!oLoadedWaste) {
            let deviceType = oScheduledJob?.addressDevice?.deviceTypeName;
            let assetID = oScheduledJob?.addressDevice?.assetID ? oScheduledJob?.addressDevice?.assetID : ''
            errorObject = {
                objectType: "scheduledJob",
                objectGuid: oScheduledJob.jobScheduleGuid,
                objectProperty: "Waste",
                errorMessage: 'Waste not loaded onto your truck for ' + deviceType + ' - ' + assetID
            }
        }
        return errorObject;
    }

    const driverValidation = async () => {
        let isValid = true;
        let vallidationErrors = [];
        const oVehicles = await driverValidation_GetDriverVehicles();
        if (oVehicles.length === 0) {
            return {
                isValid: true,
                vallidationErrors: vallidationErrors
            }
        }
        const vehicles = [...new Set(oVehicles.map((x) => { return x.vehicleID }))]
        const addressScheduledJobs = oWorkOrderTeams.map((oWorkOrderTeam) => { return oWorkOrderTeam.scheduledJobs.filter((oScheduledJob) => { return oScheduledJob.addressGuid === oAddress.addressGuid }) }).flat();
        let proms = addressScheduledJobs.map((oScheduledJob) => { return vehicles.map((vehicleID) => { return driverValidation_ValidateScheduledJob(oScheduledJob, vehicleID) }) }).flat();
        const results = await Promise.all(proms);
        vallidationErrors = results.filter((x) => { return x !== null });

        isValid = vallidationErrors.length === 0

        if (!isValid) {
            let errorObject = {
                objectProperty: "Waste",
                errorMessage: 'If you have not picked up anything for the above devices, please enter N/A.'
            }
            vallidationErrors.push(errorObject);
        }
        return {
            isValid: isValid,
            vallidationErrors: vallidationErrors
        }
    }

    const leftFromSiteValidation = async () => {
        const driverValid = await driverValidation();
        if (driverValid.isValid) {
            return true;
        }
        else {
            showInfo(driverValid.vallidationErrors, 'Driver')
            return false;
        }
    }


    const getMessage = () => {
        if (oAddress && fromGeoLocation) {
            return 'GPS has shown you are not within 100m of a device, Have you left ' + oAddress.dsmNo + ' - ' + oAddress.formattedAddress
        }
        else if (fromGeoLocation) {
            return 'GPS has shown you are not within 100m of a device, Have you left site'
        }
    }

    const saveBtnClicked = async () => {
        let eventTime = getRefField('value', dateBox);
        eventTime = dayjs(eventTime).format('YYYY-MM-DDTHH:mm:ss');
        const isValid = await leftFromSiteValidation(eventTime);
        if (isValid) {
            await leftFromSite(eventTime)
            hideFn();
        }
    } 

    const getArriveNotes = ()=>{
        let notes = '';
        if(oAddress?.customerAppCodeSystemName && oAddress?.customerAppCodeSystemName !=='None'){
            notes = notes + 'This is a '+oAddress?.customerAppCode+' App site - you MUST sign off to the app on your phone. Contact H&S if you can not access the app or done the induction. '
        } 
        return notes
    }

    useEffect(() => {
        function fetchData() {
            dateBox.current.instance.option('value', dayjs().format('YYYY-MM-DD HH:mm'))
        }
        fetchData()
    }, []);// eslint-disable-line

    return <>
        <Popup
            visible={popupVisible}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            title={popupTitle}
            container=".dx-viewport"
            width='95%'
            height='95%'
            displayFormat="dd/MM/yyyy hh:mm"
        >
            <div className="small-12 grid-x height100p" key={uuidv4()}>
                {getPopupContent()}
            </div>

        </Popup>
        <div className="small-12 grid-x popupContents">
            <div className="small-12 grid-x">
                {getMessage()}
            </div>
            <div className="small-12 heightAuto">
                <div className="small-12 arriveNotes marginBottom10px heightAuto">{getArriveNotes()}</div>
            </div>            
            <div className="small-12 grid-x height40px">
                <div className="small-12 grid-x height40px">
                    <DateBox
                        ref={dateBox}
                        label={dateLable}
                        labelMode="floating"
                        type="datetime"
                        width="100%"
                        height="100%"
                        showDropDownButton={false}
                        displayFormat="dd/MM/yyyy hh:mm"
                    />
                </div>
                <div className="small-12 grid-x height40px">
                    <div className="small-12 grid-x left height40px">
                        <Button
                            onClick={() => { saveBtnClicked() }}
                            text='Save'
                            height='40px'
                            width='100%'
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default UserLeftFromSitePopupCard