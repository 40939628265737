
import { getEquipment_API, getObjectEquipment_API } from '../../API/Equipment' 
import { getEquipment, putEquipment } from '../../DB/Equipment/Equipment';
import { getObjectEquipment, putObjectEquipment } from '../../DB/Equipment/ObjectEquipment';
import { putError } from "../../DB/Error/Error";
export async function syncFromApiEquipments(){
    let promises = []
    try{
        promises.push(syncFromApiEquipment_Equipments());
        promises.push(syncFromApiEquipment_ObjectEquipments());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiEquipment'}, 'Sync/FromAPI/Equipment', 'syncFromApiEquipment')
        return Promise.resolve()
    } 
}

async function syncFromApiEquipment_PutEquipment(data){
    let oData =  await getEquipment({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putEquipment(data)
}
 
async function syncFromApiEquipment_Equipments(){ 
    try{
        const dataFromApi = await getEquipment_API(); 
        if(!dataFromApi){
            return;
        }       
        const putProms = dataFromApi.map((data)=>{return syncFromApiEquipment_PutEquipment(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiDevice_Equipment'}, 'Sync/FromAPI/Equipment', 'syncFromApiEquipment')
        return Promise.resolve()
    }  
}

async function syncFromApiEquipment_PutObjectEquipment(data){
    let oData =  await getObjectEquipment({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putObjectEquipment(data)
}


async function syncFromApiEquipment_ObjectEquipments(){
    try{
        const dataFromApi = await getObjectEquipment_API(); 
        if(!dataFromApi){
            return;
        }        
        const putProms = dataFromApi.map((data)=>{return syncFromApiEquipment_PutObjectEquipment(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiDevice_ObjectEquipment'}, 'Sync/FromAPI/Equipment', 'syncFromApiEquipment')
        return Promise.resolve()
    }   
}
