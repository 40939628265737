import BaseObject from "../Base/BaseObject";  
export class HazardSubstance extends BaseObject{
    constructor(
        hazardSubstanceID,
        hazardSubstanceGuid, 
        name, 
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''   
    ){
        super(
            hazardSubstanceID,
            hazardSubstanceGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode            
        );
        this.hazardSubstanceID = hazardSubstanceID;
        this.hazardSubstanceGuid = hazardSubstanceGuid; 
        this.name = name;  
    }
}