import BaseObject from "../Base/BaseObject";  
export class ObjectMeasure extends BaseObject {
    constructor(  
        objectMeasureGuid,
        unitTypeGuid,
        measure,
        objectType, 
        objectGuid,
        referenceGuid,
        measureTypeID,
        measureTypeGuid,
        measureType,
        measureTypeSystemName,
        active,
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            objectMeasureGuid,
            objectMeasureGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.objectMeasureGuid = objectMeasureGuid;
        this.unitTypeGuid = unitTypeGuid;
        this.referenceGuid = referenceGuid;
        this.measure = measure;
        this.objectType = objectType; 
        this.objectGuid = objectGuid
        this.measureTypeID = measureTypeID;
        this.measureTypeGuid = measureTypeGuid;
        this.measureType = measureType;
        this.measureTypeSystemName = measureTypeSystemName;
        this.unitType = {}; 
    }

}
