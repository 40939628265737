import { db } from "../db";
import { putError } from "../Error/Error";
import { ObjectMark } from "../../Model/Mark/ObjectMark"

export function populateObject_ObjectMark(objectMark) {
    if (!objectMark) {
        return null
    }
    let oObjectMark = new ObjectMark(
        objectMark.objectMarkGuid,
        objectMark.objectType,
        objectMark.objectGuid,
        objectMark.referenceGuid,
        objectMark.markID,
        objectMark.markGuid,
        objectMark.markValue,
        objectMark.markTypeID,
        objectMark.markTypeGuid,
        objectMark.markType,
        objectMark.markTypeSystemName,
        (typeof objectMark.active === 'string' && objectMark.active === 'true') || (typeof objectMark.active === 'boolean' && objectMark.active) ? 'true' : 'false',
        objectMark.createdDate,
        objectMark.lastModifiedDate,
        objectMark.companyID,
        objectMark.regionID,
        (typeof objectMark.sent === 'string' && objectMark.sent === 'true') || (typeof objectMark.sent === 'boolean' && objectMark.sent) ? 'true' : 'false',
        objectMark.mode ? objectMark.mode : '',
    )
    return oObjectMark
}


async function getObjectMark_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const objectMark = await db.object_mark.get(keyValueObject);
    const oObjectMark = populateObject_ObjectMark(objectMark)
    return oObjectMark
}

async function getObjectMarks_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const objectMarks = await db.object_mark
        .where(where)
        .equals(equals)
        .toArray();
    const oObjectMarks = objectMarks.map((objectMark)=>{return populateObject_ObjectMark(objectMark)})    
    return oObjectMarks
}

async function putObjectMark_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.object_mark.put(oData, oData.id);
    return;
}

async function deleteObjectMarks_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_mark
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getObjectMark(keyValueObject) { 
    try { 
        let oData = await getObjectMark_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectMark.js', 'getObjectMark')
        return
    }
}

export async function getObjectMarks(where, equals) {
    try { 
        let oData = await getObjectMarks_db(where,equals)
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'ObjectMark.js', 'getObjectMarks')
        return
    }
}

export async function putObjectMark(oData) {
    try { 
        await putObjectMark_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectMark.js', 'putObjectMark')
        return
    }
}

export async function deleteObjectMarks(where, equals) {
    try { 
        await deleteObjectMarks_db(where,equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'ObjectMark.js', 'deleteObjectMarks')
        return
    }
}
 