import { getAddresses_API } from "../../API/Address"; 
import { getAddressBranchs_API } from '../../API/AddressBranch';
import { getAddressDevices_API, getAddressDeviceProerties_API } from '../../API/AddressDevice';
import { getAddress, putAddress } from "../../DB/Address/Address";
import { getAddressBranch, putAddressBranch } from "../../DB/Address/AddressBranch";
import { getAddressDevice, putAddressDevice } from "../../DB/Address/AddressDevice";
import { getAddressDeviceProperty, putAddressDeviceProperty } from "../../DB/Address/AddressDeviceProperty";
import { putError } from "../../DB/Error/Error";

export async function syncFromApiAddresses(){
    let promises = []
    try{
        promises.push(syncFromApiAddress_Addresses());
        promises.push(syncFromApiAddress_AddressBranchs());
        promises.push(syncFromApiAddress_AddressDevices());
        promises.push(syncFromApiAddress_AddressDeviceProerties()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiAddress'}, 'Sync/FromAPI/Address', 'syncFromApiAddress')
        return Promise.resolve()
    } 
}

async function syncFromApiAddress_PutAddress(data){
    let oData = await getAddress({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putAddress(data)
}

async function syncFromApiAddress_Addresses(){
    try{
        const dataFromApi = await getAddresses_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiAddress_PutAddress(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiAddress_Address'}, 'Sync/FromAPI/Address', 'syncFromApiAddress')
        return Promise.resolve()
    } 
}

async function syncFromApiAddress_PutAddressBranch(data){
    let oData = await getAddressBranch({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putAddressBranch(data)
}

async function syncFromApiAddress_AddressBranchs(){
    try{
        const dataFromApi = await getAddressBranchs_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiAddress_PutAddressBranch(data)});
        await Promise.all(putProms);
        return;

    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiAddress_AddressBranchs'}, 'Sync/FromAPI/Address', 'syncFromApiAddress')
        return Promise.resolve()
    } 
}

async function syncFromApiAddress_PutAddressDevice(data){
    let oData = await getAddressDevice({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putAddressDevice(data)
}

async function syncFromApiAddress_AddressDevices(){
    try{
        const dataFromApi = await getAddressDevices_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiAddress_PutAddressDevice(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiAddress_AddressDevices'}, 'Sync/FromAPI/Address', 'syncFromApiAddress')
        return Promise.resolve()
    }
     
}

async function syncFromApiAddress_PutAddressDeviceProperty(data){
    let oData = await getAddressDeviceProperty({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putAddressDeviceProperty(data)
}

async function syncFromApiAddress_AddressDeviceProerties(){
    try{
        const dataFromApi = await getAddressDeviceProerties_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiAddress_PutAddressDeviceProperty(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiAddress_AddressDeviceProerties'}, 'Sync/FromAPI/Address', 'syncFromApiAddress')
        return Promise.resolve()
    } 
}