import BaseObject from "../Base/BaseObject";
export class PrestartMeeting extends BaseObject {
    constructor(
        prestartMeetingID,
        prestartMeetingGuid,
        userID,
        jobScheduleID,
        jobSupervisorUserID,
        meetingHeldByUserID,
        completionDate,
        scheduledStartDate,
        addressID,
        ctTrained,
        ctWearingPPE,
        ctCrewFit,
        ctCrewAuthourized,
        ctCorrectEquipment,
        ctToolsCondition,
        hiHazardRegistered,
        hiWorksite,
        hiCardinalRule,
        hiPhysical,
        hiErgonomic,
        envDamage,
        envImpact,
        qualInspected,
        qualMaterial,
        qualEquipment,
        prmtSpaceEntry,
        prmtWorkingHeight,
        prmtHotWork,
        prmtWorkingAround,
        prmtNZWorksafe,
        statusGuid,
        sent,
        active,
        mode,
        createdDate,
        lastModifiedDate,
        companyID,
        regionID
    ) {
        super(
            prestartMeetingGuid,
            prestartMeetingGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.prestartMeetingID = prestartMeetingID;
        this.prestartMeetingGuid = prestartMeetingGuid;
        this.userID = userID;
        this.jobScheduleID = jobScheduleID;
        this.jobSupervisorUserID = jobSupervisorUserID;
        this.meetingHeldByUserID = meetingHeldByUserID;
        this.completionDate = completionDate;
        this.scheduledStartDate = scheduledStartDate;
        this.addressID = addressID;
        this.ctTrained = ctTrained;
        this.ctWearingPPE = ctWearingPPE;
        this.ctCrewFit = ctCrewFit;
        this.ctCrewAuthourized = ctCrewAuthourized;
        this.ctCorrectEquipment = ctCorrectEquipment;
        this.ctToolsCondition = ctToolsCondition;
        this.hiHazardRegistered = hiHazardRegistered;
        this.hiWorksite = hiWorksite;
        this.hiCardinalRule = hiCardinalRule;
        this.hiPhysical = hiPhysical;
        this.hiErgonomic = hiErgonomic;
        this.envDamage = envDamage;
        this.envImpact = envImpact;
        this.qualInspected = qualInspected;
        this.qualMaterial = qualMaterial;
        this.qualEquipment = qualEquipment;
        this.prmtSpaceEntry = prmtSpaceEntry;
        this.prmtWorkingHeight = prmtWorkingHeight;
        this.prmtHotWork = prmtHotWork;
        this.prmtWorkingAround = prmtWorkingAround;
        this.prmtNZWorksafe = prmtNZWorksafe;
        this.statusGuid = statusGuid;
        this.prestartTasks = []
    }
}