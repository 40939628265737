import { db } from "../db";
import { putError } from "../Error/Error";
import { JobSafetyDeviceReport } from "../../Model/JobSafetyReport/JobSafetyDeviceReport";
import { getFullJobSafetyDeviceReportActions } from "./JobSafetyDeviceReportAction";

export function populateObject_JobSafetyDeviceReport(data) {
    if (!data) {
        return null
    }
    let oData = new JobSafetyDeviceReport(
        data.jobSafetyDeviceReportGuid,
        data.jobSafetyReportHeaderGuid,
        data.deviceTypeID,
        data.deviceTypeGuid, 
        data.jobScheduleID, 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oData
} 

async function populateJobSafetyDeviceReportLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try { 
        oData.jobSafetyDeviceReportActions = await getFullJobSafetyDeviceReportActions(['jobSafetyDeviceReportGuid','active'],[oData.jobSafetyDeviceReportGuid, 'true']);
        return oData
    }
    catch (error) {
        await putError(error, oData, 'JobSafetyHazard.js', 'populateJobSafetyDeviceMappingLinkedObjects')
        return oData
    }
}

async function getJobSafetyDeviceReport_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.job_safety_device_reports.get(keyValueObject);
    let oData = populateObject_JobSafetyDeviceReport(data);
    return oData;
}

async function getJobSafetyDeviceReports_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.job_safety_device_reports
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_JobSafetyDeviceReport(object) })
    return oData;
}

async function putJobSafetyDeviceReport_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_safety_device_reports.put(oData, oData.id)
    return
}

async function deleteJobSafetyDeviceReports_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_safety_device_reports
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getJobSafetyDeviceReport(keyValueObject) {
    try {
        let oData = await getJobSafetyDeviceReport_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobSafetyDeviceReport.js', 'getJobSafetyDeviceReport')
        return
    }
}

export async function getFullJobSafetyDeviceReport(keyValueObject) {
    try {
        let oData = await getJobSafetyDeviceReport(keyValueObject);
        oData = await populateJobSafetyDeviceReportLinkedObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error,keyValueObject,'JobSafetyDeviceReport.js','getFullJobSafetyDeviceReport') 
        return Promise.resolve(null);
    }
}
 
export async function getJobSafetyDeviceReports(where, equals) {
    try {
        let oData = await getJobSafetyDeviceReports_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyDeviceReport.js', 'getJobSafetyDeviceReports')
        return
    }
}
 
export async function getFullJobSafetyDeviceReports(where, equals) {
    try {
        const oData = await getJobSafetyDeviceReports(where, equals)
        let proms = oData.map((object) => { return populateJobSafetyDeviceReportLinkedObjects(object) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'JobSafetyDeviceReport.js','getFullJobSafetyDeviceReports')  
        return Promise.resolve([]);
    }
}

export async function putJobSafetyDeviceReport(oData) {
    try {
        await putJobSafetyDeviceReport_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobSafetyDeviceReport.js', 'putJobSafetyDeviceReport')
        return
    }
}

export async function deleteJobSafetyDeviceReports(where, equals) {
    try {
        await deleteJobSafetyDeviceReports_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyDeviceReport.js', 'deleteJobSafetyDeviceReports')
        return
    }
}
