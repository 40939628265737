import Api from './Api.jsx';
import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import { populateObject_User } from '../DB/User/User'
import { populateObject_ObjectUser } from '../DB/User/ObjectUser'
import { putError } from '../DB/Error/Error.js';

export function getStaff_API(userType) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/User/getStaff', {
        params: {
            UserGuid: userGuid,
            UserType: userType
        },
    }).then(res => {
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
        let oDataList = []
        dataList.forEach((data) => {
            data.sent = 'true'
            let oData = populateObject_User(data)
            oDataList.push(oData)
        });
        return oDataList;
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        UserType: userType
                    },
                    'User',
                    'getStaff_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getObjectUsers_API() {
    const userGuid = getAuthenticatedUserGuid();
    let workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/user/getObjectUsers', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    }).then(res => {
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
        let oDataList = []
        dataList.forEach((data) => {
            data.sent = 'true'
            let oData = populateObject_ObjectUser(data)
            oDataList.push(oData)
        });
        return oDataList;
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'User',
                    'getObjectUsers_API'
                )
                return Promise.reject(error)
            }
        )
}

export function upsertObjectUser_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.active = object.active === 'true';
    return Api.post('/user/upsertObjectUser',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oData = populateObject_ObjectUser(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'User',
                    'upsertObjectUser_API'
                )
                return Promise.reject(error)
            }
        )
}