import { SyncToSource, CleanUpIndexDB } from "./DataSync";

export function clearLogoutLocalStorage() {
    // BMC: To DO. Add call to deregister device
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshAuthToken');
    localStorage.removeItem('userGuid');
    localStorage.removeItem('user');
    localStorage.removeItem('workOrderDate');
    localStorage.removeItem('workOrderListType');
    localStorage.removeItem('reportObjectGuid');
    localStorage.removeItem('reportDefinitionSystemName');
    localStorage.removeItem('report');
    localStorage.removeItem('address');
    localStorage.removeItem('scheduledJob');
    localStorage.removeItem('userDismissedOffSite');

}

export function clearLogoutIndexDb() {
    // BMC: To DO. Add call to deregister device

}

export async function logOutUtils(sync=true) {
    if(sync){
        await SyncToSource(); 
    }
    await CleanUpIndexDB('sent','true'); 
    clearLogoutLocalStorage()
}

export function setAuthToken(token) {
    localStorage.setItem('authToken', token);
}

export function setRefreshToken(token) {
    localStorage.setItem('refreshAuthToken', token);
}
 
export function getAuthToken() { 
    const Access_Token = localStorage.getItem("authToken")
    return Access_Token
}

export function getRefreshToken() {
    const Refresh_Token = localStorage.getItem("refreshAuthToken")
    return Refresh_Token
} 

export function getAuthenticatedUser() {
    const user = localStorage.getItem('authUser');
    return user? JSON.parse(user):null
}

export function getAuthenticatedUserGuid() {
    const user = getAuthenticatedUser() 
    return user? user.userGuid : null
}