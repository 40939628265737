import BaseObject from "../Base/BaseObject";  
export class StatusType extends BaseObject {
    constructor(
        statusTypeID,
        statusTypeName, 
        statusTypeGuid, 
        companyID,
        regionID 

    ) {
        super(
            statusTypeID,
            statusTypeGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.statusTypeID = statusTypeID;
        this.statusTypeGuid = statusTypeGuid;
        this.statusTypeName = statusTypeName; 
    } 

}