import { getAssemblyPoints_API } from '../../API/AssemblyPoint'; 
import { getAssemblyPoint, putAssemblyPoint } from '../../DB/AssemblyPoint/AssemblyPoint';
import { putError } from "../../DB/Error/Error";
export async function syncFromApiAssemblyPoints(){
    let promises = []
    try{
        promises.push(syncFromApiAssemblyPoint_AssemblyPoints()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiAssemblyPoint'}, 'Sync/FromAPI/AssemblyPoint', 'syncFromApiAssemblyPoint')
        return Promise.resolve()
    } 
}

async function syncFromApiAssemblyPoint_PutAssemblyPoint(data){
    let oData = await getAssemblyPoint({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putAssemblyPoint(data)
}

async function syncFromApiAssemblyPoint_AssemblyPoints(){
    try{
        const dataFromApi = await getAssemblyPoints_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiAssemblyPoint_PutAssemblyPoint(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncFromApiCode_AssemblyPoints'}, 'Sync/FromAPI/AssemblyPoint', 'syncFromApiAssemblyPoint')
        return Promise.resolve() 
    }
}