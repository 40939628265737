import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils' 
import Api from './Api.jsx';
import { putError } from '../DB/Error/Error.js';
import {  populateObject_Address } from '../DB/Address/Address'  

export function getAddresses_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Address/getAddresses', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => { 
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
            let oDataList = []
            dataList.forEach((data) => { 
                data.sent = 'true'
                let oData = populateObject_Address(data)
                oDataList.push(oData)
            }); 
            return oDataList; 
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    { UserGuid: userGuid,WorkOrderDate: workOrderDate},
                    'Address',
                    'getAddresses_API'
                )
                return Promise.reject(error);
            }
        ) 
}