import { upsertObjectEvent_API } from "../../API/Event";
import { getObjectEvents, putObjectEvent } from "../../DB/Event/ObjectEvent";
import { putError } from "../../DB/Error/Error";
 
export async function syncToApiObjectEvents(){
    try{
        const dataToApi = await getObjectEvents('sent','false');
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertObjectEvent_API(data)}));  
        if(!dataFromApi){
            return;
        }
        await Promise.all(dataFromApi.map((data)=>{return putObjectEvent(data)})); 
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiObjectEvents'}, 'Sync/ToAPI/ObjectEvent', 'syncToApiObjectEvents')
        return Promise.resolve();
    }
}
