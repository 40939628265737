import { db } from "../db";
import { putError } from "../Error/Error";
import DataSource from 'devextreme/data/data_source';
import { Item } from "../../Model/Item/Item";

export function populateObject_Item(data) {
    if (!data) {
        return null;
    }
    let oItem = new Item(
        data.itemID,
        data.itemGuid,
        data.itemSystemName,
        data.itemName,
        data.itemCode,
        data.itemDescription,
        data.htmlCode,
        data.itemTypeID,
        data.itemTypeGuid,
        data.itemType,
        data.itemTypeSystemType,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    );
    return oItem;
}

async function getItem_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const item = await db.items.get(keyValueObject)
    let oItem = populateObject_Item(item)
    return oItem
}

async function getItems_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const items = await db.items
        .where(where)
        .equals(equals)
        .toArray();
    const oItems = items.map((item) => { return populateObject_Item(item) })
    return oItems
}

async function putItem_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.items.put(oData, oData.id);
    return;
}

async function deleteItems_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.items
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getLookupDataSourceItems(where, equals, addEmpty = false) {
    try {
        const oItems = await getItems(where, equals)
        let oData = oItems.map(function (x) { return { value: x.itemID, text: x.itemCode } })
        if (addEmpty) {
            oData.unshift({ value: "", text: "" })
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource
    }
    catch (error) {
        await putError(error, { where, equals, addEmpty }, 'Item.js', 'getLookupDataSourceItems')
        return
    }
}

export async function getItem(keyValueObject) {
    try {
        let oData = await getItem_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'Item.js', 'getItem')
        return
    }
}

export async function getItems(where, equals) {
    try {
        let oData = await getItems_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'Item.js', 'getItems')
        return
    }
}

export async function putItem(oData) {
    try {
        await putItem_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'Item.js', 'putItem')
        return
    }
}

export async function deleteItems(where, equals) {
    try {
        await deleteItems_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'Item.js', 'deleteItems')
        return
    }
}
