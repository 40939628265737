import { getObjectVehicles_API, getVehicles_API, getVehicleTypes_API } from '../../API/Vehicle'
import { putError } from "../../DB/Error/Error";
import { getVehicle, putVehicle } from "../../DB/Vehicle/Vehicle";
import { getVehicleType, putVehicleType } from "../../DB/Vehicle/VehicleType";
import { getObjectVehicle, putObjectVehicle } from "../../DB/Vehicle/ObjectVehicle";
export async function syncFromApiVehicles(){
    let promises = []
    try{
        promises.push(syncFromApiVehicle_Vehicles()); 
        promises.push(syncFromApiVehicle_VehicleTypes());
        promises.push(syncFromApiVehicle_ObjectVehicles()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiVehicle'}, 'Sync/FromAPI/Vehicle', 'syncFromApiVehicle')
        return Promise.resolve()
    } 
}

async function syncFromApiVehicle_PutVehicle(data){
    let oData =  await getVehicle({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putVehicle(data)
}

async function syncFromApiVehicle_Vehicles(){ 
    try{
        const dataFromApi = await getVehicles_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiVehicle_PutVehicle(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiVehicle_Vehicles'}, 'Sync/FromAPI/Vehicles', 'syncFromApiVehicle')
        return Promise.resolve()
    } 
}

 
async function syncFromApiVehicle_PutVehicleType(data){
    let oData =  await getVehicleType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putVehicleType(data)
}

async function syncFromApiVehicle_VehicleTypes(){ 
    try{
        const dataFromApi = await getVehicleTypes_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiVehicle_PutVehicleType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiVehicle_VehicleTypes'}, 'Sync/FromAPI/Vehicle', 'syncFromApiVehicle')
        return Promise.resolve()
    } 
}

  
async function syncFromApiVehicle_PutObjectVehicle(data){
    let oData =  await getObjectVehicle({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putObjectVehicle(data)
}

async function syncFromApiVehicle_ObjectVehicles(){ 
    try{
        const dataFromApi = await getObjectVehicles_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiVehicle_PutObjectVehicle(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiVehicle_ObjectVehicles'}, 'Sync/FromAPI/Vehicle', 'syncFromApiVehicle')
        return Promise.resolve()
    } 
}
  