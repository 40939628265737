import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';  
import { populateObject_ScheduledJob } from '../DB/Scheduledjob/Scheduledjob.js' 
import { putError } from '../DB/Error/Error.js';

export function getWorkOrderJobs_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/ScheduledJob/getWorkOrderJobs', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_ScheduledJob(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    { 
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'ScheduledJob',
                    'getWorkOrderJobs_API'
                )
                return Promise.reject(error)
            }
        )         
}