import BaseObject from "../Base/BaseObject";   
export default class AddressDevice extends BaseObject {
    constructor(
        addressDeviceID,
        quantity,
        addressGuid,
        addressDeviceGuid,
        assetID,
        comment,
        deviceDSMDescription,
        assetNumber,
        contractNumber,
        trafficManagement,
        serviceCycleStartYear,
        serviceCycleLenght,
        deviceTypeID,
        deviceTypeGuid,
        deviceTypeCode,
        deviceTypeName,
        deviceTypeSystemName,
        deviceColour,
        deviceTextColour,
        calculationType,
        tMPLevel1,
        tMPLevel2,
        tMPLevel3,
        vaultShape,
        vaultLength,
        vaultWidth,
        vaultHeight,
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            addressDeviceID,
            addressDeviceGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.addressDeviceID = addressDeviceID;
        this.quantity = quantity;
        this.addressGuid = addressGuid;
        this.addressDeviceGuid = addressDeviceGuid;
        this.assetID = assetID;
        this.comment = comment;
        this.deviceDSMDescription = deviceDSMDescription;
        this.assetNumber = assetNumber;
        this.contractNumber = contractNumber;
        this.trafficManagement = trafficManagement;
        this.serviceCycleStartYear = serviceCycleStartYear;
        this.serviceCycleLenght = serviceCycleLenght;
        this.deviceTypeID = deviceTypeID;
        this.deviceTypeGuid = deviceTypeGuid;
        this.deviceTypeCode = deviceTypeCode;
        this.deviceTypeName = deviceTypeName;
        this.deviceTypeSystemName = deviceTypeSystemName;
        this.deviceColour = deviceColour;
        this.deviceTextColour = deviceTextColour;
        this.calculationType = calculationType;
        this.tMPLevel1 = tMPLevel1;
        this.tMPLevel2 = tMPLevel2;
        this.tMPLevel3 = tMPLevel3;
        this.vaultShape = vaultShape;
        this.vaultLength = vaultLength;
        this.vaultWidth = vaultWidth;
        this.vaultHeight = vaultHeight; 
        this.coordinates = [];
        this.scheduledJob = [];
        this.addressDeviceProperties = [];
    }    
} 