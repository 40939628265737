import { db } from "../db";
import { putError } from "../Error/Error";
import { ObjectMeasure } from "../../Model/Measure/ObjectMeasure";

export function populateObject_ObjectMeasure(data) {
    if (!data) {
        return null
    }
    let oData = new ObjectMeasure( 
        data.objectMeasureGuid,
        data.unitTypeGuid,
        data.measure,
        data.objectType, 
        data.objectGuid,
        data.referenceGuid,
        data.measureTypeID,
        data.measureTypeGuid,
        data.measureType,
        data.measureTypeSystemName,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate, 
        data.lastModifiedDate, 
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode 
    )

    return oData
}

async function getObjectMeasure_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const objectMeasure = await db.object_measure.get(keyValueObject)
    const oObjectMeasure = populateObject_ObjectMeasure(objectMeasure)
    return oObjectMeasure
}

async function getObjectMeasures_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const objectMeasures = await db.object_measure
        .where(where)
        .equals(equals)
        .toArray();
    const oObjectMeasures = objectMeasures.map((objectMeasure) => { return populateObject_ObjectMeasure(objectMeasure) })
    return oObjectMeasures
}

async function putObjectMeasure_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.object_measure.put(oData, oData.id);
    return;
}

async function deleteObjectMeasures_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_measure
        .where(where)
        .equals(equals)
        .delete();
    return;
}
export async function getObjectMeasure(keyValueObject) {
    try {
        let oData = await getObjectMeasure_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectMeasure.js', 'getObjectMeasure')
        return
    }
}

export async function getObjectMeasures(where, equals) {
    try {
        let oData = await getObjectMeasures_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectMeasure.js', 'getObjectMeasures')
        return
    }
}

export async function putObjectMeasure(oData) {
    try {
        await putObjectMeasure_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectMeasure.js', 'putObjectMeasure')
        return
    }
}

export async function deleteObjectMeasures(where, equals) {
    try {
        await deleteObjectMeasures_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectMeasure.js', 'deleteObjectMeasures')
        return
    }
}
