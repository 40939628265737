
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({authToken, user, refreshAuthToken, children }) => {
    // State to hold the authentication token
    const [token, setToken_] = useState(authToken);
    const [oAuthUser, setAuthUser_] = useState(user);
    const [refreshToken,setRefreshToken_] = useState(refreshAuthToken);

    // Function to set the authentication token
    const setAuthToken = (newToken, newRefreshToken, newUser) => { 
        setToken_(newToken);
        setRefreshToken_(newRefreshToken);
        setAuthUser_(newUser)
        if(!newToken){
            localStorage.removeItem('authToken');
            localStorage.removeItem('refreshAuthToken');
            localStorage.removeItem('authUser');
        }
        else{ 
            localStorage.setItem('authToken', newToken);
            localStorage.setItem('refreshAuthToken', newRefreshToken);
            localStorage.setItem('authUser',JSON.stringify(newUser)); 
        }
    };

    const isLoggedIn = () => {
        if (token && oAuthUser) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => { 
        if (!token) { 
            setAuthToken(null,null,null)
        }
    }, [refreshToken, token, oAuthUser]);// eslint-disable-line
     
    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            oAuthUser,
            refreshToken,
            setAuthToken,
            isLoggedIn,
        }),
        [token, oAuthUser, refreshToken]// eslint-disable-line
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => { 
    return useContext(AuthContext);
};

export default AuthProvider;