import { db } from "../db";
import { putError } from "../Error/Error";
import { Flag } from '../../Model/Flag/Flag'

export function populateObject_Flag(flag) {
    if (!flag) {
        return null
    }
    let oFlag = new Flag(
        flag.flagID,
        flag.flagGuid,
        flag.type,
        flag.systemType,
        flag.companyID,
        flag.regionID
    )
    return oFlag;
}

async function getFlag_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const flag = await db.flags.get(keyValueObject);
    const oFlag = populateObject_Flag(flag)
    return oFlag;
}

async function getFlags_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const flags = await db.flags
        .where(where)
        .equals(equals)
        .toArray();
    let oFlags = flags.map((flag) => { return populateObject_Flag(flag) });
    return oFlags;
}

async function putFlag_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }    
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.flags.put(oData, oData.id);
    return;
}

async function deleteFlags_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.flags
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getFlag(keyValueObject) {
    try {
        let oData = await getFlag_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'Flag.js', 'getFlag')
        return
    }
}

export async function getFlags(where, equals) {
    try {
        let oData = await getFlags_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'Flag.js', 'getFlags')
        return
    }
}

export async function putFlag(oData) {
    try {
        await putFlag_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'Flag.js', 'putFlag')
        return
    }
}

export async function deleteFlags(where, equals) {
    try {
        await deleteFlags_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'Flag.js', 'deleteFlags')
        return
    }
}
