import { db } from "../db";
import { putError } from "../Error/Error";
import { HazardFormSubstance } from "../../Model/HazardForm/HazardFormSubstance";

export function populateObject_HazardFormSubstance(data) {
    if (!data) {
        return null
    }
    let oData = new HazardFormSubstance(
        data.hazardFormSubstanceID,
        data.hazardFormSubstanceGuid,
        data.hazardFormGuid,
        data.hazardSubstanceID,
        data.hazardSubstanceGuid, 
        data.name, 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',      
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode  
    )
    return oData;
}

async function getHazardFormSubstance_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.hazard_form_substances.get(keyValueObject);
    const oData = populateObject_HazardFormSubstance(data)
    return oData;
}

async function getHazardFormSubstances_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.hazard_form_substances
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_HazardFormSubstance(object) });
    return oData;
}

async function putHazardFormSubstance_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.hazard_form_substances.put(oData, oData.id);
    return;
}

async function deleteHazardFormSubstances_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.hazard_form_substances
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getHazardFormSubstance(keyValueObject) {
    try {
        let oData = await getHazardFormSubstance_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'HazardFormSubstance.js', 'getHazardFormSubstance')
        return
    }
}

export async function getHazardFormSubstances(where, equals) {
    try {
        let oData = await getHazardFormSubstances_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'HazardFormSubstance.js', 'getHazardFormSubstances')
        return
    }
}

export async function putHazardFormSubstance(oData) {
    try {
        await putHazardFormSubstance_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'HazardFormSubstance.js', 'putHazardFormSubstance')
        return
    }
}

export async function deleteHazardFormSubstances(where, equals) {
    try {
        await deleteHazardFormSubstances_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'HazardFormSubstance.js', 'deleteHazardFormSubstances')
        return
    }
}
