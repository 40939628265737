import { upsertObjectCode_API } from "../../API/Code";
import { getObjectCodes, putObjectCode } from "../../DB/Code/ObjectCode";
import { putError } from "../../DB/Error/Error";
 
export async function syncToApiObjectCodes(){
    try{
        const dataToApi = await getObjectCodes('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertObjectCode_API(data)}));  
        if(!dataFromApi){
            return;
        }
        await Promise.all(dataFromApi.map((data)=>{return putObjectCode(data)})); 
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiObjectCodes'}, 'Sync/ToAPI/ObjectCode', 'syncToApiObjectCodes')
        return Promise.resolve();
    }
}
