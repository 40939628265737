import BaseObject from "../Base/BaseObject";  
export class VehicleType extends BaseObject {
    constructor( 
        vehicleTypeID,
        vehicleTypeGuid,
        type,
        systemType,    
        companyID,
        regionID 

    ) {
        super(
            vehicleTypeID,
            vehicleTypeGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        ); 
        this.vehicleTypeID = vehicleTypeID;
        this.vehicleTypeGuid = vehicleTypeGuid;
        this.type = type;
        this.systemType = systemType; 
    }
}
 