import BaseObject from "../Base/BaseObject";  
export class HazardControl extends BaseObject{
    constructor(
        hazardControlID,
        hazardControlGuid,
        hazardInfoID,
        hazardInfoGuid,
        controlMethod,
        controlAction, 
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''   
    ){
        super(
            hazardControlID,
            hazardControlGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode            
        );
        this.hazardControlID = hazardControlID;
        this.hazardControlGuid = hazardControlGuid;
        this.hazardInfoID = hazardInfoID;
        this.hazardInfoGuid = hazardInfoGuid;
        this.controlMethod = controlMethod;
        this.controlAction = controlAction; 
    }
}