import BaseObject from "../Base/BaseObject";  
export class ObjectMark extends BaseObject {
    constructor( 
        objectMarkGuid,
        objectType, 
        objectGuid,
        referenceGuid,
        markID,
        markGuid,
        markValue,
        markTypeID,
        markTypeGuid,
        markType,
        markTypeSystemName, 
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            objectMarkGuid,
            objectMarkGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.objectMarkGuid = objectMarkGuid;
        this.objectType = objectType; 
        this.objectGuid = objectGuid;
        this.referenceGuid = referenceGuid;
        this.markID = markID;
        this.markGuid = markGuid;
        this.markValue = markValue;
        this.markTypeID = markTypeID;
        this.markTypeGuid = markTypeGuid;
        this.markType = markType;
        this.markTypeSystemName = markTypeSystemName;
    }

}