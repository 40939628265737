import BaseObject from "../Base/BaseObject";  
export default class ReportData extends BaseObject {
    constructor(
        reportDataID,
        reportDataGuid,
        workOrderDate,
        objectType,
        objectGuid,
        statusGuid,
        statusName,
        reportDefinitionID,
        reportDefinitionGuid,
        reportDefinitionName,
        reportDefinitionSystemName,
        reportDefinition,
        reportDefinitionVersion,
        isComplete,
        reportCompletedByID,
        active, 
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent,
        mode
    ) {
        super(
            reportDataGuid,
            reportDataGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.reportDataID = reportDataID;
        this.reportDataGuid = reportDataGuid;
        this.workOrderDate = workOrderDate;
        this.objectGuid = objectGuid;
        this.objectType = objectType;
        this.statusGuid = statusGuid;
        this.statusName = statusName;
        this.reportDefinitionID = reportDefinitionID;
        this.reportDefinitionGuid = reportDefinitionGuid;
        this.reportDefinitionName = reportDefinitionName;
        this.reportDefinitionSystemName = reportDefinitionSystemName;
        this.reportDefinition = reportDefinition;
        this.reportDefinitionVersion = reportDefinitionVersion;
        this.reportCompletedByID = reportCompletedByID;
        this.isComplete = isComplete;
        this.objectPhotos = [];
        this.objectMakrs = [];
        this.objectMeasures = [];
        this.objectCodes = [];
        this.objectUsers = [];
        this.objectVehicles = [];
        this.objectEvents = [];
        this.changeHistory = [];
    }
}