import Api from './Api.jsx';
import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { populateObject_Item } from '../DB/Item/Item';
import { putError } from '../DB/Error/Error.js';

export function getItems_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Item/getItems', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_Item(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Items',
                    'getItems_API'
                )
                return Promise.reject(error)
            }
        )
}