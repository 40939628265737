import Api from './Api.jsx';
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils.jsx'
import { getAuthenticatedUserGuid } from '../Utils/AuthUtils.jsx'
import { populateObject_WorkOrderEvent } from '../DB/WorkOrder/WorkOrderEvent'
import { putError } from '../DB/Error/Error.js';

export function getWorkOrderEvents_API() {
    const userGuid = getAuthenticatedUserGuid();
    let workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/WorkOrderEvent/getWorkOrderEvents', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => { 
                let oData = populateObject_WorkOrderEvent(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'WorkOrderEvent',
                    'getWorkOrderEvents_API'
                )
                return Promise.reject(error)
            }
        )
}