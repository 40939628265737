import { upsertDumpDocket_API } from "../../API/DumpDocket";
import { upsertLoadedWaste_API } from "../../API/LoadedWaste";
import { upsertTransferedWaste_API } from "../../API/transferedWaste";
import { putError } from "../../DB/Error/Error";
import { getLoadedWastes, putLoadedWaste } from "../../DB/LoadedWaste/LoadedWaste";
import { getDumpDockets, putDumpDocket } from "../../DB/DumpDocket/DumpDocket";
import { getTransferedWastes, putTransferedWaste } from "../../DB/TransferedWaste/TransferedWaste";

export async function syncToApiWaste(){
    let upsertProms = [];
    upsertProms.push(syncToApiLoadedWastes());
    upsertProms.push(syncToApiTransferedWastes());
    upsertProms.push(syncToApiDumpDockets()); 
    await Promise.all(upsertProms);
    return;
}
 
async function syncToApiLoadedWastes(){ 
    try{ 
        const dataToApi = await getLoadedWastes('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertLoadedWaste_API(data)})); 
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putLoadedWaste(data)}));  
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiLoadedWaste'}, 'Sync/ToAPI/syncToApiWaste', 'syncToApiWaste')
        return Promise.resolve();
    }
}
 
async function syncToApiTransferedWastes(){ 
    try{ 
        const dataToApi = await getTransferedWastes('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertTransferedWaste_API(data)}));   
        if(!dataFromApi){
            return;
        }
        await Promise.all(dataFromApi.map((data)=>{return putTransferedWaste(data)}));  
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiTransferedWastes'}, 'Sync/ToAPI/syncToApiWaste', 'syncToApiWaste')
        return Promise.resolve();
    }
} 

async function syncToApiDumpDockets(){ 
    try{ 
        const dataToApi = await getDumpDockets(['sent','isComplete'],['false','true']);
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertDumpDocket_API(data)})); 
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putDumpDocket(data)}));  
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiDumpDockets'}, 'Sync/ToAPI/syncToApiWaste', 'syncToApiWaste')
        return Promise.resolve();
    }
}