import BaseObject from "../Base/BaseObject";

export default class Scheduledjob extends BaseObject {
    constructor(
        jobScheduleID,
        jobScheduleGuid, 
        addressGuid,
        addressBranchGuid,
        addressDeviceGuid,
        workOrderGuid,
        workOrderTeamGuid,
        serviceTypeGuid,
        statusGuid,
        scheduledStartDate,
        scheduledFinishDate,
        siteNotes,
        isCallOut,
        requiredManHours,
        healthSafetyTime, 
        quantity,
        companyID,
        regionID,
        sent = 'true',
        mode = ''

    ) {
        super(
            jobScheduleID,
            jobScheduleGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.jobScheduleID = jobScheduleID;
        this.jobScheduleGuid = jobScheduleGuid;
        this.addressGuid = addressGuid;
        this.addressBranchGuid = addressBranchGuid;
        this.addressDeviceGuid = addressDeviceGuid;
        this.workOrderGuid = workOrderGuid;
        this.workOrderTeamGuid = workOrderTeamGuid;
        this.serviceTypeGuid = serviceTypeGuid;
        this.statusGuid = statusGuid;
        this.scheduledStartDate = scheduledStartDate;
        this.scheduledFinishDate = scheduledFinishDate;
        this.siteNotes = siteNotes;
        this.isCallOut = isCallOut;
        this.requiredManHours = requiredManHours;
        this.healthSafetyTime = healthSafetyTime;
        this.quantity = quantity 
        this.objectEvents = [];
        this.objectUsers = [];
        this.objectVehicles = [];
        this.addressDevice = {};
        this.serviceType = {}
    } ;
    objectHasEvent(eventSystemName) {
        const oEvent = this.objectEvents.filter(x => x.eventSystemName.toLocaleLowerCase() === eventSystemName.toLocaleLowerCase() && x.active === 'true').at(0);
        return typeof oEvent !== 'undefined'
    } 
}
