import { db } from "../db";
import { putError } from "../Error/Error";
import { MeasureType } from "../../Model/Measure/MeasureType";

export function populateObject_MeasureType(measureType) {
    if (!measureType) {
        return null
    }
    let oMeasreType = new MeasureType(
        measureType.measureTypeID,
        measureType.measureTypeGuid,
        measureType.measureType,
        measureType.measureTypeSystemName,
        measureType.defaultUnitTypeGuid,
        measureType.createdDate,
        measureType.lastmodifiedDate,
        measureType.companyID,
        measureType.regionID
    );

    return oMeasreType
}

async function getMeasureType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const measureType = await db.measure_type.get(keyValueObject)
    let oMeasreType = populateObject_MeasureType(measureType)
    return oMeasreType;
}

async function getMeasureTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const measureTypes = await db.measure_type
        .where(where)
        .equals(equals)
        .toArray();
    const oMeasreTypes = measureTypes.map((measureType) => { return populateObject_MeasureType(measureType); });
    return oMeasreTypes
}

async function putMeasureType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.measure_type.put(oData, oData.id);
    return
}

async function deleteMeasureTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.measure_type
        .where(where)
        .equals(equals)
        .delete();
    return
}


export async function getMeasureType(keyValueObject) {
    try {
        let oData = await getMeasureType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'MeasureType.js', 'getMeasureType')
        return
    }
}

export async function getMeasureTypes(where, equals) {
    try {
        let oData = await getMeasureTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'MeasureType.js', 'getMeasureTypes')
        return
    }
}

export async function putMeasureType(oData) {
    try {
        await putMeasureType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'MeasureType.js', 'putMeasureType')
        return
    }
}

export async function deleteMeasureTypes(where, equals) {
    try {
        await deleteMeasureTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'MeasureType.js', 'deleteMeasureTypes')
        return
    }
}
