import { db } from "../db";
import { putError } from "../Error/Error";
import { JobSafetyReportHeader } from "../../Model/JobSafetyReport/JobSafetyReportHeader"; 
import { getFullJobSafetyDeviceReports } from "./JobSafetyDeviceReport";
import { getFullJobSafetyReportAcknowledgements } from "./JobSafetyReportAcknowledgement";

export function populateObject_JobSafetyReportHeader(data) {
    if (!data) {
        return null
    }
    let oData = new JobSafetyReportHeader(
        data.jobSafetyReportHeaderGuid,
        data.jobSupervisorUserID,
        data.completionDate,
        data.addressID,
        data.statusGuid,
        data.permitsRequired,
        data.scheduledStartDate,
        data.userID,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oData
} 

async function populateJobSafetyReportHeaderLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try { 
        oData.jobSafetyDeviceReports = await getFullJobSafetyDeviceReports(['jobSafetyReportHeaderGuid','active'],[oData.jobSafetyReportHeaderGuid,'true']);
        oData.jobSafetyReportAcknowledgements = await getFullJobSafetyReportAcknowledgements(['jobSafetyReportHeaderGuid','active'],[oData.jobSafetyReportHeaderGuid,'true']);
        return oData
    }
    catch (error) {
        await putError(error, oData, 'JobSafetyHazard.js', 'populateJobSafetyDeviceMappingLinkedObjects')
        return oData
    }
}

async function getJobSafetyReportHeader_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.job_safety_report_headers.get(keyValueObject);
    let oData = populateObject_JobSafetyReportHeader(data);
    return oData;
}

async function getJobSafetyReportHeaders_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.job_safety_report_headers
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_JobSafetyReportHeader(object) })
    return oData;
}

async function putJobSafetyReportHeader_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_safety_report_headers.put(oData, oData.id)
    return
}

async function deleteJobSafetyReportHeaders_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_safety_report_headers
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getJobSafetyReportHeader(keyValueObject) {
    try {
        let oData = await getJobSafetyReportHeader_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobSafetyReportHeader.js', 'getJobSafetyReportHeader')
        return
    }
}

export async function getFullJobSafetyReportHeader(keyValueObject) {
    try {
        let oData = await getJobSafetyReportHeader(keyValueObject);
        oData = await populateJobSafetyReportHeaderLinkedObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error,keyValueObject,'JobSafetyReportHeader.js','getFullJobSafetyReportHeader') 
        return Promise.resolve(null);
    }
} 
 
export async function getJobSafetyReportHeaders(where, equals) {
    try {
        let oData = await getJobSafetyReportHeaders_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyReportHeader.js', 'getJobSafetyReportHeaders')
        return
    }
}
 
export async function getFullJobSafetyReportHeaders(where, equals) {
    try {
        const oData = await getJobSafetyReportHeaders(where, equals)
        let proms = oData.map((object) => { return populateJobSafetyReportHeaderLinkedObjects(object) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'JobSafetyReportHeader.js','getFullJobSafetyReportHeaders')  
        return Promise.resolve([]);
    }
} 

export async function putJobSafetyReportHeader(oData) {
    try {
        await putJobSafetyReportHeader_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobSafetyReportHeader.js', 'putJobSafetyReportHeader')
        return
    }
}

export async function deleteJobSafetyReportHeaders(where, equals) {
    try {
        await deleteJobSafetyReportHeaders_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyReportHeader.js', 'deleteJobSafetyReportHeaders')
        return
    }
}
