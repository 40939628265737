import { db } from "../db";
import DataSource from 'devextreme/data/data_source';
import { putError } from "../Error/Error";
import { GenericObject } from '../../Model/GenericObject/GenericObject'
import { getObjectMeasures } from "../Measure/ObjectMeasure";

export function populateObject_GenericObject(data) {
    if (!data) {
        return null
    }
    let oData = new GenericObject(
        data.genericObjectID,
        data.genericObjectGuid,
        data.objectName,
        data.genericObjectTypeID,
        data.genericObjectTypeGuid,
        data.genericObjectType,
        data.genericObjectTypeSystemName, 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    )
    return oData
}

async function populateGenericObjectDataLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try {
        let getProms = [];
        getProms.push(getObjectMeasures(['objectType', 'objectGuid'], ['GenericObject', oData.genericObjectGuid]))

        const results = await Promise.all(getProms); 
        oData.objectMeasures = results.at(0) 
    }
    catch (error) {
        await putError(error, oData, 'GenericObject.js', 'populateGenericObjectDataLinkedObjects')
    }


    return oData
}

async function getGenericObject_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.generic_objects.get(keyValueObject);
    let oData = populateObject_GenericObject(data);
    return oData;
}

async function getGenericObjects_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.generic_objects
        .where(where)
        .equals(equals)
        .toArray();

    const oData = data.map((object) => { return populateObject_GenericObject(object) });
    return oData
}

async function deleteGenericObjects_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.generic_objects
        .where(where)
        .equals(equals)
        .delete();
    return;
}

async function putGenericObject_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.generic_objects.put(oData, oData.id);
    return;
}

export async function getGenericObject(keyValueObject) {
    try {
        let oData = await getGenericObject_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'GenericObject.js', 'getGenericObject')
        return
    }
}

export async function getGenericObjects(where, equals) {
    try {
        let oData = await getGenericObjects_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'GenericObject.js', 'getGenericObjects')
        return
    }
}

export async function getFullGenericObject(keyValueObject) {
    try {
        let oData = await getGenericObject(keyValueObject);
        oData = await populateGenericObjectDataLinkedObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error,keyValueObject,'GenericObject.js','getFullGenericObject') 
        return Promise.resolve(null);
    }
}

export async function getFullGenericObjects(where, equals) {
    try {
        const oData = await getGenericObjects(where, equals)
        let proms = oData.map((data) => { return populateGenericObjectDataLinkedObjects(data) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'GenericObject.js','getFullGenericObjects')  
        return Promise.resolve([]);
    }
}

export async function getLookupDataSourceGenericObjects(where, equals, addEmpty = false, value,text) {
    try {
        const objects = await getGenericObjects(where, equals);
        let oData = objects.map(function (x) { return { value: x[value], text: x[text] };  });
        if (addEmpty) {
            oData.unshift({ value: "", text: "" });
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource;
    } catch (error) {
        await putError(error, {where, equals, addEmpty}, 'GenericObject.js', 'getLookupDataSourceGenericObjects')
        return
    }

}

export async function putGenericObject(oData) {
    try {
        await putGenericObject_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'GenericObject.js', 'putGenericObject')
        return
    }
}

export async function deleteGenericObjects(where, equals) {
    try {
        await deleteGenericObjects_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'GenericObject.js', 'deleteGenericObjects')
        return
    }
}

