import BaseObject from "../Base/BaseObject";
export class JobSafetyCategory extends BaseObject {
    constructor(
        jobSafetyCategoryID,
        jobSafetyCategoryGuid,
        categoryName, 
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobSafetyCategoryID,
            jobSafetyCategoryGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.jobSafetyCategoryID = jobSafetyCategoryID;
        this.jobSafetyCategoryGuid = jobSafetyCategoryGuid;
        this.categoryName = categoryName; 
    }

}  