import { db } from "../db";
import { putError } from "../Error/Error";
import Address from '../../Model/Address/Address'
import { getObjectUsers } from "../User/ObjectUser";
import { getObjectCoordinates } from "../Coordinate/ObjectCoordinate";

export function populateObject_Address(data) {
    if (!data) {
        return null
    }
    let oAddress = new Address(
        data.addressID,
        data.accountManagerID,
        data.addressGuid,
        data.addressBranchGuid,
        data.customerGuid,
        data.accountManagerGuid,
        data.customerName,
        data.addressName,
        data.dsmNo,
        data.formattedAddress,
        data.shortAddress,
        data.contact,
        data.siteNotes,
        data.accountManager,
        data.customerAppCode,
        data.customerAppCodeSystemName,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID
    )
    return oAddress;
}

async function populateAddressLinkedObjects(oAddress) {
    if (!oAddress) {
        return oAddress
    }
    try{
        let proms = []
        proms.push(getObjectUsers(['objectType', 'objectGuid', 'active'], ['Address', oAddress.addressGuid, 'true']))
        proms.push(getObjectCoordinates(['objectType', 'objectGuid'], ['Address', oAddress.addressGuid]));
        const results = await Promise.all(proms);
        oAddress.objectUsers = results.at(0);
        oAddress.coordinates = results.at(1);
        return oAddress
    }
    catch(error){
        await putError(error,oAddress,'Address.js','populateAddressLinkedObjects') 
        return oAddress
    } 
}

async function getAddress_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const address = await db.addresses.get(keyValueObject);
    const oAddress = populateObject_Address(address)
    return oAddress;
}

async function getAddresses_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
 
    const addresses = await db.addresses
        .where(where)
        .equals(equals)
        .toArray();
    let oAddresses = addresses.map((address)=>{return populateObject_Address(address)}) 
    return oAddresses; 
}

async function putAddress_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.addresses.put(oData, oData.id)
    return
}

async function deleteAddress_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.addresses
        .where(where)
        .equals(equals)
        .delete()
    return;
}


export async function getAddress(keyValueObject){
    try {
        let oAddres = await getAddress_db(keyValueObject); 
        return oAddres;
    }
    catch (error) {
        await putError(error,keyValueObject,'Address.js','getAddress') 
        return Promise.resolve(null);
    }
}

export async function getFullAddress(keyValueObject) {
    try {
        let oAddres = await getAddress(keyValueObject);
        oAddres = await populateAddressLinkedObjects(oAddres)
        return oAddres
    }
    catch (error) {
        await putError(error,keyValueObject,'Address.js','getFullAddress') 
        return Promise.resolve(null);
    }
}

export async function getAddresses(where, equals){
    try {
        let oAddres = await getAddresses_db(where, equals); 
        return oAddres;
    }
    catch (error) {
        await putError(error,{where, equals},'Address.js','getAddresses') 
        return Promise.resolve(null);
    }
}

export async function putAddress(oAddress){
    try {
        await putAddress_db(oAddress);
        return 
    }
    catch (error) {
        await putError(error,oAddress,'Address.js','putAddress') 
        return
    }
}

export async function deleteAddress(where, equals){
    try {
        await deleteAddress_db(where, equals); 
        return
    }
    catch (error) { 
        await putError(error,{where, equals},'Address.js','deleteAddress') 
        return
    }
}