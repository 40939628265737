import { db } from "../db";
import { putError } from "../Error/Error";
import PhotoType from "../../Model/Photo/PhotoType";

export function populateObject_PhotoType(photoType) {
    if (!photoType) {
        return
    }
    let oPhotoType = new PhotoType(
        photoType.photoTypeID,
        photoType.photoTypeGuid,
        photoType.photoTypeName,
        photoType.photoTypeSystemName,
        photoType.createdDate,
        photoType.lastModifiedDate,
        photoType.companyID,
        photoType.regionID
    );
    return oPhotoType;
}

async function getPhotoType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const photoType = await db.photo_types.get(keyValueObject)
    let oPhotoType = populateObject_PhotoType(photoType)
    return oPhotoType;
}

async function getPhotoTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const photoTypes = await db.photo_types
        .where(where)
        .equals(equals)
        .toArray();
    let oPhotoType = photoTypes.map((photoType) => { return populateObject_PhotoType(photoType) });
    return oPhotoType;
}

async function putPhotoType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.photo_types.put(oData, oData.id);
    return;
}

async function deletePhotoTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.photo_types
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getPhotoType(keyValueObject) {
    try {
        let oData = await getPhotoType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'PhotoType.js', 'getPhotoType')
        return
    }
}

export async function getPhotoTypes(where, equals) {
    try {
        let oData = await getPhotoTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'PhotoType.js', 'getPhotoTypes')
        return
    }
}

export async function putPhotoType(oData) {
    try {
        await putPhotoType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'PhotoType.js', 'putPhotoType')
        return
    }
}

export async function deletePhotoTypes(where, equals) {
    try {
        await deletePhotoTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'PhotoType.js', 'deletePhotoTypes')
        return
    }
}
