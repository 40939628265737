import { getLoadedWaste_API } from '../../API/LoadedWaste'; 
import { putError } from "../../DB/Error/Error"; 
import { getLoadedWaste, putLoadedWaste } from "../../DB/LoadedWaste/LoadedWaste";
export async function syncFromApiWastes(){ 
    let promises = []
    try{
        promises.push(syncFromApiWaste_LoadedWastes());  
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiWaste'}, 'Sync/FromAPI/Waste', 'syncFromApiWaste')
        return Promise.resolve()
    } 
}

async function syncFromApiWaste_PutLoadedWaste(data){
    let oData =  await getLoadedWaste({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putLoadedWaste(data)
}

async function syncFromApiWaste_LoadedWastes(){ 
    try{
        const dataFromApi = await getLoadedWaste_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiWaste_PutLoadedWaste(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiWaste_Staff'}, 'Sync/FromAPI/User', 'syncFromApiWaste')
        return Promise.resolve()
    } 
}
 