import { upsertReportData_API } from "../../API/Report";
import { getReportDataList, putReportData } from "../../DB/Reporting/ReportData";
import { putError } from "../../DB/Error/Error";
  
export async function syncToApiReportData(){
    try{
        const dataToApi = await getReportDataList('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertReportData_API(data)}));  
        if(!dataFromApi){
            return;
        }
        await Promise.all(dataFromApi.map((data)=>{return putReportData(data)})); 
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiReportData'}, 'Sync/ToAPI/ReportData', 'syncToApiReportData')
        return Promise.resolve();
    }
}