import { db } from "../db";
import { putError } from "../Error/Error";
import DataSource from 'devextreme/data/data_source';
import { Vehicle } from '../../Model/Vehicle/Vehicle'
import { getFullLoadedWastes } from "../LoadedWaste/LoadedWaste";

export function populateObject_Vehicle(vehicle) {
    if (!vehicle) {
        return null
    }
    let oVehicle = new Vehicle(
        vehicle.vehicleID,
        vehicle.vehicleGuid,
        vehicle.fireExtinguisherLocation,
        vehicle.firstAidKitLocation,
        vehicle.description,
        vehicle.notes,
        vehicle.vehicleCode,
        vehicle.registrationNumber,
        vehicle.targetDailyWorkValue,
        vehicle.maxWeight,
        vehicle.truckCapacityVolume,
        vehicle.vehicleTypeID,
        vehicle.vehicleTypeGuid,
        vehicle.type,
        vehicle.systemType,
        vehicle.createdDate,
        vehicle.lastModifiedDate,
        vehicle.companyID,
        vehicle.regionID
    )
    return oVehicle

}

async function populateVehicleLinkedObjects(oVehicle) {
    try {
        let proms = []
        proms.push(getFullLoadedWastes(['vehicleID', 'deleted', 'dumped', 'active'], [oVehicle.vehicleID, 'false', 'false', 'true']))
        const results = await Promise.all(proms); 
        oVehicle.loadedWastes = results.at(0)
    }
    catch (error) {
        await putError(error, oVehicle, 'Vehicle.js', 'populateVehicleLinkedObjects');
    }
    return oVehicle
}

async function getVehicle_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const vehicle = await db.vehicles.get(keyValueObject)
    let oVehicle = populateObject_Vehicle(vehicle)
    return oVehicle
}

async function getVehicles_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const vehicles = await db.vehicles
        .where(where)
        .equals(equals)
        .toArray();
    let oVehicles = vehicles.map((vehicle) => { return populateObject_Vehicle(vehicle) }).sort((a, b) => a.vehicleCode.localeCompare(b.vehicleCode));
    return oVehicles;
}

async function getAllVehicles_db() {
    const vehicles = await db.vehicles.toArray();
    let oVehicles = vehicles.map((vehicle) => { return populateObject_Vehicle(vehicle) }).sort((a, b) => a.vehicleCode.localeCompare(b.vehicleCode));
    return oVehicles;
}

async function putVehicle_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.vehicles.put(oData, oData.id);
    return;
}

async function deleteVehicles_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    return db.vehicles
        .where(where)
        .equals(equals)
        .delete()
}

export async function getLookupDataSourceVehicles(addEmpty = false) {
    try {
        const oVehicles = await getAllVehicles();
        let oData = oVehicles.map((x)=> { return { value: x.vehicleID, text: x.vehicleCode }; });
        if (addEmpty) {
            oData.unshift({ value: "", text: "" });
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource;
    } catch (error) {
        await putError(error, {addEmpty}, 'Vehicle.js', 'getLookupDataSourceVehicles')
        return
    }
}

export async function getFullVehicles(where, equals) {
    try {
        const oVehicles = await getVehicles(where, equals) 
        const results = await Promise.all(oVehicles.map( (oVehicle) => {return populateVehicleLinkedObjects(oVehicle)}))
        return results.flat();
    }
    catch (error) {
        await putError(error, {where, equals}, 'Vehicle.js', 'getFullVehicles')
        return
    }
}
export async function getVehicle(keyValueObject) {
    try {
        let oData = await getVehicle_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'Vehicle.js', 'getVehicle')
        return
    }
}

export async function getVehicles(where, equals) {
    try {
        let oData = await getVehicles_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'Vehicle.js', 'getVehicles')
        return
    }
}

export async function getAllVehicles() {
    try {
        let oData = await getAllVehicles_db()
        return oData
    } catch (error) {
        await putError(error, { fn: 'getAllVehicles' }, 'Vehicle.js', 'getAllVehicles')
        return
    }
}

export async function putVehicle(oData) {
    try {
        await putVehicle_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'Vehicle.js', 'putVehicle')
        return
    }
}

export async function deleteVehicles(where, equals) {
    try {
        await deleteVehicles_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'Vehicle.js', 'deleteVehicles')
        return
    }
}
