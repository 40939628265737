import BaseObject from "../Base/BaseObject";   
export class ObjectEvent extends BaseObject {
    constructor( 
        objectEventGuid,
        objectType, 
        objectGuid,
        referenceGuid,
        eventID,
        eventGuid,
        eventTimeStamp,
        eventStartTimeStamp,
        eventEndTimeStamp,
        eventTypeID,
        eventTypeGuid,
        eventType,
        eventSystemName,
        userGuid,
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            objectEventGuid,
            objectEventGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.objectType = objectType; 
        this.objectGuid = objectGuid;
        this.referenceGuid = referenceGuid;
        this.userGuid = userGuid;
        this.objectEventGuid = objectEventGuid;
        this.eventID = eventID;
        this.eventGuid = eventGuid;
        this.eventTimeStamp = eventTimeStamp;
        this.eventStartTimeStamp = eventStartTimeStamp;
        this.eventEndTimeStamp = eventEndTimeStamp;
        this.eventTypeID = eventTypeID;
        this.eventTypeGuid = eventTypeGuid;
        this.eventType = eventType;
        this.eventSystemName = eventSystemName;
    }

}