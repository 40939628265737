import axios from 'axios'; 
import { setupInterceptorsTo } from '../Utils/Interceptors'
 
const Api = setupInterceptorsTo( 
    axios.create({
        baseURL: process.env.REACT_APP_APIURL,
        headers: {
            "Content-Type": "application/json",
        },
    })
);
 
export default Api;