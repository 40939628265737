import { db } from "../db";
import { putError } from "../Error/Error"; 
import { JobSafetyCategory } from "../../Model/JobSafetyReport/JobSafetyCategory";

export function populateObject_JobSafetyCategory(data) {
    if (!data) {
        return null
    }
    let oData = new JobSafetyCategory(
        data.jobSafetyCategoryID,
        data.jobSafetyCategoryGuid,
        data.categoryName, 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oData
}

async function getJobSafetyCategory_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.job_safety_categories.get(keyValueObject);
    let oData = populateObject_JobSafetyCategory(data);
    return oData;
}

async function getJobSafetyCategorys_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.job_safety_categories
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object)=>{return populateObject_JobSafetyCategory(object)}) 
    return oData; 
}

async function putJobSafetyCategory_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_safety_categories.put(oData, oData.id)
    return
}

async function deleteJobSafetyCategorys_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_safety_categories
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getJobSafetyCategory(keyValueObject) { 
    try { 
        let oData = await getJobSafetyCategory_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobSafetyCategory.js', 'getJobSafetyCategory')
        return
    }
}

export async function getJobSafetyCategorys(where, equals) {
    try { 
        let oData = await getJobSafetyCategorys_db(where,equals)
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'JobSafetyCategory.js', 'getJobSafetyCategorys')
        return
    }
}

export async function putJobSafetyCategory(oData) {
    try { 
        await putJobSafetyCategory_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobSafetyCategory.js', 'putJobSafetyCategory')
        return
    }
}

export async function deleteJobSafetyCategorys(where, equals) {
    try { 
        await deleteJobSafetyCategorys_db(where,equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'JobSafetyCategory.js', 'deleteJobSafetyCategorys')
        return
    }
}
 