import { db } from "../db";
import { putError } from "../Error/Error";
import { JobSafetyDeviceMapping } from "../../Model/JobSafetyReport/JobSafetyDeviceMapping";
import { getDeviceType } from "../Device/DeviceType";
import { getFullJobSafetyHazard } from "./JobSafetyHazard";

export function populateObject_JobSafetyDeviceMapping(data) {
    if (!data) {
        return null
    }
    let oData = new JobSafetyDeviceMapping(
        data.jobSafetyDeviceMappingID,
        data.jobSafetyDeviceMappingGuid,
        data.deviceTypeID,
        data.deviceTypeGuid,
        data.jobSafetyHazardID,
        data.jobSafetyHazardGuid,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oData
}

async function populateJobSafetyDeviceMappingLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try {
        let proms = []
        proms.push(getDeviceType({ deviceTypeGuid: oData.deviceTypeGuid }))
        proms.push(getFullJobSafetyHazard({ jobSafetyHazardGuid: oData.jobSafetyHazardGuid }));
        const results = await Promise.all(proms);
        oData.deviceType = results.at(0);
        oData.jobSafetyHazard = results.at(1);
        return oData
    }
    catch (error) {
        await putError(error, oData, 'JobSafetyHazard.js', 'populateJobSafetyDeviceMappingLinkedObjects')
        return oData
    }
}

async function getJobSafetyDeviceMapping_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.job_safety_device_mappings.get(keyValueObject);
    let oData = populateObject_JobSafetyDeviceMapping(data);
    return oData;
}

async function getJobSafetyDeviceMappings_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.job_safety_device_mappings
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_JobSafetyDeviceMapping(object) })
    return oData;
}

async function putJobSafetyDeviceMapping_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_safety_device_mappings.put(oData, oData.id)
    return
}

async function deleteJobSafetyDeviceMappings_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_safety_device_mappings
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getJobSafetyDeviceMapping(keyValueObject) {
    try {
        let oData = await getJobSafetyDeviceMapping_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobSafetyDeviceMapping.js', 'getJobSafetyDeviceMapping')
        return
    }
}

export async function getFullJobSafetyDeviceMapping(keyValueObject) {
    try {
        let oData = await getJobSafetyDeviceMapping(keyValueObject);
        oData = await populateJobSafetyDeviceMappingLinkedObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error,keyValueObject,'JobSafetyDeviceMapping.js','getFullJobSafetyDeviceMapping') 
        return Promise.resolve(null);
    }
}


export async function getJobSafetyDeviceMappings(where, equals) {
    try {
        let oData = await getJobSafetyDeviceMappings_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyDeviceMapping.js', 'getJobSafetyDeviceMappings')
        return
    }
}

export async function getFullJobSafetyDeviceMappings(where, equals) {
    try {
        const oData = await getJobSafetyDeviceMappings(where, equals)
        let proms = oData.map((object) => { return populateJobSafetyDeviceMappingLinkedObjects(object) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'JobSafetyDeviceMapping.js','getFullJobSafetyDeviceMappings')  
        return Promise.resolve([]);
    }
}

export async function putJobSafetyDeviceMapping(oData) {
    try {
        await putJobSafetyDeviceMapping_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobSafetyDeviceMapping.js', 'putJobSafetyDeviceMapping')
        return
    }
}

export async function deleteJobSafetyDeviceMappings(where, equals) {
    try {
        await deleteJobSafetyDeviceMappings_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyDeviceMapping.js', 'deleteJobSafetyDeviceMappings')
        return
    }
}
