import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import Api from './Api.jsx';
import { populateObject_JobType } from '../DB/JobType/JobType';
import { putError } from '../DB/Error/Error.js';

export function getJobTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/JobType/getJobTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_JobType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'JobType',
                    'getJobTypes_API'
                )
                return Promise.reject(error)
            }
        )
}