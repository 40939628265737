import BaseObject from "../Base/BaseObject";
export class PrestartTask extends BaseObject {
    constructor(
        prestartTaskGuid,
        prestartTaskID,
        prestartMeetingGuid,
        userID, 
        prestartTaskTypeGuid,
        prestartTaskTypeID,
        name,
        order,
        sent,
        active,
        mode,
        createdDate,
        lastModifiedDate,
        companyID,
        regionID
    ) {
        super(
            prestartTaskGuid,
            prestartTaskGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.prestartTaskGuid = prestartTaskGuid;
        this.prestartTaskID = prestartTaskID;
        this.prestartMeetingGuid = prestartMeetingGuid;
        this.userID = userID;
        this.prestartTaskTypeGuid = prestartTaskTypeGuid;
        this.prestartTaskTypeID = prestartTaskTypeID;
        this.name = name;
        this.order = order;
        this.signature = null;
        this.user = null;
    }
}