import { db } from "../db";
import { putError } from "../Error/Error";
import DataSource from 'devextreme/data/data_source';
import { JobReference } from "../../Model/JobReference/JobReference";

export function populateObject_JobRefrence(data) {
    if (!data) {
        return null
    }
    let oJobRefrence = new JobReference(
        data.jobReferenceID,
        data.jobReferenceGuid,
        data.jobReference,
        data.jobTypeID,
        data.jobTypeGuid,
        data.systemType,
        data.type,
        data.customerID,
        data.customerGuid,
        data.customerName,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oJobRefrence
}

async function getJobReference_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const jobRefrence = await db.job_references.get(keyValueObject);
    let oJobRefrence = populateObject_JobRefrence(jobRefrence);
    return oJobRefrence;
}

async function getJobReferences_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const jobRefrences = await db.job_references
        .where(where)
        .equals(equals)
        .toArray();
    let oJobRefrences = jobRefrences.map((jobRefrence)=>{return populateObject_JobRefrence(jobRefrence)}) 
    return oJobRefrences; 
}

async function putJobReference_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_references.put(oData, oData.id)
    return
}

async function deleteJobReferences_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_references
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getLookupDataSourceJobReferences(where, equals) {
    try {
        const oJobRefrences = await getJobReferences(where, equals);
        let oData = oJobRefrences.map(function (x) { return { value: x.jobReferenceGuid, text: x.jobReference } })
        const oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource;
    }
    catch (error) {
        await putError(error, {where, equals}, 'JobReference.js', 'getJobReference')
        return
    }

}

export async function getJobReference(keyValueObject) { 
    try { 
        let oData = await getJobReference_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobReference.js', 'getJobReference')
        return
    }
}

export async function getJobReferences(where, equals) {
    try { 
        let oData = await getJobReferences_db(where,equals)
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'JobReference.js', 'getJobReferences')
        return
    }
}

export async function putJobReference(oData) {
    try { 
        await putJobReference_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobReference.js', 'putJobReference')
        return
    }
}

export async function deleteJobReferences(where, equals) {
    try { 
        await deleteJobReferences_db(where,equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'JobReference.js', 'deleteJobReferences')
        return
    }
}
 