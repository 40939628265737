import BaseObject from "../Base/BaseObject";  
export class MarkType extends BaseObject {
    constructor(
        markTypeID,
        markTypeGuid,
        markType,
        markTypeSystemName,
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID
    ) {
        super(
            markTypeGuid,
            markTypeGuid,
            true,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            true,
            ''
        );
        this.markTypeID = markTypeID;
        this.markTypeGuid = markTypeGuid;
        this.markType = markType;
        this.markTypeSystemName = markTypeSystemName;
    }

} 