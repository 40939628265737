import Api from './Api.jsx'; 
import { populateObject_User } from '../DB/User/User'; 
import { getAuthToken, getRefreshToken, setAuthToken } from '../Utils/AuthUtils';
export function login(login, password) {
    return Api.post('/authenticatication/login', { login, password })
        .then(res => {
            res.data.user = populateObject_User(res.data.user, 'auth')
            return res.data;
        })
        .catch(res => {
            alert(res.response.data); 
            return { data: null, error: true } }
        );
} 

export async function refreshAuthAsync() {

    const Refresh_Token = getRefreshToken()
    const Access_Token = getAuthToken()

    return Api.post('/authenticatication/refresh', { Refresh_Token, Access_Token })
            .then(res => {
                setAuthToken(res.data) 
                return res.data;
            }) 
}

export function ping() {  
    return Promise.resolve( Api.post('/authenticatication/ping'))
} 

export default login