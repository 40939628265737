
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const OnlineContext = createContext(navigator.onLine);

export const OnlineProvider = ({ online, children }) => {
    // State to hold the authentication token
    const [isOnline, setOnlineStatus_] = useState(online); 

    // Function to set the authentication token
    const setOnlineStatus = (isOnline) => { 
        localStorage.setItem('online', isOnline);
        setOnlineStatus_(isOnline); 
    };

    useEffect(() => { 
        localStorage.setItem('online', online);
        const goOnline = function (event) { 
            setOnlineStatus(true) 
        };
        const goOffline = function (event) { 
            setOnlineStatus(false) 
        };

        window.addEventListener('offline', goOffline);
        window.addEventListener('online', goOnline);

        return () => {
            window.removeEventListener('offline', goOffline);
            window.removeEventListener('online', goOnline);
        }
    }); 
      
    const contextValue = useMemo(
        () => ({
            isOnline,
            setOnlineStatus,
        }),
        [isOnline]
    );

    // Provide the authentication context to the children components
    return (
        <OnlineContext.Provider value={contextValue}>{children}</OnlineContext.Provider>
    );
};

export const useOnline = () => { 
    return useContext(OnlineContext);
}; 
export default OnlineProvider;