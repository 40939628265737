import BaseObject from "../Base/BaseObject";
export class DumpDocket extends BaseObject {
    constructor(  
        dumpDocketGuid,
        driverID, 
        vehicleID,
        supplierID,
        supplierItemID,
        itemID,
        docketNumber,
        invoiceNo,
        dumpedDate,
        actualWeight,
        isComplete = 'false',
        deleted, 
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            dumpDocketGuid,
            dumpDocketGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.dumpDocketGuid = dumpDocketGuid;
        this.supplierID = supplierID;
        this.supplierItemID = supplierItemID; 
        this.docketNumber = docketNumber;
        this.invoiceNo = invoiceNo;
        this.itemID = itemID;
        this.driverID = driverID;
        this.vehicleID = vehicleID; 
        this.dumpedDate = dumpedDate;
        this.actualWeight = actualWeight;
        this.deleted = deleted; 
        this.isComplete = isComplete
        this.objectPhotos = [];
    }

} 