import { getAuthenticatedUserGuid } from '../Utils/AuthUtils.jsx'
import Api from './Api.jsx';
import { populateObject_UnitType } from '../DB/UnitType/UnitType.js';
import { putError } from '../DB/Error/Error.js';

export function getUnitTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/UnitType/getUnitTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_UnitType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                    },
                    'UnitType',
                    'getUnitTypes_API'
                )
                return Promise.reject(error)
            }
        )
}
