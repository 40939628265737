import BaseObject from "../Base/BaseObject";
export class JobSafetyReportHeader extends BaseObject {
    constructor(
        jobSafetyReportHeaderGuid,
        jobSupervisorUserID,
        completionDate,
        addressID,
        statusGuid,
        permitsRequired,
        scheduledStartDate,
        userID,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobSafetyReportHeaderGuid,
            jobSafetyReportHeaderGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.jobSafetyReportHeaderGuid = jobSafetyReportHeaderGuid;
        this.jobSupervisorUserID = jobSupervisorUserID;
        this.completionDate = completionDate;
        this.addressID = addressID;
        this.statusGuid = statusGuid;
        this.permitsRequired = permitsRequired;
        this.scheduledStartDate = scheduledStartDate;
        this.userID = userID;
        this.jobSafetyDeviceReports = [];
        this.jobSafetyReportAcknowledgements = [];
    }

}  