import BaseObject from "../Base/BaseObject";
export class JobSafetyDeviceMapping extends BaseObject {
    constructor(
        jobSafetyDeviceMappingID,
        jobSafetyDeviceMappingGuid,
        deviceTypeID,
        deviceTypeGuid,
        jobSafetyHazardID,
        jobSafetyHazardGuid,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobSafetyDeviceMappingID,
            jobSafetyDeviceMappingGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.jobSafetyDeviceMappingID = jobSafetyDeviceMappingID;
        this.jobSafetyDeviceMappingGuid = jobSafetyDeviceMappingGuid;
        this.deviceTypeID = deviceTypeID;
        this.deviceTypeGuid = deviceTypeGuid;
        this.jobSafetyHazardID = jobSafetyHazardID;
        this.jobSafetyHazardGuid = jobSafetyHazardGuid;
        this.deviceType = null;
        this.jobSafetyHazard = null;
    }

}  