import { refreshAuthAsync, } from '../API/Auth' 
import { logOutUtils } from '../Utils/AuthUtils' 


const onRequest = config => {
    let token = localStorage.getItem("authToken");
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config
}

const onRequestError = error => {
    return Promise.resolve(error)
}

const refreshAndRetryQueue = [];

let isRefreshing = false

export const setupInterceptorsTo = axiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError)
    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;
            if (isRefreshing === true && originalRequest.url !== '/authenticatication/refresh') {
                return new Promise((resolve, reject) => {
                    refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
                });
            }
            else if (originalRequest.url === '/authenticatication/refresh') {
                refreshAndRetryQueue.length = 0;
                isRefreshing = false;
                await logOutUtils(false);
                window.location.href = '/login'
            }
            try {
                console.log(JSON.stringify(originalRequest.url))

                if (error.response.status === 401 && error.response.data !== 'Invalid username or password') {
                    isRefreshing = true
                    // Refresh the access token
                    try {
                        const newAccessToken = await refreshAuthAsync()

                        // Update the request headers with the new access token
                        error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

                        // Retry all requests in the queue with the new token
                        refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                            axiosInstance
                                .request(config)
                                .then((response) => resolve(response))
                                .catch((err) => reject(err));
                        });

                        // Clear the queue
                        refreshAndRetryQueue.length = 0;

                        // Retry the original request
                        return axiosInstance(originalRequest);
                    }
                    catch (error) {
                        refreshAndRetryQueue.length = 0;
                        console.log(error);
                        //await logOutUtils(false);
                        //window.location.href = '/login'
                    }

                }

            }
            catch (error)  {
                console.log(error);
                //await logOutUtils(false);
                //window.location.href = '/login'
            }
            finally {
                isRefreshing = false
            }
            console.log(error)
            return Promise.reject(error)
        }
    );

    return axiosInstance
}
