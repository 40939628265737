import BaseObject from "../Base/BaseObject";
export class Item extends BaseObject {
    constructor(
        itemID,
        itemGuid,
        itemSystemName,
        itemName,
        itemCode,
        itemDescription,
        htmlCode,
        itemTypeID,
        itemTypeGuid,
        itemType,
        itemTypeSystemType,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            itemID,
            itemGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.itemID = itemID;
        this.itemGuid = itemGuid;
        this.itemSystemName = itemSystemName;
        this.itemName = itemName; 
        this.itemCode = itemCode;
        this.itemDescription = itemDescription;
        this.htmlCode = htmlCode;
        this.itemTypeID = itemTypeID; 
        this.itemTypeGuid = itemTypeGuid;
        this.itemType = itemType;
        this.itemTypeSystemType = itemTypeSystemType;
    }

}  