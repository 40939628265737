import { upsertPrestartMeeting_API, upsertPrestartTask_API } from "../../API/Prestart";
import { putError } from "../../DB/Error/Error"; 
import { getPrestartMeetings, putPrestartMeeting } from "../../DB/Prestart/PrestartMeeting";
import { getPrestartTasks, putPrestartTask } from "../../DB/Prestart/PrestartTask";

export async function syncToApiPrestart(){
    let upsertProms = []; 
    upsertProms.push(syncToApiPrestartMeetings()) 
    upsertProms.push(syncToApiPrestartTasks())
    await Promise.all(upsertProms);
    return;
}

async function syncToApiPrestartMeetings(){ 
    try{ 
        const dataToApi = await getPrestartMeetings('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertPrestartMeeting_API(data)})); 
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putPrestartMeeting(data)}));  
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiPrestartMeetings'}, 'Sync/ToAPI/syncToApiPrestart', 'syncToApiPrestart')
        return Promise.resolve();
    }
}

async function syncToApiPrestartTasks(){ 
    try{ 
        const dataToApi = await getPrestartTasks('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertPrestartTask_API(data)})); 
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putPrestartTask(data)}));  
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiPrestartTasks'}, 'Sync/ToAPI/syncToApiPrestart', 'syncToApiPrestart')
        return Promise.resolve();
    }
}