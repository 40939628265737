import BaseObject from "../Base/BaseObject";   
export class Status extends BaseObject {
    constructor(
        statusID,
        statusGuid,
        mobileJobStatusID, 
        sortOrder,
        statusName,
        htmlCode, 

        mobileJobStatusGuid,
        mobileJobStatusName,
        mobileJobStatusHTMLCode,

        statusTypeID,
        statusTypeName,
        statusTypeGuid, 

        companyID,
        regionID 

    ) {
        super(
            statusID,
            statusGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.statusID = statusID;
        this.statusGuid = statusGuid
        this.mobileJobStatusID = mobileJobStatusID;
        this.statusTypeID = statusTypeID;
        this.sortOrder = sortOrder;
        this.statusName = statusName;
        this.htmlCode = htmlCode;

        this.mobileJobStatusGuid = mobileJobStatusGuid;
        this.mobileJobStatusName = mobileJobStatusName;
        this.mobileJobStatusHTMLCode = mobileJobStatusHTMLCode;

        this.statusTypeID = statusTypeID;
        this.statusTypeGuid = statusTypeGuid;
        this.statusTypeName = statusTypeName; 
        this.objectFlags = [];
    } 
     
}
 