import { getJobTypes_API } from '../../API/JobType';
import { getJobReferences_API } from '../../API/JobReference'; 
import { getWorkOrderJobs_API } from '../../API/Scheduledjob' 
import { putError } from "../../DB/Error/Error";
import { getScheduledJob, putScheduledJob } from '../../DB/Scheduledjob/Scheduledjob'; 
import { getJobReference, putJobReference } from '../../DB/JobReference/JobReference';
import { getJobType, putJobType } from '../../DB/JobType/JobType';

export async function syncFromApiJobs(){
    let promises = []
    try{
        promises.push(syncFromApiJob_JobTypes());
        promises.push(syncFromApiJob_JobReferences('ATMission')); 
        promises.push(syncFromApiJob_Jobs());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiJob'}, 'Sync/FromAPI/Job', 'syncFromApiJob')
        return Promise.resolve()
    } 
}

async function syncFromApiJob_PutJobType(data){
    let oData =  await getJobType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putJobType(data)
}
 
async function syncFromApiJob_JobTypes(){ 
    try{
        const dataFromApi = await getJobTypes_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiJob_PutJobType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiJob_JobType'}, 'Sync/FromAPI/Job', 'syncFromApiJob')
        return Promise.resolve()
    } 
}

async function syncFromApiJob_PutJob(data){
    let oData =  await getScheduledJob({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putScheduledJob(data)
}

async function syncFromApiJob_Jobs(){
    try{
        const dataFromApi = await getWorkOrderJobs_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiJob_PutJob(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiJob_Job'}, 'Sync/FromAPI/Job', 'syncFromApiJob')
        return Promise.resolve()
    } 
}

async function syncFromApiJob_PutJobReference(data){
    let oData =  await getJobReference({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putJobReference(data)
}


async function syncFromApiJob_JobReferences(objectType){ 
    try{
        const dataFromApi = await getJobReferences_API(objectType); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiJob_PutJobReference(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiJob_JobReference'}, 'Sync/FromAPI/Job', 'syncFromApiJob')
        return Promise.resolve()
    } 
}
 