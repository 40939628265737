import { db } from "../db";
import { putError } from "../Error/Error";
import ObjectSignature from "../../Model/Signature/ObjectSignature";

export function populateObject_ObjectSignature(objectSignature) {
    if (!objectSignature) {
        return
    }
    let oObjectSignature = new ObjectSignature(
        objectSignature.objectSignatureGuid,
        objectSignature.signature,
        objectSignature.signatureName,
        objectSignature.userID,
        objectSignature.objectType,
        objectSignature.objectGuid,
        objectSignature.referenceGuid,
        objectSignature.signatureTypeID,
        objectSignature.signatureTypeGuid,
        objectSignature.signatureTypeName,
        objectSignature.signatureTypeSystemName,
        (typeof objectSignature.sent === 'string' && objectSignature.sent === 'true') || (typeof objectSignature.sent === 'boolean' && objectSignature.sent) ? 'true' : 'false',
        (typeof objectSignature.active === 'string' && objectSignature.active === 'true') || (typeof objectSignature.active === 'boolean' && objectSignature.active) ? 'true' : 'false',
        objectSignature.mode ? objectSignature.mode : '',
        objectSignature.createdDate,
        objectSignature.lastModifiedDate,
        objectSignature.companyID,
        objectSignature.regionID
    );
    return oObjectSignature;
}

async function getObjectSignature_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const signatureType = await db.object_signature.get(keyValueObject)
    let oObjectSignature = populateObject_ObjectSignature(signatureType)
    return oObjectSignature;
}

async function getObjectSignatures_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const objectSignatures = await db.object_signature
        .where(where)
        .equals(equals)
        .toArray();
    let oObjectSignatures = objectSignatures.map((objectSignature) => { return populateObject_ObjectSignature(objectSignature) })
    return oObjectSignatures
}

async function putObjectSignature_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    } 
    await db.object_signature.put(oData, oData.id);
    return;
}

async function deleteobjectSignatures_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_signature
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getObjectSignature(keyValueObject) {
    try {
        let oData = await getObjectSignature_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectSignature.js', 'getObjectSignature')
        return
    }
}

export async function getObjectSignatures(where, equals) {
    try {
        let oData = await getObjectSignatures_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectSignature.js', 'getObjectSignatures')
        return
    }
}

export async function putObjectSignature(oData) {
    try {
        await putObjectSignature_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectSignature.js', 'putObjectSignature')
        return
    }
}

export async function deleteObjectSignatures(where, equals) {
    try {
        await deleteobjectSignatures_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectSignature.js', 'deleteObjectSignatures')
        return
    }
}
