import BaseObject from "../Base/BaseObject";   
export class ObjectEquipment extends BaseObject {
    constructor(
        objectEquipmentid,
        objectEquipmentGuid,   
        objectGuid,
        referenceGuid,
        objectType,
        equipmentID,
        equipmentGuid,
        identifier,
        equipmentCode,
        description,
        notes,
        active,
        companyID,
        regionID,
        sent = 'true',
        mode = ''

    ) {
        super(
            objectEquipmentGuid,
            objectEquipmentGuid,
            active,
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.objectEquipmentGuid = objectEquipmentGuid;
        this.objectEquipmentid = objectEquipmentid;
        this.equipmentGuid = equipmentGuid 
        this.objectGuid = objectGuid;
        this.referenceGuid = referenceGuid;
        this.objectType = objectType;
        this.equipmentID = equipmentID;
        this.equipmentGuid = equipmentGuid;
        this.identifier = identifier;
        this.equipmentCode = equipmentCode;
        this.description = description;
        this.notes = notes
        this.events = [];
    }
    objectHasEvent (eventSystemName){
        const oEvent = this.events.filter(x => x.eventSystemName.toLocaleLowerCase() === eventSystemName.toLocaleLowerCase() && x.active === 'true').at(0);
        return typeof oEvent !== 'undefined'
    } 
}