import { db } from "../db";
import { putError } from "../Error/Error";
import { MeasureTypeUnitType } from "../../Model/Measure/MeasureTypeUnitType";

export function populateObject_MeasureTypeUnitType(measureTypeUnitType) {
    if (!measureTypeUnitType) {
        return null
    }
    let oMeasureTypeUnitType = new MeasureTypeUnitType(
        measureTypeUnitType.measureTypeUnitTypeID,
        measureTypeUnitType.measureTypeUnitTypeGuid,
        measureTypeUnitType.measureTypeGuid,
        measureTypeUnitType.unitTypeID,
        measureTypeUnitType.unitTypeGuid,
        measureTypeUnitType.unitType,
        measureTypeUnitType.unitTypeSystemName,
        measureTypeUnitType.unitTypeSymbol,
        measureTypeUnitType.createdDate,
        measureTypeUnitType.lastmodifiedDate,
        measureTypeUnitType.companyID,
        measureTypeUnitType.regionID
    )
    return oMeasureTypeUnitType
}

async function getMeasureTypeUnitType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const measureTypeUnitType = await db.measure_type_unit_type.get(keyValueObject)
    const oMeasureTypeUnitType = populateObject_MeasureTypeUnitType(measureTypeUnitType)
    return oMeasureTypeUnitType;
}

async function getMeasureTypeUnitTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const measureTypeUnitTypes = await db.measure_type_unit_type
        .where(where)
        .equals(equals)
        .toArray();
    const oMeasureTypeUnitTypes = measureTypeUnitTypes.map((measureTypeUnitType)=>{return populateObject_MeasureTypeUnitType(measureTypeUnitType)})
    return oMeasureTypeUnitTypes;
}

async function putMeasureTypeUnitType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.measure_type_unit_type.put(oData, oData.id);
    return;
}

async function deleteMeasureTypeUnitTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.measure_type_unit_type
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getMeasureTypeUnitType(keyValueObject) { 
    try { 
        let oData = await getMeasureTypeUnitType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'MeasureTypeUnitType.js', 'getMeasureTypeUnitType')
        return
    }
}

export async function getMeasureTypeUnitTypes(where, equals) {
    try { 
        let oData = await getMeasureTypeUnitTypes_db(where,equals)
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'MeasureTypeUnitType.js', 'getMeasureTypeUnitTypes')
        return
    }
}

export async function putMeasureTypeUnitType(oData) {
    try { 
        await putMeasureTypeUnitType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'MeasureTypeUnitType.js', 'putMeasureTypeUnitType')
        return
    }
}

export async function deleteMeasureTypeUnitTypes(where, equals) {
    try { 
        await deleteMeasureTypeUnitTypes_db(where,equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'MeasureTypeUnitType.js', 'deleteMeasureTypeUnitTypes')
        return
    }
}
 