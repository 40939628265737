import { db } from "../db";
import { putError } from "../Error/Error"; 
import { JobSafetyRisk } from "../../Model/JobSafetyReport/JobSafetyRisk";

export function populateObject_JobSafetyRisk(data) {
    if (!data) {
        return null
    }    
    let oData = new JobSafetyRisk(
        data.jobSafetyRiskID,
        data.jobSafetyRiskGuid,
        data.probability, 
        data.level, 
        data.score, 
        data.consequence, 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oData
}

async function getJobSafetyRisk_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.job_safety_risks.get(keyValueObject);
    let oData = populateObject_JobSafetyRisk(data);
    return oData;
}

async function getJobSafetyRisks_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.job_safety_risks
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object)=>{return populateObject_JobSafetyRisk(object)}) 
    return oData; 
}

async function putJobSafetyRisk_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_safety_risks.put(oData, oData.id)
    return
}

async function deleteJobSafetyRisks_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_safety_risks
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getJobSafetyRisk(keyValueObject) { 
    try { 
        let oData = await getJobSafetyRisk_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobSafetyRisk.js', 'getJobSafetyRisk')
        return
    }
}

export async function getJobSafetyRisks(where, equals) {
    try { 
        let oData = await getJobSafetyRisks_db(where,equals)
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'JobSafetyRisk.js', 'getJobSafetyRisks')
        return
    }
}

export async function putJobSafetyRisk(oData) {
    try { 
        await putJobSafetyRisk_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobSafetyRisk.js', 'putJobSafetyRisk')
        return
    }
}

export async function deleteJobSafetyRisks(where, equals) {
    try { 
        await deleteJobSafetyRisks_db(where,equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'JobSafetyRisk.js', 'deleteJobSafetyRisks')
        return
    }
}
 