import { db } from "../db";
import { putError } from "../Error/Error";
import ReportDefinition from "../../Model/Reporting/ReportDefinition";

export function populateObject_ReportDefinition(reportDefinition) {
    if (!reportDefinition) {
        return null
    }
    let oReportDefinition = new ReportDefinition(
        reportDefinition.reportDefinitionID,
        reportDefinition.reportDefinitionGuid,
        reportDefinition.reportDefinitionName,
        reportDefinition.reportDefinitionSystemName,
        typeof reportDefinition.reportDefinition === "string" ? JSON.parse(reportDefinition.reportDefinition) : reportDefinition.reportDefinition,
        reportDefinition.reportDefinitionVersion,
        reportDefinition.createdDate,
        reportDefinition.lastModifiedDate,
        reportDefinition.companyID,
        reportDefinition.regionID
    )
    return oReportDefinition;
}

async function getReportDefinition_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const reportDefinition = await db.report_definition.get(keyValueObject)
    let oReportDefinition = populateObject_ReportDefinition(reportDefinition)
    return oReportDefinition;
}

async function getReportDefinitions_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const reportDefinitions = await db.report_definition
        .where(where)
        .equals(equals)
        .toArray();
    let oReportDefinitions = reportDefinitions.map((reportDefinition)=>{return populateObject_ReportDefinition(reportDefinition) })
    return oReportDefinitions;
}

async function putReportDefintion_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null && i !== 'reportDefinition' ) {
            delete oData[i];
        }
    }
    await db.report_definition.put(oData, oData.id);
    return;
}

async function deleteReportDefintions_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.report_definition
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getReportDefinition(keyValueObject) {
    try {
        let oData = await getReportDefinition_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ReportDefintion.js', 'getReportDefintion')
        return
    }
}

export async function getReportDefinitions(where, equals) {
    try {
        let oData = await getReportDefinitions_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ReportDefintion.js', 'getReportDefintions')
        return
    }
}

export async function putReportDefintion(oData) {
    try {
        await putReportDefintion_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ReportDefintion.js', 'putReportDefintion')
        return
    }
}

export async function deleteReportDefintions(where, equals) {
    try {
        await deleteReportDefintions_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ReportDefintion.js', 'deleteReportDefintions')
        return
    }
}
