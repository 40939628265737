import { upsertObjectMeasure_API } from "../../API/Measure";
import { getObjectMeasures, putObjectMeasure } from "../../DB/Measure/ObjectMeasure";
import { putError } from "../../DB/Error/Error";
 
export async function syncToApiObjectMeasures() {
    try {
        const dataToApi = await getObjectMeasures('sent', 'false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data) => { return upsertObjectMeasure_API(data) }));
        if (!dataFromApi) {
            return;
        }
        await Promise.all(dataFromApi.map((data) => { return putObjectMeasure(data) }));
        return;
    }
    catch (error) {
        await putError(error, { message: 'Catch from syncToApiObjectMeasures' }, 'Sync/ToAPI/ObjectMeasures', 'syncToApiObjectMeasures')
        return Promise.resolve();
    }
}