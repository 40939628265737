import BaseObject from "../Base/BaseObject";  
export default class Code extends BaseObject {
    constructor(
        codeID,
        codeGuid,
        code,
        codeSystemName,
        codeTypeID,
        codeTypeGuid,
        codeType,
        codeTypeSystemName,
        companyID,
        regionID
    ) {
        super(
            codeID,
            codeGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.codeID = codeID;
        this.codeGuid = codeGuid;
        this.code = code;
        this.codeSystemName = codeSystemName;
        this.codeTypeID = codeTypeID;
        this.codeTypeGuid = codeTypeGuid; 
        this.codeType = codeType;
        this.codeTypeSystemName = codeTypeSystemName;

    }

}