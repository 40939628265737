import { db } from "../db";
import { putError } from "../Error/Error";
import DeviceProperty from '../../Model/Device/DeviceProperty'

export function populateObject_DeviceProperty(data) {
    if (!data) {
        return null
    }
    let oDeviceProperty = new DeviceProperty(
        data.devicePropertyID,
        data.devicePropertyGuid,
        data.deviceTypeGuid,
        data.description,
        data.control,
        data.mobileEnabled,
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID
    )
    return oDeviceProperty;
}

async function getDeviceProperty_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const deviceProperty = await db.device_properties.get(keyValueObject);
    let oDeviceProperty = populateObject_DeviceProperty(deviceProperty)
    return oDeviceProperty;
}

async function getDeviceProperties_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const deviceProperties = await db.device_properties
        .where(where)
        .equals(equals)
        .toArray();
    const oDeviceProperty = deviceProperties.map((deviceProperty)=>{return populateObject_DeviceProperty(deviceProperty)})   
    return oDeviceProperty
}
async function putDeviceProperty_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.device_properties.put(oData, oData.id);
    return;
}

async function deleteDeviceProperties_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.device_properties
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getDeviceProperty(keyValueObject) { 
    try { 
        let oData = await getDeviceProperty_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'DeviceProperty.js', 'getDeviceProperty')
        return
    }
}

export async function getDevicePropertys(where, equals) {
    try { 
        let oData = await getDeviceProperties_db(where,equals)
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'DeviceProperty.js', 'getDevicePropertys')
        return
    }
}

export async function putDeviceProperty(oData) {
    try { 
        await putDeviceProperty_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'DeviceProperty.js', 'putDeviceProperty')
        return
    }
}

export async function deleteDeviceProperties(where, equals) {
    try { 
        await deleteDeviceProperties_db(where,equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'DeviceProperty.js', 'deleteDevicePropertys')
        return
    }
}
 