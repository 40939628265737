import { db } from "../db";
import { putError } from "../Error/Error";
import ObjectCode from "../../Model/Code/ObjectCode";

export function populateObject_ObjectCode(data) {
    if (!data) {
        return null
    }
    let oObjectCode = new ObjectCode(
        data.objectCodeGuid,
        data.objectType,
        data.objectGuid,
        data.referenceGuid,
        data.codeID,
        data.codeGuid,
        data.code,
        data.codeSystemName,
        data.codeTypeID,
        data.codeTypeGuid,
        data.codeType,
        data.codeTypeSystemName,
        data.createdDate,
        data.lastModifiedDate,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
        data.companyID,
        data.regionID
    )
    return oObjectCode
}

async function getObjectCode_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const codeType = await db.object_code.get(keyValueObject);
    let oObjectCode = populateObject_ObjectCode(codeType);
    return oObjectCode
}

async function getObjectCodes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const objectCodes = await db.object_code
        .where(where)
        .equals(equals)
        .toArray();
    const oObjectCodes = objectCodes.map((codeType)=>{return populateObject_ObjectCode(codeType)})    
    return oObjectCodes
}

async function putObjectCode_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.object_code.put(oData, oData.id);
    return;
}

async function deleteObjectCodes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_code
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getObjectCode(keyValueObject) { 
    try { 
        let oData = await getObjectCode_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectCode.js', 'getObjectCode')
        return
    }
}

export async function getObjectCodes(where, equals) {
    try { 
        let oData = await getObjectCodes_db(where,equals)
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'ObjectCode.js', 'getObjectCodes')
        return
    }
}

export async function putObjectCode(oData) {
    try { 
        await putObjectCode_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectCode.js', 'putObjectCode')
        return
    }
}

export async function deleteObjectCodes(where, equals) {
    try { 
        await deleteObjectCodes_db(where,equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'ObjectCode.js', 'deleteObjectCodes')
        return
    }
}
 