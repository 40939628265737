import { db } from "../db";
import { putError } from "../Error/Error";
import { AddressDeviceProperty } from '../../Model/Address/AddressDeviceProperty'

export function populateObject_AddressDeviceProperty(data) {
    if (!data) {
        return null
    }
    let oAddressDeviceProperty = new AddressDeviceProperty(
        data.addressDeviceGuid,
        data.addressDevicePropertyGuid,
        data.addressDevicePropertyID,
        data.value,
        data.devicePropertyGuid,
        data.description,
        data.control,
        data.mobileEnabled,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    )
    return oAddressDeviceProperty
}

async function getAddressDeviceProperty_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const addressDeviceProperty = await db.address_device_properties.get(keyValueObject)
    const oAddressDeviceProperty = populateObject_AddressDeviceProperty(addressDeviceProperty)
    return oAddressDeviceProperty;
}

async function getAddressDeviceProperties_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const addressDeviceProperties = await db.address_device_properties
        .where(where)
        .equals(equals)
        .toArray()
    const oAddressDeviceProperties = addressDeviceProperties.map((addressDeviceProperty)=>{return populateObject_AddressDeviceProperty(addressDeviceProperty)}) 
    return oAddressDeviceProperties;
}

async function putAddressDeviceProperty_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.address_device_properties.put(oData, oData.id);
    return;
}

async function deleteAddressDeviceProperty_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.address_device_properties
        .where(where)
        .equals(equals)
        .delete()
    return;
}
 
export async function getAddressDeviceProperty(keyValueObject) {
    try {
        const oData = await getAddressDeviceProperty_db(keyValueObject);
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'AddressDeviceProperty.js', 'getAddressDeviceProperty')
        return Promise.resolve(null);
    }
}

export async function getAddressDeviceProperties(where, equals) {
    try {
        const oData = await getAddressDeviceProperties_db(where, equals);
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'AddressDeviceProperty.js', 'getAddressDeviceProperties')
        return Promise.resolve(null);
    }
}

export async function putAddressDeviceProperty(oData) {
    try {
        await putAddressDeviceProperty_db(oData); 
        return;
    }
    catch (error) {
        await putError(error,oData,'AddressDeviceProperty.js','putAddressDeviceProperty') 
        return
    }
}

export async function deleteAddressDeviceProperty(where, equals) {
    try {
        await deleteAddressDeviceProperty_db(where, equals); 
        return;
    }
    catch (error) {
        await putError(error,{where, equals},'AddressDeviceProperty.js','deleteAddressDeviceProperty') 
        return
    }
}