import { db } from "../db";
import { putError } from "../Error/Error";
import { JobSafetyReportAcknowledgement } from "../../Model/JobSafetyReport/JobSafetyReportAcknowledgement";
import { getObjectSignature } from "../Signature/ObjectSignature";

export function populateObject_JobSafetyReportAcknowledgement(data) {
    if (!data) {
        return null
    }
    let oData = new JobSafetyReportAcknowledgement(
        data.jobSafetyReportAcknowledgementGuid,
        data.jobSafetyReportHeaderGuid,
        data.userID,
        data.userName, 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',

    )
    return oData
} 

async function populateJobSafetyReportAcknowledgementLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try { 
        oData.objectSignature = await getObjectSignature({objectType:'JobSafetyReportAcknowledgement',objectGuid:oData.jobSafetyReportAcknowledgementGuid,active:'true'});
        return oData
    }
    catch (error) {
        await putError(error, oData, 'JobSafetyReportAcknowledgement.js', 'populateJobSafetyReportAcknowledgementLinkedObjects')
        return oData
    }
}

async function getJobSafetyReportAcknowledgement_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.job_safety_report_acknowledgements.get(keyValueObject);
    let oData = populateObject_JobSafetyReportAcknowledgement(data);
    return oData;
}

async function getJobSafetyReportAcknowledgements_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.job_safety_report_acknowledgements
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_JobSafetyReportAcknowledgement(object) })
    return oData;
}

async function putJobSafetyReportAcknowledgement_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.job_safety_report_acknowledgements.put(oData, oData.id)
    return
}

async function deleteJobSafetyReportAcknowledgements_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.job_safety_report_acknowledgements
        .where(where)
        .equals(equals)
        .delete()
    return
}

export async function getJobSafetyReportAcknowledgement(keyValueObject) {
    try {
        let oData = await getJobSafetyReportAcknowledgement_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'JobSafetyReportAcknowledgement.js', 'getJobSafetyReportAcknowledgement')
        return
    }
}
 
export async function getFullJobSafetyReportAcknowledgement(keyValueObject) {
    try {
        let oData = await getJobSafetyReportAcknowledgement(keyValueObject);
        oData = await populateJobSafetyReportAcknowledgementLinkedObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error,keyValueObject,'JobSafetyReportAcknowledgement.js','getFullJobSafetyReportAcknowledgement') 
        return Promise.resolve(null);
    }
}
 

export async function getJobSafetyReportAcknowledgements(where, equals) {
    try {
        let oData = await getJobSafetyReportAcknowledgements_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyReportAcknowledgement.js', 'getJobSafetyReportAcknowledgements')
        return
    }
}
  
export async function getFullJobSafetyReportAcknowledgements(where, equals) {
    try {
        const oData = await getJobSafetyReportAcknowledgements(where, equals)
        let proms = oData.map((object) => { return populateJobSafetyReportAcknowledgementLinkedObjects(object) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'JobSafetyReportAcknowledgement.js','getFullJobSafetyReportAcknowledgements')  
        return Promise.resolve([]);
    }
}

export async function putJobSafetyReportAcknowledgement(oData) {
    try {
        await putJobSafetyReportAcknowledgement_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'JobSafetyReportAcknowledgement.js', 'putJobSafetyReportAcknowledgement')
        return
    }
}

export async function deleteJobSafetyReportAcknowledgements(where, equals) {
    try {
        await deleteJobSafetyReportAcknowledgements_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'JobSafetyReportAcknowledgement.js', 'deleteJobSafetyReportAcknowledgements')
        return
    }
}
