import BaseObject from "../Base/BaseObject";  
export class GenericObject extends BaseObject {
    constructor(
        genericObjectID,
        genericObjectGuid,
        objectName,
        genericObjectTypeID,
        genericObjectTypeGuid,
        genericObjectType,
        genericObjectTypeSystemName, 
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''   
    ){
        super(
            genericObjectID,
            genericObjectGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode            
        );
        this.genericObjectID = genericObjectID;
        this.genericObjectGuid = genericObjectGuid;
        this.objectName = objectName;
        this.genericObjectTypeID = genericObjectTypeID;
        this.genericObjectTypeGuid = genericObjectTypeGuid;
        this.genericObjectType = genericObjectType;
        this.genericObjectTypeSystemName = genericObjectTypeSystemName; 
        this.objectMeasures = []
    }

}  