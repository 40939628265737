import { getPrestartMeetings_API, getPrestartTasks_API, getPrestartTaskTypes_API } from '../../API/Prestart' 
import { putError } from "../../DB/Error/Error"; 
import { getPrestartMeeting, putPrestartMeeting } from '../../DB/Prestart/PrestartMeeting';
import { getPrestartTask, putPrestartTask } from '../../DB/Prestart/PrestartTask';
import { getPrestartTaskType, putPrestartTaskType } from '../../DB/Prestart/PrestartTaskType';

export async function syncFromApiPrestarts(){
    let promises = []
    try{ 
        promises.push(syncFromApiHazard_PrestartMeetings()); 
        promises.push(syncFromApiHazard_PrestartTasks());
        promises.push(syncFromApiHazard_PrestartTaskTypes());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiPrestarts'}, 'Sync/FromAPI/Prestart', 'syncFromApiPrestarts')
        return Promise.resolve()
    } 
}

async function syncFromApiPrestart_PutPrestartMeeting(data){
    let oData =  await getPrestartMeeting({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putPrestartMeeting(data)
}

async function syncFromApiHazard_PrestartMeetings(){ 
    try{
        const dataFromApi = await getPrestartMeetings_API();
        if(!dataFromApi){
            return;
        }
        const putProms = dataFromApi.map((data)=>{return syncFromApiPrestart_PutPrestartMeeting(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_Prestarts'}, 'Sync/FromAPI/Prestart', 'syncFromApiPrestarts')
        return Promise.resolve()
    } 
}

async function syncFromApiPrestart_PutPrestartTask(data){
    let oData =  await getPrestartTask({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putPrestartTask(data)
}

async function syncFromApiHazard_PrestartTasks(){ 
    try{
        const dataFromApi = await getPrestartTasks_API();
        if(!dataFromApi){
            return;
        }
        const putProms = dataFromApi.map((data)=>{return syncFromApiPrestart_PutPrestartTask(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_PrestartTasks'}, 'Sync/FromAPI/Prestart', 'syncFromApiPrestarts')
        return Promise.resolve()
    } 
}

async function syncFromApiPrestart_PutPrestartTaskType(data){
    let oData =  await getPrestartTaskType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putPrestartTaskType(data)
}

async function syncFromApiHazard_PrestartTaskTypes(){ 
    try{
        const dataFromApi = await getPrestartTaskTypes_API();
        if(!dataFromApi){
            return;
        }
        const putProms = dataFromApi.map((data)=>{return syncFromApiPrestart_PutPrestartTaskType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_PrestartTaskTypes'}, 'Sync/FromAPI/Prestart', 'syncFromApiPrestarts')
        return Promise.resolve()
    } 
}