import BaseObject from "../Base/BaseObject";
export default class ObjectPhoto extends BaseObject {
    constructor( 
        objectPhotoGuid,
        image,  
        objectType,
        objectGuid,
        referenceGuid,
        photoTypeID,
        photoTypeGuid,
        photoTypeName,
        photoTypeSystemName, 
        sent,
        active,
        mode,
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        hasChanged = false
    ) {
        super(
            objectPhotoGuid,
            objectPhotoGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.objectPhotoGuid = objectPhotoGuid; 
        this.image = image;
        this.objectType = objectType;
        this.objectGuid = objectGuid; 
        this.referenceGuid = referenceGuid;
        this.photoTypeID = photoTypeID;
        this.photoTypeGuid = photoTypeGuid;
        this.photoTypeName = photoTypeName;
        this.photoTypeSystemName = photoTypeSystemName; 
        this.hasChanged = hasChanged

    }
}