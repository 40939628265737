import BaseObject from "../Base/BaseObject"; 
export default class DeviceType extends BaseObject {
    constructor(
        deviceTypeID,
        deviceTypeGuid,
        deviceTypeCode,
        deviceTypeName,
        deviceTypeSystemName,
        deviceColour,
        deviceTextColour,
        calculationType,
        tMPLevel1,
        tMPLevel2,
        tMPLevel3,
        vaultShape,
        vaultLength,
        vaultWidth,
        vaultHeight,   
        companyID,
        regionID 

    ) {
        super(
            deviceTypeID,
            deviceTypeGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.deviceTypeID = deviceTypeID;
        this.deviceTypeGuid = deviceTypeGuid;
        this.deviceTypeCode = deviceTypeCode;
        this.deviceTypeName = deviceTypeName;
        this.deviceTypeSystemName = deviceTypeSystemName;
        this.deviceColour = deviceColour;
        this.deviceTextColour = deviceTextColour;
        this.calculationType = calculationType;
        this.tMPLevel1 = tMPLevel1;
        this.tMPLevel2 = tMPLevel2;
        this.tMPLevel3 = tMPLevel3;
        this.vaultShape = vaultShape;
        this.vaultLength = vaultLength;
        this.vaultWidth = vaultWidth;
        this.vaultHeight = vaultHeight; 
        this.deviceProperties = [];
    } 
}
//proms.push(
//    getDeviceProperties(['deviceTypeGuid'], [oDeviceType.deviceTypeGuid])
//        .then(
//            (data) => {
//                oDeviceType.deviceProperties = data
//            }
//        )
//)