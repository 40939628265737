import { getObjectEvents } from "../../DB/Event/ObjectEvent";
import BaseObject from "../Base/BaseObject";   
 export default class WorkOrderTeam extends BaseObject {
     constructor(
         workOrderTeamID,
         workOrderTeamGuid,
         workOrderGuid,
         addressBranchGuid,
         teamNo,
         createdDate,
         lastModifiedDate,
         companyID,
         regionID

     ) {
         super(
             workOrderTeamID,
             workOrderTeamGuid,
             'true',
             createdDate,
             null,
             lastModifiedDate,
             null,
             companyID,
             regionID,
             'true',
             ''
         );
         this.workOrderTeamID = workOrderTeamID;
         this.workOrderTeamGuid = workOrderTeamGuid;
         this.workOrderGuid = workOrderGuid;
         this.addressBranchGuid = addressBranchGuid;
         this.teamNo = teamNo;
         this.workOrder = {};
         this.addressBranch = {};
         this.objectEvents = {};
         this.objectUsers = [];
         this.objectVehicles = [];
         this.objectStocks = [];
         this.objectEquipments = [];
         this.scheduledJobs = [];
         this.workOrderEvents = []; 
     }
     async objectHasEvent(eventSystemName) {
         this.events = await getObjectEvents(['objectType', 'objectGuid', 'active'], ['WorkOrderTeam', this.workOrderTeamGuid,'true'])
         const oEvent = this.events.filter(x => x.eventSystemName.toLocaleLowerCase() === eventSystemName.toLocaleLowerCase() && x.active === 'true').at(0);
         return typeof oEvent !== 'undefined'
     } 
}
