import { db } from "../db";
import { putError } from "../Error/Error";
import DataSource from 'devextreme/data/data_source';
import { Supplier } from "../../Model/Supplier/Supplier";
import { getObjectCoordinates } from "../Coordinate/ObjectCoordinate";
import { getSupplierItems } from "./SupplierItem";

export function populateObject_Supplier(data) {
    if (!data) {
        return null;
    }
    let oSupplier = new Supplier(
        data.supplierID,
        data.supplierGuid,
        data.name,
        data.address,
        data.suburb,
        data.city,
        data.notes,
        data.description,
        data.additionalCost,
        data.supplierTypeID,
        data.supplierTypeGuid,
        data.type,
        data.systemType,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    )
    return oSupplier;
}

async function populateSupplierLinkedObjects(oSupplier) {
    if (!oSupplier) {
        return oSupplier;
    }
    try {
        let proms = [];
        proms.push(getObjectCoordinates(['objectType','objectGuid'],['Supplier',oSupplier.supplierGuid]))
        proms.push(getSupplierItems('supplierID', oSupplier.supplierID))
        const results = await Promise.all(proms)
        oSupplier.coordinates = results.at(0)
        oSupplier.supplierItems = results.at(1)
    }
    catch (error) {
        await putError(error, oSupplier, 'Supplier.js', 'deleteSuppliers')
    }
    return oSupplier
}

async function getSupplier_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const supplier = await db.suppliers.get(keyValueObject)
    let oSupplier = populateObject_Supplier(supplier) 
    return oSupplier
}

async function getSuppliers_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const suppliers = await db.suppliers
        .where(where)
        .equals(equals)
        .toArray();
    let oSuppliers = suppliers.map((supplier) => { return populateObject_Supplier(supplier) })
    return oSuppliers
}

async function putSupplier_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.suppliers.put(oData, oData.id);
    return;
}

async function deleteSuppliers_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.suppliers
        .where(where)
        .equals(equals)
        .delete()
    return;
}

export async function getFullSuppliers(where, equals) { 
    try {
        const oSuppliers = await getSuppliers(where, equals);
        let proms = oSuppliers.map((oSupplier) => { return populateSupplierLinkedObjects(oSupplier) });
        const results = await Promise.all(proms);
        return results.flat();
    }
    catch (error) {
        await putError(error, {where, equals}, 'Supplier.js', 'getFullSuppliers')
        return
    } 
}

export async function getLookupDataSourceSuppliers(where, equals, addEmpty = false) {
    try {
        const oSuppliers = await getSuppliers(where, equals)
        let oData = oSuppliers.map(function (x) { return { value: x.supplierID, text: x.name } });
        if (addEmpty) {
            oData.unshift({ value: "", text: "" })
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource
    }
    catch (error) {
        await putError(error, {where, equals, addEmpty}, 'Supplier.js', 'getLookupDataSourceSuppliers')
        return
    }
}
export async function getSupplier(keyValueObject) {
    try {
        let oData = await getSupplier_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'Supplier.js', 'getSupplier')
        return
    }
}

export async function getSuppliers(where, equals) {
    try {
        let oData = await getSuppliers_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'Supplier.js', 'getSuppliers')
        return
    }
}

export async function putSupplier(oData) {
    try {
        await putSupplier_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'Supplier.js', 'putSupplier')
        return
    }
}

export async function deleteSuppliers(where, equals) {
    try {
        await deleteSuppliers_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'Supplier.js', 'deleteSuppliers')
        return
    }
}
