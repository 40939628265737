import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_MeasureType } from '../DB/Measure/MeasureType';
import { populateObject_MeasureTypeUnitType } from '../DB/Measure/MeasureTypeUnitType';
import { populateObject_ObjectMeasure } from '../DB/Measure/ObjectMeasure';
import { putError } from '../DB/Error/Error.js';

export function getMeasureTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Measure/getMeasureTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_MeasureType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                    },
                    'Measure',
                    'getMeasureTypes_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getMeasureTypeUnitTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Measure/getMeasureTypeUnitTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_MeasureTypeUnitType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                    },
                    'Measure',
                    'getMeasureTypeUnitTypes_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getObjectMeasures_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Measure/getObjectMeasures', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_ObjectMeasure(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'Measure',
                    'getObjectMeasures_API'
                )
                return Promise.reject(error)
            }
        )
}

export function upsertObjectMeasure_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.active = object.active === 'true';
    return Api.post('/Measure/upsertObjectMeasure',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oData = populateObject_ObjectMeasure(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Measure',
                    'getObjectMeasures_API'
                )
                return Promise.reject(error)
            }
        )
}