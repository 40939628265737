export const Schema = {
    addresses: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, addressGuid, addressID',
    address_breanches: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, addressBranchGuid', 
    address_devices: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, addressDeviceGuid, addressGuid',
    address_device_properties: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, addressDeviceGuid', 
    assembly_points: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, name, assemblyPointID',
    change_history: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode', 
    code: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, codeTypeSystemName, [codeSystemName+codeTypeGuid], [codeTypeSystemName+active], [codeSystemName+codeTypeSystemName+active], [code+codeTypeSystemName+active]',
    code_type: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, codeTypeSystemName', 
    device_type: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, deviceTypeID, deviceTypeGuid',
    device_properties: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode',
    dump_dockets: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, dumpDocketGuid, [sent+guid], [sent+isComplete], [driverID+vehicleID+isComplete], [driverID+vehicleID+supplierID+isComplete]', 
    equipment: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode',
    errors: 'id, [id+sent], [active+sent]',
    event_types: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, eventSystemName', 
    flags: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode',    
    generic_objects: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, genericObjectGuid, [genericObjectTypeSystemName+active]',
    hazard_controls: 'id, [id+sent], [active+sent], [hazardInfoID+active], guid, active, companyID, regionID, sent, mode, hazardControlID, hazardInfoGuid, controlMethod',   
    hazard_infos: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, position',   
    hazard_substances: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, name, hazardSubstanceGuid',
    hazard_forms:'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [addressID+active], [jobScheduleID+active]',
    hazard_form_hazard_infos:'id, [id+sent], [active+sent], [hazardFormGuid+hazardInfoID+active], guid, active, companyID, regionID, sent, mode, hazardFormGuid, [hazardFormGuid+active]',
    hazard_form_substances:'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, hazardFormGuid, [hazardFormGuid+active], [hazardFormGuid+hazardSubstanceID+active]',
    hazard_form_user_acknowledgements:'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, hazardFormGuid, [hazardFormGuid+active], [hazardFormGuid+userID+active]',
    items: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, itemID, itemSystemName, [itemTypeSystemType+active]',
    job_references: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, jobReferenceGuid, [jobTypeID+active], [jobReference+jobTypeID+active]',
    job_types: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, systemType, jobTypeGuid', 
    job_safety_categories: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, jobSafetyCategoryID',
    job_safety_device_actions: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [jobSafetyDeviceReportGuid+active]',
    job_safety_device_mappings: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, deviceTypeGuid, jobSafetyHazardGuid, [jobSafetyHazardGuid+deviceTypeGuid]',
    job_safety_device_reports:'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [jobSafetyReportHeaderGuid+active], [jobSafetyReportHeaderGuid+deviceTypeID+active]',
    job_safety_risks: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, jobSafetyRiskGuid, jobSafetyRiskID',
    job_safety_hazards: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, jobSafetyHazardGuid, jobSafetyHazardID, jobSafetyCategoryID',
    job_safety_report_acknowledgements: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [jobSafetyReportHeaderGuid+active]',
    job_safety_report_headers: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [addressID+active]',
    loaded_waste: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, loadedWasteGuid, scheduledJobGuid, deleted, addressGuid, [vehicleID+deleted+dumped+active], [addressGuid+deleted+scheduledJobGuid+active], [jobSystemType+jobGuid+vehicleID+itemID+driverID+deleted+dumped], [addressGuid+jobTypeGuid+jobGuid+vehicleID+deleted], [JobSystemType+JobGuid+VehicleID+ItemID+DriverID+Deleted+Dumped]',
    mark_type: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, markTypeSystemName',
    measure_type: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, measureTypeSystemName',
    measure_type_unit_type: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode', 
    object_code: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active], [objectType+objectGuid+codeTypeSystemName+active], [objectType+objectGuid+codeTypeGuid+active]',
    object_coordinate: 'id, [id+sent], [active+sent], [sent+objectType], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active]', 
    object_equipments: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active]', 
    object_events: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent],[objectType+eventSystemName+sent], [objectType+objectGuid+active], [objectType+eventSystemName+userGuid+active], [objectType+objectGuid+eventSystemName+active]',  
    object_flags: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active]', 
    object_mark: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectMarkGuid, [markTypeSystemName+markValue+objectType+objectGuid], objectType, objectguid, [objectType+sent], [objectType+objectGuid+active], [objectType+objectGuid+markTypeGuid+active]',
    object_measure: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active]',
    object_photo: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active], [objectType+photoTypeSystemName+objectGuid+active], [photoTypeSystemName+objectType+objectGuid+active]',
    object_signature: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active], [userID+objectType+objectGuid+signatureTypeSystemName+active], [signatureTypeGuid+objectType+objectGuid+referenceGuid+userID+active], [signatureTypeGuid+objectType+objectGuid+referenceGuid+active], [objectType+objectGuid+signatureTypeSystemName+active]',
    object_stocks: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active]',
    object_users: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active], [objectUserGuid+userGuid+active], [objectType+objectGuid+userGuid+active], [objectType+userGuid+active]',
    object_vehicles: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, vehicleCode, sent, mode, objectVehicleGuid, objectType, objectguid, [objectType+sent], [objectType+objectGuid+active]', 
    photo_types: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, photoTypeSystemName',
    prestart_tasks:'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [prestartMeetingGuid+active], [prestartMeetingGuid+prestartTaskTypeGuid+active], [prestartMeetingGuid+prestartTaskTypeGuid+userID+active]',
    prestart_task_types:'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode,prestartTaskTypeGuid ',
    prestart_meetings:'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [addressID+active], [prestartMeetingGuid+active], [addressID+sent]',
    report_definition: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, reportDefinitionSystemName',
    report_data: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [objectType+objectGuid+active], [reportDefinitionSystemName+objectGuid+active],[reportDefinitionSystemName+objectType+objectGuid+active]',  
    scheduled_jobs: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, workOrderGuid, addressGuid, addressDeviceGuid, jobScheduleGuid, jobScheduleID, [workOrderTeamGuid+active], [workOrderTeamGuid+workOrderGuid+addressGuid] ',
    service_types: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, serviceTypeGuid',
    signature_types: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, signatureTypeSystemName',  
    statuses: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, statusTypeName, statusGuid, [statusTypeName+statusName]',
    status_types: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode',
    stocks: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode',
    suppliers: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, systemType, supplierID, [systemType+active]',
    supplier_items: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, supplierItemID, [supplierID+itemTypeSystemType+active]',
    transfered_waste: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, [sent+guid]', 
    users: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, userGuid, type, userID, [type+sent]', 
    unit_type: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, unitTypeSystemName', 
    vehicles: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, vehicleID, vehicleCode, vehicleGuid',
    vehicles_types: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode',
    work_orders: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, workOrderDate, workOrderGuid',
    work_order_events: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, workOrderGuid, workOrderDate, workOrderTeamGuid',  
    work_order_teams: 'id, [id+sent], [active+sent], guid, active, companyID, regionID, sent, mode, workOrderGuid, workOrderDate, workOrderTeamGuid' 
}
export const version = 1002