import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_ObjectCoordinate} from '../DB/Coordinate/ObjectCoordinate'
import { putError } from '../DB/Error/Error.js';

export function getCoordinates_API(objectType) {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Coordinate/getCoordinates', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate,
            ObjectType:objectType
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            data.active = 'true'
            let oData = populateObject_ObjectCoordinate(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate,
                        ObjectType:objectType
                    },
                    'Coordinate',
                    'getCoordinates_API'
                )
                return Promise.reject(error);
            }
        ) 
}

export function upsertCoordinate_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.post('/Coordinate/upsertCoordinate',
        {
            Dto:JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent='true';
                let oObject = populateObject_ObjectCoordinate(data)
                return oObject
            }
        )
        .catch(
           async (error) => {
                await putError(
                    error,
                    object,
                    'Coordinate',
                    'upsertCoordinate_API'
                )
                return Promise.reject(error)
            }
        ) 
}