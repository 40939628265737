import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx'; 
import { populateObject_AddressDevice, putAddressDevice } from '../DB/Address/AddressDevice'
import { populateObject_AddressDeviceProperty, putAddressDeviceProperty } from '../DB/Address/AddressDeviceProperty'
import { putError } from '../DB/Error/Error.js';

export function getAddressDevices_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/AddressDevice/getWorkOrderAddressDevices', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
            let oDataList = []
            dataList.forEach((data) => { 
                data.sent = 'true'
                let oData = populateObject_AddressDevice(data)
                oDataList.push(oData)
            }); 
            return oDataList; 

        })
        .catch(
           async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'AddressDevice',
                    'getAddressDevices_API'
                )
                return Promise.resolve(error)
            }
        ) 
}
export function upsertAddressDevice_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.post('/AddressDevice/upsertAddressDevice',
        {
            Dto:JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data; 
                data.sent = 'true';
                let oObject = populateObject_AddressDevice(data); 
                return putAddressDevice(oObject);
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'AddressDevice',
                    'upsertAddressDevice_API' 
                )
                return Promise.reject(error);
            }
        ) 
}

export function getAddressDeviceProerties_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/AddressDevice/getWorkOrderAddressDeviceProerties', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
            let oDataList = []
            dataList.forEach((data) => { 
                data.sent = 'true'
                data.active = 'true'
                let oData = populateObject_AddressDeviceProperty(data)
                oDataList.push(oData)
            }); 
            return oDataList; 
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'AddressDevice',
                    'getAddressDeviceProerties_API' 
                )
                return Promise.reject(error);
            }
        ) 
}

export function upsertAddressDeviceProperty_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.post('/AddressDevice/upsertAddressDeviceProperty',
        {
            Dto:JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data; 
                let oObject = populateObject_AddressDeviceProperty(data)
                return putAddressDeviceProperty(oObject)
            }
        )
        .catch(
           async (error) => {
                await putError(
                    error,
                    object,
                    'AddressDevice',
                    'upsertAddressDeviceProperty_API' 
                )
                return Promise.reject(error);
            }
        )
}