import { db } from "../db";
import { putError } from "../Error/Error";
import { HazardFormHazardInfo } from "../../Model/HazardForm/HazardFormHazardInfo";
import { getHazardControl } from "../Hazard/HazardControl";

export function populateObject_HazardFormHazardInfo(data) {
    if (!data) {
        return null
    }
    let oData = new HazardFormHazardInfo(
        data.hazardFormHazardInfoID,
        data.hazardFormHazardInfoGuid,
        data.hazardFormGuid,
        data.hazardControlID,
        data.hazardInfoID,
        data.hazardInfoGuid,
        data.position,
        data.name,
        data.risk,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',      
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode  
    )
    return oData;
}

async function populateHazardFormHazardInfoLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try{ 
        if(oData.hazardControlID){
            oData.hazardControl = await getHazardControl({hazardControlID:oData.hazardControlID}); 
        } 
        return oData
    }
    catch(error){
        await putError(error,oData,'HazardFormHazardInfo.js','populateHazardFormHazardInfoLinkedObjects') 
        return oData
    } 
}

async function getHazardFormHazardInfo_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.hazard_form_hazard_infos.get(keyValueObject);
    const oData = populateObject_HazardFormHazardInfo(data)
    return oData;
}

async function getHazardFormHazardInfos_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.hazard_form_hazard_infos
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_HazardFormHazardInfo(object) });
    return oData;
}

async function putHazardFormHazardInfo_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.hazard_form_hazard_infos.put(oData, oData.id);
    return;
}

async function deleteHazardFormHazardInfos_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.hazard_form_hazard_infos
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getHazardFormHazardInfo(keyValueObject) {
    try {
        let oData = await getHazardFormHazardInfo_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'HazardFormHazardInfo.js', 'getHazardFormHazardInfo')
        return
    }
}

export async function getFullHazardFormHazardInfo(keyValueObject){
    try {
        let oData = await getHazardFormHazardInfo(keyValueObject);
        oData = await populateHazardFormHazardInfoLinkedObjects(oData)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'HazardFormHazardInfo.js', 'getFullHazardFormHazardInfo')
        return
    }
}

export async function getHazardFormHazardInfos(where, equals) {
    try {
        let oData = await getHazardFormHazardInfos_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'HazardFormHazardInfo.js', 'getHazardFormHazardInfos')
        return
    }
}

export async function getFullHazardFormHazardInfos(where, equals) {
    try {
        const oData = await getHazardFormHazardInfos(where, equals)
        let proms = oData.map((object) => { return populateHazardFormHazardInfoLinkedObjects(object) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'HazardFormHazardInfo.js','getFullHazardFormHazardInfos')  
        return Promise.resolve([]);
    }
}


export async function putHazardFormHazardInfo(oData) {
    try {
        await putHazardFormHazardInfo_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'HazardFormHazardInfo.js', 'putHazardFormHazardInfo')
        return
    }
}

export async function deleteHazardFormHazardInfos(where, equals) {
    try {
        await deleteHazardFormHazardInfos_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'HazardFormHazardInfo.js', 'deleteHazardFormHazardInfos')
        return
    }
}
