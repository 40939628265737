import BaseObject from "../Base/BaseObject";  
export class Flag extends BaseObject {
    constructor(
        flagID,
        flagGuid,
        type,
        systemType, 
        companyID,
        regionID 
    ) {
        super(
            flagID,
            flagGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.flagID = flagID;
        this.flagGuid = flagGuid;
        this.type = type;
        this.systemType = systemType;
    }

}  