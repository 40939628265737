import { db } from "../db";
import { putError } from "../Error/Error";
import { PrestartTaskType } from "../../Model/Prestart/PrestartTaskType";

export function populateObject_PrestartTaskType(data) {
    if (!data) {
        return null
    }
    let oData = new PrestartTaskType(  
        data.prestartTaskTypeGuid,
        data.prestartTaskTypeID,
        data.name,
        data.order,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false', 
        'true',
        data.mode,
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID
    )
    return oData
}

async function getPrestartTaskType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.prestart_task_types.get(keyValueObject);
    const oData = populateObject_PrestartTaskType(data)
    return oData
}

async function getPrestartTaskTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.prestart_task_types
        .where(where)
        .equals(equals)
        .toArray();
    const oData = data.map((x) => { return populateObject_PrestartTaskType(x) }).sort((a, b) => a.order - b.order)
    return oData
}

async function putPrestartTaskType_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.prestart_task_types.put(oData, oData.id);
    return;
}

async function deletePrestartTaskTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.prestart_task_types
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getPrestartTaskType(keyValueObject) {
    try {
        let oData = await getPrestartTaskType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'PrestartTaskType.js', 'getPrestartTaskType')
        return
    }
}

export async function getPrestartTaskTypes(where, equals) {
    try {
        let oData = await getPrestartTaskTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'PrestartTaskType.js', 'getPrestartTaskTypes')
        return
    }
}

export async function putPrestartTaskType(oData) {
    try {
        await putPrestartTaskType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'PrestartTaskType.js', 'putPrestartTaskType')
        return
    }
}

export async function deletePrestartTaskTypes(where, equals) {
    try {
        await deletePrestartTaskTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'PrestartTaskType.js', 'deletePrestartTaskTypes')
        return
    }
}
