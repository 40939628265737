import BaseObject from "../Base/BaseObject";
export class JobSafetyDeviceReport extends BaseObject {
    constructor(
        jobSafetyDeviceReportGuid,
        jobSafetyReportHeaderGuid,
        deviceTypeID,
        deviceTypeGuid, 
        jobScheduleID, 
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobSafetyDeviceReportGuid,
            jobSafetyDeviceReportGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.jobSafetyReportHeaderGuid = jobSafetyReportHeaderGuid;
        this.jobSafetyDeviceReportGuid = jobSafetyDeviceReportGuid;
        this.deviceTypeID = deviceTypeID;
        this.deviceTypeGuid = deviceTypeGuid; 
        this.jobScheduleID = jobScheduleID; 
        this.jobSafetyDeviceReportActions = [];
    }

}  