import BaseObject from "../Base/BaseObject";
export default class ObjectSignature extends BaseObject {
    constructor(
        objectSignatureGuid,
        signature,
        signatureName,
        userID,
        objectType,
        objectGuid,
        referenceGuid,
        signatureTypeID,
        signatureTypeGuid,
        signatureTypeName,
        signatureTypeSystemName,
        sent,
        active,
        mode,
        createdDate,
        lastModifiedDate,
        companyID,
        regionID 
    ) {
        super(
            objectSignatureGuid,
            objectSignatureGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.objectSignatureGuid = objectSignatureGuid;
        this.signature = signature;
        this.signatureName = signatureName;
        this.userID = userID;
        this.objectType = objectType;
        this.objectGuid = objectGuid;
        this.referenceGuid = referenceGuid;
        this.signatureTypeID = signatureTypeID;
        this.signatureTypeGuid = signatureTypeGuid;
        this.signatureTypeName = signatureTypeName;
        this.signatureTypeSystemName = signatureTypeSystemName; 

    }
}