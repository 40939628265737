import BaseObject from "../Base/BaseObject";  
export default class ObjectCode extends BaseObject {
    constructor( 
        objectCodeGuid, 
        objectType, 
        objectGuid,
        referenceGuid,
        codeID,
        codeGuid,
        code,
        codeSystemName,
        codeTypeID,
        codeTypeGuid,
        codeType,
        codeTypeSystemName,
        createdDate,
        lastModifiedDate,
        active,
        sent,
        mode,
        companyID,
        regionID
    ) {
        super(
            objectCodeGuid,
            objectCodeGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.objectCodeGuid = objectCodeGuid;
        this.objectType = objectType; 
        this.objectGuid = objectGuid
        this.referenceGuid = referenceGuid
        this.codeID = codeID;
        this.codeGuid = codeGuid;
        this.code = code;
        this.codeSystemName = codeSystemName;
        this.codeTypeID = codeTypeID;
        this.codeTypeGuid = codeTypeGuid;
        this.codeType = codeType;
        this.codeTypeSystemName = codeTypeSystemName;

    }

}