import { upsertJobSafetyReportHeader_API, upsertJobSafetyDeviceReport_API, upsertJobSafetyDeviceReportAction_API, upsertJobSafetyReportAcknowledgement_API } from '../../API/JobSafetyReport' 
import { putError } from "../../DB/Error/Error";  
import { getJobSafetyReportHeaders, putJobSafetyReportHeader } from '../../DB/JobSafetyReport/JobSafetyReportHeader';
import { getJobSafetyDeviceReports, putJobSafetyDeviceReport } from '../../DB/JobSafetyReport/JobSafetyDeviceReport';
import { getJobSafetyDeviceReportActions, putJobSafetyDeviceReportAction } from '../../DB/JobSafetyReport/JobSafetyDeviceReportAction';
import { getJobSafetyReportAcknowledgements, putJobSafetyReportAcknowledgement } from '../../DB/JobSafetyReport/JobSafetyReportAcknowledgement';

export async function syncToApiJobSafetyReports(){
    let promises = []
    try{ 
        promises.push(syncToApiJobSafetyReport_JobSafetyReportHeaders());
        promises.push(syncToApiJobSafetyReport_JobSafetyDeviceReports());
        promises.push(syncToApiJobSafetyReport_JobSafetyDeviceReportActions());
        promises.push(syncToApiJobSafetyReport_JobSafetyReportAcknowledgements());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiJobSafetyReports'}, 'Sync/FromAPI/JobSafetyReport', 'syncToApiJobSafetyReports')
        return Promise.resolve()
    } 
}
  
async function syncToApiJobSafetyReport_JobSafetyReportHeaders(){ 
    try{
        const dataToApi = await getJobSafetyReportHeaders('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi =  await Promise.all(dataToApi.map((data)=>{return upsertJobSafetyReportHeader_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putJobSafetyReportHeader(data)}));
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiJobSafetyReport_JobSafetyReportHeaders'}, 'Sync/ToAPI/JobSafetyReport', 'syncToApiJobSafetyReports')
        return Promise.resolve()
    } 
} 
  
async function syncToApiJobSafetyReport_JobSafetyDeviceReports(){ 
    try{
        const dataToApi = await getJobSafetyDeviceReports('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi =  await Promise.all(dataToApi.map((data)=>{return upsertJobSafetyDeviceReport_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putJobSafetyDeviceReport(data)}));
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiJobSafetyReport_JobSafetyReportHeaders'}, 'Sync/ToAPI/JobSafetyReport', 'syncToApiJobSafetyReports')
        return Promise.resolve()
    } 
} 
  
async function syncToApiJobSafetyReport_JobSafetyDeviceReportActions(){ 
    try{
        const dataToApi = await getJobSafetyDeviceReportActions('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi =  await Promise.all(dataToApi.map((data)=>{return upsertJobSafetyDeviceReportAction_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putJobSafetyDeviceReportAction(data)}));
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiJobSafetyReport_JobSafetyDeviceReportActions'}, 'Sync/ToAPI/JobSafetyReport', 'syncToApiJobSafetyReports')
        return Promise.resolve()
    } 
} 
  
async function syncToApiJobSafetyReport_JobSafetyReportAcknowledgements(){ 
    try{
        const dataToApi = await getJobSafetyReportAcknowledgements('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi =  await Promise.all(dataToApi.map((data)=>{return upsertJobSafetyReportAcknowledgement_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putJobSafetyReportAcknowledgement(data)}));
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncToApiJobSafetyReport_JobSafetyReportAcknowledgements'}, 'Sync/ToAPI/JobSafetyReport', 'syncToApiJobSafetyReports')
        return Promise.resolve()
    } 
} 