import { getItems_API } from '../../API/Item' 
import { putError } from "../../DB/Error/Error";
import { getItem, putItem } from '../../DB/Item/Item'; 
export async function syncFromApiItems(){
    let promises = []
    try{
        promises.push(syncFromApiItem_Items()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiItem'}, 'Sync/FromAPI/Item', 'syncFromApiItem')
        return Promise.resolve()
    } 
}

async function syncFromApiItem_PutItem(data){
    let oData =  await getItem({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putItem(data)
}


async function syncFromApiItem_Items(){ 
    try{
        const dataFromApi = await getItems_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiItem_PutItem(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiItem_Item'}, 'Sync/FromAPI/Item', 'syncFromApiItem')
        return Promise.resolve()
    } 
}
 