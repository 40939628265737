import { db } from "../db";
import { putError } from "../Error/Error";
import { StatusType } from '../../Model/Status/StatusType'

export function populateObject_StatusType(statusType) {
    if (!statusType) {
        return null
    }
    let oStatusType = new StatusType(
        statusType.statusTypeID,
        statusType.statusTypeName,
        statusType.statusTypeGuid,
        statusType.companyID,
        statusType.regionID
    );

    return oStatusType
}

async function getStatusType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const statusType = await db.status_types.get(keyValueObject)
    let oStatusType = populateObject_StatusType(statusType)
    return oStatusType
}

async function getStatusTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const statusTypes = await db.status_types
        .where(where)
        .equals(equals)
        .toArray();
    let oStatusTypes = statusTypes.map((statusType) => { return populateObject_StatusType(statusType) })
    return oStatusTypes
}

async function putStatusType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.status_types.put(oData, oData.id);
    return;
}

async function deleteStatusTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.status_types
        .where(where)
        .equals(equals)
        .delete();
    return;
} 

export async function getStatusType(keyValueObject) {
    try {
        let oData = await getStatusType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'StatusType.js', 'getStatusType')
        return
    }
}

export async function getStatusTypes(where, equals) {
    try {
        let oData = await getStatusTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'StatusType.js', 'getStatusTypes')
        return
    }
}

export async function putStatusType(oData) {
    try {
        await putStatusType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'StatusType.js', 'putStatusType')
        return
    }
}

export async function deleteStatusTypes(where, equals) {
    try {
        await deleteStatusTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'StatusType.js', 'deleteStatusTypes')
        return
    }
}
