import BaseObject from "../Base/BaseObject";  
export class Vehicle extends BaseObject {
    constructor( 
        vehicleID,
        vehicleGuid,
        fireExtinguisherLocation,
        firstAidKitLocation,
        description,
        notes,
        vehicleCode,
        registrationNumber,
        targetDailyWorkValue,
        maxWeight,
        truckCapacityVolume,
        vehicleTypeID,
        vehicleTypeGuid,
        type,
        systemType, 
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID 

    ) {
        super(
            vehicleID,
            vehicleGuid,
            'true',
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.vehicleID = vehicleID;
        this.vehicleGuid = vehicleGuid;
        this.vehicleTypeID = vehicleTypeID;
        this.vehicleCode = vehicleCode;
        this.registrationNumber = registrationNumber;
        this.targetDailyWorkValue = targetDailyWorkValue;
        this.maxWeight = maxWeight;
        this.truckCapacityVolume = truckCapacityVolume;
        this.description = description;
        this.notes = notes; 
        this.fireExtinguisherLocation = fireExtinguisherLocation;
        this.firstAidKitLocation = firstAidKitLocation; 
        this.vehicleTypeGuid = vehicleTypeGuid;
        this.type = type;
        this.systemType = systemType; 
        this.loadedWastes = [];
    }        
}
 