import { db } from "../db";
import { putError } from "../Error/Error";
import { EventType } from '../../Model/Event/EventType'

export function populateObject_EventType(eventType) {
    if (!eventType) {
        return null
    }
    let oEventType = new EventType(
        eventType.eventTypeID,
        eventType.eventTypeGuid,
        eventType.eventType,
        eventType.eventSystemName,
        eventType.createdDate,
        eventType.lastModifiedDate,
        eventType.companyID,
        eventType.regionID
    )
    return oEventType;
}

async function getEventType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const eventType = await db.event_types.get(keyValueObject);
    let oEventType = populateObject_EventType(eventType)
    return oEventType;
}

async function getEventTypes_db() {
    const eventTypes = await db.event_types.toArray();
    let oEventTypes = eventTypes.map((eventType) => { return populateObject_EventType(eventType) })
    return oEventTypes;
}

async function putEventType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.event_types.put(oData, oData.id);
    return;
}

async function deleteEventTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.event_types
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getEventType(keyValueObject) {
    try {
        let oData = await getEventType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'EventType.js', 'getEventType')
        return
    }
}

export async function getEventTypes(where, equals) {
    try {
        let oData = await getEventTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'EventType.js', 'getEventTypes')
        return
    }
}

export async function putEventType(oData) {
    try {
        await putEventType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'EventType.js', 'putEventType')
        return
    }
}

export async function deleteEventTypes(where, equals) {
    try {
        await deleteEventTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'EventType.js', 'deleteEventTypes')
        return
    }
}


