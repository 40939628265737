import { db } from "../db";
import { putError } from "../Error/Error";
import AddressBranch from '../../Model/Address/AddressBranch'

export function populateObject_AddressBranch(data) {
    if (!data) {
        return null
    }
    let oAddressBranch = new AddressBranch(
        data.addressBranchID,
        data.addressBranchGuid,
        data.branchName,
        data.displayColour,
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID
    )
    return oAddressBranch;
}

async function getAddressBranch_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const addressBranch = await db.address_breanches.get(keyValueObject);
    const oAddressBranch = populateObject_AddressBranch(addressBranch)
    return oAddressBranch;
}

async function putAddressBranch_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.address_breanches.put(oData, oData.id);
    return;
}

async function deleteAddressBranch_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.address_breanches
        .where(where)
        .equals(equals)
        .delete()
    return;
}


export async function getAddressBranch(keyValueObject) {
    try {
        let oAddressBranch = await getAddressBranch_db(keyValueObject); 
        return oAddressBranch
    }
    catch (error) {
        await putError(error,keyValueObject,'AddressBranch.js','getAddressBranch') 
        return Promise.resolve(null);
    }
}

export async function putAddressBranch(oAddressBranch) {
    try {
        await putAddressBranch_db(oAddressBranch); 
        return;
    }
    catch (error) {
        await putError(error,oAddressBranch,'AddressBranch.js','putAddressBranch') 
        return
    }
}

export async function deleteAddressBranch(where, equals) {
    try {
        await deleteAddressBranch_db(where, equals); 
        return;
    }
    catch (error) {
        await putError(error,{where, equals},'AddressBranch.js','deleteAddressBranch') 
        return
    }
}