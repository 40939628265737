import BaseObject from "../Base/BaseObject";  

export class MeasureTypeUnitType extends BaseObject {
    constructor(
        measureTypeUnitTypeID,
        measureTypeUnitTypeGuid,
        measureTypeGuid, 
        unitTypeID,
        unitTypeGuid,
        unitType,
        unitTypeSystemName,
        unitTypeSymbol,
        createdDate,
        lastmodifiedDate,
        companyID,
        regionID
    ) {
        super(
            measureTypeUnitTypeID,
            measureTypeUnitTypeGuid,
            'true',
            createdDate,
            null,
            lastmodifiedDate,
            null,
            companyID,
            regionID,
            'sent',
            ''
        );
        this.measureTypeUnitTypeID = measureTypeUnitTypeID;
        this.measureTypeUnitTypeGuid = measureTypeUnitTypeGuid;
        this.measureTypeGuid = measureTypeGuid;  
        this.unitTypeID = unitTypeID;
        this.unitTypeGuid = unitTypeGuid;
        this.unitType = unitType;
        this.unitTypeSystemName = unitTypeSystemName;
        this.unitTypeSymbol = unitTypeSymbol
    }
}