import { getPhotoTypes_API, getObjectPhotos_API } from '../../API/Photo' 
import { putError } from "../../DB/Error/Error";
import { getPhotoType, putPhotoType } from "../../DB/Photo/PhotoType";
import { getObjectPhoto, putObjectPhoto } from "../../DB/Photo/ObjectPhoto";

export async function syncFromApiPhotos(){
    let promises = []
    try{
        promises.push(syncFromApiPhoto_PhotoTypes());
        promises.push(syncFromApiPhoto_ObjectPhotos());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiPhoto'}, 'Sync/FromAPI/Photo', 'syncFromApiPhoto')
        return Promise.resolve()
    } 
}

async function syncFromApiPhoto_PutPhotoType(data){
    let oData =  await getPhotoType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putPhotoType(data)
}

async function syncFromApiPhoto_PhotoTypes(){ 
    try{
        const dataFromApi = await getPhotoTypes_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiPhoto_PutPhotoType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiPhoto_PhotoTypes'}, 'Sync/FromAPI/Photo', 'syncFromApiPhoto')
        return Promise.resolve()
    }  
}

async function syncFromApiPhoto_PutObjectPhoto(data){
    let oData =  await getObjectPhoto({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putObjectPhoto(data)
}

async function syncFromApiPhoto_ObjectPhotos(){ 
    try{
        const dataFromApi = await getObjectPhotos_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiPhoto_PutObjectPhoto(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiPhoto_ObjectPhoto'}, 'Sync/FromAPI/Photo', 'syncFromApiPhoto')
        return Promise.resolve()
    }  
}