import BaseObject from "../Base/BaseObject";  
export default class AddressBranch extends BaseObject {
    constructor(
        addressBranchID,
        addressBranchGuid,
        branchName,
        displayColour,  
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID 
    ) {
        super(
            addressBranchID,
            addressBranchGuid,
            'true',
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.addressBranchID = addressBranchID;
        this.addressBranchGuid = addressBranchGuid;
        this.branchName = branchName;
        this.displayColour = displayColour; 

    } 
}