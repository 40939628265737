import BaseObject from "../Base/BaseObject";
export class JobSafetyRisk extends BaseObject {
    constructor(
        jobSafetyRiskID,
        jobSafetyRiskGuid,
        probability, 
        level,
        score,
        consequence,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobSafetyRiskID,
            jobSafetyRiskGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.jobSafetyRiskID = jobSafetyRiskID;
        this.jobSafetyRiskGuid = jobSafetyRiskGuid;
        this.probability = probability; 
        this.level = level; 
        this.score = score; 
        this.consequence = consequence; 
    }

}  