import BaseObject from "../Base/BaseObject";
export class HazardFormHazardInfo extends BaseObject {
    constructor(
        hazardFormHazardInfoID,
        hazardFormHazardInfoGuid,
        hazardFormGuid,
        hazardControlID,
        hazardInfoID,
        hazardInfoGuid,
        position,
        name,
        risk,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            hazardFormHazardInfoGuid,
            hazardFormHazardInfoGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.hazardFormHazardInfoID = hazardFormHazardInfoID;
        this.hazardFormHazardInfoGuid = hazardFormHazardInfoGuid;
        this.hazardFormGuid = hazardFormGuid;
        this.hazardControlID = hazardControlID;
        this.hazardInfoID = hazardInfoID;
        this.hazardInfoGuid = hazardInfoGuid;
        this.position = position;
        this.name = name;
        this.risk = risk
        this.hazardControl = {};
    }
}