import { getAuthenticatedUserGuid } from '../Utils/AuthUtils.jsx' 
import Api from './Api.jsx';
import { populateObject_TransferedWaste } from '../DB/TransferedWaste/TransferedWaste.js';
import { putError } from '../DB/Error/Error.js';
 
export function upsertTransferedWaste_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.post('/TransferedWaste/upsertTransferedWaste',
        {
            Dto:JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oObject = populateObject_TransferedWaste(data)
                return oObject
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'TransferedWaste',
                    'upsertTransferedWaste_API'
                )
                return Promise.reject(error)
            }
        ) 
}