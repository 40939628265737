import { db } from "../db";
import { putError } from "../Error/Error";
import { LoadedWaste } from "../../Model/LoadedWaste/LoadedWaste";
import { getItem } from "../Item/Item";
import { getJobType } from "../JobType/JobType";
import { getUser } from "../User/User";
import { getVehicle } from "../Vehicle/Vehicle";
import { getAddress } from "../Address/Address";
import { getFullScheduledJob } from "../Scheduledjob/Scheduledjob";
import { getJobReference } from "../JobReference/JobReference";

export function populateObject_LoadedWaste(loadedWaste) {
    if (!loadedWaste) {
        return null;
    }
    let oLoadedWaste = new LoadedWaste(
        loadedWaste.loadedWasteGuid,
        loadedWaste.dumpDocketGuid,
        loadedWaste.dsmNo,
        loadedWaste.address,
        loadedWaste.addressGuid,
        loadedWaste.scheduledJobGuid,
        loadedWaste.jobGuid,
        loadedWaste.jobTypeGuid,
        loadedWaste.itemID,
        loadedWaste.driverID,
        loadedWaste.vehicleID,
        loadedWaste.jobTypeID,
        loadedWaste.loadDate,
        loadedWaste.estimatedWeight,
        (typeof loadedWaste.deleted === 'string' && loadedWaste.deleted === 'true') || (typeof loadedWaste.deleted === 'boolean' && loadedWaste.deleted) ? 'true' : 'false',
        (typeof loadedWaste.dumped === 'string' && loadedWaste.dumped === 'true') || (typeof loadedWaste.dumped === 'boolean' && loadedWaste.dumped) ? 'true' : 'false',
        (typeof loadedWaste.active === 'string' && loadedWaste.active === 'true') || (typeof loadedWaste.active === 'boolean' && loadedWaste.active) ? 'true' : 'false',
        loadedWaste.createdDate,
        loadedWaste.lastModifiedDate,
        loadedWaste.companyID,
        loadedWaste.regionID,
        (typeof loadedWaste.sent === 'string' && loadedWaste.sent === 'true') || (typeof loadedWaste.sent === 'boolean' && loadedWaste.sent) ? 'true' : 'false',
        loadedWaste.mode ? loadedWaste.mode : '',

    )
    return oLoadedWaste;

}

export async function populateLoadedWasteLinkedObjects(oLoadedWaste) {
    try {
        let proms = [];
        proms.push(getItem({ itemID: oLoadedWaste.itemID }))
        proms.push(getJobType({ jobTypeGuid: oLoadedWaste.jobTypeGuid }))
        proms.push(getUser({ userID: oLoadedWaste.driverID }))
        proms.push(getVehicle({ vehicleID: oLoadedWaste.vehicleID }))

        const results = await Promise.all(proms);
        oLoadedWaste.item = results.at(0);
        oLoadedWaste.jobType = results.at(1);
        oLoadedWaste.driver = results.at(2);
        oLoadedWaste.vehicle = results.at(3);


        if (oLoadedWaste.addressGuid) {
            oLoadedWaste.addressObject = await getAddress({ addressGuid: oLoadedWaste.addressGuid })
        }

        if (oLoadedWaste.scheduledJobGuid) {
            oLoadedWaste.scheduledJob = await getFullScheduledJob({ jobScheduleGuid: oLoadedWaste.scheduledJobGuid })
        }

        if (oLoadedWaste.jobType.systemType === 'Site') {
            oLoadedWaste.job = oLoadedWaste.scheduledJob
            return oLoadedWaste
        }
        else {
            oLoadedWaste.job = await getJobReference({ 'jobReferenceGuid': oLoadedWaste.jobGuid })

        }
    }
    catch (error) {
        await putError(error, oLoadedWaste, 'LoadedWaste.js', 'populateLoadedWasteLinkedObjects')
    }
    return oLoadedWaste

}


async function getLoadedWaste_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const loadedWaste = await db.loaded_waste.get(keyValueObject);
    const oLoadedWaste = populateObject_LoadedWaste(loadedWaste);
    return oLoadedWaste
}

async function getLoadedWastes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const loadedWastes = await db.loaded_waste
        .where(where)
        .equals(equals)
        .toArray();

    let oLoadedWastes = loadedWastes.map((loadedWaste) => { return populateObject_LoadedWaste(loadedWaste); });
    return oLoadedWastes
}

async function putLoadedWaste_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.loaded_waste.put(oData, oData.id);
    return
}

async function deleteLoadedWastes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.loaded_waste
        .where(where)
        .equals(equals)
        .delete();
    return;
}


export async function getFullLoadedWaste(keyValueObject) {
    try {
        let oLoadedWaste = await getLoadedWaste(keyValueObject)
        oLoadedWaste = await populateLoadedWasteLinkedObjects(oLoadedWaste)
        return oLoadedWaste;
    }
    catch (error) {
        await putError(error, keyValueObject, 'LoadedWaste.js', 'getFullLoadedWaste')
        return
    }
}

export async function getFullLoadedWastes(where, equals) {
    try {
        const oLoadedWastes = await getLoadedWastes(where, equals);
        const results = await Promise.all(oLoadedWastes.map((oLoadedWaste) => { return populateLoadedWasteLinkedObjects(oLoadedWaste) })) 
        return results.flat();
    }
    catch (error) {
        await putError(error, { where, equals }, 'LoadedWaste.js', 'getFullLoadedWastes')
        return
    }
}

export async function getLoadedWaste(keyValueObject) {
    try {
        let oData = await getLoadedWaste_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'LoadedWaste.js', 'getLoadedWaste')
        return
    }
}

export async function getLoadedWastes(where, equals) {
    try {
        let oData = await getLoadedWastes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'LoadedWaste.js', 'getLoadedWastes')
        return
    }
}

export async function putLoadedWaste(oData) {
    try {
        await putLoadedWaste_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'LoadedWaste.js', 'putLoadedWaste')
        return
    }
}

export async function deleteLoadedWastes(where, equals) {
    try {
        await deleteLoadedWastes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'LoadedWaste.js', 'deleteLoadedWastes')
        return
    }
}
