import { v4 as uuidv4 } from 'uuid';
import { db } from '../db';
export async function putError(error, data, page, fnName) {
    try {
        let id = uuidv4();
        let message = error.response ? error.response.data : error;
        let code = error.code ? error.code : 'Js catch';

        let oError = {
            id: id,
            message: message,
            code: code,
            data: JSON.stringify(data),
            page: page,
            fnName: fnName
        }
        console.log(JSON.stringify(oError));
        await db.errors.put(oError, oError.id) 
    }
    catch {
        return Promise.resolve()
    }

}