
import BaseObject from "../Base/BaseObject";   
export class ObjectUser extends BaseObject {
    constructor(
        objectUserGuid, 
        objectGuid,
        referenceGuid,
        objectType,
        userGuid,
        userID,
        name,
        position,
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''

    ) {
        super(
            objectUserGuid,
            objectUserGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.userID = userID;
        this.userGuid = userGuid;
        this.name = name;
        this.position = position
        this.referenceGuid = referenceGuid;
        this.objectUserGuid = objectUserGuid 
        this.objectGuid = objectGuid;
        this.objectType = objectType;
        this.objectCodes = []
    }
}
      