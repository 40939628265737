import { upsertObjectVehicle_API } from "../../API/Vehicle";
import { getObjectVehicles, putObjectVehicle } from "../../DB/Vehicle/ObjectVehicle";
import { putError } from "../../DB/Error/Error";

export async function syncToApiObjectVehicles(){
    try{
        const dataToApi = await getObjectVehicles('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertObjectVehicle_API(data)}));
        if(!dataFromApi){
            return;
        }  
        await Promise.all(dataFromApi.map((data)=>{return putObjectVehicle(data)})); 
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiObjectVehicles'}, 'Sync/ToAPI/ObjectVehiles', 'syncToApiObjectVehicles')
        return Promise.resolve();
    }
}