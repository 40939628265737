import { getMeasureTypes_API, getMeasureTypeUnitTypes_API, getObjectMeasures_API } from '../../API/Measure' 
import { putError } from "../../DB/Error/Error";
import { getMeasureType, putMeasureType } from "../../DB/Measure/MeasureType";
import { getMeasureTypeUnitType, putMeasureTypeUnitType} from "../../DB/Measure/MeasureTypeUnitType";
import { getObjectMeasure, putObjectMeasure} from "../../DB/Measure/ObjectMeasure";

export async function syncFromApiMeasures(){
    let promises = []
    try{
        promises.push(syncFromApiMeasure_MeasureTypeUnitTypes());
        promises.push(syncFromApiMeasure_ObjectMeasures());
        promises.push(syncFromApiMeasure_MeasureTypes()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiMeasure'}, 'Sync/FromAPI/Measure', 'syncFromApiMeasure')
        return Promise.resolve()
    } 
}

async function syncFromApiMeasure_PutObjectMeasures(data){
    let oData =  await getObjectMeasure({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putObjectMeasure(data)
}

async function syncFromApiMeasure_ObjectMeasures(){ 
    try{
        const dataFromApi = await getObjectMeasures_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiMeasure_PutObjectMeasures(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiMeasure_ObjectMeasure'}, 'Sync/FromAPI/Measure', 'syncFromApiMeasure')
        return Promise.resolve()
    } 
}

async function syncFromApiMeasure_PutMeasureTypeUnitType(data){
    let oData =  await getMeasureTypeUnitType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putMeasureTypeUnitType(data)
}

async function syncFromApiMeasure_MeasureTypeUnitTypes(){
    try{
        const dataFromApi = await getMeasureTypeUnitTypes_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiMeasure_PutMeasureTypeUnitType(data)});
        await Promise.all(putProms);
        return;        
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiMeasure_MeasureTypeUnitType'}, 'Sync/FromAPI/Measure', 'syncFromApiMeasure')
        return Promise.resolve()
    } 
}

async function syncFromApiMeasure_PutMeasureType(data){
    let oData =  await getMeasureType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putMeasureType(data)
}

async function syncFromApiMeasure_MeasureTypes(){ 
    try{
        const dataFromApi = await getMeasureTypes_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiMeasure_PutMeasureType(data)});
        await Promise.all(putProms);
        return; 
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiMeasure_MeasureType'}, 'Sync/FromAPI/Measure', 'syncFromApiMeasure')
        return Promise.resolve()
    } 
}