import { db } from "../db";
import { putError } from "../Error/Error";
import SignatureType from "../../Model/Signature/SignatureType";

export function populateObject_SignatureType(signatureType) {
    if (!signatureType) {
        return
    }
    let oSignatureType = new SignatureType(
        signatureType.signatureTypeID,
        signatureType.signatureTypeGuid,
        signatureType.signatureTypeName,
        signatureType.signatureTypeSystemName,
        signatureType.createdDate,
        signatureType.lastModifiedDate,
        signatureType.companyID,
        signatureType.regionID
    );
    return oSignatureType;
}

async function getSignatureType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const signatureType = await db.signature_types.get(keyValueObject)
    let oSignatureType = populateObject_SignatureType(signatureType)
    return oSignatureType;
}

async function getSignatureTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const signatureTypes = await db.signature_types
        .where(where)
        .equals(equals)
        .toArray();
    let oSignatureTypes = signatureTypes.map((signatureType) => { return populateObject_SignatureType(signatureType) });
    return oSignatureTypes;
}

async function putSignatureType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.signature_types.put(oData, oData.id);
    return;
}

async function deleteSignatureTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.signature_types
        .where(where)
        .equals(equals)
        .delete();
    return;
}
export async function getSignatureType(keyValueObject) {
    try {
        let oData = await getSignatureType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'SignatureType.js', 'getSignatureType')
        return
    }
}

export async function getSignatureTypes(where, equals) {
    try {
        let oData = await getSignatureTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'SignatureType.js', 'getSignatureTypes')
        return
    }
}

export async function putSignatureType(oData) {
    try {
        await putSignatureType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'SignatureType.js', 'putSignatureType')
        return
    }
}

export async function deleteSignatureTypes(where, equals) {
    try {
        await deleteSignatureTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'SignatureType.js', 'deleteSignatureTypes')
        return
    }
}
