
import { getDeviceProperties_API } from '../../API/DeviceProperty'
import { getDeviceTypes_API } from '../../API/DeviceType'
import { getDeviceProperty, putDeviceProperty } from '../../DB/Device/DeviceProperty';
import { getDeviceType, putDeviceType } from '../../DB/Device/DeviceType';
import { putError } from "../../DB/Error/Error";
export async function syncFromApiDevices(){
    let promises = []
    try{
        promises.push(syncFromApiDevice_DeviceProperties());
        promises.push(syncFromApiDevice_DeviceTypes());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiDevice'}, 'Sync/FromAPI/Device', 'syncFromApiDevice')
        return Promise.resolve()
    } 
}

async function syncFromApiDevice_PutDeviceProperties(data){
    let oData = await getDeviceProperty({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putDeviceProperty(data)
}

async function syncFromApiDevice_DeviceProperties(){
    try{
        const dataFromApi = await getDeviceProperties_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiDevice_PutDeviceProperties(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiDevice_DeviceProperties'}, 'Sync/FromAPI/Device', 'syncFromApiDevice')
        return Promise.resolve()
    } 
}

async function syncFromApiDevice_PutDeviceType(data){
    let oData = await getDeviceType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putDeviceType(data)
}

async function syncFromApiDevice_DeviceTypes(){
    try{
        const dataFromApi = await getDeviceTypes_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiDevice_PutDeviceType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiDevice_DeviceTypes'}, 'Sync/FromAPI/Device', 'syncFromApiDevice')
        return Promise.resolve()
    }  
}
