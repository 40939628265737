import BaseObject from "../Base/BaseObject"; 
export class LoadedWaste extends BaseObject {
    constructor( 
        loadedWasteGuid,
        dumpDocketGuid,
        dsmNo,
        address,
        addressGuid,
        scheduledJobGuid,
        jobGuid,
        jobTypeGuid,
        itemID,
        driverID,
        vehicleID,
        jobTypeID,
        loadDate,
        estimatedWeight,
        deleted,
        dumped,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            loadedWasteGuid,
            loadedWasteGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.loadedWasteGuid = loadedWasteGuid;
        this.dumpDocketGuid = dumpDocketGuid;
        this.dsmNo = dsmNo;
        this.address = address;
        this.addressGuid = addressGuid;
        this.scheduledJobGuid = scheduledJobGuid;
        this.jobGuid = jobGuid;
        this.jobTypeGuid = jobTypeGuid;
        this.itemID = itemID;
        this.driverID = driverID;
        this.vehicleID = vehicleID;
        this.jobTypeID = jobTypeID;
        this.loadDate = loadDate;
        this.estimatedWeight = estimatedWeight;
        this.deleted = deleted;
        this.dumped = dumped;
        this.newLine = false;
        this.lineChanged = false;
        this.calculatedWeight = null;
        this.job = {}
        this.item = {};
        this.jobType = {};
        this.driver = {};
        this.vehicle = {};
        this.addressObject = {} 
        this.scheduledJob = {}
    }

} 