import { db } from "../db";
import { putError } from "../Error/Error";
import Scheduledjob from '../../Model/Scheduledjob/ScheduledJob'
import { getFullAddressDevice } from '../Address/AddressDevice'
import { getObjectVehicles } from '../Vehicle/ObjectVehicle'
import { getObjectUsers } from '../User/ObjectUser'
import { getServiceType } from '../ServiceType/ServiceType'
import { getObjectEvents } from "../Event/ObjectEvent";
import { getAddress } from "../Address/Address";
import { getFullReportData } from "../Reporting/ReportData";
import { getLoadedWastes } from "../LoadedWaste/LoadedWaste";


export function populateObject_ScheduledJob(scheduledJob) {
    if (!scheduledJob) {
        return null
    }
    let oScheduledJob = new Scheduledjob(
        scheduledJob.jobScheduleID,
        scheduledJob.jobScheduleGuid,
        scheduledJob.addressGuid,
        scheduledJob.addressBranchGuid,
        scheduledJob.addressDeviceGuid,
        scheduledJob.workOrderGuid,
        scheduledJob.workOrderTeamGuid,
        scheduledJob.serviceTypeGuid,
        scheduledJob.statusGuid,
        scheduledJob.scheduledStartDate,
        scheduledJob.scheduledFinishDate,
        scheduledJob.siteNotes,
        scheduledJob.isCallOut,
        scheduledJob.requiredManHours,
        scheduledJob.healthSafetyTime,
        scheduledJob.quantity,
        scheduledJob.companyID,
        scheduledJob.regionID,
        (typeof scheduledJob.sent === 'string' && scheduledJob.sent === 'true') || (typeof scheduledJob.sent === 'boolean' && scheduledJob.sent) ? 'true' : 'false',
        scheduledJob.mode ? scheduledJob.mode : '',
    )
    return oScheduledJob
}
async function populateScheduledJobLinkedObjects(oScheduledjob) {
    if(!oScheduledjob){
        return oScheduledjob
    }
    try {
        let proms = []
        proms.push(getObjectVehicles(['objectType', 'objectGuid', 'active'], ['Scheduledjob', oScheduledjob.jobScheduleGuid, 'true']));
        proms.push(getObjectUsers(['objectType', 'objectGuid', 'active'], ['Scheduledjob', oScheduledjob.jobScheduleGuid, 'true']));
        proms.push(getObjectEvents(['objectType', 'objectGuid', 'active'], ['Scheduledjob', oScheduledjob.jobScheduleGuid, 'true']));
        proms.push(getFullReportData({ 'objectType': 'ScheduledJob', 'objectGuid': oScheduledjob.jobScheduleGuid, 'active': 'true' }));
        proms.push(getLoadedWastes(['addressGuid', 'deleted', 'scheduledJobGuid', 'active'], [oScheduledjob.addressGuid, 'false', oScheduledjob.jobScheduleGuid, 'true']));
        proms.push(getFullAddressDevice({ 'addressDeviceGuid': oScheduledjob.addressDeviceGuid }));
        proms.push(getServiceType({ 'serviceTypeGuid': oScheduledjob.serviceTypeGuid }));
        proms.push(getAddress({ 'addressGuid': oScheduledjob.addressGuid }));

        const results = await Promise.all(proms);
        oScheduledjob.objectVehicles = results.at(0)
        oScheduledjob.objectUsers = results.at(1)
        oScheduledjob.objectEvents = results.at(2)
        oScheduledjob.reportData = results.at(3)
        oScheduledjob.loadedWastes = results.at(4)
        oScheduledjob.addressDevice = results.at(5)
        oScheduledjob.serviceType = results.at(6)
        oScheduledjob.address = results.at(7)
        
        return oScheduledjob;
    } catch (error) {
        await putError(error, oScheduledjob, 'ServiceType.js', 'populateScheduledJobLinkedObjects')
        return oScheduledjob
    }
}

async function getScheduledJob_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const scheduledJob = await db.scheduled_jobs.get(keyValueObject)
    let oScheduledjob = populateObject_ScheduledJob(scheduledJob)
    return oScheduledjob
}

async function getScheduledJobs_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const scheduledJobs = await db.scheduled_jobs
        .where(where)
        .equals(equals)
        .toArray();
    let oScheduledJobs = scheduledJobs.map((scheduledJob) => { return populateObject_ScheduledJob(scheduledJob) })
    return oScheduledJobs
}

async function putScheduledJob_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.scheduled_jobs.put(oData, oData.id);
    return;
}

async function deleteScheduledJobs_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.scheduled_jobs
        .where(where)
        .equals(equals)
        .delete();
    return;
}


export async function getFullScheduledJob(keyValueObject) {
    try {
        const oScheduledJob = await getScheduledJob(keyValueObject)
        return populateScheduledJobLinkedObjects(oScheduledJob)
    }
    catch (error) {
        await putError(error,keyValueObject,'ScheduledJob.js','getFullScheduledJob')  
        return Promise.resolve(null)
    }
}

export async function getFullScheduledJobs(where, equals) {
    try {
        const oScheduledJobs = await getScheduledJobs(where, equals)
        let proms = oScheduledJobs.map((oScheduledJob) => { return populateScheduledJobLinkedObjects(oScheduledJob) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'ScheduledJob.js','getFullScheduledJobs')  
        return Promise.resolve([]);
    }
}

export async function getScheduledJob(keyValueObject) {
    try {
        let oData = await getScheduledJob_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ScheduledJob.js', 'getScheduledJob')
        return
    }
}

export async function getScheduledJobs(where, equals) {
    try {
        let oData = await getScheduledJobs_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ScheduledJob.js', 'getScheduledJobs')
        return
    }
}

export async function putScheduledJob(oData) {
    try {
        await putScheduledJob_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ScheduledJob.js', 'putScheduledJob')
        return
    }
}

export async function deleteScheduledJobs(where, equals) {
    try {
        await deleteScheduledJobs_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ScheduledJob.js', 'deleteScheduledJobs')
        return
    }
}
