import { getAuthenticatedUserGuid } from '../Utils/AuthUtils' 
import Api from './Api.jsx'; 
import { populateObject_DeviceProperty } from '../DB/Device/DeviceProperty'
import { putError } from '../DB/Error/Error.js';

export function getDeviceProperties_API() {
    const userGuid = getAuthenticatedUserGuid() 
    return Api.get('/DeviceProperties/getDeviceProperties', {
        params: {
            UserGuid: userGuid, 
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_DeviceProperty(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid, 
                    },
                    'DeviceProperty',
                    'getDeviceProperties_API'
                )
                return Promise.reject(error)
            }
        )         
}