import { getAuthenticatedUserGuid } from '../Utils/AuthUtils' 
import Api from './Api.jsx'; 
import { populateObject_DumpDocket } from '../DB/DumpDocket/DumpDocket';
import { putError } from '../DB/Error/Error.js';

export function upsertDumpDocket_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.active = object.active === 'true'
    object.deleted = object.deleted === 'true'
    object.isComplete = object.isComplete === 'true'
    return Api.post('/DumpDocket/upsertDumpDocket',
        {
            Dto:JSON.stringify(object),
            UserGuid: userGuid
        }
    )
    .then(res => { 
        let data = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        data.sent = 'true'
        let oData = populateObject_DumpDocket(data)
        return oData; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'DumpDocket',
                    'upsertDumpDocket_API'
                )
                return Promise.reject(error)
            }
        )
}