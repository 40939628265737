import { db } from "../db";
import { putError } from "../Error/Error";
import { PrestartTask } from "../../Model/Prestart/PrestartTask";
import { getObjectSignature } from "../Signature/ObjectSignature";
import { getUser } from "../User/User";

export function populateObject_PrestartTask(data) {
    if (!data) {
        return null
    }
    let oData = new PrestartTask( 
        data.prestartTaskGuid,
        data.prestartTaskID,
        data.prestartMeetingGuid,
        data.userID, 
        data.prestartTaskTypeGuid,
        data.prestartTaskTypeID,
        data.name,
        data.order,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false', 
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.mode,
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID
    )
    return oData
}

async function populatePrestartTaskLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try{
        let proms = []
        proms.push(getObjectSignature([ 'objectType','objectGuid', 'active'], ['PrestartTask',oData.prestartTaskGuid, 'true'])) 
        proms.push(getUser({userID:oData.userID})) 
        const results = await Promise.all(proms);
        oData.signature = results.at(0); 
        oData.user = results.at(1);  
        return oData
    }
    catch(error){
        await putError(error,oData,'PrestartMeeting.js','populatePrestartTaskLinkedObjects') 
        return oData
    } 
}


async function getPrestartTask_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.prestart_tasks.get(keyValueObject);
    const oData = populateObject_PrestartTask(data)
    return oData
}

async function getPrestartTasks_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.prestart_tasks
        .where(where)
        .equals(equals)
        .toArray();
    const oData = data.map((x) => { return populateObject_PrestartTask(x) });
    return oData
}

async function putPrestartTask_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.prestart_tasks.put(oData, oData.id);
    return;
}

async function deletePrestartTasks_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.prestart_tasks
        .where(where)
        .equals(equals)
        .delete();
    return;
}


export async function getPrestartTask(keyValueObject) {
    try {
        let oData = await getPrestartTask_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'PrestartTask.js', 'getPrestartTask')
        return
    }
}

export async function getFullPrestartTask(keyValueObject) {
    try {
        let oData = await getPrestartTask(keyValueObject);
        oData = await populatePrestartTaskLinkedObjects(oData)
        return oData
    }
    catch (error) {
        await putError(error,keyValueObject,'Address.js','getFullAddress') 
        return Promise.resolve(null);
    }
}

export async function getPrestartTasks(where, equals) {
    try {
        let oData = await getPrestartTasks_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'PrestartTask.js', 'getPrestartTasks')
        return
    }
}

export async function getFullPrestartTasks(where, equals) {
    try {
        const oData = await getPrestartTasks(where, equals)
        let proms = oData.map((x) => { return populatePrestartTaskLinkedObjects(x) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error,{where, equals},'PrestartTask.js','getFullPrestartTasks')  
        return Promise.resolve([]);
    }
}

export async function putPrestartTask(oData) {
    try {
        await putPrestartTask_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'PrestartTask.js', 'putPrestartTask')
        return
    }
}

export async function deletePrestartTasks(where, equals) {
    try {
        await deletePrestartTasks_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'PrestartTask.js', 'deletePrestartTasks')
        return
    }
}
