import { upsertObjectUser_API } from "../../API/User";
import { getObjectUsers, putObjectUser } from "../../DB/User/ObjectUser";
import { putError } from "../../DB/Error/Error"; 
 
export async function syncToApiObjectUsers(){
    try{
        const dataToApi = await getObjectUsers('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertObjectUser_API(data)}));  
        if(!dataFromApi){
            return;
        }
        await Promise.all(dataFromApi.map((data)=>{return putObjectUser(data)})); 
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiObjectUsers'}, 'Sync/ToAPI/ObjectUsers', 'syncToApiObjectUsers')
        return Promise.resolve();
    }
}