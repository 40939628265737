import { db } from "../db";
import { putError } from "../Error/Error";
import Equipment from '../../Model/Equipment/Equipment'
export function populateObject_Equipment(data) {
    if (!data) {
        return null
    }

    let oEquipment = new Equipment(
        data.equipmentID,
        data.equipmentGuid,
        data.identifier,
        data.equipmentCode,
        data.description,
        data.notes,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    )

    return oEquipment;

}

async function getEquipment_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const equipment = await db.equipment.get(keyValueObject);
    let oEquipment = populateObject_Equipment(equipment);
    return oEquipment;
}

async function getEquipments_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const equipments = await db.equipment
        .where(where)
        .equals(equals)
        .toArray();
    const oEquipments = equipments.map((equipment) => { return populateObject_Equipment(equipment); });
    return oEquipments
}

async function putEquipment_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.equipment.put(oData, oData.id);
    return;
}

async function deleteEquipments_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.equipment
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getEquipment(keyValueObject) {
    try {
        let oData = await getEquipment_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'Equipment.js', 'getEquipment')
        return
    }
}

export async function getEquipments(where, equals) {
    try {
        let oData = await getEquipments_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'Equipment.js', 'getEquipments')
        return
    }
}

export async function putEquipment(oData) {
    try {
        await putEquipment_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'Equipment.js', 'putEquipment')
        return
    }
}

export async function deleteEquipments(where, equals) {
    try {
        await deleteEquipments_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'Equipment.js', 'deleteEquipments')
        return
    }
}
