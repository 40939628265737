import { getMobileStatuses_API, getStatusType_API } from '../../API/Status'
import { putError } from "../../DB/Error/Error";
import { getStatus, putStatus } from "../../DB/Status/Status";
import { getStatusType, putStatusType } from "../../DB/Status/StatusType";
export async function syncFromApiStatuses(){
    let promises = []
    try{
        promises.push(syncFromApiStatus_StatusTypes()); 
        promises.push(syncFromApiStatus_Statuses()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiStatus'}, 'Sync/FromAPI/Status', 'syncFromApiStatus')
        return Promise.resolve()
    } 
}

async function syncFromApiStatus_PutStatusType(data){
    let oData =  await getStatusType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putStatusType(data)
}

async function syncFromApiStatus_StatusTypes(){
    try{
        const dataFromApi = await getStatusType_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiStatus_PutStatusType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiStatus_StatusType'},  'Sync/FromAPI/Status', 'syncFromApiStatus')
        return Promise.resolve()
    } 
}

async function syncFromApiStatus_PutStatus(data){
    let oData =  await getStatus({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putStatus(data)
}

async function syncFromApiStatus_Statuses(){
    try{
        const dataFromApi = await getMobileStatuses_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiStatus_PutStatus(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiStatus_Statuses'},  'Sync/FromAPI/Status', 'syncFromApiStatus')
        return Promise.resolve() 
    } 
}