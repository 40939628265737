import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import Api from './Api.jsx'; 
import { putError } from '../DB/Error/Error.js';
import { populateObject_HazardInfo } from '../DB/Hazard/HazardInfo.js';
import { populateObject_HazardControl } from '../DB/Hazard/HazardControl.js';
import { populateObject_HazardSubstance } from '../DB/Hazard/HazardSubstance.js';


export function getHazardInfos_API(){
    const userGuid = getAuthenticatedUserGuid();
    return Api.get('/Hazard/getHazardInfos', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_HazardInfo(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
           async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Hazards',
                    'getHazardInfos_API'
                ) 
                return Promise.reject(error)
            }
        )         

}

export function getHazardControls_API(){
    const userGuid = getAuthenticatedUserGuid();
    return Api.get('/Hazard/getHazardControls', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_HazardControl(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
           async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Hazards',
                    'getHazardControls_API'
                ) 
                return Promise.reject(error)
            }
        )         

}

export function getHazardSubstances_API(){
    const userGuid = getAuthenticatedUserGuid();
    return Api.get('/Hazard/getHazardSubstances', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_HazardSubstance(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
           async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Hazards',
                    'getHazardSubstances_API'
                ) 
                return Promise.reject(error)
            }
        )         

}