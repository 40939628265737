import BaseObject from "../Base/BaseObject"; 
export default class Equipment extends BaseObject {
    constructor( 
        equipmentID,
        equipmentGuid,
        identifier,
        equipmentCode,
        description,
        notes,
        active,
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            equipmentID,
            equipmentGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.equipmentID = equipmentID;
        this.equipmentGuid = equipmentGuid;
        this.identifier = identifier;
        this.equipmentCode = equipmentCode;
        this.description = description;
        this.notes = notes;
        this.objectEvents = [];
        this.objectMarks = [];
        this.objectMeasures = [];
        this.objectCodes = [];
    } 

} 