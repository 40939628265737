import { db } from "../db";
import { putError } from "../Error/Error";
import { SupplierItem } from "../../Model/Supplier/SupplierItem";
import DataSource from 'devextreme/data/data_source';

export function populateObject_SupplierItem(data) {
    if (!data) {
        return null;
    }
    let oSupplierItem = new SupplierItem(
        data.supplierItemID,
        data.supplierItemGuid,
        data.supplierID,
        data.poNumber,
        data.itemID,
        data.itemGuid,
        data.itemSystemName,
        data.itemName,
        data.itemCode,
        data.itemDescription,
        data.htmlCode,
        data.itemTypeID,
        data.itemTypeGuid,
        data.itemType,
        data.itemTypeSystemType,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    )
    return oSupplierItem;
}

async function getSupplierItem_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const supplierItem = await db.supplier_items.get(keyValueObject);
    let oSupplierItem = populateObject_SupplierItem(supplierItem) 
    return oSupplierItem
}

async function getSupplierItems_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const supplierItems = await db.supplier_items
        .where(where)
        .equals(equals)
        .toArray();
    const oSupplierItems = supplierItems.map((supplierItem)=>{return populateObject_SupplierItem(supplierItem)})   
    return oSupplierItems
}

async function putSupplierItem_db(oData) {
    if (!oData.id) {
        return Promise.resolve()
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.supplier_items.put(oData, oData.id);
    return;
}

async function deleteSupplierItems_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.supplier_items
        .where(where)
        .equals(equals)
        .delete();
        return;
}

export async function getSupplierItem(keyValueObject) {
    try {
        let oData = await getSupplierItem_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'SupplierItem.js', 'getSupplierItem')
        return
    }
}

export async function getSupplierItems(where, equals) {
    try {
        let oData = await getSupplierItems_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'SupplierItem.js', 'getSupplierItems')
        return
    }
}

export async function putSupplierItem(oData) {
    try {
        await putSupplierItem_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'SupplierItem.js', 'putSupplierItem')
        return
    }
}

export async function deleteSupplierItems(where, equals) {
    try {
        await deleteSupplierItems_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'SupplierItem.js', 'deleteSupplierItems')
        return
    }
}


export async function getLookupDataSourceSupplierItems(where, equals, addEmpty = false) {
    try{
        const oSupplierItems = await getSupplierItems(where, equals)
        let oData =  oSupplierItems.map(function (x) { return { value: x.supplierItemID, text: x.itemCode } });
        if (addEmpty) {
            oData.unshift({ value: "", text: "" })
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource        
    }
    catch(error){
        await putError(error, { where, equals }, 'SupplierItem.js', 'getLookupDataSourceSupplierItems')
        return 
    } 
}