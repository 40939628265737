import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_MarkType } from '../DB/Mark/MarkType';
import { populateObject_ObjectMark } from '../DB/Mark/ObjectMark';
import { putError } from '../DB/Error/Error.js';

export function getMarkTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Mark/getMarkTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_MarkType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(

            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                    },
                    'Mark',
                    'getMarkTypes_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getObjectMarks_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Mark/getObjectMarks', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_ObjectMark(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'Mark',
                    'getObjectMarks_API'
                )
                return Promise.reject(error)
            }
        )
}

export function upsertObjectMark_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.active = object.active === 'true';
    if (typeof object.markValue === 'boolean') {
        object.markValue = object.markValue === true ? 'true' : 'false'
    }
    return Api.post('/Mark/upsertObjectMark',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oData = populateObject_ObjectMark(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Mark',
                    'upsertObjectMark_API'
                )
                return Promise.reject(error)
            }
        )
}