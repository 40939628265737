import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import Api from './Api.jsx'; 
import { populateObject_Flag } from '../DB/Flag/Flag'
import { putError } from '../DB/Error/Error.js';

export function getFlags_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Flag/getFlags', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_Flag(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
           async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Flags',
                    'getFlags_API'
                ) 
                return Promise.reject(error)
            }
        )         
}