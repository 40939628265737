import { db } from "../db";
import { putError } from "../Error/Error";
import { VehicleType } from '../../Model/Vehicle/VehicleType'

export function populateObject_VehicleType(vehicleType) {
    if (!vehicleType) {
        return null
    }
    let oVehicleType = new VehicleType(
        vehicleType.vehicleTypeID,
        vehicleType.vehicleTypeGuid,
        vehicleType.type,
        vehicleType.systemType,
        vehicleType.companyID,
        vehicleType.regionID
    )
    return oVehicleType
}

async function getVehicleType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const vehicleType = await db.vehicles_types.get(keyValueObject)
    let oVehicleType = populateObject_VehicleType(vehicleType)
    return oVehicleType
}

async function getAllVehicleTypes_db() {
    const vehicleTypes = await db.vehicles_types.toArray()
    let oVehicleTypes = vehicleTypes.map((vehicleType) => { return populateObject_VehicleType(vehicleType) })
    return oVehicleTypes
}

async function getVehicleTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const vehicleTypes = await db.vehicles_types
        .where(where)
        .equals(equals)
        .toArray();
    let oVehicleTypes = vehicleTypes.map((vehicleType) => { return populateObject_VehicleType(vehicleType) })
    return oVehicleTypes
}

async function putVehicleType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.vehicles_types.put(oData, oData.id);
    return;
}

async function deleteVehicleTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.vehicles_types
        .where(where)
        .equals(equals)
        .delete();
    return;
}
export async function getVehicleType(keyValueObject) {
    try {
        let oData = await getVehicleType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'VehicleType.js', 'getVehicleType')
        return
    }
}

export async function getVehicleTypes(where, equals) {
    try {
        let oData = await getVehicleTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'VehicleType.js', 'getVehicleTypes')
        return
    }
}

export async function getAllVehicleTypes() {
    try {
        let oData = await getAllVehicleTypes_db()
        return oData
    } catch (error) {
        await putError(error, { fn: 'getAllVehicleTypes' }, 'VehicleType.js', 'getVehicleTypes')
        return
    }
}

export async function putVehicleType(oData) {
    try {
        await putVehicleType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'VehicleType.js', 'putVehicleType')
        return
    }
}

export async function deleteVehicleTypes(where, equals) {
    try {
        await deleteVehicleTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'VehicleType.js', 'deleteVehicleTypes')
        return
    }
}
