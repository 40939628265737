import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'  
import Api from './Api.jsx';
import { populateObject_AddressBranch } from '../DB/Address/AddressBranch' 
import { putError } from '../DB/Error/Error.js';

export function getAddressBranchs_API() {
    const userGuid = getAuthenticatedUserGuid() 
    return Api.get('/AddressBranch/getAddressBranchs', {
        params: {
            UserGuid: userGuid, 
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
            let oDataList = []
            dataList.forEach((data) => { 
                data.sent = 'true'
                let oData = populateObject_AddressBranch(data)
                oDataList.push(oData)
            }); 
            return oDataList; 
             
        })        
        .catch(
            async (error) => {
                await putError(
                    error,
                    {UserGuid: userGuid},
                    'AddressBranch',
                    'getAddressBranchs_API'
                )
                return Promise.reject(error)
            }
        ) 
}