import { insertChangeHistory_API } from "../../API/ChangeHistory";
import { getChangeHistories, putChangeHistory } from "../../DB/ChangeHistory/ChangeHistory";
import { putError } from "../../DB/Error/Error";


export async function syncToApiChangeHistory(){
    try{
        const dataToApi = await getChangeHistories('sent','false'); 
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return insertChangeHistory_API(data)}));  
        if(!dataFromApi){
            return;
        }     
        await Promise.all(dataFromApi.map((data)=>{return putChangeHistory(data)})); 
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiChangeHistory'}, 'Sync/ToAPI/ChangeHistory', 'syncToApiChangeHistory')
        return Promise.resolve();
    }
     
}
 