import { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'; 
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Popup } from 'devextreme-react/popup';
import { getLocalStoragePageRout, getLocalStorageLastPageRout, setLocalStorageLastPageRout, setLocalStoragePageRout, getLocalStorageWorkOrderDate} from "../Utils/LocalStorageUtils";
import { getEventType } from "../DB/Event/EventType";
import { getAuthenticatedUser } from "../Utils/AuthUtils";
import { getWorkOrder } from "../DB/WorkOrder/WorkOrder";
import { populateObject_ObjectEvent, putObjectEvent } from "../DB/Event/ObjectEvent";


export const PageContext = createContext();

export const PageProvider = ({ children }) => {
    const [pageChangeCount, setPageChangeCount_] = useState(1);
    const [loadIndicatorVisible, setLoadIndicatorVisible_] = useState(false);
    const [validateCompletePage, setValidateCompletePage_] = useState(false);
    const [pageRout, setPageRout_] = useState(getLocalStoragePageRout()); 
    const [lastPageRout, setLastPageRout_] = useState(getLocalStorageLastPageRout()); 
    const [pageIsValid, setPageIsValid_] = useState(true);
    const [pageIsValiding, setPageIsValiding_] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupText, setPopupText] = useState('');    

    const showInfo = useCallback(
        async (helpText) => {
            setPopupText(helpText)
            setPopupVisible(true);
        },
        [setPopupVisible],
    );

    const hideInfo = useCallback(async () => {
        setPopupVisible(false);
    }, [setPopupVisible]);

    const setPageChangeCount = () => {
        let count = pageChangeCount + 1; 
        setPageChangeCount_(count);
    }

    const setLoadIndicatorVisible = (visible) => {
        setLoadIndicatorVisible_(visible);
    }

    const setValidateCompletePage = (validate) => {
        setValidateCompletePage_(validate);
    }

    const setPageIsValid = (valid) => {
        setPageIsValid_(valid);
    }

    const setPageIsValiding = (isValiding) => {
        setPageIsValiding_(isValiding)
    }

    const setProvidorPageRout = (page) => {
        setLocalStoragePageRout(page)
        setPageRout_(page)
    }

    const setLastPageRout = (page) => {
        setLocalStorageLastPageRout(page)
        setLastPageRout_(page)
    } 

    const raiseEvent = async (eventSystemName, object, eventStartTimeStamp, eventEndTimeStamp) => { 
        const timeStamp = dayjs().format('YYYY-MM-DDTHH:mm:ss');
        const oEventType = await getEventType({eventSystemName:eventSystemName.toLocaleLowerCase()}); 
        const oWorkOrder = await getWorkOrder({'workOrderDate':getLocalStorageWorkOrderDate()})
        const oUser = getAuthenticatedUser(); 
        if(!oEventType){
            return Promise.resolve()
        }

        let objectEventData = {
            objectEventGuid: uuidv4(),
            objectType: object.objectType,
            objectGuid: object.objectGuid,
            referenceGuid: oWorkOrder.workOrderGuid,
            eventID: null,
            eventGuid: uuidv4(),
            eventTimeStamp: timeStamp,
            eventStartTimeStamp: eventStartTimeStamp,
            eventEndTimeStamp: eventEndTimeStamp,
            eventTypeID: oEventType.eventTypeID,
            eventTypeGuid: oEventType.eventTypeGuid,
            eventType: oEventType.eventType,
            eventSystemName: oEventType.eventSystemName,
            userGuid: oUser.userGuid,
            active: 'true',
            createdDate: timeStamp,
            lastModifiedDate: timeStamp,
            companyID: oUser.companyID,
            regionID: oUser.regionID,
            sent: 'false',
            mode: 'Add'
        };

        let oObjectEvent = populateObject_ObjectEvent(objectEventData);
        await putObjectEvent(oObjectEvent);
        return oObjectEvent
    }

    const navigateTo = (page,callbackFN) => {
        let currentPage = pageRout
        switch(currentPage){
            case 'login':
                currentPage = 'login'
                break;
            case 'dumpWaste':
                localStorage.removeItem("dumpDocket");
                localStorage.removeItem("supplier");
                localStorage.removeItem("vehicle");
                localStorage.removeItem("objectUser");    
                break;
            default:
                break;
        }
        setLastPageRout(currentPage);
        setProvidorPageRout(page)
        let rout = '/' + page
        callbackFN(rout, { replace: true });
    }

    const navigateBack = (callbackFN) => {
        let page = lastPageRout
        setLastPageRout(pageRout);
        setProvidorPageRout(page)
        let rout = '/' + page
        callbackFN(rout, { replace: true });
    }

    const setRefField = (option, value, ref) => {
        if (!ref.current) {
            return
        }
        ref.current.instance.option(option, value);
    }

    const getRefField = (option,ref)=>{
        if (!ref.current) {
            return null
        }
        return ref.current.instance.option(option);
    }

    useEffect(() => {
        const fetchData = async () => {
             
        };
        fetchData();
    }, [pageChangeCount, loadIndicatorVisible, validateCompletePage, pageIsValid, pageRout]);

    const contextValue = useMemo(
        () => ({
            pageChangeCount,
            loadIndicatorVisible,
            validateCompletePage,
            pageIsValid,
            pageIsValiding,
            setPageChangeCount,
            setLoadIndicatorVisible,
            setValidateCompletePage,
            setPageIsValid, 
            showInfo,
            setPageIsValiding,
            navigateTo,
            navigateBack,
            setProvidorPageRout,
            raiseEvent,
            setRefField,
            getRefField
        }),
        [pageChangeCount, loadIndicatorVisible, validateCompletePage, pageIsValid, pageIsValiding, pageRout]// eslint-disable-line
    );

    return ( 
        <PageContext.Provider value={contextValue}>
            <LoadIndicator
                id="large-indicator"
                height={60}
                width={60}
                visible={loadIndicatorVisible}
            />
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={"Help"}
                container=".dx-viewport"
                width='95%'
                height='95%'
            >
                <div className="small-12 grid-x height100p">
                    {popupText}
                </div>

            </Popup>
            {children}
        </PageContext.Provider>
    );
};

export const useLocation = () => {
    return useContext(PageContext);
};

export default PageProvider;