import { db } from "../db";
import { putError } from "../Error/Error";
import { UnitType } from "../../Model/UnitType/UnitType";

export function populateObject_UnitType(unitType) {
    if (!unitType) {
        return null
    }
    let oUnitType = new UnitType(
        unitType.unitTypeID,
        unitType.unitTypeGuid,
        unitType.unitType,
        unitType.unitTypeSystemName,
        unitType.unitTypeSymbol,
        unitType.createdDate,
        unitType.lastmodifiedDate,
        unitType.companyID,
        unitType.regionID
    )
    return oUnitType
}

async function getUnitType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const unitType = await db.unit_type.get(keyValueObject)
    let oUnitType = populateObject_UnitType(unitType)
    return oUnitType
}

async function getUnitTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const unitTypes = await db.unit_type
        .where(where)
        .equals(equals)
        .toArray();
    let oUnitTypes = unitTypes.map((unitType) => { return populateObject_UnitType(unitType) });
    return oUnitTypes;
}

async function putUnitType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.unit_type.put(oData, oData.id);
    return;
}

async function deleteUnitTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.unit_type
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getUnitType(keyValueObject) {
    try {
        let oData = await getUnitType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'UnitType.js', 'getUnitType')
        return
    }
}

export async function getUnitTypes(where, equals) {
    try {
        let oData = await getUnitTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'UnitType.js', 'getUnitTypes')
        return
    }
}

export async function putUnitType(oData) {
    try {
        await putUnitType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'UnitType.js', 'putUnitType')
        return
    }
}

export async function deleteUnitTypes(where, equals) {
    try {
        await deleteUnitTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'UnitType.js', 'deleteUnitTypes')
        return
    }
}
