import { db } from "../db";
import { putError } from "../Error/Error";
import { Status } from '../../Model/Status/Status'

export function populateObject_Status(status) {
    if (!status) {
        return null
    }
    let oStatus = new Status(
        status.statusID,
        status.statusGuid,
        status.mobileJobStatusID,
        status.sortOrder,
        status.statusName,
        status.htmlCode,
        status.mobileJobStatusGuid,
        status.mobileJobStatusName,
        status.mobileJobStatusHTMLCode,
        status.statusTypeID,
        status.statusTypeName,
        status.statusTypeGuid,
        status.companyID,
        status.regionID
    );

    return oStatus
}

async function getStatus_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const status = await db.statuses.get(keyValueObject)
    let oStatus = populateObject_Status(status)

    return oStatus
}

async function getStatuses_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const statuses = await db.statuses
        .where(where)
        .equals(equals)
        .toArray();
    let oStatuses = statuses.map((status) => { return populateObject_Status(status) })
    return oStatuses
}

async function putStatus_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.statuses.put(oData, oData.id);
    return;
}

async function deleteStatus_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.statuses
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getStatus(keyValueObject) {
    try {
        let oData = await getStatus_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'Status.js', 'getStatus')
        return
    }
}

export async function getStatuses(where, equals) {
    try {
        let oData = await getStatuses_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'Status.js', 'getStatuss')
        return
    }
}

export async function putStatus(oData) {
    try {
        await putStatus_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'Status.js', 'putStatus')
        return
    }
}

export async function deleteStatus(where, equals) {
    try {
        await deleteStatus_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'Status.js', 'deleteStatuss')
        return
    }
}
