import { db } from "../db";
import { putError } from "../Error/Error";
import { ObjectVehicle } from '../../Model/Vehicle/ObjectVehicle'

export function populateObject_VehicleObject(objectVehicle) {
    if (!objectVehicle) {
        return null
    }
    let oVehicleObject = new ObjectVehicle(
        objectVehicle.objectVehicleGuid,
        objectVehicle.vehicleGuid,
        objectVehicle.objectGuid,
        objectVehicle.referenceGuid,
        objectVehicle.objectType,
        objectVehicle.vehicleID,
        objectVehicle.fireExtinguisherLocation,
        objectVehicle.firstAidKitLocation,
        objectVehicle.description,
        objectVehicle.notes,
        objectVehicle.vehicleCode,
        objectVehicle.registrationNumber,
        objectVehicle.targetDailyWorkValue,
        objectVehicle.maxWeight,
        objectVehicle.truckCapacityVolume,
        objectVehicle.vehicleTypeID,
        objectVehicle.vehicleTypeGuid,
        objectVehicle.type,
        objectVehicle.systemType,
        (typeof objectVehicle.active === 'string' && objectVehicle.active === 'true') || (typeof objectVehicle.active === 'boolean' && objectVehicle.active) ? 'true' : 'false',
        objectVehicle.createdDate,
        objectVehicle.lastModifiedDate,
        objectVehicle.companyID,
        objectVehicle.regionID,
        (typeof objectVehicle.sent === 'string' && objectVehicle.sent === 'true') || (typeof objectVehicle.sent === 'boolean' && objectVehicle.sent) ? 'true' : 'false',
        objectVehicle.mode ? objectVehicle.mode : '',
    )
    return oVehicleObject
}

async function getObjectVehicle_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const objectVehicle = await db.object_vehicles.get(keyValueObject)
    let oVehicleObject = populateObject_VehicleObject(objectVehicle)
    return oVehicleObject
}

async function getObjectVehicles_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const vehicleObjects = await db.object_vehicles
        .where(where)
        .equals(equals)
        .toArray();
    let oVehicleObjects = vehicleObjects.map((objectVehicle) => { return populateObject_VehicleObject(objectVehicle) });
    return oVehicleObjects
}

async function putObjectVehicle_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.object_vehicles.put(oData, oData.id);
    return;
}

async function deleteObjectVehicles_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_vehicles
        .where(where)
        .equals(equals)
        .delete();
    return;
}
export async function getObjectVehicle(keyValueObject) {
    try {
        let oData = await getObjectVehicle_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectVehicle.js', 'getObjectVehicle')
        return
    }
}

export async function getObjectVehicles(where, equals) {
    try {
        let oData = await getObjectVehicles_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectVehicle.js', 'getObjectVehicles')
        return
    }
}

export async function putObjectVehicle(oData) {
    try {
        await putObjectVehicle_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectVehicle.js', 'putObjectVehicle')
        return
    }
}

export async function deleteObjectVehicles(where, equals) {
    try {
        await deleteObjectVehicles_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectVehicle.js', 'deleteObjectVehicles')
        return
    }
}
