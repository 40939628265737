import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx'; 
import { populateObject_Code  } from '../DB/Code/Code';
import { populateObject_CodeType } from '../DB/Code/CodeType';
import { putObjectCode, populateObject_ObjectCode } from '../DB/Code/ObjectCode';
import { putError } from '../DB/Error/Error.js';

export function getCodeTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Code/getCodeTypes', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_CodeType(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
    .catch(
        async (error) => {
            await putError(
                error,
                {
                    UserGuid: userGuid, 
                },
                'CodeType',
                'getCodeTypes_API'
            )
            return Promise.resolve(error)
        }
    ) 
}

export function getCodes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Code/getCodes', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_Code(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
    .catch(
        async (error) => {
            await putError(
                error,
                {
                    UserGuid: userGuid, 
                },
                'Code',
                'getCodes_API'
            )
            return Promise.resolve(error)
        }
    ) 
}

export function getObjectCodes_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Code/getObjectCodes', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_ObjectCode(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
    .catch(
        async (error) => {
            await putError(
                error,
                {
                    UserGuid: userGuid, 
                },
                'ObjectCode',
                'getObjectCodes_API'
            )
            return Promise.resolve(error)
        }
    ) 
}

export function upsertObjectCode_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    if(object.codeID === null){
        object.sent = 'true';
        return putObjectCode(object)
    }

    object.active = object.active === 'true';

    return Api.post('/Code/upsertObjectCode',
        {
            Dto:JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oData = populateObject_ObjectCode(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Code',
                    'upsertObjectCode_API'
                )
                return Promise.resolve()
            }
        )
}