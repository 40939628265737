import BaseObject from "../Base/BaseObject";
export class Supplier extends BaseObject {
    constructor( 
        supplierID,
        supplierGuid,
        name,
        address,
        suburb,
        city,
        notes,
        description,
        additionalCost,
        supplierTypeID,
        supplierTypeGuid,
        type,
        systemType,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            supplierID,
            supplierGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.supplierID = supplierID;
        this.supplierGuid = supplierGuid;
        this.name = name;
        this.address = address;
        this.suburb = suburb;
        this.city = city;
        this.notes = notes;
        this.description = description;
        this.additionalCost = additionalCost;
        this.supplierTypeID = supplierTypeID;
        this.supplierTypeGuid = supplierTypeGuid;
        this.type = type;
        this.systemType = systemType;
        this.coordinates = []
    }

}  