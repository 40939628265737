import { db } from "../db";
import { putError } from "../Error/Error";
import DeviceType from '../../Model/Device/DeviceType'

export function populateObject_DeviceType(data) {
    if (!data) {
        return null
    }
    let oDeviceType = new DeviceType(
        data.deviceTypeID,
        data.deviceTypeGuid,
        data.deviceTypeCode,
        data.deviceTypeName,
        data.deviceTypeSystemName,
        data.deviceColour,
        data.deviceTextColour,
        data.calculationType,
        data.tMPLevel1,
        data.tMPLevel2,
        data.tMPLevel3,
        data.vaultShape,
        data.vaultLength,
        data.vaultWidth,
        data.vaultHeight,
        data.companyID,
        data.regionID
    )
    return oDeviceType;
}

async function getDeviceType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const deviceType = await db.device_type.get(keyValueObject)
    let oDeviceType = populateObject_DeviceType(deviceType)
    return oDeviceType;
}
async function getDeviceTypes_db() {
    const deviceTypes = await db.device_type.toArray();
    const oDeviceTypes = deviceTypes.map((deviceType) => { return populateObject_DeviceType(deviceType) }).sort((a, b) => a.deviceTypeName.localeCompare(b.deviceTypeName));
    return oDeviceTypes;
}

async function putDeviceType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.device_type.put(oData, oData.id);
    return;
}

async function deleteDeviceTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.device_type
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getDeviceType(keyValueObject) {
    try {
        let oData = await getDeviceType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'DeviceType.js', 'getDeviceType')
        return
    }
}

export async function getDeviceTypes(where, equals) {
    try {
        let oData = await getDeviceTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'DeviceType.js', 'getDeviceTypes')
        return
    }
}

export async function putDeviceType(oData) {
    try {
        await putDeviceType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'DeviceType.js', 'putDeviceType')
        return
    }
}

export async function deleteDeviceTypes(where, equals) {
    try {
        await deleteDeviceTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'DeviceType.js', 'deleteDeviceTypes')
        return
    }
}
