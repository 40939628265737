import { db } from "../db";
import { putError } from "../Error/Error";
import { ObjectEquipment } from '../../Model/Equipment/ObjectEquipment'

export function populateObject_ObjectEquipment(data) {
    if (!data) {
        return null
    }
    let oEquipmentObject = new ObjectEquipment(
        data.objectEquipmentGuid,
        data.objectGuid,
        data.referenceGuid,
        data.objectType,
        data.equipmentID,
        data.equipmentGuid,
        data.identifier,
        data.equipmentCode,
        data.description,
        data.notes,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    )

    return oEquipmentObject
}

async function getObjectEquipment_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const equipmentObject = await db.object_events.get(keyValueObject);
    let oEquipmentObject = populateObject_ObjectEquipment(equipmentObject)
    return oEquipmentObject;
}

async function getObjectEquipments_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const equipmentObjects = await db.object_equipments
        .where(where)
        .equals(equals)
        .toArray();
    const oEquipmentObjects = equipmentObjects.map((equipmentObject) => { return populateObject_ObjectEquipment(equipmentObject) })
    return oEquipmentObjects;
}

async function putObjectEquipment_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.object_equipments.put(oData, oData.id);
    return;
}

async function deleteObjectEquipments_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_equipments
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getObjectEquipment(keyValueObject) {
    try {
        let oData = await getObjectEquipment_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectEquipment.js', 'getObjectEquipment')
        return
    }
}

export async function getObjectEquipments(where, equals) {
    try {
        let oData = await getObjectEquipments_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectEquipment.js', 'getObjectEquipments')
        return
    }
}

export async function putObjectEquipment(oData) {
    try {
        await putObjectEquipment_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectEquipment.js', 'putObjectEquipment')
        return
    }
}

export async function deleteObjectEquipments(where, equals) {
    try {
        await deleteObjectEquipments_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectEquipment.js', 'deleteObjectEquipments')
        return
    }
}
