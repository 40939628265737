import BaseObject from "../Base/BaseObject";
export class JobSafetyHazard extends BaseObject {
    constructor(
        jobSafetyHazardID,
        jobSafetyHazardGuid,
        hazardDescription,
        hazardControls,
        riskAssessment,
        unControlledRiskID,
        controlledRiskID,
        jobSafetyCategoryID,
        jobSafetyCategoryGuid,
        categoryName, 
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobSafetyHazardID,
            jobSafetyHazardGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.jobSafetyHazardID = jobSafetyHazardID;
        this.jobSafetyHazardGuid = jobSafetyHazardGuid;
        this.hazardDescription = hazardDescription;
        this.hazardControls = hazardControls;
        this.riskAssessment = riskAssessment;
        this.unControlledRiskID = unControlledRiskID;
        this.controlledRiskID = controlledRiskID;
        this.jobSafetyCategoryID = jobSafetyCategoryID;
        this.jobSafetyCategoryGuid = jobSafetyCategoryGuid;
        this.categoryName = categoryName; 
        this.unControlledRisk = null;
        this.controlledRisk = null;
    }

}  