import { getCoordinates_API } from '../../API/Coordinate'; 
import { getObjectCoordinate, putObjectCoordinate } from '../../DB/Coordinate/ObjectCoordinate';
import { putError } from "../../DB/Error/Error";
export async function syncFromApiCoordinates(){
    let promises = []
    try{
        promises.push(syncFromApiCoordinate_Coordinates()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiCoordinate'}, 'Sync/FromAPI/Coordinate', 'syncFromApiCoordinate')
        return Promise.resolve()
    } 
}

async function syncFromApiCoordinate_PutObjectCoordinate(data){
    let oData = await getObjectCoordinate({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putObjectCoordinate(data)
}

async function syncFromApiCoordinate_Coordinates(){
    try{
        const dataFromApi = await getCoordinates_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiCoordinate_PutObjectCoordinate(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncFromApiCode_Coordinates'}, 'Sync/FromAPI/Coordinate', 'syncFromApiCoordinate')
        return Promise.resolve() 
    }
}