import { getServiceTypes_API} from '../../API/ServiceType' 
import { putError } from "../../DB/Error/Error";
import { getServiceType, putServiceType } from "../../DB/ServiceType/ServiceType";
export async function syncFromApiServices(){
    let promises = []
    try{
        promises.push(syncFromApiService_ServiceTypes()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiService'}, 'Sync/FromAPI/Service', 'syncFromApiService')
        return Promise.resolve()
    } 
}

async function syncFromApiService_PutServiceType(data){
    let oData =  await getServiceType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putServiceType(data)
}

async function syncFromApiService_ServiceTypes(){ 
    try{
        const dataFromApi = await getServiceTypes_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiService_PutServiceType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiService_ServiceTypes'}, 'Sync/FromAPI/Service', 'syncFromApiService')
        return Promise.resolve()
    } 
}
 