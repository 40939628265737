import { db } from "../db";
import { putError } from "../Error/Error";
import WorkOrderEvent from '../../Model/WorkOrder/WorkOrderEvent'

export function populateObject_WorkOrderEvent(workOrderEvent) {
    if (!workOrderEvent) {
        return null
    }
    let oWorkOrderEvent = new WorkOrderEvent(
        workOrderEvent.workOrderEventID,
        workOrderEvent.workOrderEventGuid,
        workOrderEvent.workOrderTeamGuid,
        workOrderEvent.workOrderID,
        workOrderEvent.eventStartTime,
        workOrderEvent.eventFinishTime,
        workOrderEvent.name,
        workOrderEvent.description,  
        workOrderEvent.addressBranchID,   
        workOrderEvent.companyID,
        workOrderEvent.regionID
    )
    return oWorkOrderEvent;
}

async function getWorkOrderEvent_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const workOrderEvent = await db.work_order_events.get(keyValueObject)
    let oWorkOrderEvent = populateObject_WorkOrderEvent(workOrderEvent);
    return oWorkOrderEvent;
}

async function getWorkOrderEvents_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const workOrderEvents = await db.work_order_events
        .where(where)
        .equals(equals)
        .toArray();
    let oWorkOrderEvents = workOrderEvents.map((workOrderEvent) => { return populateObject_WorkOrderEvent(workOrderEvent) });
    return oWorkOrderEvents;
}

async function putWorkOrderEvent_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.work_order_events.put(oData, oData.id);
    return
}

async function deleteWorkOrderEvents_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.work_order_events
        .where(where)
        .equals(equals)
        .delete();
    return
}

export async function getWorkOrderEvent(keyValueObject) {
    try {
        let oData = await getWorkOrderEvent_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'WorkOrderEvent.js', 'getWorkOrderEvent')
        return
    }
}

export async function getWorkOrderEvents(where, equals) {
    try {
        let oData = await getWorkOrderEvents_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'WorkOrderEvent.js', 'getWorkOrderEvents')
        return
    }
}

export async function putWorkOrderEvent(oData) {
    try {
        await putWorkOrderEvent_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'WorkOrderEvent.js', 'putWorkOrderEvent')
        return
    }
}

export async function deleteWorkOrderEvents(where, equals) {
    try {
        await deleteWorkOrderEvents_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'WorkOrderEvent.js', 'deleteWorkOrderEvents')
        return
    }
}
