import { db } from "../db";
import { putError } from "../Error/Error";
import { ChangeHistory } from '../../Model/ChangeHistory/ChangeHistory'

export function populateObject_ChangeHistory(data) {
    if (!data) {
        return null
    }
    let oChangeHistory = new ChangeHistory(
        data.changeGuid,
        data.userID,
        data.oldValue,
        data.newValue,
        data.objectGuid,
        data.objectType,
        data.property,
        data.time,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    )
    return oChangeHistory
}

async function getChangeHistory_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.change_history.get(keyValueObject);
    const oData = populateObject_ChangeHistory(data)
    return oData;
}

async function getChangeHistories_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const changeHistories = await db.change_history
        .where(where)
        .equals(equals)
        .toArray();
    const oChangeHistories = changeHistories.map((changeHistory)=>{return populateObject_ChangeHistory(changeHistory)})
    return oChangeHistories
}

async function putChangeHistory_db(oChangeHistory) {
    if (!oChangeHistory.id) {
        return Promise.reject('No ID')
    }
    if (typeof oChangeHistory.newValue !== 'string') {
        try {
            oChangeHistory.newValue = oChangeHistory.newValue.toString()
        }
        catch {
            let error = 'Incorect typeof, type is' + typeof oChangeHistory.newValue
            throw(error)
        }
    }
    if (typeof oChangeHistory.oldValue !== 'string') {
        try {
            oChangeHistory.oldValue = oChangeHistory.oldValue.toString()
        }
        catch { 
            let error = 'Incorect typeof, type is' + typeof oChangeHistory.oldValue
            throw(error)
        }
    }
    await db.change_history.put(oChangeHistory, oChangeHistory.id);
    return;
}

async function deleteChangeHistory_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.change_history
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export function raiseChangHistoryRecord(data) {
    let oChangeHistory = populateObject_ChangeHistory(data)
    return putChangeHistory(oChangeHistory)
}

export async function getChangeHistory(keyValueObject) {
    try {
        const oData = await getChangeHistory_db(keyValueObject);
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ChangeHistory.js', 'getChangeHistory')
        return Promise.resolve(null);
    }
}

export async function getChangeHistories(where, equals) {
    try {
        const oData = await getChangeHistories_db(where, equals);
        return oData
    } catch (error) {
        await putError(error, {where, equals}, 'ChangeHistory.js', 'getChangeHistories')
        return Promise.resolve(null);
    }
}

export async function putChangeHistory(oData) {
    try {
        await putChangeHistory_db(oData); 
        return;
    } catch (error) {
        await putError(error, oData, 'ChangeHistory.js', 'putChangeHistory')
        return
    }
}

export async function deleteChangeHistory(where, equals) {
    try {
        await deleteChangeHistory_db(where, equals)
        return
    } catch (error) {
        await putError(error, {where, equals}, 'ChangeHistory.js', 'deleteChangeHistory')
        return
    }
} 