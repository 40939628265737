import BaseObject from "../Base/BaseObject";  
export class HazardFormSubstance extends BaseObject{
    constructor(
        hazardFormSubstanceID,
        hazardFormSubstanceGuid,
        hazardFormGuid,
        hazardSubstanceID,
        hazardSubstanceGuid, 
        name, 
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''   
    ){
        super(
            hazardFormSubstanceGuid,
            hazardFormSubstanceGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode            
        );
        this.hazardFormSubstanceID = hazardFormSubstanceID;
        this.hazardFormSubstanceGuid = hazardFormSubstanceGuid;
        this.hazardFormGuid = hazardFormGuid;
        this.hazardSubstanceID = hazardSubstanceID;
        this.hazardSubstanceGuid = hazardSubstanceGuid; 
        this.name = name;  
    }
}