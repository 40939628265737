import { Fragment } from "react"; 
import { Button } from 'devextreme-react/button';

export const ValidationErrorCard = ({ oErrorList,functions }) => {

    return <>
        <div className="small-12 grid-x errorList heightAuto">
            {
                oErrorList.map((oError, ix) => {
                   return <Fragment key={ix}>
                        <div className='small-12 grid-x heightAuto' key={'errorLine-' + ix}>
                            {oError.errorMessage}
                        </div>
                    </Fragment> 
                })
            }
            <div className="small-12 grid-x left height40px">
                <Button
                    onClick={() => { functions.hideInfo() }}
                    text='Ok'
                    height='40px'
                    width='100%'
                />
            </div>
        </div>
    </>
}
export default ValidationErrorCard;