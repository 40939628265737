import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import Api from './Api.jsx';
import { populateObject_JobRefrence } from '../DB/JobReference/JobReference.js';
import { putError } from '../DB/Error/Error.js';

export function getJobReferences_API(jobType) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/JobReference/getJobReferences', {
        params: {
            UserGuid: userGuid,
            JobType: jobType
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_JobRefrence(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(

            async (error) => {
                await putError(
                    error, {
                    UserGuid: userGuid,
                    JobType: jobType
                },
                    'JobReference',
                    'getJobReferences_API'
                )
                return Promise.reject(error)
            }
        )
}
export function upsertJobReferences_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.active = object.active === 'true';
    return Api.post('/JobReference/upsertJobReference',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true'
                let oObject = populateObject_JobRefrence(data)
                return oObject
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'JobReference',
                    'upsertJobReferences_API'
                )
                return Promise.reject(error)
            }
        )
}