import { getEventTypes_API, getObjectEvents_API } from '../../API/Event' 
import { getEventType, putEventType } from '../../DB/Event/EventType';
import { getObjectEvent, putObjectEvent } from '../../DB/Event/ObjectEvent';
import { putError } from "../../DB/Error/Error";
export async function syncFromApiEvents(){
    let promises = []
    try{
        promises.push(syncFromApiEquipment_EventTypes());
        promises.push(syncFromApiEvent_ObjectEvents());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiEvent'}, 'Sync/FromAPI/Event', 'syncFromApiEvent')
        return Promise.resolve()
    } 
}

async function syncFromApiEquipment_PutEventType(data){
    let oData =  await getEventType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putEventType(data)
}

async function syncFromApiEquipment_EventTypes(){
    try{
        const dataFromApi = await getEventTypes_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiEquipment_PutEventType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiEquipment_EventType'}, 'Sync/FromAPI/Event', 'syncFromApiEvent')
        return Promise.resolve()
    } 
}

async function syncFromApiEquipment_PutObjectEvent(data){
    let oData =  await getObjectEvent({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putObjectEvent(data)
}

async function syncFromApiEvent_ObjectEvents(){  
    try{
        const dataFromApi = await getObjectEvents_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiEquipment_PutObjectEvent(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiEvent_ObjectEvent'}, 'Sync/FromAPI/Event', 'syncFromApiEvent')
        return Promise.resolve()
    } 
}
