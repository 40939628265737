import BaseObject from "../Base/BaseObject";  

export default class ServiceType extends BaseObject {
    constructor(
        serviceTypeID, 
        serviceTypeGuid,
        serviceName, 
        defaultPrice,
        hasDRReport,
        wasteRemoved,  
        companyID,
        regionID 

    ) {
        super(
            serviceTypeID,
            serviceTypeGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.serviceTypeID = serviceTypeID; 
        this.serviceTypeGuid = serviceTypeGuid;
        this.defaultPrice = defaultPrice;
        this.hasDRReport = hasDRReport 
        this.wasteRemoved = wasteRemoved; 
        this.serviceName = serviceName; 

    }      
}