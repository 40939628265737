import BaseObject from "../Base/BaseObject";
export class JobSafetyDeviceReportAction extends BaseObject {
    constructor(
        jobSafetyDeviceReportActionGuid,
        jobSafetyDeviceReportGuid,
        deviceTypeID,
        deviceTypeGuid, 
        hazardDescription,
        hazardControls,
        riskAssessment,
        categoryName,
        unControlledRiskID,
        unControlledScore,
        controlledRiskID,
        controlledScore,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobSafetyDeviceReportActionGuid,
            jobSafetyDeviceReportActionGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.jobSafetyDeviceReportActionGuid = jobSafetyDeviceReportActionGuid;
        this.jobSafetyDeviceReportGuid = jobSafetyDeviceReportGuid;
        this.deviceTypeID = deviceTypeID;
        this.deviceTypeGuid = deviceTypeGuid; 
        this.hazardDescription = hazardDescription;
        this.hazardControls = hazardControls; 
        this.riskAssessment = riskAssessment;
        this.categoryName = categoryName; 
        this.unControlledRiskID = unControlledRiskID;
        this.unControlledScore = unControlledScore; 
        this.controlledRiskID = controlledRiskID;
        this.controlledScore = controlledScore; 
        this.unControlledRisk = null; 
        this.controlledRisk = null;
    }

}  