import { db } from "../db";
import { putError } from "../Error/Error";
import { HazardForm } from "../../Model/HazardForm/HazardForm";
import { getUser } from "../User/User";
import { getStatus } from "../Status/Status";
import { getScheduledJob } from "../Scheduledjob/Scheduledjob";
import { getAddress } from "../Address/Address";
import { getAssemblyPoint } from "../AssemblyPoint/AssemblyPoint";
import { getVehicle } from "../Vehicle/Vehicle";
import { getFullHazardFormHazardInfos } from "./HazardFormHazardInfo";
import { getHazardFormSubstances } from "./HazardFormSubstance";
import { getFullHazardFormUserAcknowledgements } from "./HazardFormUserAcknowledgement";


export function populateObject_HazardForm(data) {
    if (!data) {
        return null
    }
    let oData = new HazardForm(
        data.hazardFormID,
        data.hazardFormGuid,
        data.userID,
        data.statusGuid,
        data.addressID,
        data.jobScheduleID,
        data.supervisorID,
        data.assemblyPointID,
        data.assemblyDescription,
        data.emergencyCentreID,//This is a codeSystemName of codetype EmergencyCentre
        data.vehicleFirstAidKitID,
        data.vehicleFireExtinguisherID,
        data.notifiableWork,
        data.emergencyCentre,
        data.reportDate,
        (typeof data.hasHazardSubstances === 'string' && data.hasHazardSubstances === 'true') || (typeof data.hasHazardSubstances === 'boolean' && data.hasHazardSubstances) ? 'true' : 'false',
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode
    )
    return oData;
}
async function populateHazardFormLinkedObjects(oData) {
    if (!oData) {
        return oData
    }
    try {
        if (oData.userID) {
            oData.user = await getUser({ userID: oData.userID });
        }
        if (oData.statusGuid) {
            oData.status = await getStatus({ statusGuid: oData.statusGuid });
        }
        if (oData.jobScheduleID) {
            oData.scheduledJob = await getScheduledJob({ jobScheduleID: oData.jobScheduleID });
        }
        if (oData.addressID) {
            oData.address = await getAddress({ addressID: oData.addressID });
        }
        if (oData.supervisorID) {
            oData.supervisor = await getUser({ userID: oData.supervisorID });
        }
        if (oData.assemblyPointID) {
            oData.assemblyPoint = await getAssemblyPoint({ assemblyPointID: oData.assemblyPointID });
        }
        if (oData.vehicleFirstAidKitID) {
            oData.vehicleFirstAidKit = await getVehicle({ vehicleID: oData.vehicleFirstAidKitID });
        }
        if (oData.vehicleFireExtinguisherID) {
            oData.vehicleFireExtinguisher = await getVehicle({ vehicleID: oData.vehicleFireExtinguisherID });
        }

        let proms = [];
        proms.push(getFullHazardFormHazardInfos(['hazardFormGuid', 'active'], [oData.hazardFormGuid, 'true']));
        proms.push(getHazardFormSubstances(['hazardFormGuid', 'active'], [oData.hazardFormGuid, 'true']));
        proms.push(getFullHazardFormUserAcknowledgements(['hazardFormGuid', 'active'], [oData.hazardFormGuid, 'true']));

        const results = await Promise.all(proms);
        oData.hazardFormHazardInfos = results.at(0);
        oData.hazardFormSubstances = results.at(1);
        oData.hazardFormUserAcknowledgement = results.at(1);

        return oData
    }
    catch (error) {
        await putError(error, oData, 'HazardForm.js', 'populateHazardFormLinkedObjects')
        return oData
    }
}

async function getHazardForm_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.hazard_forms.get(keyValueObject);
    const oData = populateObject_HazardForm(data)
    return oData;
}

async function getHazardForms_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.hazard_forms
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_HazardForm(object) });
    return oData;
}

async function putHazardForm_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.hazard_forms.put(oData, oData.id);
    return;
}

async function deleteHazardForms_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.hazard_forms
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getHazardForm(keyValueObject) {
    try {
        let oData = await getHazardForm_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'HazardForm.js', 'getHazardForm')
        return
    }
}

export async function getFullHazardFrom(keyValueObject) {
    try {
        let oData = await getHazardForm(keyValueObject);
        oData = await populateHazardFormLinkedObjects(oData)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'HazardForm.js', 'getHazardForm')
        return
    }
}

export async function getHazardForms(where, equals) {
    try {
        let oData = await getHazardForms_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'HazardForm.js', 'getHazardForms')
        return
    }
}

export async function getFullHazardForms(where, equals) {
    try {
        const oData = await getHazardForms(where, equals)
        let proms = oData.map((object) => { return populateHazardFormLinkedObjects(object) })
        const results = await Promise.all(proms)
        return results.flat();
    }
    catch (error) {
        await putError(error, { where, equals }, 'HazardForm.js', 'getFullHazardForms')
        return Promise.resolve([]);
    }
}

export async function putHazardForm(oData) {
    try {
        await putHazardForm_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'HazardForm.js', 'putHazardForm')
        return
    }
}

export async function deleteHazardForms(where, equals) {
    try {
        await deleteHazardForms_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'HazardForm.js', 'deleteHazardForms')
        return
    }
}
