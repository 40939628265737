import BaseObject from "../Base/BaseObject";  

export class ObjectVehicle extends BaseObject {
    constructor(
        objectVehicleGuid,
        vehicleGuid, 
        objectGuid,
        referenceGuid,
        objectType,
        vehicleID,
        fireExtinguisherLocation,
        firstAidKitLocation,
        description,
        notes,
        vehicleCode,
        registrationNumber,
        targetDailyWorkValue,
        maxWeight,
        truckCapacityVolume,
        vehicleTypeID,
        vehicleTypeGuid,
        type,
        systemType, 
        active = 'true',
        createdDate,
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''

    ) {
        super(
            objectVehicleGuid,
            objectVehicleGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.objectVehicleGuid = objectVehicleGuid; 
        this.objectGuid = objectGuid;
        this.objectType = objectType;
        this.vehicleID = vehicleID;
        this.vehicleGuid = vehicleGuid;
        this.vehicleTypeID = vehicleTypeID;
        this.vehicleCode = vehicleCode;
        this.registrationNumber = registrationNumber;
        this.targetDailyWorkValue = targetDailyWorkValue;
        this.maxWeight = maxWeight;
        this.truckCapacityVolume = truckCapacityVolume;
        this.description = description;
        this.notes = notes;
        this.fireExtinguisherLocation = fireExtinguisherLocation;
        this.firstAidKitLocation = firstAidKitLocation;
        this.vehicleTypeID = vehicleTypeID;
        this.vehicleTypeGuid = vehicleTypeGuid;
        this.type = type;
        this.systemType = systemType; 
    } 
}