import { db } from "../db";
import { putError } from "../Error/Error";
import { HazardControl } from "../../Model/Hazard/HazardControl";

export function populateObject_HazardControl(data) {
    if (!data) {
        return null
    }
    let oData = new HazardControl(
        data.hazardControlID,
        data.hazardControlGuid,
        data.hazardInfoID,
        data.hazardInfoGuid,
        data.controlMethod,
        data.controlAction, 
        'true',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        'true',
        ''
    )
    return oData;
}

async function getHazardControl_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.hazard_controls.get(keyValueObject);
    const oData = populateObject_HazardControl(data)
    return oData;
}

async function getHazardControls_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.hazard_controls
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_HazardControl(object) });
    return oData;
}

async function putHazardControl_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.hazard_controls.put(oData, oData.id);
    return;
}

async function deleteHazardControls_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.hazard_controls
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getHazardControl(keyValueObject) {
    try {
        let oData = await getHazardControl_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'HazardControl.js', 'getHazardControl')
        return
    }
}

export async function getHazardControls(where, equals) {
    try {
        let oData = await getHazardControls_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'HazardControl.js', 'getHazardControls')
        return
    }
}

export async function putHazardControl(oData) {
    try {
        await putHazardControl_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'HazardControl.js', 'putHazardControl')
        return
    }
}

export async function deleteHazardControls(where, equals) {
    try {
        await deleteHazardControls_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'HazardControl.js', 'deleteHazardControls')
        return
    }
}
