import BaseObject from "../Base/BaseObject";
export class PrestartTaskType extends BaseObject {
    constructor(
        prestartTaskTypeGuid,
        prestartTaskTypeID,
        name,
        order,
        sent,
        active,
        mode,
        createdDate,
        lastModifiedDate,
        companyID,
        regionID
    ) {
        super(
            prestartTaskTypeID,
            prestartTaskTypeGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.prestartTaskTypeGuid = prestartTaskTypeGuid;
        this.prestartTaskTypeID = prestartTaskTypeID;
        this.name = name;
        this.order = order;
    }
}