import BaseObject from "../Base/BaseObject";  
export class HazardFormUserAcknowledgement extends BaseObject{
    constructor(
        hazardUserAcknowledgementID,
        hazardUserAcknowledgementGuid,
        hazardFormGuid,
        userID,
        visitorName,
        signInDateTime,
        signOutDateTime, 
        hazardAck,
        assemblyPointAck,
        firstAidKitAck,
        fireExtinguisherAck,
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''   
    ){
        super(
            hazardUserAcknowledgementGuid,
            hazardUserAcknowledgementGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode            
        );
        this.hazardUserAcknowledgementID = hazardUserAcknowledgementID;
        this.hazardUserAcknowledgementGuid = hazardUserAcknowledgementGuid; 
        this.hazardFormGuid = hazardFormGuid; 
        this.userID = userID; 
        this.visitorName = visitorName;   
        this.signInDateTime = signInDateTime;
        this.signOutDateTime = signOutDateTime;
        this.hazardAck = hazardAck;
        this.assemblyPointAck = assemblyPointAck;
        this.firstAidKitAck = firstAidKitAck;
        this.fireExtinguisherAck = fireExtinguisherAck
        this.signature = {};
    }
}