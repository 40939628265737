import { db } from "../db";
import { putError } from "../Error/Error";
import CodeType from "../../Model/Code/CodeType";

export function populateObject_CodeType(data) {
    if (!data) {
        return null
    }
    let oCodeType = new CodeType(
        data.codeTypeID,
        data.codeTypeGuid,
        data.codeType,
        data.codeTypeSystemName,
        data.companyID,
        data.regionID
    )
    return oCodeType
}

async function getCodeType_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const codeType = await db.code_type.get(keyValueObject);
    const oCodeType = populateObject_CodeType(codeType);
    return oCodeType
}

async function getCodeTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const codeTypes = await db.code_type
        .where(where)
        .equals(equals)
        .toArray();
    const oCodeTypes = codeTypes.map((codeType) => { return populateObject_CodeType(codeType) })
    return oCodeTypes
}

async function putCodeType_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.code_type.put(oData, oData.id);
    return;
}

async function deleteCodeTypes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.code_type
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getCodeType(keyValueObject) {
    try {
        let oData = await getCodeType_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'CodeType.js', 'getCodeType')
        return
    }
}

export async function getCodeTypes(where, equals) {
    try {
        let oData = await getCodeTypes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'CodeType.js', 'getCodeType')
        return
    }
}

export async function putCodeType(oData) {
    try {
        await putCodeType_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'CodeType.js', 'getCodeType')
        return
    }
}

export async function deleteCodeTypes(where, equals) {
    try {
        await deleteCodeTypes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'CodeType.js', 'getCodeType')
        return
    }
}
