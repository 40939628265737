import { getAuthenticatedUserGuid } from '../Utils/AuthUtils' 
import Api from './Api.jsx';
import { populateObject_AssemblyPoint} from '../DB/AssemblyPoint/AssemblyPoint'
import { putError } from '../DB/Error/Error.js';

export function getAssemblyPoints_API(objectType) {
    const userGuid = getAuthenticatedUserGuid() 
    return Api.get('/AssemblyPoint/getAssemblyPoints', {
        params: {
            UserGuid: userGuid 
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_AssemblyPoint(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid 
                    },
                    'AssemblyPoint',
                    'getAssemblyPoints_API'
                )
                return Promise.reject(error);
            }
        ) 
}
 