
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { OnlineContext } from "./onlineProvider";
import { AuthContext } from "./authProvider";
import { SyncToSource, SyncFromSource, CleanUpIndexDB } from "../Utils/DataSync";// eslint-disable-line
import { putError } from "../DB/Error/Error";

export const SyncStatusContext = createContext(false);

export const SyncStatusProvider = ({ syncing, mode, children }) => {
    // State to hold the authentication token 
    const {isOnline} = useContext(OnlineContext)
    const {isLoggedIn, oAuthUser} = useContext(AuthContext) 
    const [isSyncing, setSyncing_] = useState(syncing);
    const [syncMode, setSyncMode_] = useState(mode);
    const [intervalID , setIntervalID ] = useState(null);

    const setSyncing = (isSyncing) => {
        setSyncing_(isSyncing);
    };

    const setSyncMode = (mode) => {
        setSyncMode_(mode)
    }

    // const syncOccuring = () => { 
    //     let syncing = localStorage.getItem("isSyncing") === 'true';
    //     let mode = localStorage.getItem("syncMode")
    //     console.log('syncing: '+syncing)
    //     console.log('syncMode: '+mode)
    //     setSyncing(syncing);
    //     setSyncMode(mode); 
    // }

    const setSyncCycle = ()=>{
        if (!intervalID) {
            let id = setInterval(async () => {    
                await sync()
            }, (1000 * 30));
            setIntervalID(id)
        }
        else if (intervalID) {
            clearInterval(intervalID) 
            let id = setInterval(async () => {     
                await sync()
            }, (1000 * 30));
            setIntervalID(id)
        }   
    }


    const manualSync = async(callBackFN)=>{
        if (!isSyncing && isOnline) { 
            await CleanUpIndexDB('sent','true')
            await sync(callBackFN);
        }

    }

    const sync = async (callBackFN) => {
        if (!isSyncing && isOnline) {
            setSyncing(true)
            try {
                setSyncMode('SyncToSource')
                await SyncToSource();
            }
            catch (error) {
                await putError(error, { message: 'Catching SyncToSource' }, 'SyncStatusProvider', 'sync')
            }

            try {
                setSyncMode('SyncFromSource')
                await SyncFromSource();
            }
            catch (error) {
                await putError(error, { message: 'Catching SyncFromSource' }, 'SyncStatusProvider', 'sync')
            }

            try { 
                await CleanUpIndexDB(['active','sent'],['false','true']);
            }
            catch (error) {
                await putError(error, { message: 'Catching SyncFromSource' }, 'SyncStatusProvider', 'sync')
            }
            setSyncMode('None')
            setSyncing(false)
            if (typeof callBackFN === 'function') {
                callBackFN()
            }
        }
    }

    // useEffect(() => { 
    //     window.removeEventListener('foxyMobile-syncing', syncOccuring)
    //     window.addEventListener('foxyMobile-syncing', syncOccuring);

    // }, []);// eslint-disable-line

    useEffect(() => {  
        if(!isOnline || isSyncing || !isLoggedIn()){
            if (intervalID) {
                clearInterval(intervalID)  
                setIntervalID(null)
            }          
        }
        else{
            setSyncCycle()  
        }

        },[isOnline,isSyncing,oAuthUser]);// eslint-disable-line

    const contextValue = useMemo(
        () => ({
            isSyncing,
            syncMode,
            sync,
            manualSync
        }),
        [isSyncing, syncMode]// eslint-disable-line
    );

    // Provide the authentication context to the children components
    return (
        <SyncStatusContext.Provider value={contextValue}>{children}</SyncStatusContext.Provider>
    );
};

export const useSyncStatus = () => {
    return useContext(SyncStatusContext);
};

export default SyncStatusProvider;