import BaseObject from "../Base/BaseObject";
export class JobReference extends BaseObject {
    constructor( 
        jobReferenceID,
        jobReferenceGuid,
        jobReference,
        jobTypeID,
        jobTypeGuid,
        systemType,
        type,
        customerID,
        customerGuid,
        customerName,
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobReferenceGuid,
            jobReferenceGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.jobReferenceID = jobReferenceID
        this.jobReferenceGuid = jobReferenceGuid
        this.jobReference = jobReference
        this.jobTypeID = jobTypeID
        this.jobTypeGuid = jobTypeGuid
        this.systemType = systemType
        this.type = type
        this.customerID = customerID
        this.customerGuid = customerGuid
        this.customerName = customerName

    }

}
