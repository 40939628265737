
import { getHazardInfos_API, getHazardControls_API, getHazardSubstances_API } from '../../API/Hazard' 
import { putError } from "../../DB/Error/Error"; 
import { getHazardInfo, putHazardInfo } from '../../DB/Hazard/HazardInfo';
import { getHazardControl, putHazardControl } from '../../DB/Hazard/HazardControl';
import { getHazardSubstance, putHazardSubstance } from '../../DB/Hazard/HazardSubstance';

export async function syncFromApiHazards(){
    let promises = []
    try{
        promises.push(syncFromApiHazard_Infos()); 
        promises.push(syncFromApiHazard_Controls()); 
        promises.push(syncFromApiHazard_Substances());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard'}, 'Sync/FromAPI/Hazard', 'syncFromApiHazard')
        return Promise.resolve()
    } 
}

async function syncFromApiHazard_PutInfo(data){
    let oData =  await getHazardInfo({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putHazardInfo(data)
}

async function syncFromApiHazard_Infos(){ 
    try{
        const dataFromApi = await getHazardInfos_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiHazard_PutInfo(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_Hazard'}, 'Sync/FromAPI/Hazard', 'syncFromApiHazard')
        return Promise.resolve()
    } 
}
 
async function syncFromApiHazard_PutControl(data){
    let oData =  await getHazardControl({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putHazardControl(data)
}

async function syncFromApiHazard_Controls(){ 
    try{
        const dataFromApi = await getHazardControls_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiHazard_PutControl(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from ssyncFromApiHazard_Hazard'}, 'Sync/FromAPI/Hazard', 'syncFromApiHazard')
        return Promise.resolve()
    } 
}

async function syncFromApiHazard_PutSubstance(data){
    let oData =  await getHazardSubstance({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putHazardSubstance(data)
}

async function syncFromApiHazard_Substances(){ 
    try{
        const dataFromApi = await getHazardSubstances_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiHazard_PutSubstance(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiHazard_Substances'}, 'Sync/FromAPI/Hazard', 'syncFromApiHazard')
        return Promise.resolve()
    } 
}