import { getAuthenticatedUserGuid } from '../Utils/AuthUtils' 
import Api from './Api.jsx';
import { populateObject_ChangeHistory } from '../DB/ChangeHistory/ChangeHistory'; 
import { putError } from '../DB/Error/Error.js';

export function insertChangeHistory_API(object) {
    const userGuid = getAuthenticatedUserGuid() 

    return Api.post('/ChangeHistory/insertChangeHistory',
        {
            Dto:JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            async (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data; 
                data.sent ='true' 
                data.active = 'false'
                let oData = populateObject_ChangeHistory(data) 
                return oData;
            }
        )
            .catch(
                async (error) => {
                    await  putError(
                        error,
                        object,
                        'ChangeHistory',
                        'insertChangeHistory_API'
                    )
                    return Promise.resolve()
                }
            )
}