import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_ObjectEquipment, putObjectEquipment } from '../DB/Equipment/ObjectEquipment'
import { populateObject_Equipment } from '../DB/Equipment/Equipment'
import { putError } from '../DB/Error/Error.js';

export function getEquipment_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Equipment/getEquipment', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_Equipment(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Equipment',
                    'getEquipment_API'
                )
                return Promise.reject(error);
            }
        )
}

export function getObjectEquipment_API() {
    const userGuid = getAuthenticatedUserGuid();
    let workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Equipment/getObjectEquipment', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_ObjectEquipment(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'Equipment',
                    'getObjectEquipment_API'
                )
                return Promise.reject(error);
            } 
        )
}

export function upsertObjectEquipment_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.post('/Equipment/upsertObjectEquipment',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oObject = populateObject_ObjectEquipment(data)
                return putObjectEquipment(oObject)
            }
        )
        .catch( 
            async (error) => {
                await putError(
                    error,
                    object,
                    'Equipment',
                    'upsertObjectEquipment_API'
                )
                return Promise.reject(error);
            } 
        )
}