import { upsertObjectMark_API } from "../../API/Mark";
import { getObjectMarks, putObjectMark } from "../../DB/Mark/ObjectMark";
import { putError } from "../../DB/Error/Error"; 

export async function syncToApiObjectMarks(){
    try{
        const dataToApi = await getObjectMarks('sent','false');
        if(!dataToApi){
            return;
        }
        const dataFromApi = await Promise.all(dataToApi.map((data)=>{return upsertObjectMark_API(data)}));  
        if(!dataFromApi){
            return;
        }
        await Promise.all(dataFromApi.map((data)=>{return putObjectMark(data)})); 
        return;
    }
    catch(error){
        await putError(error,{message:'Catch from syncToApiObjectMarks'}, 'Sync/ToAPI/ObjectMark', 'syncToApiObjectMarks')
        return Promise.resolve();
    }
}