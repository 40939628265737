import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_Vehicle } from '../DB/Vehicle/Vehicle'
import { populateObject_VehicleType } from '../DB/Vehicle/VehicleType'
import { populateObject_VehicleObject } from '../DB/Vehicle/ObjectVehicle'
import { putError } from '../DB/Error/Error.js';

export function getVehicles_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Vehicle/getVehicles', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_Vehicle(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error, {
                    UserGuid: userGuid
                },
                    'Vehicle',
                    'getVehicles_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getVehicleTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Vehicle/getVehicleTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_VehicleType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error, {
                    UserGuid: userGuid
                },
                    'Vehicle',
                    'getVehicleTypes_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getObjectVehicles_API() {
    const userGuid = getAuthenticatedUserGuid();
    let workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/Vehicle/getObjectVehicles', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    }).then(res => {
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
        let oDataList = []
        dataList.forEach((data) => {
            data.sent = 'true'
            let oData = populateObject_VehicleObject(data)
            oDataList.push(oData)
        });
        return oDataList;
    })
        .catch(
            async (error) => {
                await putError(
                    error, {
                    UserGuid: userGuid,
                    WorkOrderDate: workOrderDate
                },
                    'Vehicle',
                    'getObjectVehicles_API'
                )
                return Promise.reject(error)
            }
        )
}

export function upsertObjectVehicle_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.active = object.active === 'true';
    return Api.post('/Vehicle/upsertObjectVehicle',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oData = populateObject_VehicleObject(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Vehicle',
                    'upsertObjectVehicle_API'
                )
                return Promise.reject(error)
            }
        )
}