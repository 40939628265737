import Api from './Api';
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { populateObject_WorkOrderTeam } from '../DB/WorkOrder/WorkOrderTeam'
import { putError } from '../DB/Error/Error';

export function getWorkOrderTeams_API() {
    const userGuid = getAuthenticatedUserGuid();
    let workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/WorkOrderTeam/getWorkOrderTeam', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    }).then(res => {
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
        let oDataList = []
        dataList.forEach((data) => {
            data.sent = 'true'
            let oData = populateObject_WorkOrderTeam(data)
            oDataList.push(oData)
        });
        return oDataList;
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'WorkOrderTeam',
                    'getWorkOrderTeams_API'
                )
                return Promise.reject(error)
            }
        )
}
