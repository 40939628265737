import Dexie from 'dexie';  
import { Schema, version } from './Schema'; 
export default class FoxyDB extends Dexie {
    constructor() {
        super('FoxyDB');
        try {
            this.version(version).stores(Schema)
        }
        catch(err) {
            debugger;
        };
    }

}

export  const db = new FoxyDB()

