import { db } from "../db";
import { putError } from "../Error/Error";
import ObjectPhoto from "../../Model/Photo/ObjectPhoto";

export function populateObject_ObjectPhoto(objectPhoto) {
    if (!objectPhoto) {
        return
    }
    let oObjectPhoto = new ObjectPhoto(
        objectPhoto.objectPhotoGuid,
        objectPhoto.image,
        objectPhoto.objectType,
        objectPhoto.objectGuid,
        objectPhoto.referenceGuid,
        objectPhoto.photoTypeID,
        objectPhoto.photoTypeGuid,
        objectPhoto.photoTypeName,
        objectPhoto.photoTypeSystemName,
        (typeof objectPhoto.sent === 'string' && objectPhoto.sent === 'true') || (typeof objectPhoto.sent === 'boolean' && objectPhoto.sent) ? 'true' : 'false',
        (typeof objectPhoto.active === 'string' && objectPhoto.active === 'true') || (typeof objectPhoto.active === 'boolean' && objectPhoto.active) ? 'true' : 'false',
        objectPhoto.mode ? objectPhoto.mode : '',
        objectPhoto.createdDate,
        objectPhoto.lastModifiedDate,
        objectPhoto.companyID,
        objectPhoto.regionID
    );
    return oObjectPhoto;
}

async function getObjectPhoto_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const objectPhoto = await db.object_photo.get(keyValueObject)
    let oObjectPhoto = populateObject_ObjectPhoto(objectPhoto)
    return oObjectPhoto;
}

async function getObjectPhotos_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const objectPhotos = await db.object_photo
        .where(where)
        .equals(equals)
        .toArray();
    const oObjectPhotos = objectPhotos.map((objectPhoto) => { return populateObject_ObjectPhoto(objectPhoto) })
    return oObjectPhotos
}

async function putObjectPhoto_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.object_photo.put(oData, oData.id);
    return
}

async function deleteObjectPhotos_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_photo
        .where(where)
        .equals(equals)
        .delete();
    return;
}


export async function getObjectPhoto(keyValueObject) {
    try {
        let oData = await getObjectPhoto_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectPhoto.js', 'getObjectPhoto')
        return
    }
}

export async function getObjectPhotos(where, equals) {
    try {
        let oData = await getObjectPhotos_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectPhoto.js', 'getObjectPhotos')
        return
    }
}

export async function putObjectPhoto(oData) {
    try {
        await putObjectPhoto_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectPhoto.js', 'putObjectPhoto')
        return
    }
}

export async function deleteObjectPhotos(where, equals) {
    try {
        await deleteObjectPhotos_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectPhoto.js', 'deleteObjectPhotos')
        return
    }
}
