import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_PhotoType } from '../DB/Photo/PhotoType'
import { populateObject_ObjectPhoto } from '../DB/Photo/ObjectPhoto';
import { putError } from '../DB/Error/Error.js';

export function getPhotoTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Photo/getPhotoTypes', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_PhotoType(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'Photo',
                    'getPhotoTypes_API'
                )
                return Promise.reject(error)
            }
        )
}

export function getObjectPhotos_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate()
    return Api.get('/Photo/getObjectPhotos', {
        params: {
            WorkOrderDate: workOrderDate,
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                let oData = populateObject_ObjectPhoto(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        WorkOrderDate: workOrderDate,
                        UserGuid: userGuid
                    },
                    'Photo',
                    'getObjectPhotos_API'
                )
                return Promise.reject(error)
            }
        )
}
export function upsertObjectPhoto_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.active = object.active === 'true'
    return Api.post('/Photo/upsertObjectPhoto',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                let oObject = populateObject_ObjectPhoto(data)
                return oObject
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'Photo',
                    'upsertObjectPhoto_API'
                )
                return Promise.reject(error)
            }
        )
}