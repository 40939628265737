import BaseObject from "../Base/BaseObject";
export default class SignatureType extends BaseObject {
    constructor(
        signatureTypeID,
        signatureTypeGuid,
        signatureTypeName,
        signatureTypeSystemName, 
        createdDate,
        lastModifiedDate,
        companyID,
        regionID
    ) {
        super(
            signatureTypeID,
            signatureTypeGuid,
            'true',
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.signatureTypeID = signatureTypeID;
        this.signatureTypeGuid = signatureTypeGuid;
        this.signatureTypeName = signatureTypeName;
        this.signatureTypeSystemName = signatureTypeSystemName; 

    }
}