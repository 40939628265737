import BaseObject from "../Base/BaseObject";
export default class PhotoType extends BaseObject {
    constructor(
        photoTypeID,
        photoTypeGuid,
        photoTypeName,
        photoTypeSystemName, 
        createdDate,
        lastModifiedDate,
        companyID,
        regionID
    ) {
        super(
            photoTypeID,
            photoTypeGuid,
            'true',
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.photoTypeID = photoTypeID;
        this.photoTypeGuid = photoTypeGuid;
        this.photoTypeName = photoTypeName;
        this.photoTypeSystemName = photoTypeSystemName; 

    }
}