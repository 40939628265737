import { getReportData_API, getReportDefinitions_API} from '../../API/Report' 
import { putError } from "../../DB/Error/Error";
import { getReportDefinition, putReportDefintion } from "../../DB/Reporting/ReportDefinition";
import { getReportData, putReportData } from "../../DB/Reporting/ReportData";

export async function syncFromApiReports(){
    let promises = []
    try{
        promises.push(syncFromApiReport_ReportDefinitions());
        promises.push(syncFromApiReport_ReportData())
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiReport'}, 'Sync/FromAPI/Report', 'syncFromApiReport')
        return Promise.resolve()
    } 
}

async function syncFromApiReport_PutReportDefinition(data){
    let oData =  await getReportDefinition({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putReportDefintion(data)
} 

async function syncFromApiReport_ReportDefinitions(){
    try{
        const dataFromApi = await getReportDefinitions_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiReport_PutReportDefinition(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiReport_ReportDefinitions'},  'Sync/FromAPI/Report', 'syncFromApiReport')
        return Promise.resolve()
    } 
}

async function syncFromApiReport_PutReportData(data){
    let oData =  await getReportData({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putReportData(data)
} 

async function syncFromApiReport_ReportData(){ 
    try{
        const dataFromApi = await getReportData_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiReport_PutReportData(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiReport_ReportData'},  'Sync/FromAPI/Report', 'syncFromApiReport')
        return Promise.resolve()
    } 
}