import { db } from "../db";
import { putError } from "../Error/Error";
import DataSource from 'devextreme/data/data_source';
import AddressDevice from '../../Model/Address/AddressDevice'
import { getObjectCoordinates } from "../Coordinate/ObjectCoordinate";
import { getAddressDeviceProperties } from "./AddressDeviceProperty";

export function populateObject_AddressDevice(data) {
    if (!data) {
        return null
    }
    let oAddressDevice = new AddressDevice(
        data.addressDeviceID,
        data.quantity,
        data.addressGuid,
        data.addressDeviceGuid,
        data.assetID,
        data.comment,
        data.deviceDSMDescription,
        data.assetNumber,
        data.contractNumber,
        data.trafficManagement,
        data.serviceCycleStartYear,
        data.serviceCycleLenght,
        data.deviceTypeID,
        data.deviceTypeGuid,
        data.deviceTypeCode,
        data.deviceTypeName,
        data.deviceTypeSystemName,
        data.deviceColour,
        data.deviceTextColour,
        data.calculationType,
        data.tMPLevel1,
        data.tMPLevel2,
        data.tMPLevel3,
        data.vaultShape,
        data.vaultLength,
        data.vaultWidth,
        data.vaultHeight,
        (typeof data.active === 'string' && data.active === 'true') || (typeof data.active === 'boolean' && data.active) ? 'true' : 'false',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        (typeof data.sent === 'string' && data.sent === 'true') || (typeof data.sent === 'boolean' && data.sent) ? 'true' : 'false',
        data.mode ? data.mode : '',
    )

    return oAddressDevice;
}

async function populateAddressDeviceLinkedObjects(oAddressDevice) {
    if (!oAddressDevice) {
        return oAddressDevice
    }
    try {
        let proms = [];
        proms.push(getObjectCoordinates(['objectType', 'objectGuid'], ['AddressDevice', oAddressDevice.addressDeviceGuid]));
        proms.push(getObjectCoordinates(['objectType', 'objectGuid'], ['AddressDeviceLocation', oAddressDevice.addressDeviceGuid]));
        proms.push(getAddressDeviceProperties('addressDeviceGuid', oAddressDevice.addressDeviceGuid))
        const results = await Promise.all(proms)

        oAddressDevice.coordinates = results.at(0);
        oAddressDevice.coordinates = oAddressDevice.coordinates.concat(results.at(1))
        oAddressDevice.addressDeviceProperties = results.at(2);
        
        return oAddressDevice

    }
    catch (error) {
        await putError(error, oAddressDevice, 'AddressDevice.js', 'populateAddressDeviceLinkedObjects')
        return Promise.resolve(null);

    }
}

async function getAddressDevice_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const addressDevice = await db.address_devices.get(keyValueObject)
    const oAddressDevice = populateObject_AddressDevice(addressDevice)
    return oAddressDevice;
}

async function getAddressDevices_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const addressDevices = await db.address_devices
        .where(where)
        .equals(equals)
        .toArray();
    const oAddressDevices = addressDevices.map((addressDevice) => { return populateObject_AddressDevice(addressDevice) })
    return oAddressDevices
}

async function putAddressDevice_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.address_devices.put(oData, oData.id) 
    return;
}

async function deleteAddressDevice_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.address_devices
        .where(where)
        .equals(equals)
        .delete()
    return;
}


export async function getLookupDataSourceAddressDevices(where, equals, addEmpty = false) {
    try {
        const oAddressDevices = await getAddressDevices_db(where, equals);
        let oData = oAddressDevices.map(function (x) { return { value: x.addressDeviceGuid, text: x.assetID ? x.deviceTypeName + " | " + x.assetID : x.deviceTypeName }; });
        if (addEmpty) {
            oData.unshift({ value: "", text: "" });
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource;
    } catch (error) {
        await putError(error, { where, equals, addEmpty }, 'AddressDevice.js', 'getLookupDataSourceAddressDevices')
        return Promise.resolve(null);
    }
}


export async function getFullAddressDevice(keyValueObject) {
    try {
        let oAddressDevice = await getAddressDevice_db(keyValueObject);
        oAddressDevice = await populateAddressDeviceLinkedObjects(oAddressDevice);
        return oAddressDevice
    } catch (error) {
        await putError(error, keyValueObject, 'AddressDevice.js', 'getFullAddressDevice')
        return Promise.resolve(null);
    }
}

export async function getAddressDevice(keyValueObject) {
    try {
        const oAddressDevice = await getAddressDevice_db(keyValueObject);
        return oAddressDevice
    } catch (error) {
        await putError(error, keyValueObject, 'AddressDevice.js', 'getFullAddressDevice')
        return Promise.resolve(null);
    }
}

export async function getAddressDevices(where, equals) {
    try {
        const oAddressDevices = await getAddressDevices_db(where, equals);
        return oAddressDevices
    } catch (error) {
        await putError(error, { where, equals }, 'AddressDevice.js', 'getAddressDevices')
        return Promise.resolve(null);
    }
}

export async function putAddressDevice(oData) {
    try {
        await putAddressDevice_db(oData);
        return;
    }
    catch (error) {
        await putError(error, oData, 'AddressDevice.js', 'putAddressDevice')
        return
    }
}

export async function deleteAddressDevice(where, equals) {
    try {
        await deleteAddressDevice_db(where, equals);
        return;
    }
    catch (error) {
        await putError(error, { where, equals }, 'AddressDevice.js', 'deleteAddressDevice')
        return
    }
}