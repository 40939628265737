import { db } from "../db";
import { putError } from "../Error/Error";
import WorkOrderTeam from '../../Model/WorkOrder/WorkOrderTeam'
import { getFullScheduledJobs } from "../Scheduledjob/Scheduledjob";
import { getWorkOrder } from "./WorkOrder";
import { getWorkOrderEvents } from "./WorkOrderEvent"
import { getObjectUsers } from "../User/ObjectUser"
import { getObjectVehicles } from "../Vehicle/ObjectVehicle";
import { getObjectEquipments } from "../Equipment/ObjectEquipment";
import { getAddressBranch } from "../Address/AddressBranch";
import { getObjectEvents } from "../Event/ObjectEvent";

export function populateObject_WorkOrderTeam(workOrderTeam) {
    if (!workOrderTeam) {
        return null
    };

    let oWorkOrderTeam = new WorkOrderTeam(
        workOrderTeam.workOrderTeamID,
        workOrderTeam.workOrderTeamGuid,
        workOrderTeam.workOrderGuid,
        workOrderTeam.addressBranchGuid,
        workOrderTeam.teamNo,
        workOrderTeam.createdDate,
        workOrderTeam.lastModifiedDate,
        workOrderTeam.companyID,
        workOrderTeam.regionID
    )
    return oWorkOrderTeam;
}


async function populateWorkOrderTeamLinkedObjects(oWorkOrderTeam) {
    try {
        let proms = []

        proms.push(getObjectVehicles(['objectType', 'objectGuid', 'active'], ['WorkOrderTeam', oWorkOrderTeam.workOrderTeamGuid, 'true']))
        proms.push(getObjectUsers(['objectType', 'objectGuid', 'active'], ['WorkOrderTeam', oWorkOrderTeam.workOrderTeamGuid, 'true']))
        proms.push(getObjectEquipments(['objectType', 'objectGuid', 'active'], ['WorkOrderTeam', oWorkOrderTeam.workOrderTeamGuid, 'true']))
        proms.push(getObjectEvents(['objectType', 'objectGuid', 'active'], ['WorkOrderTeam', oWorkOrderTeam.workOrderTeamGuid, 'true']))
        proms.push(getAddressBranch({ "addressBranchGuid": oWorkOrderTeam.addressBranchGuid }))
        proms.push(getFullScheduledJobs(['workOrderTeamGuid', 'active'], [oWorkOrderTeam.workOrderTeamGuid, 'true']))
        proms.push(getWorkOrderEvents('workOrderTeamGuid', oWorkOrderTeam.workOrderTeamGuid))
        proms.push(getWorkOrder({ "workOrderGuid": oWorkOrderTeam.workOrderGuid }))

        const results = await Promise.all(proms);

        oWorkOrderTeam.objectVehicles = results.at(0)
        oWorkOrderTeam.objectUsers = results.at(1)
        oWorkOrderTeam.objectEquipments = results.at(2)
        oWorkOrderTeam.objectEvents = results.at(3)
        oWorkOrderTeam.addressBranch = results.at(4)
        oWorkOrderTeam.scheduledJobs = results.at(5)
        oWorkOrderTeam.workOrderEvents = results.at(6)
        oWorkOrderTeam.workOrder = results.at(7)
        oWorkOrderTeam.objectStocks = [{}]
        await Promise.all(proms);
    } catch (error) {
        await putError(error, oWorkOrderTeam, 'WorkOrderTeam.js', 'populateWorkOrderTeamLinkedObjects')
    }
    return oWorkOrderTeam;
}


async function getWorkOrderTeam_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const workOrderTeam = await db.work_order_teams.get(keyValueObject)
    let oWorkOrderTeam = populateObject_WorkOrderTeam(workOrderTeam)
    return oWorkOrderTeam;
}


async function getWorkOrderTeams_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const workOrderTeams = await db.work_order_teams
        .where(where)
        .equals(equals)
        .toArray();
    let oWorkOrderTeams = workOrderTeams.map((workOrderTeam) => { return populateObject_WorkOrderTeam(workOrderTeam) });
    return oWorkOrderTeams;
}

async function putWorkOrderTeam_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.work_order_teams.put(oData, oData.id);
    return;
}

async function deleteWorkOrderTeams_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.work_order_teams
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getFullWorkOrderTeam(keyValueObject) {
    try {
        let oWorkOrderTeam = await getWorkOrderTeam(keyValueObject)
        oWorkOrderTeam = await populateWorkOrderTeamLinkedObjects(oWorkOrderTeam)
        return oWorkOrderTeam;
    } catch (error) {
        await putError(error, keyValueObject, 'WorkOrderTeam.js', 'getFullWorkOrderTeams')
        return
    }
}

export async function getFullWorkOrderTeams(where, equals) {
    try {
        const oWorkOrderTeams = await getWorkOrderTeams(where, equals)
        const results = await Promise.all(oWorkOrderTeams.map( (oWorkOrderTeam) => { return populateWorkOrderTeamLinkedObjects(oWorkOrderTeam)}))
        return results.flat();

    } catch (error) {
        await putError(error, {where, equals}, 'WorkOrderTeam.js', 'getFullWorkOrderTeams')
        return
    }
}

export async function getUserWorkOrderTeams(userGuid, oWorkOrder) {
    try {
        const oWorkOrderTeams = await getFullWorkOrderTeams('workOrderGuid', oWorkOrder.workOrderGuid);
        let oUserWorkOrderTeams = [];
        oWorkOrderTeams.forEach(
            (oWorkOrderTeam) => {
                let data = oWorkOrderTeam.objectUsers.filter((x) => { return x.userGuid === userGuid; });
                if (data.length !== 0) {
                    oUserWorkOrderTeams.push(oWorkOrderTeam);
                }
            }
        );
        return oUserWorkOrderTeams;
    } catch (error) {
        await putError(error, {userGuid, oWorkOrder}, 'WorkOrderTeam.js', 'getUserWorkOrderTeams')
        return
    }

}
export async function getWorkOrderTeam(keyValueObject) {
    try {
        let oData = await getWorkOrderTeam_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'WorkOrderTeam.js', 'getWorkOrderTeam')
        return
    }
}

export async function getWorkOrderTeams(where, equals) {
    try {
        let oData = await getWorkOrderTeams_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'WorkOrderTeam.js', 'getWorkOrderTeams')
        return
    }
}

export async function putWorkOrderTeam(oData) {
    try {
        await putWorkOrderTeam_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'WorkOrderTeam.js', 'putWorkOrderTeam')
        return
    }
}

export async function deleteWorkOrderTeams(where, equals) {
    try {
        await deleteWorkOrderTeams_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'WorkOrderTeam.js', 'deleteWorkOrderTeams')
        return
    }
}
