
import BaseObject from "../Base/BaseObject";  
export class ChangeHistory extends BaseObject {
    constructor(
        changeGuid,
        userID,
        oldValue,
        newValue, 
        objectGuid,
        objectType,
        property,
        time,
        active,
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            changeGuid,
            changeGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.changeGuid = changeGuid
        this.userID = userID;
        this.oldValue = oldValue;
        this.newValue = newValue; 
        this.objectGuid = objectGuid;
        this.objectType = objectType;
        this.property = property;
        this.time = time;

    }
}