import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import Api from './Api.jsx'; 
import { populateObject_DeviceType } from '../DB/Device/DeviceType'
import { putError } from '../DB/Error/Error.js';

export function getDeviceTypes_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Device/getDeviceTypes', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_DeviceType(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid, 
                    },
                    'DeviceType',
                    'getDeviceTypes_API'
                )
                return Promise.reject(error)
            }
        )           
}