import { db } from "../db";
import { putError } from "../Error/Error";
import { ObjectEvent } from '../../Model/Event/ObjectEvent'

export function populateObject_ObjectEvent(objectEvent) {
    if (!objectEvent) {
        return null
    }
    let oObjectEvent = new ObjectEvent(
        objectEvent.objectEventGuid,
        objectEvent.objectType,
        objectEvent.objectGuid,
        objectEvent.referenceGuid,
        objectEvent.eventID,
        objectEvent.eventGuid,
        objectEvent.eventTimeStamp,
        objectEvent.eventStartTimeStamp,
        objectEvent.eventEndTimeStamp,
        objectEvent.eventTypeID,
        objectEvent.eventTypeGuid,
        objectEvent.eventType,
        objectEvent.eventSystemName,
        objectEvent.userGuid,
        (typeof objectEvent.active === 'string' && objectEvent.active === 'true') || (typeof objectEvent.active === 'boolean' && objectEvent.active) ? 'true' : 'false',
        objectEvent.createdDate,
        objectEvent.lastModifiedDate,
        objectEvent.companyID,
        objectEvent.regionID,
        (typeof objectEvent.sent === 'string' && objectEvent.sent === 'true') || (typeof objectEvent.sent === 'boolean' && objectEvent.sent) ? 'true' : 'false',
        objectEvent.mode ? objectEvent.mode : '',
    )
    return oObjectEvent;
}

async function getObjectEvent_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const objectEvent = await db.object_events.get(keyValueObject)
    let oObjectEvent = populateObject_ObjectEvent(objectEvent);
    return oObjectEvent;
}

async function getObjectEvents_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const objectEvents = await db.object_events
        .where(where)
        .equals(equals)
        .toArray();
    let oObjectEvents = objectEvents.map((objectEvent) => { return populateObject_ObjectEvent(objectEvent) });
    return oObjectEvents
}

async function putObjectEvent_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.object_events.put(oData, oData.id);
    return
}

async function deleteObjectEvents_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.object_events
        .where(where)
        .equals(equals)
        .delete();
    return
}

export async function getObjectEvent(keyValueObject) {
    try {
        let oData = await getObjectEvent_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'ObjectEvent.js', 'getObjectEvent')
        return
    }
}

export async function getObjectEvents(where, equals) {
    try {
        let oData = await getObjectEvents_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectEvent.js', 'getObjectEvents')
        return
    }
}

export async function putObjectEvent(oData) {
    try {
        await putObjectEvent_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'ObjectEvent.js', 'putObjectEvent')
        return
    }
}

export async function deleteObjectEvents(where, equals) {
    try {
        await deleteObjectEvents_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'ObjectEvent.js', 'deleteObjectEvents')
        return
    }
}


