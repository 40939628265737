import { getAuthenticatedUserGuid } from '../Utils/AuthUtils.jsx'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils.jsx';
import Api from './Api.jsx';
import { putError } from '../DB/Error/Error.js';
import { populateObject_JobSafetyCategory } from '../DB/JobSafetyReport/JobSafetyCategory.js';
import { populateObject_JobSafetyRisk } from '../DB/JobSafetyReport/JobSafetyRisk.js';
import { populateObject_JobSafetyHazard } from '../DB/JobSafetyReport/JobSafetyHazard.js';
import { populateObject_JobSafetyDeviceMapping } from '../DB/JobSafetyReport/JobSafetyDeviceMapping.js';
import { populateObject_JobSafetyReportHeader } from '../DB/JobSafetyReport/JobSafetyReportHeader.js';
import { populateObject_JobSafetyDeviceReport } from '../DB/JobSafetyReport/JobSafetyDeviceReport.js';
import { populateObject_JobSafetyDeviceReportAction } from '../DB/JobSafetyReport/JobSafetyDeviceReportAction.js';
import { populateObject_JobSafetyReportAcknowledgement } from '../DB/JobSafetyReport/JobSafetyReportAcknowledgement.jsx';

export function getJobSafetyCategories_API() {
    const userGuid = getAuthenticatedUserGuid();
    return Api.get('/JobSafetyReport/getJobSafetyCategories', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true';
                data.active = 'true';
                let oData = populateObject_JobSafetyCategory(data);
                oDataList.push(oData);
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'JobSafetyReport',
                    'getJobSafetyCategories_API'
                )
                return Promise.reject(error)
            }
        )

}

export function getJobSafetyRisks_API() {
    const userGuid = getAuthenticatedUserGuid();
    return Api.get('/JobSafetyReport/getJobSafetyRisks', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true';
                data.active = 'true';
                let oData = populateObject_JobSafetyRisk(data);
                oDataList.push(oData);
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'JobSafetyReport',
                    'getJobSafetyRisks_API'
                )
                return Promise.reject(error)
            }
        )

}

export function getJobSafetyHazards_API() {
    const userGuid = getAuthenticatedUserGuid();
    return Api.get('/JobSafetyReport/getJobSafetyHazards', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true';
                data.active = 'true';
                let oData = populateObject_JobSafetyHazard(data);
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'JobSafetyReport',
                    'getJobSafetyHazards_API'
                )
                return Promise.reject(error)
            }
        )

}

export function getJobSafetyDeviceMappings_API() {
    const userGuid = getAuthenticatedUserGuid();
    return Api.get('/JobSafetyReport/getJobSafetyDeviceMappings', {
        params: {
            UserGuid: userGuid
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true';
                data.active = 'true';
                let oData = populateObject_JobSafetyDeviceMapping(data);
                oDataList.push(oData);
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid
                    },
                    'JobSafetyReport',
                    'getJobSafetyHazards_API'
                )
                return Promise.reject(error)
            }
        )

}

export function getJobSafetyReportHeaders_API() {
    const userGuid = getAuthenticatedUserGuid();
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/JobSafetyReport/getJobSafetyReportHeaders', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                data.active = 'true'
                let oData = populateObject_JobSafetyReportHeader(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'JobSafetyReport',
                    'getJobSafetyReportHeaders_API'
                )
                return Promise.reject(error)
            }
        )

}

export function getJobSafetyDeviceReports_API() {
    const userGuid = getAuthenticatedUserGuid();
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/JobSafetyReport/getJobSafetyDeviceReports', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                data.active = 'true'
                let oData = populateObject_JobSafetyDeviceReport(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'JobSafetyReport',
                    'getJobSafetyDeviceReports_API'
                )
                return Promise.reject(error)
            }
        )

}

export function getJobSafetyDeviceReportActions_API() {
    const userGuid = getAuthenticatedUserGuid();
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/JobSafetyReport/getJobSafetyDeviceReportActions', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                data.active = 'true'
                let oData = populateObject_JobSafetyDeviceReportAction(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'JobSafetyReport',
                    'getJobSafetyDeviceReports_API'
                )
                return Promise.reject(error)
            }
        )

}

export function getJobSafetyReportAcknowledgements_API() {
    const userGuid = getAuthenticatedUserGuid();
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/JobSafetyReport/getJobSafetyReportAcknowledgements', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                data.active = 'true'
                let oData = populateObject_JobSafetyReportAcknowledgement(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'JobSafetyReport',
                    'getJobSafetyDeviceReports_API'
                )
                return Promise.reject(error)
            }
        )

}

export function upsertJobSafetyReportHeader_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.post('/JobSafetyReport/upsertJobSafetyReportHeader',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                data.active = 'true'
                let oData = populateObject_JobSafetyReportHeader(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'JobSafetyReport',
                    'upsertJobSafetyReportHeader_API'
                )
                return Promise.resolve()
            }
        )
}

export function upsertJobSafetyDeviceReport_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.post('/JobSafetyReport/upsertJobSafetyDeviceReport',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                data.active = 'true'
                let oData = populateObject_JobSafetyDeviceReport(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'JobSafetyReport',
                    'upsertJobSafetyDeviceReport_API'
                )
                return Promise.resolve()
            }
        )
}

export function upsertJobSafetyDeviceReportAction_API(object) {
    const userGuid = getAuthenticatedUserGuid()

    object.unControlledScore = object.unControlledScore.toString()
    object.controlledScore = object.controlledScore.toString()

    return Api.post('/JobSafetyReport/upsertJobSafetyDeviceReportAction',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                data.active = 'true'
                let oData = populateObject_JobSafetyDeviceReportAction(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'JobSafetyReport',
                    'upsertJobSafetyDeviceReportAction_API'
                )
                return Promise.resolve()
            }
        )
}

export function upsertJobSafetyReportAcknowledgement_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    return Api.post('/JobSafetyReport/upsertJobSafetyReportAcknowledgement',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true';
                data.active = 'true'
                let oData = populateObject_JobSafetyReportAcknowledgement(data)
                return oData
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'JobSafetyReport',
                    'upsertJobSafetyReportAcknowledgement_API'
                )
                return Promise.resolve()
            }
        )
}