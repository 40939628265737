import { getSupplierItems_API, getSuppliers_API } from '../../API/Supplier'
import { putError } from "../../DB/Error/Error";
import { getSupplier, putSupplier } from "../../DB/Supplier/Supplier";
import { getSupplierItem, putSupplierItem } from "../../DB/Supplier/SupplierItem";

export async function syncFromApiSuppliers(){
    let promises = []
    try{
        promises.push(syncFromApiSupplier_Suppliers());
        promises.push(syncFromApiSupplier_SupplierItems());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiSupplier'}, 'Sync/FromAPI/Supplier', 'syncFromApiSupplier')
        return Promise.resolve()
    } 
}

async function syncFromApiSupplier_PutSupplier(data){
    let oData =  await getSupplier({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putSupplier(data)
}

async function syncFromApiSupplier_Suppliers(){
    try{
        const dataFromApi = await getSuppliers_API(); 
        if(!dataFromApi){
            return;
        }
        const putProms = dataFromApi.map((data)=>{return syncFromApiSupplier_PutSupplier(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiSupplier_Suppliers'},  'Sync/FromAPI/Supplier', 'syncFromApiSupplier')
        return Promise.resolve()
    }  
}

async function syncFromApiSupplier_PutSupplierItem(data){
    let oData =  await getSupplierItem({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putSupplierItem(data)
}

async function syncFromApiSupplier_SupplierItems(){
    try{
        const dataFromApi = await getSupplierItems_API(); 
        if(!dataFromApi){
            return;
        }
        const putProms = dataFromApi.map((data)=>{return syncFromApiSupplier_PutSupplierItem(data)});
        await Promise.all(putProms);
        return; 
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiSupplier_PutSupplierItem'}, 'Sync/FromAPI/Supplier', 'syncFromApiSupplier')
        return Promise.resolve()
    } 
}