import { db } from "../db";
import { putError } from "../Error/Error";
import WorkOrder from '../../Model/WorkOrder/WorkOrder'
import { setLocalStorageWorkOrder } from "../../Utils/LocalStorageUtils";

export function populateObject_WorkOrder(workOrder) {
    if (!workOrder) {
        return null
    }
    let oWorkOrder = new WorkOrder(
        workOrder.workOrderID,
        workOrder.workOrderGuid,
        workOrder.workOrderDate,
        workOrder.companyID,
        workOrder.regionID
    )
    return oWorkOrder
}

async function getWorkOrder_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const workOrder = await db.work_orders.get(keyValueObject)
    let oWorkOrder = populateObject_WorkOrder(workOrder)
    return oWorkOrder
}

async function getWorkOrders_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const workOrders = await db.work_orders
        .where(where)
        .equals(equals)
        .toArray();
    let oWorkOrders = workOrders.map((workOrder) => { return populateObject_WorkOrder(workOrder) })
    return oWorkOrders
}

async function putWorkOrder_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    setLocalStorageWorkOrder(oData);
    await db.work_orders.put(oData, oData.id);
    return;
}

async function deleteWorkOrders_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.work_orders
        .where(where)
        .equals(equals)
        .delete();
    return;
}
export async function getWorkOrder(keyValueObject) {
    try {
        let oData = await getWorkOrder_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'WorkOrder.js', 'getWorkOrder')
        return
    }
}

export async function getWorkOrders(where, equals) {
    try {
        let oData = await getWorkOrders_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'WorkOrder.js', 'getWorkOrders')
        return
    }
}

export async function putWorkOrder(oData) {
    try {
        await putWorkOrder_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'WorkOrder.js', 'putWorkOrder')
        return
    }
}

export async function deleteWorkOrders(where, equals) {
    try {
        await deleteWorkOrders_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'WorkOrder.js', 'deleteWorkOrders')
        return
    }
}
