import { db } from "../db";
import { putError } from "../Error/Error";
import { HazardSubstance } from "../../Model/Hazard/HazardSubstance";

export function populateObject_HazardSubstance(data) {
    if (!data) {
        return null
    }
    let oData = new HazardSubstance(
        data.hazardSubstanceID,
        data.hazardSubstanceGuid, 
        data.name, 
        'true',
        data.createdDate,
        data.lastModifiedDate,
        data.companyID,
        data.regionID,
        'true',
        ''
    )
    return oData;
}

async function getHazardSubstance_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const data = await db.hazard_substances.get(keyValueObject);
    const oData = populateObject_HazardSubstance(data)
    return oData;
}

async function getHazardSubstances_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const data = await db.hazard_substances
        .where(where)
        .equals(equals)
        .toArray();
    let oData = data.map((object) => { return populateObject_HazardSubstance(object) });
    return oData;
}

async function putHazardSubstance_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.hazard_substances.put(oData, oData.id);
    return;
}

async function deleteHazardSubstances_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.hazard_substances
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getHazardSubstance(keyValueObject) {
    try {
        let oData = await getHazardSubstance_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'HazardSubstance.js', 'getHazardSubstance')
        return
    }
}

export async function getHazardSubstances(where, equals) {
    try {
        let oData = await getHazardSubstances_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'HazardSubstance.js', 'getHazardSubstances')
        return
    }
}

export async function putHazardSubstance(oData) {
    try {
        await putHazardSubstance_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'HazardSubstance.js', 'putHazardSubstance')
        return
    }
}

export async function deleteHazardSubstances(where, equals) {
    try {
        await deleteHazardSubstances_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'HazardSubstance.js', 'deleteHazardSubstances')
        return
    }
}
