 
import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import Api from './Api.jsx'; 
import { populateObject_Status } from '../DB/Status/Status'
import { populateObject_StatusType } from '../DB/Status/StatusType' 
import { putError } from '../DB/Error/Error.js';

export function getMobileStatuses_API() {
    const userGuid = getAuthenticatedUserGuid() 
    return Api.get('/Status/getMobileStatuses', {
        params: {
            UserGuid: userGuid 
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_Status(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,{
                        UserGuid: userGuid 
                    },
                    'Supplier',
                    'getSupplierItems_API'
                )
                return Promise.reject(error)
            }
        )         
             
}

export function getStatusType_API() {
    const userGuid = getAuthenticatedUserGuid()
    return Api.get('/Status/getStatusTypes', {
        params: {
            UserGuid: userGuid
        },
    })
    .then(res => { 
        let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data; 
        let oDataList = []
        dataList.forEach((data) => { 
            data.sent = 'true'
            let oData = populateObject_StatusType(data)
            oDataList.push(oData)
        }); 
        return oDataList; 
    })
        .catch(
            async (error) => {
                await putError(
                    error,{
                        UserGuid: userGuid 
                    },
                    'Supplier',
                    'getStatusType_API'
                )
                return Promise.reject(error)
            }
        )       
}