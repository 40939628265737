import BaseObject from "../Base/BaseObject";
export class JobSafetyReportAcknowledgement extends BaseObject {
    constructor(
        jobSafetyReportAcknowledgementGuid,
        jobSafetyReportHeaderGuid,
        userID,
        userName, 
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            jobSafetyReportAcknowledgementGuid,
            jobSafetyReportAcknowledgementGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.jobSafetyReportAcknowledgementGuid = jobSafetyReportAcknowledgementGuid;
        this.jobSafetyReportHeaderGuid = jobSafetyReportHeaderGuid;
        this.userID = userID;
        this.userName = userName; 
        this.objectSignature = null
    }

}  