import Api from './Api.jsx';
import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import { populateObject_WorkOrder } from '../DB/WorkOrder/WorkOrder'
import { putError } from '../DB/Error/Error.js';

export function getWorkOrder_API() {
    const userGuid = getAuthenticatedUserGuid();
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/WorkOrder/getWorkOrder', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
            data.sent = 'true'
            let oData = populateObject_WorkOrder(data)
            return oData;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'WorkOrder',
                    'getWorkOrder_API'
                )
                return Promise.reject(error)
            }
        )
}