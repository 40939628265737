
import { getCodeTypes_API, getCodes_API, getObjectCodes_API } from '../../API/Code';
import { getCodeType, putCodeType } from '../../DB/Code/CodeType';
import { getCode, putCode } from '../../DB/Code/Code';
import { getObjectCode, putObjectCode } from '../../DB/Code/ObjectCode';
import { putError } from "../../DB/Error/Error";
export async function syncFromApiCodes(){
    let promises = []
    try{
        promises.push(syncFromApiCode_CodeTypes());
        promises.push(syncFromApiCode_Codes());
        promises.push(syncFromApiCode_ObjectCodes());
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiCode'}, 'Sync/FromAPI/Code', 'syncFromApiCode')
        return Promise.resolve()
    } 
}

async function syncFromApiCode_PutCodeType(data){
    let oData = await getCodeType({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putCodeType(data)
}

async function syncFromApiCode_CodeTypes(){
    try{
        const dataFromApi = await getCodeTypes_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiCode_PutCodeType(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiCode_CodeTypes'}, 'Sync/FromAPI/Code', 'syncFromApiCode')
        return Promise.resolve()
    } 
}

async function syncFromApiCode_PutCode(data){
    let oData = await getCode({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putCode(data)
}

async function syncFromApiCode_Codes(){ 
    try{
        const dataFromApi = await getCodes_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiCode_PutCode(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiCode_Codes'}, 'Sync/FromAPI/Code', 'syncFromApiCode')
        return Promise.resolve()
    }  
}


async function syncFromApiCode_PutObjectCode(data){
    let oData = await getObjectCode({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putObjectCode(data)
}

async function syncFromApiCode_ObjectCodes(){
    try{
        const dataFromApi = await getObjectCodes_API();
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiCode_PutObjectCode(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiCode_ObjectCode'}, 'Sync/FromAPI/Code', 'syncFromApiCode')
        return Promise.resolve()
    }   
}