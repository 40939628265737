
import { getWorkOrder_API } from '../../API/WorkOrder'; 
import { getWorkOrderEvents_API } from '../../API/WorkOrderEvent' 
import { getWorkOrderTeams_API } from '../../API/WorkOrderTeam'    
import { putError } from "../../DB/Error/Error";  
import { getWorkOrder, putWorkOrder } from "../../DB/WorkOrder/WorkOrder";
import { getWorkOrderTeam, putWorkOrderTeam  } from "../../DB/WorkOrder/WorkOrderTeam";
import { getWorkOrderEvent, putWorkOrderEvent  } from "../../DB/WorkOrder/WorkOrderEvent";
export async function syncFromApiWorkOrders(){
    let promises = []
    try{
        promises.push(syncFromApiWorkOrder_WorkOrder()); 
        promises.push(syncFromApiWorkOrder_WorkOrderTeams());
        promises.push(syncFromApiWorkOrder_WorkOrderEvents()); 
        return await Promise.all(promises);
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiWorkOrder'}, 'Sync/FromAPI/WorkOrder', 'syncFromApiWorkOrder')
        return Promise.resolve()
    } 
}

async function syncFromApiWorkOrder_PutWorkOrder(data){
    let oData =  await getWorkOrder({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putWorkOrder(data)
}

async function syncFromApiWorkOrder_WorkOrder(){ 
    try{
        const dataFromApi = await getWorkOrder_API();  
        if(!dataFromApi){
            return;
        } 
        await syncFromApiWorkOrder_PutWorkOrder(dataFromApi)
        return;
    }
    catch(error){ 
        putError(error,{message:'Catch from syncFromApiWorkOrder_WorkOrder'}, 'Sync/FromAPI/WorkOrder', 'syncFromApiWorkOrder')
        return Promise.resolve()
    } 
}

async function syncFromApiWorkOrder_PutWorkOrderTeam(data){
    let oData =  await getWorkOrderTeam({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putWorkOrderTeam(data)
}

async function syncFromApiWorkOrder_WorkOrderTeams(){ 
    try{
        const dataFromApi = await getWorkOrderTeams_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiWorkOrder_PutWorkOrderTeam(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiWorkOrder_WorkOrderTeams'}, 'Sync/FromAPI/WorkOrder', 'syncFromApiWorkOrder')
        return Promise.resolve()
    } 
}
 
async function syncFromApiWorkOrder_PutWorkOrderEvent(data){
    let oData =  await getWorkOrderEvent({id:data.id, sent:'false'})
    if(oData){
        return Promise.resolve()
    }
    return putWorkOrderEvent(data)
}

async function syncFromApiWorkOrder_WorkOrderEvents(){ 
    try{
        const dataFromApi = await getWorkOrderEvents_API(); 
        if(!dataFromApi){
            return;
        } 
        const putProms = dataFromApi.map((data)=>{return syncFromApiWorkOrder_PutWorkOrderEvent(data)});
        await Promise.all(putProms);
        return;
    }
    catch(error){
        putError(error,{message:'Catch from syncFromApiWorkOrder_WorkOrderEvents'}, 'Sync/FromAPI/WorkOrder', 'syncFromApiWorkOrder')
        return Promise.resolve()
    } 
}