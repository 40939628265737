
import { getSignatureTypes_API, getObjectSignatures_API } from '../../API/Signature';
import { putError } from "../../DB/Error/Error";
import { getObjectSignature, putObjectSignature } from '../../DB/Signature/ObjectSignature';
import { getSignatureType, putSignatureType } from "../../DB/Signature/SignatureType";
export async function syncFromApiSignatures() {
    let promises = []
    try {
        promises.push(syncFromApiSignature_SignatureTypes());
        promises.push(syncFromApiPhoto_ObjectSignatures());
        return await Promise.all(promises);
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiSignature' }, 'Sync/FromAPI/Signature', 'syncFromApiSignature')
        return Promise.resolve()
    }
}

async function syncFromApiSignature_PutSignatureType(data) {
    let oData = await getSignatureType({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putSignatureType(data)
}

async function syncFromApiSignature_SignatureTypes() {
    try {
        const dataFromApi = await getSignatureTypes_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiSignature_PutSignatureType(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiSignature_SignatureTypes' }, 'Sync/FromAPI/Signature', 'syncFromApiSignature')
        return Promise.resolve()
    }
}

async function syncFromApiPhoto_PutObjectSignature(data) {
    let oData = await getObjectSignature({ id: data.id, sent: 'false' })
    if (oData) {
        return Promise.resolve()
    }
    return putObjectSignature(data)
}

async function syncFromApiPhoto_ObjectSignatures() {
    try {
        const dataFromApi = await getObjectSignatures_API();
        if (!dataFromApi) {
            return;
        } 
        const putProms = dataFromApi.map((data) => { return syncFromApiPhoto_PutObjectSignature(data) });
        await Promise.all(putProms);
        return;
    }
    catch (error) {
        putError(error, { message: 'Catch from syncFromApiPhoto_ObjectSignature' }, 'Sync/FromAPI/Photo', 'syncFromApiPhoto')
        return Promise.resolve()
    }
}