import BaseObject from "../Base/BaseObject";
export class TransferedWaste extends BaseObject {
    constructor(
        transferedWasteGuid,
        loadedWasteGuidFrom,
        loadedWasteGuidTo,
        percentTransfered,
        userID,
        transferedDate, 
        active = 'true',
        createdDate,
        lastModifiedDate,
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            transferedWasteGuid,
            transferedWasteGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        );
        this.transferedWasteGuid = transferedWasteGuid;
        this.loadedWasteGuidFrom = loadedWasteGuidFrom;
        this.loadedWasteGuidTo = loadedWasteGuidTo;
        this.percentTransfered = percentTransfered;
        this.userID = userID;
        this.transferedDate = transferedDate; 
    }

} 