import { v4 as uuidv4 } from 'uuid';
export default class BaseObject {
    constructor(
        id,
        guid,
        active,
        createdDate,
        createdBy,
        lastModifiedDate,
        lastModifiedBy,
        companyID,
        regionID,
        sent,
        mode
    ) {
        this.id = id;
        this.guid = guid ? guid : uuidv4();
        this.active = active === true || active === 'true' ? 'true' : 'false';
        this.createdDate = createdDate;
        this.createdBy = createdBy;
        this.lastModifiedDate = lastModifiedDate;
        this.lastModifiedBy = lastModifiedBy;
        this.companyID = companyID;
        this.regionID = regionID;
        this.sent = sent === true || sent === 'true' ? 'true' : 'false';
        this.mode = mode;// add/update/delete
    }
}