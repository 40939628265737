import DataSource from 'devextreme/data/data_source';
import { db } from "../db";
import { putError } from "../Error/Error";
import Code from "../../Model/Code/Code";

export function populateObject_Code(data) {
    if (!data) {
        return null
    }
    let oCode = new Code(
        data.codeID,
        data.codeGuid,
        data.code,
        data.codeSystemName,
        data.codeTypeID,
        data.codeTypeGuid,
        data.codeType,
        data.codeTypeSystemName,
        data.companyID,
        data.regionID
    )
    return oCode
}
async function getCode_db(keyValueObject) {
    for ( var i in keyValueObject ) {
        if ( typeof keyValueObject[i] === "undefined") {
           return Promise.reject('undefined value in keyValueObject: '+JSON.stringify(keyValueObject))
        }
    }
    const codeType = await db.code.get(keyValueObject)
    let oCode = populateObject_Code(codeType);
    return oCode
}

async function getCodes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    const codeTypes = await db.code
        .where(where)
        .equals(equals)
        .toArray();
    const oCodeTypes = codeTypes.map((codeType) => { return populateObject_Code(codeType) });
    return oCodeTypes;
}

async function putCode_db(oData) {
    if (!oData.id) {
        return Promise.reject('No ID')
    }
    for ( var i in oData ) {
        if ( typeof oData[i] === "object" && oData[i] !== null ) {
            delete oData[i];
        }
    }
    await db.code.put(oData, oData.id);
    return;
}

async function deleteCodes_db(where, equals) {
    if(typeof equals === 'undefined' || (typeof equals === 'object' && equals.filter((x)=>{return typeof x === 'undefined' }).length > 0)){
        return Promise.reject('undefined value in equals: '+JSON.stringify({"where":where, "equals":equals}))
    }
    await db.code
        .where(where)
        .equals(equals)
        .delete();
    return;
}

export async function getCode(keyValueObject) {
    try {
        let oData = await getCode_db(keyValueObject)
        return oData
    } catch (error) {
        await putError(error, keyValueObject, 'Code.js', 'getCode')
        return
    }
}

export async function getCodes(where, equals) {
    try {
        let oData = await getCodes_db(where, equals)
        return oData
    } catch (error) {
        await putError(error, { where, equals }, 'Code.js', 'getCode')
        return
    }
}

export async function getLookupDataSourceCodes(where, equals, addEmpty = false, value,text) {
    try {
        const objects = await getCodes(where, equals);
        let oData = objects.map(function (x) { return { value: x[value], text: x[text] }; });
        if (addEmpty) {
            oData.unshift({ value: "", text: "" });
        }
        let oDataSource = new DataSource({
            store: oData,
            key: 'value'
        });
        return oDataSource;
    } catch (error) {
        await putError(error, {where, equals, addEmpty}, 'AssemblyPoint.js', 'getLookupDataSourceAssemblyPoints')
        return
    }

}

export async function putCode(oData) {
    try {
        await putCode_db(oData)
        return
    } catch (error) {
        await putError(error, oData, 'Code.js', 'getCode')
        return
    }
}

export async function deleteCodes(where, equals) {
    try {
        await deleteCodes_db(where, equals)
        return
    } catch (error) {
        await putError(error, { where, equals }, 'Code.js', 'getCode')
        return
    }
}
