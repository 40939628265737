import BaseObject from "../Base/BaseObject";  

export class UnitType extends BaseObject {
    constructor(
        unitTypeID,
        unitTypeGuid,
        unitType,
        unitTypeSystemName,
        unitTypeSymbol,
        createdDate,
        lastmodifiedDate,
        companyID,
        regionID
    ) {
        super(
            unitTypeID,
            unitTypeGuid,
            'true',
            createdDate,
            null,
            lastmodifiedDate,
            null,
            companyID,
            regionID,
            'sent',
            ''
        );
        this.unitTypeID = unitTypeID;
        this.unitTypeGuid = unitTypeGuid;
        this.unitType = unitType;
        this.unitTypeSystemName = unitTypeSystemName;
        this.unitTypeSymbol = unitTypeSymbol
    }
}