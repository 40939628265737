import { LoadIndicator } from 'devextreme-react/load-indicator';

export const Loading =()=>{

    return <>
        <div className="small-12 grid-x height100p center">
            <LoadIndicator
                    id="large-indicator"
                    height={60}
                    width={60}
                    visible={true}
                />
        </div>

    </>
}
export default Loading