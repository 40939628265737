import { getAuthenticatedUserGuid } from '../Utils/AuthUtils'
import { getLocalStorageWorkOrderDate } from '../Utils/LocalStorageUtils'
import Api from './Api.jsx';
import { populateObject_LoadedWaste } from '../DB/LoadedWaste/LoadedWaste';
import { putError } from '../DB/Error/Error.js';

export function getLoadedWaste_API() {
    const userGuid = getAuthenticatedUserGuid()
    const workOrderDate = getLocalStorageWorkOrderDate();
    return Api.get('/LoadedWaste/getLoadedWaste', {
        params: {
            UserGuid: userGuid,
            WorkOrderDate: workOrderDate
        },
    })
        .then(res => {
            let dataList = typeof res.data === String ? JSON.parse(res.data) : res.data;
            let oDataList = []
            dataList.forEach((data) => {
                data.sent = 'true'
                data.active = !data.deleted
                let oData = populateObject_LoadedWaste(data)
                oDataList.push(oData)
            });
            return oDataList;
        })
        .catch(
            async (error) => {
                await putError(
                    error,
                    {
                        UserGuid: userGuid,
                        WorkOrderDate: workOrderDate
                    },
                    'LoadedWaste',
                    'getLoadedWaste_API'
                )
                return Promise.reject(error)
            }
        )
}

export function upsertLoadedWaste_API(object) {
    const userGuid = getAuthenticatedUserGuid()
    object.deleted = object.deleted === 'true';
    object.dumped = object.dumped === 'true';
    return Api.post('/LoadedWaste/upsertLoadedWaste',
        {
            Dto: JSON.stringify(object),
            UserGuid: userGuid
        }
    )
        .then(
            (res) => {
                let data = typeof res.data === String ? JSON.parse(res.data) : res.data;
                data.sent = 'true'
                let oObject = populateObject_LoadedWaste(data)
                return oObject
            }
        )
        .catch(
            async (error) => {
                await putError(
                    error,
                    object,
                    'LoadedWaste',
                    'upsertLoadedWaste_API'
                )
                return Promise.reject(error)
            }
        )
}