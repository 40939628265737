import BaseObject from "../Base/BaseObject";  
 
export class AddressDeviceProperty extends BaseObject {
    constructor(
        addressDeviceGuid,
        addressDevicePropertyGuid,
        addressDevicePropertyID,
        value, 
        devicePropertyGuid,
        description,
        control,
        mobileEnabled, 
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''
    ) {
        super(
            addressDevicePropertyGuid,
            addressDevicePropertyGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode
        ); 
        this.mobileEnabled = mobileEnabled;
        this.addressDeviceGuid = addressDeviceGuid;
        this.addressDevicePropertyGuid = addressDevicePropertyGuid;
        this.addressDevicePropertyID = addressDevicePropertyID;
        this.value = value;
        this.devicePropertyGuid = devicePropertyGuid;
        this.description = description;
        this.control = control;
        
    } 

}