import BaseObject from "../Base/BaseObject";  
export class HazardForm extends BaseObject{
    constructor(
        hazardFormID,
        hazardFormGuid,
        userID,
        statusGuid, 
        addressID,
        jobScheduleID,
        supervisorID,
        assemblyPointID,
        assemblyDescription,
        emergencyCentreID,//This is a codeSystemName of codetype EmergencyCentre
        vehicleFirstAidKitID,
        vehicleFireExtinguisherID,
        notifiableWork,
        emergencyCentre, 
        reportDate,
        hasHazardSubstances,
        active = 'true',
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID,
        sent = 'true',
        mode = ''   
    ){
        super(
            hazardFormGuid,
            hazardFormGuid,
            active,
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            sent,
            mode            
        ); 
        this.hazardFormID = hazardFormID;
        this.hazardFormGuid = hazardFormGuid;
        this.userID = userID;
        this.statusGuid = statusGuid; 
        this.addressID = addressID;
        this.jobScheduleID = jobScheduleID;
        this.supervisorID = supervisorID;
        this.assemblyPointID = assemblyPointID;
        this.assemblyDescription = assemblyDescription;
        this.emergencyCentreID = emergencyCentreID;
        this.vehicleFirstAidKitID = vehicleFirstAidKitID;
        this.vehicleFireExtinguisherID = vehicleFireExtinguisherID;
        this.notifiableWork = notifiableWork;
        this.emergencyCentre = emergencyCentre; 
        this.reportDate = reportDate;
        this.hasHazardSubstances = hasHazardSubstances;
        this.user = {};
        this.status = {};
        this.scheduledJob = {};
        this.address = {};
        this.supervisor = {};
        this.assemblyPoint = {};
        this.vehicleFirstAidKit = {};
        this.vehicleFireExtinguisher = {};
        this.hazardFormHazardInfos = [];
        this.hazardFormSubstances = [];
        this.hazardFormUserAcknowledgement = [];
    }
}