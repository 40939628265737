import BaseObject from "../Base/BaseObject";  
export default class ReportDefinition extends BaseObject {
    constructor(  
        reportDefinitionID,
        reportDefinitionGuid,
        reportDefinitionName,
        reportDefinitionSystemName,
        reportDefinition,
        reportDefinitionVersion,
        createdDate, 
        lastModifiedDate, 
        companyID,
        regionID
    ) {
        super(
            reportDefinitionID,
            reportDefinitionGuid,
            'true',
            createdDate,
            null,
            lastModifiedDate,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.reportDefinitionID = reportDefinitionID;
        this.reportDefinitionGuid = reportDefinitionGuid;
        this.reportDefinitionName = reportDefinitionName;
        this.reportDefinitionSystemName = reportDefinitionSystemName;
        this.reportDefinition = reportDefinition;
        this.reportDefinitionVersion = reportDefinitionVersion;

    }
}