import BaseObject from "../Base/BaseObject";   
export default class WorkOrder extends BaseObject {
    constructor(
        workOrderID,
        workOrderGuid, 
        workOrderDate, 
        companyID,
        regionID 

    ) {
        super(
            workOrderID,
            workOrderGuid,
            'true',
            null,
            null,
            null,
            null,
            companyID,
            regionID,
            'true',
            ''
        );
        this.workOrderGuid = workOrderGuid;
        this.workOrderID = workOrderID;
        this.workOrderDate = workOrderDate; 
        this.wordOrderTeams = [];
        this.wordOrderEvents = [];
    }      
     
}
//proms.push(
//    getWorkOrderTeams('workOrderGuid', oWorkOrder.workOrderGuid)
//        .then(
//            (data) => {
//                oWorkOrder.wordOrderTeams = data
//            }
//        )
//);
//proms.push(
//    getWorkOrderEvents('workOrderGuid', oWorkOrder.workOrderGuid)
//        .then(
//            (data) => {
//                oWorkOrder.wordOrderEvents = data
//            }
//        )
//);
